<template>
  <v-card tile :height="defaultHeight" elevation="4" class="mx-auto">
    <v-card-title class="bg-primary white--text">
      Actions
      <v-spacer />
    </v-card-title>

    <!-- Current Action Selection -->
    <MultiOptionAdd 
      :options="ACTION_TYPES" 
      selection-label="Action To Add" 
      button-title="Add New Action" 
      item-text="text" 
      item-value="value" 
      @add-new-option="addNewAction"
    />

    <v-text-field 
      v-show="showSearch" 
      v-model="searchActions" 
      label="Search Actions..."
      clearable />

    <v-virtual-scroll
      :id="`doc-${currentDoc.uid}-rule-${currentRule}`"
      :items="actions"
      :height="64 * 6"
      bench="4"
      item-height="64"
      style="border: solid 1px lightgray"
    >
      <template v-slot:default="{ item, index }">
        <v-list-item
          :id="`action-${item.uid}`"
          :key="index"
          @click="activateAction(index)"
          :class="{ 'm-active-list-item': currentAction === index }"
        >
          <v-list-item-action>
            <v-btn fab small depressed color="primary">
              {{ index + 1 }}
            </v-btn>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title class="text-left font-size-x-small">
              <strong>{{ item && item.title }}</strong>
              <br />
              <span>{{ item && item.type }}</span>
              <span class="float-right font-size-x-small font-italic">{{
                "Page " + item.location.page
              }}</span>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn outlined icon @click.stop="deleteOldAction(index)">
              <v-icon small> delete </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
      </template>
    </v-virtual-scroll>
  </v-card>
</template>

<script>
import actionsListMixin from '@/mixins/components/actionsList.mixin';

export default {
  name: 'ActionsList',
 
  mixins: [actionsListMixin]
};
</script>