import rulesMixin from './rules.mixin'

export default {
  mixins: [rulesMixin],
  
  methods: {
    setDefaults() {
      const defaultTypes = ['single-select', 'state-select','text-input-short', 'text-input-long', 'number-input']
      const defaults = this.formFields.filter(field => defaultTypes.indexOf(field.type) > -1)
      const toAutoSave = []
      for(let f = 0; f < defaults.length; f++) {
        const field = defaults[f]
        const resp = this.setDefault(field)
        if(resp) toAutoSave.push(resp)
      }
      return toAutoSave
    },

    setDefault(field) {
      if(['text-input-short', 'text-input-long', 'number-input'].indexOf(field.type) > -1 && !field.content) {
        if(!!field?.default) field.answeredStatus = true
        field.content = field?.default || ''
      } else if(['single-select','state-select'].indexOf(field.type) > -1 && !field.selection) {
        if(!!field?.default) field.answeredStatus = true
        field.selection = field?.default || ''
      }

      if(field.answeredStatus) {
        return field
      }
      return
    },

    rulesStatus(element, extraRule = '') {
      const rules = [];
      const required = element.required;
      const validator = (element?.validator === 'number-input') ? 'numeric' : element.validator      
      if (required) rules.push(this.rules.required);
      if(element.type === 'date-picker') {
        if(element.monthPicker !== true) {
          rules.push(this.rules['date'])
        } else {
          rules.push(this.rules[extraRule])
        }
      }
      if (validator) rules.push(this.rules[validator])
      if(element.upperBound && element.lowerBound) {
        const rangeFunc = this.rules['range'](element.lowerBound, element.upperBound)
        rules.push(rangeFunc)
      }
      return rules
    },

    fieldType(field) {
      let type = 'text'
      if(!field?.validator) return type
      switch(field.validator) {
        case "phone":
          type = "tel"
          break;
        case "email":
          type = "email"
          break;
        case "time": 
          type = "time"
          break;
      }
      return type
    },

    getMask({ validator }) {
      if(validator === 'phone') {
        return '###-###-####'
      } else if(validator === 'ssn') {
        return '###-##-####'
      } else {
        return ''
      }
    },

    handleValidation(element, value) {
      if(element.validator) {
        switch(element.validator) {
          case 'ssn':
            if(value.length === 3) {
              value += '-'
            } else if(value.length === 6) {
              value += '-'
            } else if(value.length === 9 && !isNaN(value)) {
              value = `${value.substr(0,3)}-${value.substr(3,2)}-${value.substr(5,4)}`
            }
            break;
          case 'phone':
            if(value.length === 3) {
              value += '-'
            } else if(value.length === 7) {
              value += '-'
            } else if(value.length === 10 && !isNaN(value)) {
              value = `${value.substr(0,3)}-${value.substr(3,3)}-${value.substr(6,4)}`
            }
            break;
        }
      }

      return value
    }
  }
}
