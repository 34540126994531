import axios from 'axios';
import { getProject } from '../gcloud/getProject';

export default async function copyWorkflow({ workflowId_original, workflowId_new, keepCollaborators = 'keep', userEmail }, projectUrl = getProject(), httpRequest = axios) {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };

  const body = {
    workflowId_original,
    workflowId_new,
    keepCollaborators,
    userEmail
  }

  return await httpRequest({
    url: `${projectUrl}/copyWorkflow`,
    method: 'POST',
    headers: config,
    data: JSON.stringify(body)
  })
}