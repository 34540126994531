const smtp = require('@/services/smtp/emailer')
const loadHtml = require('@/utils/load-html.util')
const emailTemplate = require('@/constants/email-templates')
const dayjs = require('dayjs')
const $logger = require('@/utils/logger.util')
const { getDateFormat } = require('@/utils/getDateFormat.util')
const polly = require('polly-js')

const sendEmailUtil = async (baseUrl, user, emailType, subject, msg = '', pg = 'sign-in', attachment = null, formId = '', replaceValues = null) => {
  return new Promise(async resolve => {
    const footerHtml = await loadHtml('../email-templates/footer.html', baseUrl)
    let headerHtml = await loadHtml('../email-templates/header.html', baseUrl)
    let bodyHtml = await loadHtml('../email-templates/body.html', baseUrl)
    const greeting = emailTemplate["greeting"].replace("{{FirstName}}", (user.name || 'Hello'))
    const signature = emailTemplate["signature"]
    headerHtml = headerHtml.data.replace('{{header}}', emailType)
    bodyHtml = bodyHtml.data.replace("~base_url~", baseUrl).replace('sign-in', pg)
    if(emailType.toLowerCase() === 'invite') {
      bodyHtml = bodyHtml.replace(/LOGIN/g, 'REGISTER')
    }
    let body = greeting + (msg || emailTemplate[emailType]) + signature
    body = body.replace("{{clickHereLink}}", `${baseUrl}/respondent-guide/${formId}`).replace("{{shawLawLink}}", "https://coillaw.com/")
    body = body.replace("{{completionDate}}", getDateFormat())
    body = body.replace("{{readyDate}}", getDateFormat(dayjs().add(30, 'day')))
    if(replaceValues) {
      Object.keys(replaceValues).forEach(key => {
        body = body.replace(`{{${key}}}`, replaceValues[key])
      })
    }
    const message = bodyHtml.replace("{{header}}", headerHtml).replace("{{footer}}", footerHtml.data).replace("{{message}}", body)
    const to = user.email

    await polly()
      .waitAndRetry(3)
      .executeForPromise(async () => {
        await smtp().sendEmail({ to, subject, message, attachment })
        $logger.info(`Email sent to ${user.email}`)
        resolve()
      })
  })
}

module.exports = sendEmailUtil