import {arrayUnion} from "../general";
import {nanoid} from "nanoid";

const getExpressionNodeStatus = async ({ dispatch, commit, getters }, payload) => {
  const workflowType = payload.workflowType;
  const formElementUid = payload.formElementUid;
  const currentExpression = payload.expressionNode;

  if (currentExpression.condition === 'always') {
    return true
  } else if (currentExpression.condition === 'if') {
    // populate rulesAffected appropriately
    const questions = currentExpression?.questions ? currentExpression.questions : [];
    const questionUids = questions.map( x => x.uid );
    const updatedAffectedRules = arrayUnion([questionUids, getters.formElementsTemplate(formElementUid).rulesAffected]);
    commit('EDIT_FORM_ELEMENTS_TEMPLATE', { uid: formElementUid, updates: { rulesAffected: updatedAffectedRules } });

    switch (currentExpression.logical_operator) {

      case 'and':
        let expressionCriteria_and = true; // Default to expression being true, set to false if any question criteria isn't met

        for (let i = 0; i < currentExpression.questions.length; i++) {

          let questionCriteria = await dispatch('checkQuestionForMatch', {
            workflowType: workflowType,
            question: currentExpression.questions[i]
          });

          if (!questionCriteria) {
            expressionCriteria_and = false;
          }
        }

        return expressionCriteria_and
      case 'or':
        let expressionCriteria_or = false; // Default to expression being false, set to true if any question criteria is met

        for (let i = 0; i < currentExpression.questions.length; i++) {
          let questionCriteria = await dispatch('checkQuestionForMatch', {
            workflowType: workflowType,
            question: currentExpression.questions[i]
          });

          if (questionCriteria) {
            expressionCriteria_or = true;
            break;
          }
        }

        return expressionCriteria_or
      case 'not':
        let expressionCriteria_not = true; // Default to expression being true, set to false if any question criteria is met

        for (let i = 0; i < currentExpression.questions.length; i++) {

          let questionCriteria = await dispatch('checkQuestionForMatch', {
            workflowType: workflowType,
            question: currentExpression.questions[i]
          });

          if (questionCriteria) {
            expressionCriteria_not = false;
          }
        }

        return expressionCriteria_not
      case 'x-or':
        let num_question_criteria_met = 0; // Holds number of question criteria that has been met

        for (let i = 0; i < currentExpression.questions.length; i++) {
          let questionCriteria = await dispatch('checkQuestionForMatch', {
            workflowType: workflowType,
            question: currentExpression.questions[i]
          });

          if (questionCriteria) {
            num_question_criteria_met++;
          }
        }

        return num_question_criteria_met === 1
      default:
        // If no logical_operator is specified, default to true
        return true
    }
  } else {
    // If no condition is specified, default to true
    return true
  }
}

const getLibraryNodeStatus = async ({ dispatch, commit, getters }, payload) => {
  const workflowType = payload.workflowType;
  const formElementUid = payload.formElementUid;
  const libraryNode = payload.libraryNode;

  const currentNode = getters.logicLibraryEntry(libraryNode.library_uid);
  if(currentNode === undefined || !currentNode?.node_type) {
    return 'Could not find display logic library node'
  } else if (currentNode.node_type === 'compound') {
    const nodeStatus = await getCompoundNodeStatus({ dispatch, commit, getters }, {
      compoundNode: currentNode,
      workflowType,
      formElementUid
    });
    return nodeStatus
  } else if (currentNode.node_type === 'expression') {
    const nodeStatus = await getExpressionNodeStatus({ dispatch, commit, getters }, {
      expressionNode: currentNode,
      workflowType,
      formElementUid
    });
    return nodeStatus
  } else {
    // If node_type of currentNode is not compound or expression, resolve the status of the node as true
    return true
  }
}

const getCompoundNodeStatus = async ({ dispatch, commit, getters }, payload) => {
  const workflowType = payload.workflowType;
  const formElementUid = payload.formElementUid;
  const compoundNode = payload.compoundNode;

  const nodePromises = [];

  const containsLength = compoundNode?.contains ? compoundNode.contains.length : 0;
  for (let i = 0; i < containsLength; i++) {      
    switch (compoundNode.contains[i].node_type) {
      case 'compound':
        const status_compound = getCompoundNodeStatus({ dispatch, commit, getters }, { compoundNode: compoundNode.contains[i], workflowType, formElementUid });
        nodePromises.push(status_compound);
        break;
      case 'expression':
        const status_expression = getExpressionNodeStatus({ dispatch, commit, getters }, { expressionNode: compoundNode.contains[i], workflowType, formElementUid });
        nodePromises.push(status_expression);
        break;
      case 'library':
        const status_library = getLibraryNodeStatus({ dispatch, commit, getters }, { libraryNode: compoundNode.contains[i], workflowType, formElementUid });
        nodePromises.push(status_library);
        break;
    }
  }

  // Wait for all nodes to be evaluated
  const nodeResults = await Promise.all(nodePromises);
  let nodeFinalStatus = true;

  switch (compoundNode.logical_operator) {
    case 'and':
      nodeFinalStatus = nodeResults.every( x => x );
      break;
    case 'or':
      nodeFinalStatus = nodeResults.some( x => x );
      break;
    case 'x-or':
      nodeFinalStatus = nodeResults.filter( x => x ).length === 1;
      break;
  }

  if (compoundNode.not) {
    nodeFinalStatus = !nodeFinalStatus;
  }

  return nodeFinalStatus
}

const updateParentNodeProp = async (node, parentNode) => {
  if (!node?.uid) {
    node.uid = nanoid(12);
  }

  if (!node?.parent_node) {
    node.parent_node = parentNode ? parentNode.uid : null;
  }

  if (node?.contains) {
    const parentPropUpdates = []
    for (let i = 0; i < node.contains.length; i++) {
      parentPropUpdates.push(updateParentNodeProp(node.contains[i], node))
    }
    await Promise.all(parentPropUpdates)
  }
  return
}

// function getAffectedRules({ getters }, payload) {
//   const affectedRules = {};
//   const formElementDisplayLogic = getters.formElements;
//   for (let uid in formElementDisplayLogic) {
//     affectedRules[uid] = getRulesFromNode(formElementDisplayLogic[uid]);
//   }
//   return affectedRules;
// }

// function getRulesFromNode(node) {
//   // Parse through node to see if it contains results from other form elements
// }

export { getExpressionNodeStatus, getLibraryNodeStatus, getCompoundNodeStatus, updateParentNodeProp };