import handleStorageError from "./handle-storage-error.helper"
import { getCloudStorage } from "@/services/gcloud/getProject"
import $logger from '@/utils/logger.util'

export const deleteTemplateImage = (ref) => {
    return ref.delete().then(snapshot => {
        $logger.info('Page deleted from storage.');
    }).catch(error => {
        handleStorageError(error)
    });
};

export const uploadTemplateImage = async (templateImage, ref) => {
    try {
        const snapshot = await ref.putString(templateImage, 'data_url')
    } catch(error) {
        handleStorageError(error)
    }
    return
};

export const uploadImageGetURL = (templateImage, ref) => {
  return new Promise((resolve, reject) => {
      ref.putString(templateImage, 'data_url').then(snapshot => {

      snapshot.ref.getDownloadURL().then(url => {
          resolve(url);
      });
      }).catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          handleStorageError(error)

          reject(error.code);
      });
  });
};

export const getTemplateImageURL = function (pageArray, index, ref) {
    return ref.getDownloadURL().then(url => {
        pageArray[index].dbRef = url.indexOf('http') < 0 ? `${getCloudStorage()}${url}` : url
    }).catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        handleStorageError(error)
    });
};
