<template>
  <v-card tile elevation="2">
    <v-card-title class="bg-primary white--text">
      <span>Dialog Preview</span>

      <v-spacer />

      <v-switch
        v-if="showSwitch"
        @change="switchFlip"
        :label="switchLabel"
        dark
      />

      <template v-if="showButton">
        <v-spacer />
        <v-btn @click="buttonClick">
          <slot name="button-label">
            <v-icon>add</v-icon>
          </slot>
        </v-btn>
      </template>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <DialogQuestions
        :form-id="formId"
        :dialog-fields="fields"
        :preview-mode="false" />
    </v-card-text>
  </v-card>
</template>

<script>
import DialogQuestions from "../components/DialogQuestions";

export default {
  name: "DialogBuilderPreview",

  components: {
    DialogQuestions,
  },

  props: {
    fields: {
      type: Array,
      default: [],
    },

    showButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    showSwitch: {
      type: Boolean,
      required: false,
      default: false,
    },

    formId: {
      type: String,
      default: "",
    },
  },
};
</script>
