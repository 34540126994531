const { VUE_APP_PROJECT, VUE_APP_REGION } = process.env

const functionsBaseUri = `https://${VUE_APP_REGION}-${VUE_APP_PROJECT}.cloudfunctions.net`;
const getProject = () => functionsBaseUri;
const getEndpointTEAP = () => `${functionsBaseUri}/TextEditorActionParser`;
const getCloudStorage = () => `https://firebasestorage.googleapis.com/v0/b/${VUE_APP_PROJECT}.appspot.com/o/`
const getEnvironment = () => {
  let env = 'dev'
  if(VUE_APP_PROJECT === 'simpleending-stage') {
    env = 'stg'
  } else if(VUE_APP_PROJECT === 'simple-ending') {
    env = 'prod'
  }
  return env
}

module.exports = {
  getProject,
  getEndpointTEAP,
  getCloudStorage,
  getEnvironment,
}
