<template>
    <v-card tile class="mb-6">
        <v-card-title class="bg-primary white--text" style="direction:flex;justify-content: space-between;">
            Display Logic
            <v-btn 
                v-if="currentDisplayLogic.conditional || currentDisplayLogic.operator"
                @click="$emit('update', { conditional: '', operator: '', questions: []})"
            >
                Clear
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="6">
                <v-select
                    :items="conditionals"
                    v-model="currentDisplayLogic.conditional"
                    label="Conditional"
                    @change="$emit('update',{...currentDisplayLogic})"
                />
                </v-col>
                <v-col v-if="currentDisplayLogic.conditional !== 'always'" cols="12" md="4">
                <v-select
                    :items="logicalOperators"
                    v-model="currentDisplayLogic.operator"
                    label="Logical Operator"
                    @change="$emit('update',{...currentDisplayLogic})"
                />
                </v-col>
            </v-row>
            <v-row style="flex-direction: column;">
                <v-divider />

                <template v-if="showAddQuestion">
                    <v-btn
                        class="mx-16 my-4"
                        outlined
                        @click="addQuestion"
                    >
                        <v-icon>add</v-icon>
                        <span>Add Question</span>
                    </v-btn>

                    <v-divider />
                </template>

                <template v-for="(question, index) in currentDisplayLogic.questions">
                    <v-row :id="`question-row-${index}`">
                        <v-col
                            cols="6"
                            class="d-flex justify-center align-center"
                        >
                            <!-- Sets answer options when changed -->
                            <v-autocomplete
                                v-model="question.uid"
                                :label="'Question ' + (index + 1)"
                                :items="parsedQuestionList"
                                class="py-2 text-left solo-height-fix"
                                item-text="questionListTitle"
                                item-value="uid"
                                auto-select-first
                                solo
                                @change="getSelectedQuestionAnswers(question.uid, index)"
                            />
                        </v-col>
                        <v-col
                            v-if="showAnswerDropdown(question)"
                            cols="5"
                        >
                            <v-select
                                v-model="question.selection['answer']"
                                :label="'Answer'"
                                :items="question.options"
                                solo
                                class="py-2 text-left solo-height-fix"
                            />
                        </v-col>
                        <template v-if="question.type === 'number-input'">
                            <v-col cols="3">
                                <v-select
                                    v-model="question.selection.comparison"
                                    :label="'Comparison'"
                                    :items="question.options"
                                    solo
                                    class="py-2 text-left solo-height-fix"
                                />
                            </v-col>
                            <template  v-if="question.selection.comparison === 'greater-than'">
                                <v-col cols="2">
                                    <v-text-field
                                        v-model="question.selection.lowerLimit"
                                        type="number"
                                        label="Lower Limit"
                                        solo
                                        class="py-2 text-left solo-height-fix"
                                    />
                                </v-col>
                            </template>
                            <template v-if="question.selection.comparison === 'equal-to'">
                                <v-col cols="2">
                                    <v-text-field
                                        v-model="question.selection.equalTo"
                                        type="number"
                                        label="Equal To"
                                        solo
                                        class="py-2 text-left solo-height-fix"
                                    />
                                </v-col>
                            </template>
                            <template v-if="question.selection.comparison === 'less-than'">
                                <v-col cols="2">
                                    <v-text-field
                                        v-model="question.selection.upperLimit"
                                        type="number"
                                        label="Upper Limit"
                                        solo
                                        class="py-2 text-left solo-height-fix"
                                    />
                                </v-col>
                            </template>
                            <template  v-if="question.selection.comparison === 'between'">
                                <v-col cols="1">
                                    <v-text-field
                                        v-model="question.selection.lowerLimit"
                                        type="number"
                                        label="Lower Limit"
                                        solo
                                        class="py-2 text-left solo-height-fix"
                                    />
                                </v-col>
                                <v-col cols="1">
                                    <v-text-field
                                        v-model="question.selection.upperLimit"
                                        type="number"
                                        label="Upper Limit"
                                        solo
                                        class="py-2 text-left solo-height-fix"
                                    />
                                </v-col>
                            </template>
                        </template>
                        <template
                            v-if="question.type === 'multi-select' ||
                            (question.type === 'embedded-image' &&
                                question.interactionType === 'multi-select')
                            "
                        >
                            <v-col cols="4">
                            <v-select
                                v-model="question.selection.answer"
                                :label="'Answer'"
                                :items="question.options"
                                multiple
                                solo
                                class="pt-4 solo-height-fix"
                            />
                            </v-col>
                            <v-col cols="1">
                            <v-switch
                                v-model="question.selection.orLogic"
                                :label="question.selection.orLogic ? 'Any' : 'All'"
                            />
                            </v-col>
                        </template>
                        <v-col
                            cols="1"
                            class="d-flex align-center justify-center"
                        >
                            <v-btn
                            icon
                            class="mt-3"
                            @click="deleteQuestion(index)"
                            >
                            <v-icon>cancel</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div
                        v-if="showCurrentOperator(index)"
                        class="d-flex justify-center align-center mt-4 mb-0"
                    >
                        <span class="pa-1 border-solid-1">{{
                            currentDisplayLogic.operator
                        }}</span>
                    </div>
                </template>              
            </v-row>
        </v-card-text>
    </v-card>    
</template>

<script>
import questionsMixin from "@/mixins/questions.mixin"
import parsedQuestionListMixin from '@/mixins/parsed-question-list.mixin'

export default {
    name: 'DocDisplayLogic',

    mixins: [questionsMixin,parsedQuestionListMixin],

    data() {
        return {
            logicalOperators: ["and", "or", "not", "x-or"],
            conditionals: ["if", "always", "else"],
            currentDisplayLogic: {}
        }
    },

    props: {
        displayLogic: {
            type: Object,
            default: () => {}
        },

        formFields: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        showAddQuestion() {
            return ['','always'].includes(this.currentDisplayLogic.conditional) === false && this.currentDisplayLogic.operator
        },

        showCurrentOperator() {
            return (index) => this.currentDisplayLogic.operator && index !== this.currentDisplayLogic.questions.length - 1
        }
    },

    watch: {
        displayLogic: {
            handler: function(newVal) {
                if(newVal) {
                    this.currentDisplayLogic = {...newVal}
                } else {
                    this.currentDisplayLogic = {
                        operator: null,
                        conditional: null,
                        questions: []
                    }
                }
            },
            deep: true
        }
    },

    mounted() {
        this.currentDisplayLogic = {...this.displayLogic}
    }, 

    methods: {
        deleteQuestion(index) {
            this.currentDisplayLogic.questions.splice(index, 1);
            this.$emit('update', {...this.currentDisplayLogic})
        },
    
        addQuestion() {
            if(!this.currentDisplayLogic?.questions)
                this.currentDisplayLogic.questions = []
                
            this.currentDisplayLogic.questions.push({
                uid: null,
                title: null,
                type: null,
                selection: {},
                options: [],
            });
            this.$emit('update', {...this.currentDisplayLogic})
        },


        getSelectedQuestionAnswers(uid, index) {
            for (let i = 0; i < this.parsedQuestionList.length; i++) {
                if (this.parsedQuestionList[i].uid === uid) {
                    const currentQuestion = this.parsedQuestionList[i];

                    const currentAnswerOptions = this.getSelectedQuestionAnswer(currentQuestion);

                    this.currentDisplayLogic.questions[index].title = currentQuestion.title;
                    this.currentDisplayLogic.questions[index].uid = currentQuestion.uid;
                    this.currentDisplayLogic.questions[index].type = currentQuestion.type;
                    this.currentDisplayLogic.questions[index].options = currentAnswerOptions;
                    this.$forceUpdate();
                }
            }
        },
    }
}
</script>

<style scoped>

</style>