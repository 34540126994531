import { getDateFormat } from "@/utils/getDateFormat.util"
import dayjs from 'dayjs';

const couponTemplate = {
    amount: 0,
    code: '',
    count: 0,
    limit: 0,
    paywall: 'both',
    type: 'percent',
    startDate: '',
    endDate: '',
    campaign: false,
    modified: Date.now()
}

const defaultState = () => ({
    coupons: {},
    currentCoupon: {...couponTemplate}
})

export const coupons = {
    namespaced: true,
    state: defaultState(),

    actions: {
        resetState({ commit }) {
            commit('RESET_STATE')
        },
        
        setCurrentCoupon({ commit }, payload) {
            commit('SET_CURRENT_COUPON', payload)
        },

        async setCoupons({ commit }, payload) {
            commit('SET_COUPONS', payload)
            return
        },

        async findCoupon({ commit, rootState, dispatch }, code) {
            const _dayjs = dayjs
            const resp = await rootState.firebaseConfig.db.collection('coupon-codes').doc(code).get()

            if(resp.exists) {
                const couponInfo = resp.data()
                if(couponInfo.count === couponInfo.limit) {
                    return 'Sorry, this coupon has reached its limit'
                } else if (couponInfo.startDate && _dayjs().diff(couponInfo.startDate, 'day') < 0) {
                    return `Sorry, this coupon will not be active until ${getDateFormat(couponInfo.startDate,'YYYY-MM-DD')}`
                } else if(couponInfo.endDate && _dayjs().diff(couponInfo.endDate, 'day') > 0) {
                    return `Sorry, this coupon expired on ${getDateFormat(couponInfo.endDate,'YYYY-MM-DD')}`
                } else {
                    commit('SET_CURRENT_COUPON', couponInfo)
                    dispatch('incrementCouponCount', couponInfo)
                    return 'success'
                }
            }
            return 'Sorry, could not find this coupon'
        },

        incrementCouponCount({ dispatch }, payload) {
            payload.count = payload.count + 1
            dispatch('updateCoupon', payload)
        },

        async getCoupons({ rootState, commit }) {
            const resp = await rootState.firebaseConfig.db.collection('coupon-codes').get()
            let coupons = {}
            resp.forEach(c => {
                coupons[c.id] = c.data()
            })
            commit('SET_COUPONS', coupons)
        },

        async updateCoupon({ rootState, state, commit }, payload) {
            const db = rootState.firebaseConfig.db
            const coupons = {...state.coupons}
            coupons[payload.code] = payload
            commit('SET_COUPONS', coupons)
            delete payload.original
            payload['modified'] = Date.now()
            await db.collection('coupon-codes').doc(payload.code).set(payload, { merge: true })
            return
        },

        async deleteCoupon({ rootState, commit, state }, code) {
            const coupons = {...state.coupons}
            delete coupons[code]
            commit('SET_COUPONS', coupons)
            await rootState.firebaseConfig.db.collection('coupon-codes').doc(code).delete()
            return
        },

        resetCurrentCoupon({ commit }) {
            commit('SET_CURRENT_COUPON', {...couponTemplate})
        },

        async handleDuplicates({ rootState }) {
            const couponRef = rootState.firebaseConfig.db.collection('coupon-codes')
            const dups = (await couponRef.where('campaign', '==', true).get()).docs.map(d => ({...d.data(), uid: d.id })).sort((a,b) => a.modified - b.modified)
            const toSet = dups.map(d => couponRef.doc(d.uid).set({ campaign: false }, { merge: true }))
            await Promise.all(toSet)
            return
        }
    },

    mutations: {
        RESET_STATE(state) {
            Object.assign(state, defaultState());
        },

        SET_CURRENT_COUPON(state, payload) {
            state.currentCoupon = payload
        },

        SET_COUPONS(state, payload) {
            state.coupons = payload
        }
    },

    getters: {
        currentCoupon: (state) => {
            const startDate = getDateFormat(state.currentCoupon.startDate,'MM/DD/YYYY')
            const endDate = getDateFormat(state.currentCoupon.endDate,'MM/DD/YYYY')
            return {
                ...state.currentCoupon,
                startDate: startDate === 'Invalid Date' ? '' : startDate,
                endDate: endDate === 'Invalid Date' ? '' : endDate,
            }
        },

        coupons: (state) => Object.values(state.coupons).map(c => {
            const startDate = getDateFormat(c.startDate,'YYYY-MM-DD')
            const endDate = getDateFormat(c.endDate,'YYYY-MM-DD')
            return {
                ...c,
                original: c,
                amount: c.amount,
                limit: c.limit,
                startDate: startDate === 'Invalid Date' ? '' : startDate,
                endDate: endDate === 'Invalid Date' ? '' : endDate,
                campaign: c.campaign ?? false
            }
        }),

        coupon: (state) => (code) => state.coupons[code]
    }
}
