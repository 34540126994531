const axios = require('axios')

const postIt = async ({ url, body, contentType = null }, httpRequest = axios) => {
    const config = {
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Content-Type': contentType || 'application/json'
    }

    return await httpRequest({
        url: url,
        method: 'POST',
        headers: config,
        data: body
    });
}

module.exports = postIt