<template>
  <div :id="formId" class="respondent-guide">
    <teap-alert/>
    <div class="left-nav">
      <v-stepper
          v-model="step"
          vertical
          style="height: 90%; display: flex; flex-direction: column; justify-content: space-evenly; padding-bottom: 0 !important;"
      >

        <v-stepper-step
            :complete="step >= 1"
            :style="isAdmin ? 'cursor:pointer;' : ''"
            step="1"
            @click="stepClick(1)"
        >
          Shared Questionnaire
        </v-stepper-step>

        <v-divider/>

        <v-stepper-step
            :complete="step > 2"
            :style="isAdmin ? 'cursor:pointer;' : ''"
            step="2"
            @click="stepClick(2)"
        >
          Review & E-sign
        </v-stepper-step>

        <v-divider/>

        <v-stepper-step
          id="last-step"
          :complete="step === 3"
          :style="isAdmin ? 'cursor:pointer;' : ''"
          step="3"
          @click="stepClick(3)"
        >
          Completed Documents
        </v-stepper-step>

      </v-stepper>

      <span
       v-if="step === 1"
       class="stepper-btn"
      >
        <v-btn
            dark
            @click="buttonClick"
            :class="disableButtonToProceed ?
            'disable-events' :
            'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary'"
        >
          {{ buttonText }}
        </v-btn>
      </span>

      <span 
        v-else-if="step === 2"
        class="stepper-btn"
      >
        <v-btn :class="isSigned === true || isAdmin ?
          'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary' :
          'disable-events'"
            @click="buttonClick"
            :loading="btnLoading"
        >
            {{ buttonText }}
          </v-btn>
      </span>

      <v-btn
        v-else
        :disabled="disableBtn"
        tile
        dark
        color="primary"
        class="stepper-btn"
        :loading="btnLoading"
        @click="buttonClick"
      >
        {{ buttonText }}
      </v-btn>
    </div>
    <div class="right-content">
      <v-stepper v-if="formLoaded" v-model="step" tile>
        <v-stepper-content step="1" class="pa-0">
          <template v-if="step === 1 && petitionerStep === 3">
            <WorkflowGuide
              form-type="respondent"
              :disable-comments="disableComments"
              :form-fields="formData.fields"
              :parent-id="formData.parentId"
              :state="formData.state"
              :read-only="true"
              :is-owner="isOwner"
              :can-save="false"
              :activateWarningDialog="false"
              :shared-user-props="sharedUserProps"
              :comment-owner-name="sharedUserProps && sharedUserProps.user.name"
              @limit="characterLimit = $event"
            />
          </template>
          <div v-else class="pa-15">
              <h2 class="text-center">Please wait a little longer</h2>
              <img :src="require(`@/assets/images/almost-there-bg.png`)" alt="img" class="header-img mb-10">
              <p>
                Your spouse is still in the process of filling out the questionnaire.<br />
                You will received notice via email once they have completed it.<br />
                At that time you will be able to login and review their responses to the questions.
              </p>
            </div>
        </v-stepper-content>


        <v-stepper-content step="2">
          <template v-if="step === 2">
            <template v-if="petitionerHasSigned || isAdmin">
              <div class="bottomOfPage">
                <v-btn
                    title="Go to signature area"
                    color="primary"
                    @click="scrollToBottom"
                >
                  Go to Signature
                </v-btn>
                <v-btn
                  style="margin-left: 10px;"
                  title="restart the process"
                  color="primary"
                  @click="restart"
                >
                  Return to Questionnaire
              </v-btn>
              </div>
              <h1>Review &amp; E-Sign Your Completed Documents</h1>
              <FileRenderer :pdf-file="processedFile">
                <template #card-title>Finished Computed File</template>
              </FileRenderer>
              <p style='margin-top: 20px;'>After e-signing, you will be able to generate and download your documents.
                They
                will be ready to file with the court.</p>
              <div class="signature-pad">
                <signature-pad
                  v-if="step === 2"
                  id="e_sign_respondent"
                  label="Sign Here"
                  :hide-save-btn="false"
                  :form-id="$route.params.id"
                  :required="true"
                  :field="spouseEsign"
                  @signed="handleSignature($event)"
                />
              </div>
            </template>

            <div v-else class="pa-15">
              <h2 class="text-center">Please wait a little longer</h2>
              <img :src="require(`@/assets/images/almost-there-bg.png`)" alt="img" class="header-img mb-10">
              <p>
                You and your spouse have agreed to the general terms for divorce. <br>
                Your spouse will be required to pay the additional fee before you can download the stipulation. <br>
                Once they have signed, you'll be able to review and sign for further review.
              </p>
            </div>
          </template>
        </v-stepper-content>

        <v-stepper-content step="3">
          <template v-if="step === 3 && formData.id">
            <h1>Final Documents</h1>
            <generate-doc :final-pdf="formData.finalPDF"/>
          </template>
        </v-stepper-content>
      </v-stepper>
    </div>


    <!-- Dialog that pops up when user wants to submit response (by clicking the Submit Response button) -->
    <v-dialog persistent v-model="submitResponseDialog" max-width="600">
      <v-card class="pa-3">
        <v-card-title class="headline mb-3">
          <v-icon color="error" class="mr-2">error</v-icon>
          Finished Reviewing the Document?

          <v-icon
              style="position: absolute; top: 20px; right: 20px"
              @click="submitResponseDialog = false"
          >
            close
          </v-icon>
        </v-card-title>

        <v-card-text class="mb-0 pa-0">
          <p style="max-width: 80%; margin: 20px auto">You can either <strong>Agree</strong> with your spouse, or
            <strong>Send Back</strong> the document for further review.</p>
          <strong class="my-3 d-block">This action will be permanent.</strong>
          <p class="mt-3 mb-0 text-danger">
            Before continuing please ensure that you've reviewed all questions and submitted responses.
          </p>
        </v-card-text>

        <v-card-actions class="finished-buttons mt-4">
          <v-btn 
            v-if="!agreeLoading" 
            color="primary" 
            text style="min-width: 200px" 
            :disabled="reviewLoading"
            @click="submitResponse('send-back')"
          >
            <span v-if="!reviewLoading">
              Send Back for Spousal Review
            </span>

            <v-progress-circular
                v-else
                indeterminate
                color="primary"
            ></v-progress-circular>
          </v-btn>
          <v-spacer/>

          <div v-if="visibleRequiredFields.length" class="d-flex align-items-center">
            <p>
              <b class="text-danger">{{visibleRequiredFields.length}}</b>
              required fields unfilled.
            </p>
          </div>
          <div v-else class="d-flex align-items-center">
            <v-checkbox v-if="!agreeLoading && !reviewLoading" v-model="checkbox" />
            <v-btn
              v-if="!reviewLoading"
              @click="submitResponse('agree')"
              :class="{'primary' : checkbox}"
              text
              :disabled="!checkbox"
              :loading="agreeLoading"
            >
              I Agree to Terms
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5 primary py-0 mb-5 text--white">
            <span class="text-light">
              Important
            </span>
            <v-btn
                class="ms-auto"
                text
                color="white"
                fab
                @click="dialog = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="text-start">
            Your spouse has gone through and added information and made selections in regard to the terms of your
            divorce. This is your opportunity to review the information and selections your spouse has made. If you find
            information that is incorrect or they have selected an answer you do not agree with, please note that in the
            comment box. If there are changes that need to be made, when you are finished reviewing the questionnaire,
            you'll send it back to your spouse to make changes consistent with your comments. If you believe all of the
            information is correct and agree with the answer selections, you can agree and move forward.
          </v-card-text>
          <v-card-text class="text-start">
            Once you have both agreed, a contractual agreement will be prepared for both of you to review and then sign.
          </v-card-text>
          <v-card-text class="text-start mb-5">
            After you have both signed the stipulation the remaining documents necessary to finalize your divorce will
            be prepared and provided to you and your spouse with instructions on how to complete the process with the
            court.
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import respondentGuideMixin from '../mixins/views/respondentGuide.mixin';

export default {
  name: 'RespondentGuide',

  mixins: [respondentGuideMixin]
}
</script>

<style lang="scss">
.respondent-guide {
  height: calc(100vh - 120px);
  display: flex;

  .bottomOfPage {
    position: fixed;
    right: 20px;
    bottom: 60px;
    z-index: 20;    

    button {
      margin-bottom: 10px;
    }   
  }

  .left-nav {
    width: 25%;
    height: 100%;
    border-right: solid 1px rgba(0, 0, 0, 0.12);

    .v-stepper {
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-bottom: 0 !important;
    }

    .stepper-btn {
      width: 100%;
      height: 10% !important;

      button {
        width: 100%;
        height: 80px;
      }
    }
  }

  .right-content {
    width: 75%;
    height: 100%;
    overflow-y: auto;

    .v-stepper__content {
      height: 100% !important;

      .v-stepper__wrapper {
        height: 100% !important;
      }
    }

    .signature-pad {
      width: 75%;
      margin: 0 auto; 
      padding-bottom: 60px;    
    }    
  }
}

.disable-events {
  pointer-events: none;
  background: gray !important;
  opacity: 0.8;
}

@media(max-width: 900px) {
  .respondent-guide {
    .left-nav {
      .v-stepper {
        height: fit-content !important;
      }

      .stepper-btn {
        width: 100%;
        height: 10% !important;

        button {
          height: 20%;
        }
      }
    }
  }
}

@media(max-width: 700px) {
  .respondent-guide {
    display: block;

    .bottomOfPage {
      position:fixed;
      right: 0px;
      bottom: 10px;

      button {
        margin-bottom: 10px;
      }
    }

    .left-nav {
      margin-bottom: 5px;
      width: 100%;
      display: block;
      height: 80px;
      float: left;
      border: 1px solid silver;
      overflow-y: auto;

      .v-stepper {
        width: 50%;
        height: 100%;
        display: block;
        float: left;

        .v-stepper__step {
          padding: 18px 18px 12px !important;
        }

        .v-stepper__step--inactive,
        .v-stepper__step--complete,
        .v-divider {
          display: none;
        }

        #last-step.v-stepper__step--complete {
          display:flex;
        }
      }

      .stepper-btn {
        display: block;
        float: left;
        height: 100% !important;
        width: 50%;

        button {
          padding: 0 8px !important;
          height: 100% !important;
          display: block;
          white-space: break-spaces !important;
        }
      }
    }

    .right-content {
      width: 100%;
      display: block;
      float: left;

      .v-stepper__content {
        padding: 0 !important;
      }

      .signature-pad {
        width: 95%;
      }       
    }
  }

  .finished-buttons {
    flex-direction: column !important;
  }
}
</style>

