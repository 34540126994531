import { mapActions } from 'vuex'

export default {
    data() {
        return {
          hasDisplayLogic: false,
        }
    },

    methods: {
        ...mapActions("displayLogic", ["buildTemplates"]),

        async buildDisplayLogic() {
          let displayLogicRules = [];
  
          for (let i = 0; i < this.formData.docs.length; i++) {
            const filteredRules = this.formData.docs[i]?.rules?.filter(rule => rule.type === 'Display Logic')
            if (filteredRules !== undefined) displayLogicRules = [...displayLogicRules, ...filteredRules];
          }
  
          await this.buildTemplates({
            workflowId: this.formData.id,
            workflowType: 'published',
            formElements: this.formData.fields,
            rules: displayLogicRules
          })
  
          setTimeout(() => {
            this.hasDisplayLogic = true
          },2000)
          
          return
        },
    }
}