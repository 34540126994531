<template>
  <v-alert 
    v-if="showAlert" 
    style="width:95%; position:fixed; left: 1%; z-index: 100;"
    :type="alert.type"
    dismissible
    @input="setShow(false)"
  >
    {{ alert.message }}
  </v-alert>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Alert',

  computed: {
    ...mapGetters("alert", ["showAlert", "alert"])
  },

  watch: {
    $route (to, from) {
      this.setShow(false)
    }
  },

  methods: {
    ...mapActions("alert", ["setShow"]),
  }
}
</script>
