<template>
  <v-container fluid class="full-height">
    <v-row justify="center">      
      <v-btn
        v-show="!submitted"
        id="pay-pal-test-btn"
        :loading="loading"
        @click="sendPayment()">Pay $1</v-btn>
      <v-alert v-show="message" type="error">{{ message }}</v-alert>
    </v-row>
  </v-container>
</template>

<script>
import payPalService from '../services/paypal';
import adminCheckerMixin from '@/mixins/adminChecker.mixin'

export default {
  name: 'PayPalTest',

  mixins: [adminCheckerMixin],

  data () {
    return {
      submitted: false,
      message: '',
      loading: false
    };
  },

  methods: {
    sendPayment (paypal = payPalService()) {
      this.loading = true;
      localStorage.setItem("paypalAmount", "1.00")
      paypal
        .requestPayment('1.00')
        .then((resp) => {
          this.submitted = true;

          if (resp.data.href) {
            location.href = resp.data.href;
          } else {
            this.message = resp.data.body.response.error_description;
          }

          this.loading = false;
        })
        .catch((err) => {
          this.message = err.message
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.full-height {
  margin-top: 40px;
}

.v-alert {
  width: 100%;
  margin: 20px;
}
</style>