import MONTHS from '@/utils/months.util'
import { getDateFormat } from '@/utils/getDateFormat.util'
import dayjs from 'dayjs'
 
export default {
  computed: {
    monthsOfTheYear() {
      return MONTHS
    },
  },

  methods: {
    getYear(value) {
      return getDateFormat(value, 'YYYY')
    },

    getMonthName(value) {
      return getDateFormat(value, 'MMMM')
    },

    monthPickerHandler(field, event) {
      if(field.dateSingle === 'Invalid Date')
        field.dateSingle = dayjs().format('MM/DD/YYYY')

      const safeDate = field.dateSingle.replace(/nd|th|st|rd/g, '')
      if(typeof event === 'string' && isNaN(event)) {
        const monthNum = this.monthsOfTheYear.findIndex(m => m === event)
        const newMonth = dayjs(safeDate || dayjs()).set('month', monthNum)
        return getDateFormat(newMonth,field.format)
      }
      else {
        const newYear = dayjs(safeDate || dayjs()).set('year', event)
        return getDateFormat(newYear,field.format)
      }
    }
  }
}