const postIt = require('@/services/http/postIt')
const { getProject } = require('@/services/gcloud/getProject')
const defaultFromAddress = require('@/constants/defaultFromEmail')

const scheduleEmail = async ({ to, subject, message, sendOn = new Date(), from = defaultFromAddress, formId = '', attachment = null }, httpPost = postIt) => {
    const url = `${getProject()}/scheduleEmail`;
    const body = {
        to,
        subject,
        from,
        message,
        sendOn,
        formId,
        attachment
    }

    const status = { type: 'scheduled', status: await httpPost({ url, body }) }

    return status
}

module.exports = scheduleEmail