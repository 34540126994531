<template>
  <v-container fluid class="dialog-questions mb-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-form>
          <v-row>
            <v-col sm="12">
              <template v-for="(field, index) in dialogFields">
                <div id="formFieldContainer" :key="index">
                  <template v-if="field.type === 'text-input-short'">
                    <div :class="{ 'd-none': displayStatus(field.uid) }">
                      <v-text-field
                          :value="field.content"
                          :label="expressionator(field.label, dialogFields)"
                          :readonly="previewMode"
                          :rules="rulesStatus(field)"
                          :placeholder="field.placeholder || 'Enter text here'"
                          clearable
                          @input="inputHandler(field, $event)"
                      />
                      <br v-if="field.spaceAfter" />
                    </div>
                  </template>

                  <template v-if="field.type === 'signature-pad'">
                    <div :class="{ 'd-none': displayStatus(field.uid) }">
                      <SignaturePad
                          :id="`signature-pad-${index}`"
                          :form-id="formId"
                          :field="field"
                          :label="expressionator(field.label, dialogFields)"
                          :error="answerStatus(field)"
                          @change="inputHandler(field, $event)"
                      />
                      <br />
                      <br v-if="field.spaceAfter" />
                    </div>
                  </template>

                  <template v-if="field.type === 'text-input-long'">
                    <div :class="{ 'd-none': displayStatus(field.uid) }">
                      <v-textarea
                          :value="field.content"
                          :label="expressionator(field.label, dialogFields)"
                          :readonly="previewMode"
                          :rules="rulesStatus(field)"
                          @input="inputHandler(field, $event)"
                          rows="15"
                      />
                      <br v-if="field.spaceAfter" />
                    </div>
                  </template>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignaturePad from "@/components/SignaturePad";
import expressionatorMixin from "@/mixins/expressionator.mixin";
import { mapState } from "vuex";
import validatorsMixin from "@/mixins/validators.mixin";

export default {
  name: "DialogQuestions",

  components: {
    SignaturePad,
  },

  mixins: [expressionatorMixin, validatorsMixin],

  props: {
    dialogFields: {
      type: Array,
      default: () => [],
    },

    previewMode: {
      type: Boolean,
      default: false,
    },

    fill: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      checkAnswerCalled: false,
      currentPage: null,
      inputHandlerDebounce: undefined,
    };
  },

  computed: {
    ...mapState({
      displayTemplate: (state) => state.displayLogic.formElementsTemplate,
    }),

    pages() {
      let hasPageBreaks = false;
      const indices = [];

      function findWithAttr(array, attr, value) {
        let arrayLength = 0;
        if (array !== undefined && array.length !== 0) {
          arrayLength = array.length;
        }

        for (let i = 0; i < arrayLength; i++) {
          if (array[i][attr] === value) {
            indices.push(i);
            hasPageBreaks = true;
          }
        }
      }

      const array = this.dialogFields;
      const attr = "type";
      const value = "page-break";

      findWithAttr(array, attr, value);

      // Put fieldIndex into array that will be spliced to return pageSubsetArray, that way we can reference back to its position in dialogFields
      if (array !== undefined) {
        for (let i = 0; i < array.length; i++) {
          array[i].fieldsIndex = i;
        }
      }

      if (hasPageBreaks) {
        const pageSubsetArray = {};

        for (let i = 0; i < indices.length; i++) {
          if (i === indices.length - 1) {
            pageSubsetArray[i] = array.slice(indices[i] + 1, array.length);
          } else {
            pageSubsetArray[i] = array.slice(indices[i] + 1, indices[i + 1]);
          }
        }

        return pageSubsetArray;
      } else {
        return {
          0: array,
        };
      }
    },

    pageNames() {
      const array = this.dialogFields;
      const pageNames = [];

      let arrayLength = 0;
      if (array !== undefined && array.length !== 0) {
        arrayLength = array.length;
      }

      let currentPage = 0;

      for (let i = 0; i < arrayLength; i++) {
        if (array[i]["type"] === "page-break") {
          currentPage++;
          if (!!array[i].name) {
            pageNames.push(array[i].name);
          } else {
            const defaultPageName = "Page " + currentPage;

            pageNames.push(defaultPageName);
          }
        }
      }

      return pageNames;
    },

    //
    // Display Logic
    //

    displayStatus() {
      return (uid) => {
        return this.displayTemplate && uid && this.displayTemplate[uid]
          ? !this.displayTemplate[uid].display
          : false;
      };
    },

    answerStatus() {
      return (element) => {
        return (
          element.required &&
          this.checkAnswerCalled &&
          (!element?.answeredStatus || !element.answeredStatus)
        );
      };
    },
  },

  methods: {
    inputHandler(field, event) {
      // Triggers display logic template to update after user has input something

      if (this.inputHandlerDebounce) {
        clearTimeout(this.inputHandlerDebounce);
      }

      event = this.handleValidation(field, event)

      this.inputHandlerDebounce = setTimeout(() => {
        if (["text-input-short", "text-input-long"].indexOf(field.type) > -1) {
          this.dialogFields[field.fieldsIndex].content = event;
          this.dialogFields[field.fieldsIndex].answerStatus =
            typeof this.rules.required(event) === "boolean";
        } else if (["signature-pad"].indexOf(field.type) > -1) {
          this.dialogFields[field.fieldsIndex].answeredStatus = event.signed;
          this.dialogFields[field.fieldsIndex].signature = { ...event };
        }
      }, 1500);
    },
  },
};
</script>
