import { mapState, mapGetters, mapActions } from 'vuex';
import workflowBuilderConstants from "@/constants/workflowBuilderConstants";
import questionsMixin from "@/mixins/questions.mixin"
import MultiOptionAdd from "@/components/Common/MultiOptionAdd";
const { v4: uuidv4 } = require('uuid');

export default {
  components: {
    MultiOptionAdd
  },
  mixins: [questionsMixin],
  props: {
    parentActiveRule: {
      type: Number,
      default: 0
    },
    parentActiveAction: {
      type: Number,
      default: 0
    },
    parsedQuestionList: {
      type: Array,
      default: () => []
    },
    parsedTextQuestionsList: {
      type: Array,
      default: () => []
    },
    parsedImageFormElementsList: {
      type: Array,
      default: () => []
    },
    docId: {
      type: Number,
      default: 0
    },

  },
  watch:{
    currentBlock: {
      handler: function (newVal) {
        newVal.uid = newVal.tag
      },
      deep: true
    }
  },
  data() {
    return {
      filterBy: '',
      activeRule: null,
      conditionals: ['if', 'always', 'else'],
      conditionalSelection: '',
      logicalOperators: ['and', 'or', 'not', 'x-or'],
      logicalOperatorSelection: '',
      changeNameFlag: false,

      searchBlocks: null,
      currentBlock: null,
      currentBlockIndex: null,
    };
  },
  computed: {
    ...mapGetters(['getStates']),

    ...mapState({form: 'formBuilderActiveForm'}),

    ...mapGetters('blocks', ["blocks"]),


    displayLogicColor() {
      return (uid) => {
        const block = this.blocks.find(b => b.uid === uid)
        const completeQuestions = block?.questions?.filter(q => q?.uid && (q?.selection?.comparison || q?.selection?.answer))
        const checkProps = ['condition','name','operator','questions','tag','tagName','type']
        const propsFound = checkProps.filter(check => !!block?.[check]).length === checkProps.length
        const questionValueCheck = !!block?.value?.questionListTitle || block?.type !== 'question-input'
        return block?.condition === 'always' || propsFound && questionValueCheck && block.questions?.length > 0 && block.questions?.length === completeQuestions.length ? 'primary' : 'error'
      }
    },

    hasDisplayLogic() {
      return (uid) => {
        return this.blocks.find(b => b.uid === uid)
      }
    },

    filteredDynamicBlocks() {
      let currentBlocks = [...this.blocks]
      if (this.searchBlocks) {
        currentBlocks = currentBlocks
          .filter(x => {
            if (x.tagName) {
              if (x.tagName.toUpperCase().indexOf(this.searchBlocks.toUpperCase()) > -1) return true;
            }
            if (x.type) {
              if (x.type.toUpperCase().indexOf(this.searchBlocks.toUpperCase()) > -1) return true;
            }
            return false;
          });
      }

      if(this.filterBy?.length > 0) {
        const filterTxt = this.filterBy.toLowerCase()
        currentBlocks = currentBlocks.filter(b => b.questions?.length === 0 || this.filterOptions(filterTxt, b.uid))
      }

      return currentBlocks
    },

    groupedOptions() {
      return question => {
        const liveQuestion = this.form.fields.find(x => x.uid === question.uid);
        if (liveQuestion && liveQuestion?.groupingOptions) {
          return Object.values(liveQuestion.groupingOptions);
        }
        return [];
      }
    },
  },

  async created() {
    // Initializing
    this.DYNAMIC_BLOCK_TYPES = workflowBuilderConstants.dynamicBlockTypes;
    if((!this.blocks || this.blocks.length === 0) && this?.form?.id) {
      await this.setBlocks({ id: this.form.id, collectionName: 'forms' })
    }
  },

  methods: {
    ...mapActions("blocks", ["setBlocks","addBlock","deleteBlock","updateBlock"]),

    ...mapActions("confirmation", ["confirm"]),


    filterOptions(filterTxt, uid) {
      return filterTxt === '' ? true : this.completeDisplayLogicChecker(filterTxt, uid)
    },

    completeDisplayLogicChecker(filterTxt, uid) {
      return (this.displayLogicColor(uid) === 'primary' && filterTxt === 'completed' || this.displayLogicColor(uid) === 'error' && filterTxt === 'incomplete')
    },

    removeBlockClick(uid) {
      this.confirm({
        title: 'Are you sure you want to delete this block?',
        accepted: () => {
          const blockIndex = this.blocks.findIndex(b => b.uid === uid)
          if(blockIndex > -1) {
            this.deleteBlock(blockIndex)
          }
        }
      })
    },

    getBlockIndexByTag(key,value) {
      return this.blocks.findIndex(b => b[key].toLowerCase() === value.toLowerCase())
    },

    setBlock(tag, prop, val) {
      const index = this.getBlockIndexByTag('tag',tag)
      const block = {...this.blocks[index]}
      block[prop] = val
      this.updateBlock({ index, block })
    },

    getSelectedQuestionAnswers(block, title, index2) {
      const question = block.questions[index2]
      if(!title) {
        question.uid = null
        question.title = ''
        question.type = ''
        question.options = []
        question.selection = {
          answer: ''
        }
        const index = this.getBlockIndexByTag('tag', block.tag)
        this.updateBlock({ index, block })        
      } else {
        this.parsedQuestionList.filter(q => q.title === title).forEach(currentQuestion => {
          const currentAnswerOptions = this.getSelectedQuestionAnswer(currentQuestion);
       
          question.uid = currentQuestion.uid;
          question.title = currentQuestion.title;
          question.type = currentQuestion.type;
          question.options = currentAnswerOptions;
          question.selection = {
            answer: ''
          }
          const index = this.getBlockIndexByTag('tag', block.tag)
          this.updateBlock({ index, block })
        })
      }
    },

    createBlock(type) {
      const newBlock = {
        name: `${type.toUpperCase()}`,
        type,
        tag: `[*${type.toUpperCase()}*]`,
        tagName: `${type.toUpperCase()}`,
        value: {
          title: type.toLowerCase(),
          uid: uuidv4()
        },
        condition: 'always',
        operator: null,
        questions: [
          {
            uid: null,
            title: null,
            type: '',
            selection: {},
            options: []
          }
        ]
      };

      this.addBlock(newBlock)
    },

    activateBlock(index) {
      this.currentBlock = this.blocks[index];
      this.currentBlockIndex = index;
    },

    changeName() {
      this.changeNameFlag = !this.changeNameFlag;
    },

    addQuestion(tag) {
      const index = this.getBlockIndexByTag('tag',tag)
      const block = {...this.blocks[index]}
      block.questions.push({
        uid: null,
        title: null,
        type: null,
        selection: {},
        options: []
      });

      this.updateBlock({ index, block })
    },

    deleteQuestion(tag, index2) {
      const index = this.getBlockIndexByTag('tag',tag)
      const block = {...this.blocks[index]}
      block.questions.splice(index2, 1)
      this.updateBlock({ index, block })
    },

    copyText(index, tag, method) {
      if (method === 'clip') {
        const ref = `block-tag-${index}`;
        const copyText = document.getElementById(ref);

        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
      } else {
        navigator.clipboard.writeText(tag)
          .catch(err => {
            this.$logger.error(err);
          });
      }
    },

    setBlockTag(block,key,val) {
      const index = this.getBlockIndexByTag('uid',block.uid)

      block[key] = val
      if (!block.name) {
        block.name = 'Block ' + (parseInt(index) + 1);
      }

      block.tagName = block.name.toUpperCase().replace(/\s/g, '_');

      if (['log-data','question-input','image-input','pet-signature-date','pet-signature-img','resp-signature-img','resp-signature-date'].indexOf(block.type) !== -1) {
        block.tag = '[*' + block.tagName + '*]';
        if(block.type.indexOf('-signature-') > 0) {
          block.operator = ""
          block.conditional = "always"
        }
      } else if (block.type === 'static-block') {
        block.tag = '[*' + block.tagName + '*|  |**]';
      }

      this.updateBlock({ index, block })
    }
  }
}