<template>
  <v-expansion-panel :class="bg">
    <v-expansion-panel-header>
      <v-row>
        <v-col
            cols="7"
            class="d-block align-center justify-center"
        >
          <h4 class="text-left align-center justify-center">
            <span class="font-italic pr-1">{{ title }}</span>
            <v-icon>{{ icon }}</v-icon>
          </h4>
          <h5 class="text-left align-center justify-center" :class="{ 'text-danger': !isUnique}">{{ data.title }}</h5>
        </v-col>
        <v-col cols="3" class="d-flex align-center">
          <v-btn
              v-if="data.fieldsIndex !== 0 || data.type !== 'page-break'"
              outlined
              @click.stop="removeInput"
              small
          >
            <v-icon>delete_outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2"/>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-select
        v-if="data.fieldsIndex > 0"
        v-model="data.moveToSelection"
        :items="questionList"
        label="Move Element To After"
        item-text="questionListTitle"
        item-value="fieldsIndex"
        solo
        dense
        @input="moveFormElement"
      />
      <v-alert v-show="!isUnique" type="error">
        Please enter a unique title for this form element.
      </v-alert>
      <v-text-field
        v-if="uniqueTitle"
        :label="uniqueTitle"
        v-model="data.title"
        @keyup="parseTitle(data)"
        @blur="checkForUniqTitle(data)"
      />
      <v-text-field
          v-if="name"
          :label="name"
          v-model="data.name"
      />
      <v-text-field
        v-if="url"
        :label="url"
        v-model="data.videoURL"
      />
      <v-text-field
        v-if="label"
        :label="label"
        v-model="data.label"
      />
      <v-text-field
        v-if="heading"
        :label="heading"
        v-model="data.heading"
      />
      <v-text-field
        v-if="text"
        :label="text"
        v-model="data.text"
      />
      <v-text-field
        v-if="content && typeof data.content === 'string'"
        :label="content"
        v-model="data.content"
      />
      <v-text-field v-if="min" v-model="data.lowerBound" :label="min" type="number"/>
      <v-text-field v-if="max" v-model="data.upperBound" :label="max" type="number"/>
      <v-text-field v-if="data.hasOwnProperty('prefix')" v-model="data.prefix" label="Prefix"/>
      <v-text-field v-if="data.hasOwnProperty('suffix')" v-model="data.suffix" label="Suffix"/>
      <v-switch
        v-if="data.type === 'computed-property'"
        v-model="data.visible"
        label="Option - Visible"
      />
      <v-switch v-if="data.hasOwnProperty('required') && formElementOptions.required.indexOf(data.type) !== -1" label="Required" v-model="data.required" />
      <v-select
        v-if="data.align"
        :items="['center', 'left', 'right']"
        v-model="data.align"
        label="User | Text Align"
      />
      <v-switch
        v-if="data.spaceAfter"
        label="Option - Add Vertical Space After Question"
        v-model="data.spaceAfter"
      />
      <v-img v-if="data.img" :src="data.img"/>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DialogElement',

  props: {
    data: {
      type: Object,
      required: true
    },

    required: {
      type: Boolean,
      default: true,
    },

    questionList: {
      type: Array,
      required: true
    },

    isUnique: {
      type: Boolean,
      default: true
    },

    bg: {
      type: String,
      required: true
    },

    title: {
      type: String,
      default: null
    },

    name: {
      type: String,
      default: null
    },

    content: {
      type: String,
      default: null
    },

    uniqueTitle: {
      type: String,
      default: null
    },

    label: {
      type: String,
      default: null
    },

    prefix: {
      type: String,
      default: null
    },

    suffix: {
      type: String,
      default: null
    },

    min: {
      type: String,
      default: null
    },

    max: {
      type: String,
      default: null
    },

    url: {
      type: String,
      default: null
    },

    heading: {
      type: String,
      default: null
    },

    text: {
      type: String,
      default: null
    },

    icon: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      parseTitleDebounce: undefined
    }
  },

  computed: {
    ...mapGetters({formElementOptions: "getFormElementOptions"})
  },

  methods: {
    ...mapActions({
      toggleOverlay: 'toggleLoadingOverlayStatus',
    }),

    checkForUniqTitle(data) {
      this.parseTitle(data)
      this.$emit('uniqueCheck');
    },

    parseTitle (data) {
      // Use to capitalize & remove spaces from title
      if (this.parseTitleDebounce) {
        clearTimeout(this.parseTitleDebounce);
      }

      this.parseTitleDebounce = setTimeout(() => {
        let parsedTitle = data.title.replace(/\s/g, '-');
        parsedTitle = parsedTitle.toUpperCase();
        data.title = parsedTitle;
        this.parseTitleDebounce = undefined;
      }, 500);
    },

    moveFormElement (moveSelection) {
      this.$emit('move', moveSelection);
    },

    removeInput () {
      this.$emit('remove');
    },
  },
};
</script>
