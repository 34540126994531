<template>
    <div>
        <v-form v-model="userFormValid">
            <v-text-field
                @input="() => (coupon.code = coupon.code.toUpperCase())"
                v-model="coupon.code"
                label="Code"
                :rules="[rules.required]"
                :disabled="operation === 'edit'"
            />
            <v-select
                v-model="coupon.campaign"
                label="Campaign"
                :items="campaignItems"
                @change="handleCampaignChange" />
            <v-text-field
                v-model="coupon.amount"
                label="Amount"
                type="number"
                min="1"
                max="100"
                :rules="[rules.required,rules.range(1,100)]"
                :suffix="showPercent" />
            <v-text-field
                v-model="coupon.limit"
                label="Limit"
                type="number" />
            <v-text-field
                v-model="coupon.startDate"
                label="Start Date"
                placeholder="MM/DD/YYYY"
                :rules="[rules.required,rules.date]"
                v-mask="'##/##/####'" />
            <v-text-field
                v-model="coupon.endDate"
                label="End Date"
                placeholder="MM/DD/YYYY"
                :rules="[rules.required,rules.date]"
                v-mask="'##/##/####'" />
            <v-btn
                :loading="loading"
                :disabled="!userFormValid"
                @click="handleCouponCode"
            >
                Save
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import rulesMixin from "@/mixins/rules.mixin";
const { getDateFormat } = require('@/utils/getDateFormat.util')

export default {
    name: "EditCoupon",

    mixins: [rulesMixin],

    data() {
        return {
            userFormValid: false,
            loading: false,
            coupon: {
                amount: 1,
                campaign: false,
                code: '',
                count: 0,
                limit: 0,
                payall: 'both',
                type: 'percent',
                startDate: getDateFormat(new Date(), 'MM/DD/YYYY'),
                endDate: ''
            },
            campaignItems: [
                {
                    value: false,
                    text: 'No'
                },
                {
                    value: true,
                    text: 'Yes'
                },
            ]
        }
    },

    props: {
        operation: {
            Type: String,
            default: 'add'
        }
    },

    watch: {
        currentCoupon: {
            handler: function (newVal) {
                this.coupon = newVal
            },
            deep: true,
            immediate: true
        }
    },

    computed: {
        ...mapGetters("coupons", ["currentCoupon", "coupons"]),

        showPercent() {
            return this.currentCoupon.type === 'percent' ? '%' : ''
        }
    },

    mounted() {

    },

    methods: {
        ...mapActions("coupons", [
            "setCurrentCoupon",
            "updateCoupon",
            "addCoupon",
            "handleDuplicates"
        ]),

        async handleCouponCode() {
            this.loading = true
            await this.handleDuplicates()
            this.coupon.limit = +this.coupon.limit
            await this.updateCoupon(this.coupon)
            this.loading = false
            this.$emit('saved')
        },

        async handleCampaignChange() {
            if(this.currentCoupon.campaign === false) return
            const formerCampaigns = this.coupons.filter(c => c.code != this.currentCoupon.code && c.campaign === true)
            const toUpdate = []
            for(let c = 0; c < formerCampaigns.length; c++) {
                formerCampaigns[c].campaign = false
                toUpdate.push(this.updateCoupon(formerCampaigns[c]))
            }
            await Promise.all(toUpdate)
        }
    }
}
</script>
