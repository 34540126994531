<template>
  <v-row>
    <v-col cols="12" class="d-flex justify-center align-center">
      <v-container style="margin-bottom:0px;">
        <v-row>
          <v-col cols="9">
            <v-text-field 
              v-model="keyword" 
              :label="label" 
              clearable 
              outlined 
              :error="!hasResults" 
              :error-messages="resultsMessage" 
              @input="queueFilter" 
              @click:clear="queueFilter"/>
          </v-col>
          <v-col cols="3">
            <v-checkbox v-model="caseSensitive" label="Case Sensitive" class="pt-sm-2 pr-sm-4" @change="queueFilter"/>
          </v-col>
        </v-row>
        <v-row style="margin-top:0px;">
          <v-col cols="12" class="d-flex justify-center align-center">
            <span style="margin-right: 20px;">Children Filter:</span>
            <v-radio-group v-model="childrenOptions" :column="false" @change="handleChildrenOptions($event)">
              <v-radio value="" label="All" style="margin-right: 10px;" />
              <v-radio value="both" label="With/without" style="margin-right: 10px;" />
              <v-radio value="with" label="With Kids" style="margin-right: 10px;" />
              <v-radio value="without" label="Without Kids" style="margin-right: 10px;" />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FilterKeyword',
  props: {
    // Must be array of objects to work properly
    arrayToFilter: {
      type: Array,
      default: () => []
    },
    // Pass the names of the properties (as strings in the array) on the objects in the arrayToFilter that you'd like to
    // check for the keyword
    propertiesFilterable: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Filter By Keyword'
    }
  },
  data() {
    return {
      keyword: '',
      childrenOptions: '',
      caseSensitive: false,
      filterDebounce: null,
      hasResults: true,
      resultsMessage: '',
    }
  },
  methods: {
    handleChildrenOptions(evt) {
      this.keyword = evt
      this.queueFilter()
    },

    queueFilter() {
      if (this.filterDebounce) {
        clearTimeout(this.filterDebounce);
      }

      this.filterDebounce = setTimeout(() => {
        const filtered = this.runFilter();
        this.$emit('filtered', filtered)
      }, 300);
    },
    
    runFilter() {
      // Short circuit if there's no keyword
      if (!this.keyword) {
        this.hasResults = true;
        this.resultsMessage = '';
        return this.arrayToFilter;
      }

      // Run filter if there's a keyword
      const arrayToReturn = this.arrayToFilter.filter(el => {
        const keyword = this.caseSensitive ? this.keyword : this.keyword.toLowerCase();
        const propertiesLength = this.propertiesFilterable.length;
        let keywordFound = false;

        for (let i = 0; i < propertiesLength; i++) {
          const propertyValue = this.caseSensitive ? el[this.propertiesFilterable[i]] : el[this.propertiesFilterable[i]].toLowerCase();
          if (propertyValue.includes(keyword)) {
            keywordFound = true;
            break;
          }
        }
        return keywordFound;
      });

      // Update hasResults
      if (arrayToReturn.length === 0 && !!this.keyword) {
        this.hasResults = false;
        this.resultsMessage = 'No results.';
      } else {
        this.hasResults = true;
        this.resultsMessage = '';
      }

      return arrayToReturn
    },
  }
}
</script>
