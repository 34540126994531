<template>
    <div style="padding: 40px;">
        <v-icon style="font-size:200px;">mdi-account-hard-hat</v-icon>
        <header style="font-weight:bold; font-size: 24px; margin-bottom: 20px;">
            Site Maintenance in Progress
        </header>
        <p>
            We're making some improvements, please check back later
        </p>
    </div>
</template>

<script>
export default {
    name: 'Page503',
}
</script>
