const { getEnvironment } = require('@/services/gcloud/getProject')
const Sentry = require('@sentry/browser')

const logit = (type = 'log') => (args) => {
    if(getEnvironment() !== 'prod') {
        console[type].apply(console, Array.prototype.slice.call(args))
    } else if(type === 'error') {
        Sentry.captureException(new Error(Array.prototype.slice.call(args)))
    }
}

const logger = {
    info: (...args) => {
        logit()(args)
    },

    warn: (...args) => {
        logit('warn')(args)
    },

    error: (...args) => {
        logit('error')(args)
    }
}

module.exports = logger