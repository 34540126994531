<template>
  <v-row>
    <v-col cols="12" lg="6" xl="5">
      <v-card tile>
        <v-card-title class="bg-primary white--text">
          Text Editor Action
        </v-card-title>
        <v-card-text style="padding:0;">
          <div class="o-quill-container">
            <QuillEditor
                v-model="activeAction.quillSavedData"
                :options="editorOption"
                :style="{ height: (contentHeight - 104) + 'px'}"
                class="o-quill-editor border-solid-1"
                @change="onEditorChange($event)"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" xl="7" :style="`height: ${contentHeight - 30}px; overflow-y: auto;`">
      <RuleConfiguration
        :parsed-question-list="parsedQuestionList"
        :parsed-text-questions-list="parsedTextQuestionsList"
        :parsed-image-form-elements-list="parsedImageFormElementsList"
        :parent-active-rule="currentRule"
        :parent-active-action="currentAction"
        :doc-id="currentDocIndex"
      />
      <AutoNumbering :active-rule="currentRule" :active-action="currentAction"/>
      <v-card tile class="mt-4">
        <v-card-title class="bg-primary white--text">Position Text Editor</v-card-title>
        <v-card-text class="d-flex">
          <v-row class="mt-2">
            <template
                v-if="activeAction.type === 'text-editor'"
            >
              <v-col cols="12" md="12">
                <v-select v-model="activeAction.location.page" :items="documentPages" label="Document Page"/>
              </v-col>

              <v-col cols="12">
                <h3>First Page Margins</h3>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="activeAction.location.firstPageMargins.top" type="number" label="Top Margin" suffix="in" />
              </v-col>
              <v-col cols="0" md="3">
                <v-text-field v-model="activeAction.location.firstPageMargins.bottom" type="number" label="Bottom Margin" suffix="in" />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="activeAction.location.firstPageMargins.right" type="number" label="Right Margin" suffix="in" />
              </v-col>
              <v-col cols="0" md="3">
                <v-text-field v-model="activeAction.location.firstPageMargins.left" type="number" label="Left Margin" suffix="in" />
              </v-col>

              <v-col cols="12">
                <h3>Subsequent Page Margins</h3>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="activeAction.location.laterPageMargins.top" type="number" label="Top Margin" suffix="in" />
              </v-col>
              <v-col cols="0" md="3">
                <v-text-field v-model="activeAction.location.laterPageMargins.bottom" type="number" label="Bottom Margin" suffix="in" />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="activeAction.location.laterPageMargins.right" type="number" label="Right Margin" suffix="in" />
              </v-col>
              <v-col cols="0" md="3">
                <v-text-field v-model="activeAction.location.laterPageMargins.left" type="number" label="Left Margin" suffix="in" />
              </v-col>

            </template>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {quillEditor} from "vue-quill-editor";
import {QUILL_TOOLBAR_BASIC} from "@/constants/optionsConstants";
import {mapGetters} from "vuex";

import RuleConfiguration from "@/components/TextEditorAction/RuleConfiguration";
import AutoNumbering from "@/components/TextEditorAction/AutoNumbering";

export default {
  name: 'ActionsTextEditor',

  components: {
    QuillEditor: quillEditor,
    RuleConfiguration,
    AutoNumbering,
  },

  props: {
    contentHeight: {
      type: Number,
      default: 400
    },

    parsedQuestionList: {
      type: Array,
      default: () => [],
    },
    parsedTextQuestionsList: {
      type: Array,
      default: () => [],
    },
    parsedImageFormElementsList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      topBottomMargin: 1,
      sidesMargin: 1,
      editorOption: {
        modules: QUILL_TOOLBAR_BASIC,
      },
      rules: {
        pointPosition: value => {
          if (value <= 100 && value > 0) {
            return true;
          } else {
            return "Must be a value between 0 and 100";
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      currentDoc: 'getWorkflowBuilderCurrentDoc',
      currentDocIndex: 'getFormBuilderDocIndex',
      currentRule: 'workflow/getWorkflowBuilderCurrentRule',
      currentAction: 'workflow/getWorkflowBuilderCurrentAction',
    }),

    activeRule() {
      if (this.currentDoc) {
        return this.currentDoc.rules[this.currentRule];
      } else {
        return null;
      }
    },

    activeAction() {
      if (this.currentDoc) {

        // Set text editor action margins if not already set
        if (!this.currentDoc.rules[this.currentRule].actions[this.currentAction].location?.firstPageMargins) {
          this.currentDoc.rules[this.currentRule].actions[this.currentAction].location.firstPageMargins = {
            top: 1.0,
            bottom: 1.0,
            left: 1.0,
            right: 1.0,
          };
        }
        if (!this.currentDoc.rules[this.currentRule].actions[this.currentAction].location?.laterPageMargins) {
          this.currentDoc.rules[this.currentRule].actions[this.currentAction].location.laterPageMargins = {
            top: 1.0,
            bottom: 1.0,
            left: 1.0,
            right: 1.0,
          };
        }

        return this.currentDoc.rules[this.currentRule].actions[this.currentAction];
      } else {
        return null;
      }
    },

    documentPages() {
      const pages = [];
      let docSize = 0;
      if (this.currentDoc) {
        if (this.currentDoc?.existingPages) {
          docSize += this.currentDoc.existingPages.length;
        }
        if (this.currentDoc?.currentSessionPages) {
          docSize += this.currentDoc.currentSessionPages.length;
        }
      }

      for (let i = 1; i <= docSize; i++) {
        pages.push(i);
      }
      return pages;
    }
  },

  methods: {
    onEditorChange(content) {
      this.activeAction.savedData = { text: content.text };
    }
  }
}
</script>