import Vue from "vue";
import questionMatcher from "@/utils/question-matcher.util";

import {
  getCompoundNodeStatus,
  getExpressionNodeStatus,
  getLibraryNodeStatus,
  updateParentNodeProp,
} from "../services/displayLogic";

export const displayLogic = {
  namespaced: true,

  state: () => ({
    rulesTemplate: {},
    formElementsTemplate: {},
    overrideStatus: true,

    displayLogicActiveElement: {
      contains: [],
      hideByDefault: false,
      logical_operator: "and",
      name: "",
      node_type: "",
      not: false,
      parent_node: null,
      uid: "",
    },
    formElements: {},

    displayLogicActiveRule: {},
    logicLibrary: {},
  }),

  mutations: {
    SET_RULES_TEMPLATE(state, payload) {
      state.rulesTemplate = payload;
    },

    SET_RULES_TEMPLATE_BY_UID(state, payload) {
      state.rulesTemplate[payload.uid] = payload;
    },

    SET_FORM_ELEMENTS_TEMPLATE(state, payload) {
      state.formElementsTemplate = payload;
    },

    EDIT_FORM_ELEMENTS_TEMPLATE(state, payload) {
      for (let property in payload.updates) {
        Vue.set(
          state.formElementsTemplate[payload.uid],
          property,
          payload.updates[property]
        );
      }
    },

    SET_OVERRIDE_STATUS(state, payload) {
      state.overrideStatus = payload;
    },

    SET_DISPLAY_LOGIC_ELEMENTS(state, payload) {
      state.formElements = payload;
    },

    ADD_DISPLAY_LOGIC_ELEMENT(state, payload) {
      Vue.set(state.formElements, payload.uid, payload.node);
    },

    EDIT_DISPLAY_LOGIC_ELEMENT(state, payload) {
      for (let property in payload.updates) {
        Vue.set(
          state.formElements[payload.uid],
          property,
          payload.updates[property]
        );
      }
    },

    REMOVE_DISPLAY_LOGIC_ELEMENT(state, uid) {
      Vue.delete(state.formElements, uid);
    },

    SET_LOGIC_LIBRARY(state, payload) {
      state.logicLibrary = payload;
    },

    ADD_LOGIC_LIBRARY_ENTRY(state, payload) {
      Vue.set(state.logicLibrary, payload.uid, payload);
    },

    EDIT_LOGIC_LIBRARY_ENTRY(state, { containsIndex, payload }) {
      for (let property in payload.updates) {
        if (containsIndex < 0) {
          Vue.set(
            state.logicLibrary[payload.uid],
            property,
            payload.updates[property]
          );
        } else {
          Vue.set(
            state.logicLibrary[payload.updates.parent_node].contains[
              containsIndex
            ],
            property,
            payload.updates[property]
          );
        }
      }
    },

    REMOVE_LOGIC_LIBRARY_ENTRY(state, uid) {
      Vue.delete(state.logicLibrary, uid);
    },

    SET_DISPLAY_LOGIC_ACTIVE_ELEMENT(state, payload) {
      state.displayLogicActiveElement = payload;
    },

    SET_DISPLAY_LOGIC_ACTIVE_RULE(state, payload) {
      state.displayLogicActiveRule = payload;
    },
  },

  getters: {
    displayLogicActiveElement: (state) => state.displayLogicActiveElement,

    formElements: (state) => state.formElements,

    formElementsTemplate: (state) => (uid) => {
      return uid ? state.formElementsTemplate[uid] : state.formElementsTemplate;
    },

    rulesTemplate: (state) => state.rulesTemplate,

    rulesTemplateByUid: (state) => (uid) => state.rulesTemplate[uid],

    displayLogicActiveRule: (state) => state.displayLogicActiveRule,

    logicLibrary: (state) => state.logicLibrary,

    logicLibraryEntry: (state) => (uid) => state.logicLibrary[uid],

    overrideStatus: (state) => state.overrideStatus,

    visiblyRequired: (state) => (requiredFields) => {
      return requiredFields.filter(f => state?.formElementsTemplate[f.uid]?.display === true)
    },
  },

  actions: {
    setOverrideStatus({ commit }, payload) {
      commit("SET_OVERRIDE_STATUS", payload);
    },

    async setDisplayLogicElements(
      { commit, rootState },
      { workflowId, collectionName = "forms" }
    ) {
      const db = rootState.firebaseConfig.db;
      if (!workflowId) return;
      const collection = await db
        .collection(collectionName)
        .doc(workflowId)
        .collection("display-logic-elements")
        .get();
      const displayLogicElements = {};
      if (collection.size) {
        collection.docs.map(
          (doc) => (displayLogicElements[doc.id] = doc.data())
        );
        if (displayLogicElements.length === 0) {
          return;
        }
        // Sets parent_node property
        const parentPropsToUpdate = []
        for (let uid in displayLogicElements) {
          parentPropsToUpdate.push(updateParentNodeProp(displayLogicElements[uid]))
        }
        Promise.all(parentPropsToUpdate)
      }

      commit("SET_DISPLAY_LOGIC_ELEMENTS", displayLogicElements);
      return;
    },

    addDisplayLogicElement({ commit }, payload) {
      commit("ADD_DISPLAY_LOGIC_ELEMENT", payload);
    },

    editDisplayLogicElement({ commit }, payload) {
      commit("EDIT_DISPLAY_LOGIC_ELEMENT", payload);
    },

    removeDisplayLogicElement({ commit }, payload) {
      commit("REMOVE_DISPLAY_LOGIC_ELEMENT", payload);
    },

    async setLogicLibrary(
      { commit, rootState },
      { workflowId, collection = "forms" }
    ) {
      const db = rootState.firebaseConfig.db;
      const snapshot = await db
        .collection(collection)
        .doc(workflowId)
        .collection("display-logic-library")
        .get();
      if (snapshot.size) {
        const logicLibrary = {};
        snapshot.docs.map((doc) => (logicLibrary[doc.id] = doc.data()));
        commit("SET_LOGIC_LIBRARY", logicLibrary);
      } else {
        const logicLibrary = {};
        commit("SET_LOGIC_LIBRARY", logicLibrary);
      }
      return;
    },

    addLogicLibraryEntry({ commit }, payload) {
      commit("ADD_LOGIC_LIBRARY_ENTRY", payload);
    },

    editLogicLibraryEntry({ commit, state }, payload) {
      let containsIndex = -1;
      if (payload.parent_node) {
        containsIndex = state.logicLibrary[
          payload.parent_node
        ].contains.findIndex((c) => c.uid === payload.uid);
        if (containsIndex >= 0) {
          commit("EDIT_LOGIC_LIBRARY_ENTRY", {
            containsIndex,
            payload: { updates: payload },
          });
        } else {
          commit("EDIT_LOGIC_LIBRARY_ENTRY", { containsIndex, payload });
        }
      } else {
        commit("EDIT_LOGIC_LIBRARY_ENTRY", { containsIndex, payload });
      }
    },

    removeLogicLibraryEntry({ commit }, payload) {
      commit("REMOVE_LOGIC_LIBRARY_ENTRY", payload);
    },

    setDisplayLogicActiveElement({ state, rootState, commit }, formElementUid) {
      return new Promise((resolve, reject) => {
        if (state.formElements?.[formElementUid]) {
          // If the form element's display logic has previously been loaded
          commit(
            "SET_DISPLAY_LOGIC_ACTIVE_ELEMENT",
            state.formElements[formElementUid]
          );
          resolve();
        } else {
          // If the form element's display logic has not already been loaded
          const ref = rootState.firebaseConfig.db
            .collection("forms")
            .doc(rootState.formBuilderActiveForm.id)
            .collection("display-logic-elements")
            .doc(formElementUid);
          ref
            .get()
            .then((response) => {
              if (response.exists) {
                // If document exists, set it
                let displayLogicData = response.data();

                // Set uid on top-level node
                if (!displayLogicData?.uid) {
                  displayLogicData.uid = formElementUid;
                }

                // Set parent_node on top-level node
                if (!displayLogicData?.parent_node) {
                  displayLogicData.parent_node = null;
                }

                if (!displayLogicData?.hideByDefault) {
                  displayLogicData.hideByDefault = false;
                }

                updateParentNodeProp(displayLogicData);

                commit("SET_DISPLAY_LOGIC_ACTIVE_ELEMENT", displayLogicData);
                commit("ADD_DISPLAY_LOGIC_ELEMENT", {
                  uid: formElementUid,
                  node: displayLogicData,
                });
                resolve();
              } else {
                // If document doesn't exist, create a new local version

                const newDisplayLogic = {
                  uid: formElementUid,
                  name: "Root Node",
                  parent_node: null,
                  node_type: "compound",
                  logical_operator: "and",
                  not: false,
                  contains: [],
                  hideByDefault: false,
                };

                commit("SET_DISPLAY_LOGIC_ACTIVE_ELEMENT", newDisplayLogic);
                commit("ADD_DISPLAY_LOGIC_ELEMENT", {
                  uid: formElementUid,
                  node: newDisplayLogic,
                });
                resolve();
              }
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },

    setDisplayLogicActiveRule({ commit }, ruleUid) {
      commit("SET_DISPLAY_LOGIC_ACTIVE_RULE", ruleUid);
    },

    async buildTemplates({ dispatch, state }, payload) {
      const { formElements } = payload;

      // Now build template for form element display status
      const formElementsTemplate = formElements
        .map((fe) => fe.uid)
        .reduce((map, uid) => {
          map[uid] = {
            display: true, // Computed after looping over the display logic rules below from rulesApplicable
            rulesAffected: [], // Populated while looping over the display logic rules below (rules whose logic is dependent on this form element's value)
          };
          return map;
        }, {});

      // Set formElementsTemplate so it's accessible for later functions via the state
      dispatch("setFormElementsTemplate", { formElementsTemplate });

      // Get display logic rules from subcollection on workflow document called display-logic-elements
      if (Object.keys(state.formElements).length === 0) {
        const collectionName =
          payload.workflowType === "published" ? "workflows-user" : "forms";
        await dispatch("setDisplayLogicElements", {
          workflowId: payload.workflowId,
          collectionName,
        });
      }

      // Now build template for rules
      await dispatch("buildRulesTemplate", payload);
      return;
    },

    async buildRulesTemplate(
      { dispatch, state, getters },
      { formElements, workflowType }
    ) {
      // Holds all rules by reference to uid
      let rulesTemplate = {};

      for (let i = 0; i < formElements.length; i++) {
        const currentElement = formElements[i];
        const uid = currentElement.uid;
        const displayLogicConfig = state.formElements?.[uid] ?? null;
        const hideByDefault = state.formElements?.[uid]?.hideByDefault ?? true;
        rulesTemplate[currentElement.uid] = {
          status: await dispatch("getLogicNodeStatus", {
            node: displayLogicConfig,
            workflowType,
            formElementUid: currentElement.uid,
          }), // workflowType specifies which
          hideByDefault,
          uid: currentElement.uid,
        };
      }

      // Update local formElementsTemplate variable from state because it was updated by getLogicNodeStatus
      const formElementsTemplate = getters.formElementsTemplate();

      dispatch("setRulesTemplate", { rulesTemplate });
      dispatch("updateFormElementsDisplayStatus", {
        formElementsTemplate,
        workflowType,
        formElementsToUpdate: "all",
      });
      return true
    },

    setRulesTemplate({ commit }, payload) {
      commit("SET_RULES_TEMPLATE", payload.rulesTemplate);
    },

    setRulesTemplateByUid({ commit }, payload) {
      commit("SET_RULES_TEMPLATE_BY_UID", payload);
    },

    setFormElementsTemplate({ commit, state }, payload) {
      let formElementsTemplate;
      if (payload?.formElementsTemplate) {
        formElementsTemplate = payload.formElementsTemplate;
      } else {
        formElementsTemplate = state.formElementsTemplate;
      }

      commit("SET_FORM_ELEMENTS_TEMPLATE", formElementsTemplate);
    },

    async updateFormElements(
      { dispatch, state, getters },
      { formElementsToUpdate, workflowType, formElementsTemplate }
    ) {
      for (let e = 0; e < formElementsToUpdate.length; e++) {
        const uid = formElementsToUpdate[e];
        const currentRule = state.rulesTemplate[uid];
        if (!currentRule || !getters.formElements[currentRule.uid]) continue;
        dispatch("getLogicNodeStatus", {
          node: getters.formElements[currentRule.uid],
          workflowType,
          formElementUid: currentRule.uid,
        }).then((status) => {
          const formElementDisplayStatus = currentRule.hideByDefault
            ? !status
            : status;
          formElementsTemplate[uid].display = formElementDisplayStatus;
          dispatch("setFormElementsTemplate", { formElementsTemplate });
        });
      }

      dispatch("setRulesTemplate", { rulesTemplate: state.rulesTemplate });
      return true
    },

    async updateFormElementsDisplayStatus({ dispatch, state }, payload) {
      const workflowType = payload.workflowType;

      let formElementsTemplate;
      if (payload.formElementsTemplate) {
        formElementsTemplate = payload.formElementsTemplate;
      } else {
        formElementsTemplate = Object.assign({}, state.formElementsTemplate);
      }

      if (state.overrideStatus) {
        for (const uid in formElementsTemplate) {
          formElementsTemplate[uid].display = true;
        }
        dispatch("setFormElementsTemplate", { formElementsTemplate });
        return;
      } else {
        if (payload.formElementsToUpdate === "all") {
          await dispatch("updateFormElements", {
            formElementsToUpdate: Object.keys(formElementsTemplate),
            workflowType,
            formElementsTemplate,
          });
        } else if (Array.isArray(payload.formElementsToUpdate)) {
          const formElementsToUpdate = payload.formElementsToUpdate;
          const formElementChecker = formElementsToUpdate[0];
          for (const [key, value] of Object.entries(formElementsTemplate)) {
            const ruleFound =
              value.rulesAffected.findIndex(
                (rule) => rule === formElementChecker
              ) >= 0;
            if (ruleFound) formElementsToUpdate.push(key);
          }

          await dispatch("updateFormElements", {
            formElementsToUpdate,
            workflowType,
            formElementsTemplate,
          });
        } else {
          dispatch("setFormElementsTemplate", { formElementsTemplate });
        }

        return true
      }
    },

    async getLogicNodeStatus({ dispatch, commit, getters }, payload) {
      const workflowType = payload.workflowType;
      const formElementUid = payload.formElementUid;
      const currentLogicNode = payload.node;

      if (currentLogicNode === null) {
        return true;
      } else {
        let logicNodeStatus = true;

        switch (currentLogicNode.node_type) {
          case "compound":
            logicNodeStatus = await getCompoundNodeStatus(
              {
                dispatch,
                commit,
                getters,
              },
              {
                compoundNode: currentLogicNode,
                workflowType: workflowType,
                formElementUid: formElementUid,
              }
            );
            break;
          case "expression":
            logicNodeStatus = await getExpressionNodeStatus(
              {
                dispatch,
                commit,
                getters,
              },
              {
                expressionNode: currentLogicNode,
                workflowType: workflowType,
                formElementUid: formElementUid,
              }
            );
            break;
          case "library":
            logicNodeStatus = await getLibraryNodeStatus(
              { dispatch, commit, getters },
              {
                libraryNode: currentLogicNode,
                workflowType: workflowType,
                formElementUid: formElementUid,
              }
            );
            break;
        }

        return logicNodeStatus;
      }
    },

    checkQuestionForMatch({ rootState }, payload) {
      const logicQuestion = payload.question;
      if (payload.workflowType === "published") {
        return questionMatcher(
          rootState.workflowPublished.formData,
          logicQuestion
        );
      } else {
        return questionMatcher(rootState.formBuilderActiveForm, logicQuestion);
      }
    },
  },
};
