<template>
  <tr>
    <td align="center" valign="top" id="templateFooter">
        <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer">
            <tr>
                <td valign="top" class="footerContainer">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnFollowBlock" style="min-width:100%;">
                            <tr>
                                <td align="center" valign="top" style="padding:9px" class="mcnFollowBlockInner">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnFollowContentContainer" style="min-width:100%;">
                                        <tr>
                                            <td align="center" style="padding-left:9px;padding-right:9px;">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;" class="mcnFollowContent">
                                                    <tr>
                                                        <td align="center" valign="top" style="padding-top:9px; padding-right:9px; padding-left:9px;">
                                                            <table align="center" border="0" cellpadding="0" cellspacing="0">
                                                                <tr>
                                                                    <td align="center" valign="top">                            
                                                                        <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnFollowStacked" style="display:inline;">
                                                                            <tr>
                                                                                <td align="center" valign="top" class="mcnFollowIconContent" style="padding-right:10px; padding-bottom:9px;">
                                                                                    <a href="https://www.facebook.com/simpleending/" target="_blank"><img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-facebook-96.png" alt="Facebook" class="mcnFollowBlockIcon" width="48" style="width:48px; max-width:48px; display:block;"></a>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                        <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnFollowStacked" style="display:inline;">
                                                                            <tr>
                                                                                <td align="center" valign="top" class="mcnFollowIconContent" style="padding-right:10px; padding-bottom:9px;">
                                                                                    <a href="mailto:Community@SimpleEnding.com" target="_blank"><img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-forwardtofriend-96.png" alt="Email" class="mcnFollowBlockIcon" width="48" style="width:48px; max-width:48px; display:block;"></a>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                        <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnFollowStacked" style="display:inline;">
                                                                            <tr>
                                                                                <td align="center" valign="top" class="mcnFollowIconContent" style="padding-right:10px; padding-bottom:9px;">
                                                                                    <a href="https://www.instagram.com/simpleendingdivorce/" target="_blank"><img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-instagram-96.png" alt="Link" class="mcnFollowBlockIcon" width="48" style="width:48px; max-width:48px; display:block;"></a>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                        <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnFollowStacked" style="display:inline;">
                                                                            <tr>
                                                                                <td align="center" valign="top" class="mcnFollowIconContent" style="padding-right:0; padding-bottom:9px;">
                                                                                    <a href="https://simpleending.com/" target="_blank"><img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-light-link-96.png" alt="Website" class="mcnFollowBlockIcon" width="48" style="width:48px; max-width:48px; display:block;"></a>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                            <tr>
                                <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                        <tr>
                                            <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">
                                                <em>&nbsp;SimpleEnding™</em><em>&nbsp;All rights reserved.</em><br>
                                                <strong>Our mailing address is:</strong><br>
                                                2100 West Plesant Grove BLVD, Suite 450 Plesant Grove, Utah 84062
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
            </tr>
        </table>        
    </td>
  </tr>
</template>

<script>
export default {
  name: "EmailFooter",

  props: {
    link: {
      type: Object,
      default: () => ({
        name: "login",
        url: ".sign-in",
      }),
    },
  },
};
</script>
