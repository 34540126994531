<template>
    <div style="margin-top:20px;display:flex;align-items: center;justify-content: center;">
        <cost-info
            price="$295"
            :tab="{ title: 'Initial Payment', bg: '#63DBD1', fg: 'black'}"
            :items="items"
            summary="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            image="/img/solo.svg"
        />
    </div>
</template>

<script>
import CostInfo from '../components/CostInfo.vue';

export default {
  components: { CostInfo },
    name: 'Practice',

    data() {
        return {
            items: [
                {
                    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                    has: true,
                },
                {
                    title: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco',
                    has: true,
                },
                {
                    title: 'Excepteur sint occaecat cupidatat non proident',
                    has: false,
                },
            ]
        }
    }
}
</script>