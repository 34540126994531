import { mapGetters, mapActions } from 'vuex';
import DisplayLogicLevel from "@/components/WorkflowBuilder/DisplayLogic/DisplayLogicLevel";
import DisplayLogicExpression from "@/components/WorkflowBuilder/DisplayLogic/DisplayLogicExpression";
import DisplayLogicLibrary from "@/components/WorkflowBuilder/DisplayLogic/DisplayLogicLibrary";

export default {
  components: {DisplayLogicLibrary, DisplayLogicExpression, DisplayLogicLevel},

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    pageNames: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    parsedQuestionList: {
      type: Array,
      default: () => [],
    },
    contentHeight: {
      type: Number,
      default: 500,
    }
  },

  data() {
    return {
      pageName: '',
      filterBy: '',
      searchDisplayLogic: "",
      activeFormElement: 1,
      selectedIndex: 0,
      lowestActiveLevel: 1,

      activeNode: null,
      activeNodeLevelOne: null,
      activeNodeLevelTwo: null,
      activeNodeLevelThree: null,
      activeNodeLevelFour: null,
      activeNodeLevelFive: null,
      activeNodeLevelSix: null,
      activeNodeLevelSeven: null,
      activeNodeLevelEight: null,
      activeNodeLevelNine: null,
      activeNodeLevelTen: null,

      logicalOperators: ['and', 'or', 'x-or'],
      logicalOperatorSelection: '',
      conditionals: ['if', 'always', 'else'],
      conditionalSelection: '',
      answers: [],
      answerSelection: null,
      numConversion: {
        1: 'One',
        2: 'Two',
        3: 'Three',
        4: 'Four',
        5: 'Five',
        6: 'Six',
        7: 'Seven',
        8: 'Eight',
        9: 'Nine',
        10: 'Ten',
      }
    }
  },

  computed: {
    ...mapGetters('displayLogic', ['displayLogicActiveElement', 'rulesTemplateByUid', 'formElements']),

    showSearch() {
      return this.fields.length > 10
    },

    displayLogicColor() {
      return (uid) => {
        const logic = this.formElements?.[uid]?.contains?.[0]
        const completeQuestions = logic?.questions?.filter(q => q?.uid && (q?.selection?.comparison || q?.selection?.answer))
        return logic?.condition === 'always' || logic?.condition && logic?.logical_operator && logic?.questions?.length === completeQuestions?.length ? 'primary' : 'error'
      }
    },

    hasDisplayLogic() {
      return (uid) => this.formElements?.[uid]?.contains?.length > 0
    },

    displayLogicItems() {
      let currentFields = [...this.fields]
      if(this.searchDisplayLogic?.length >= 3) {
        const search = this.searchDisplayLogic.toLowerCase()
        currentFields = this.fields.filter(item => (item.title && item.title.toLowerCase().indexOf(search) >= 0) || (item.type && item.type.toLowerCase().indexOf(search) >= 0))
      }

      if(this.filterBy?.length > 0) {
        const filterTxt = this.filterBy.toLowerCase()
        currentFields = currentFields.filter(f => this.filterOptions(f, filterTxt))
      }

      if(this.pageName?.length > 0) {
        const pageIndex = this.pageNames.findIndex(p => p === this.pageName)
        currentFields = currentFields.filter(f => f.page === pageIndex + 1)
      }

      return currentFields
    },
  },

  mounted() {
    // Activate first form element
    this.activateFormElement(this.activeFormElement);
  },

  methods: {
    ...mapActions("displayLogic", ["setRulesTemplateByUid"]),

    filterOptions(item, filterTxt) {
      return filterTxt === '' ? true : this.completeDisplayLogicChecker(filterTxt, item.uid)
    },

    completeDisplayLogicChecker(filterTxt, uid) {
      return this.hasDisplayLogic(uid) && (this.displayLogicColor(uid) === 'primary' && filterTxt === 'completed' || this.displayLogicColor(uid) === 'error' && filterTxt === 'incomplete')
    },

    handleDefaultDisplayLogic(uid, hideByDefault) {
      const ruleTemplate = this.rulesTemplateByUid(uid)
      ruleTemplate.hideByDefault = hideByDefault
      this.setRulesTemplateByUid(ruleTemplate)
    },
    
    activateFormElement(index) {
      if(this.fields.length === 0) return
      if (index !== this.activeFormElement) {
        this.clearNodes(1);
        this.activeNode = null;
      }      
      const correctIndex = this.fields.findIndex(f => f.fieldsIndex === index)
      this.activeFormElement = correctIndex < 0 ? 0 : correctIndex;
      this.selectedIndex = index;
      const fieldUid = this.fields[this.activeFormElement].uid;
      this.$store.dispatch('displayLogic/setDisplayLogicActiveElement', fieldUid);
    },

    activateNode(node, level) {
      let newLevel = level;
      this.lowestActiveLevel = level;

      if (node.node_type === 'compound' && newLevel < 10) {
        newLevel++;
        const propertyName = 'activeNodeLevel' + this.numConversion[newLevel];
        this[propertyName] = node;
      } else if (node.node_type === 'expression') {
        // this.activeNode = node;
      } else if (node.node_type === 'library') {
        // this.activeNode = node;
      }

      this.activeNode = node;

      // Clear deeper nodes
      newLevel++;
      this.clearNodes(newLevel);
    },

    deleteActiveNode(node, level) {

      if (node.node_type === 'compound' && level < 10) {
        const propertyName = 'activeNodeLevel' + this.numConversion[level];
        this[propertyName] = null;
      } else if (node.node_type === 'expression') {
        // this.activeNode = node;
      } else if (node.node_type === 'library') {
        // this.activeNode = node;
      }

      // Clear deeper nodes
      level++;
      this.clearNodes(level);

      this.activeNode = null;
    },

    addQuestion() {
      this.rule.questions.push({
        uid: null,
        title: null,
        type: null,
        selection: {},
        options: []
      });
    },

    setLibraryReference(payload) {
      this.$set(this.activeNode, 'library_uid', payload.uid);
      this.$set(this.activeNode, 'name', payload.name);
    },

    clearNodes(startingLevel) {
      while (startingLevel <= 10) {
        const propertyName = 'activeNodeLevel' + this.numConversion[startingLevel];
        this[propertyName] = null;
        startingLevel++;
      }
    },

    nodeName(node) {
      return node.name ?? (node.node_type === 'compound' ? `${node.node_type.toUpperCase() + node.logical_operator} - ${node.contains.length}` : `${node.node_type.toUpperCase()}`);
    },
  }
}