const { setTextEditorFonts } = require('./set-text-editor-fonts.util')
const { addImgToPdf } = require("./add-img-to-pdf.util")
const expressionatorMethod = require("./expressionator.util")
const handleFormatting = require('./handle-formatting.util')
const { getImageHeightWidth } = require("./get-img-height-width.util")

const textEditorPdfInjector = async (newPDF, pdfWidth, pdfHeight, formData, action, signatureImgMap, getDataUrlFromImageFormElement) => {
  // Set margins (default to 1.0 inch margins)
  const fontSize = parseFloat(action.fontSize) || 12;
  let lineSpacing = 1.15;
  newPDF.setLineHeightFactor(lineSpacing);
  const lineHeight = (fontSize / 72.0) * lineSpacing;
  
  
  /* Text is placed with the vertical offset as the bottom of the line, so it is necessary to
      add the height of a line for the text to be positioned correctly. */
  let topMargin = (parseFloat(action.location?.firstPageMargins?.top) || 1.0);
  let bottomMargin = (parseFloat(action.location?.firstPageMargins?.bottom) || 1.0);
  let rightMargin = (parseFloat(action.location?.firstPageMargins?.right) || 1.0);
  let leftMargin = (parseFloat(action.location?.firstPageMargins?.left) || 1.0);
  let verticalOffset = topMargin + lineHeight;
  let horizontalOffset = (parseFloat(action.location?.firstPageMargins?.left) || 1.0);
  let linesWidth = pdfWidth - (parseFloat(action.location?.firstPageMargins?.left) + parseFloat(action.location?.firstPageMargins?.right) || 2.0);
  let currentPageNum = 1;
  let actionText = [];
  if (action && action.parsedActionText) {
    actionText = action.parsedActionText;
  }
  
  // Set font family
  setTextEditorFonts(newPDF, action)
  newPDF.setFontSize(fontSize);
  newPDF.setLineWidth(0.02);
  
  if (actionText && actionText.length > 0) {
    const actionTextSize = actionText.length;
    for (let j = 0; j < actionTextSize; j++) {
      let currentTextBlock = actionText[j];
      let currentTextBlockProgressIndex = 0;
  
      if (typeof currentTextBlock === 'string') {
        currentTextBlock = currentTextBlock.replaceAll("\t", "    ");
        let lines = newPDF.splitTextToSize(currentTextBlock, linesWidth);
  
        let emptyLines = true;
        let shiftStatus = false;
        let linesLength = lines.length;
        for (let k = 0; k < linesLength; k++) {
          if (lines[k].replace(/\s/g, '') !== '') {
            emptyLines = false;
            shiftStatus = true;
          }
        }
  
        let linesVerticalOffset = linesLength * lineHeight;
  
        // Only add lines if non-empty
        if (!emptyLines) {
          let totalOffset = linesVerticalOffset + verticalOffset;
          let bottomMarginPosition = (pdfHeight - bottomMargin);
  
          // If adding lines would put text into bottom margin, add new page and split lines
          if (totalOffset > bottomMarginPosition) {
  
            let linesPastMargin = Math.floor((totalOffset - bottomMarginPosition) / lineHeight);
  
            const linesBeforeMargin = lines.length - linesPastMargin;
  
            let linesCurrentPage = lines.slice(0, linesBeforeMargin);
            currentTextBlockProgressIndex += linesCurrentPage.join(' ').length;
  
            // Put lines that fit on current page
            handleFormatting(newPDF, {
              horizontalOffset,
              verticalOffset,
              linesCurrentPage,
              linesWidth,
              lineHeight
            })
  
            // Update margins with values for subsequent pages
            topMargin = (parseFloat(action.location?.laterPageMargins?.top) || 1.0) + lineHeight;
            bottomMargin = (parseFloat(action.location?.laterPageMargins?.bottom) || 1.0);
            rightMargin = (parseFloat(action.location?.laterPageMargins?.right) || 1.0);
            leftMargin = (parseFloat(action.location?.laterPageMargins?.left) || 1.0);
            horizontalOffset = (parseFloat(action.location?.laterPageMargins?.left) || 1.0);
            linesWidth = pdfWidth - (parseFloat(action.location?.laterPageMargins?.left) + parseFloat(action.location?.laterPageMargins?.right) || 2.0);
            let linesHeight = Math.floor((pdfHeight - (parseFloat(action.location?.laterPageMargins?.top) + parseFloat(action.location?.laterPageMargins?.bottom) || 2.0)) / lineHeight);
  
            // Evaluate how many lines are left
            let nextPageSubstring = currentTextBlock.substring(currentTextBlockProgressIndex);
            let linesNextPage = newPDF.splitTextToSize(nextPageSubstring, linesWidth);
  
            // Add as many pages as needed to fit the rest of the text editor action
            while (linesNextPage.length > 0) {
              // Add new page & set vertical offset
              newPDF.addPage();
              currentPageNum++;
              verticalOffset = topMargin;
  
              let foundNonEmptyLine = false;
  
              const unfilteredCurrentPageLines = linesNextPage.slice(0, linesHeight);
  
              let currentPageLines = unfilteredCurrentPageLines.filter(line => {
                const lineEmpty = line.replace(/\s/g, '') === '';
                if (!lineEmpty) {
                  foundNonEmptyLine = true;
                }
                return !lineEmpty || foundNonEmptyLine;
              });
  
              //currentPageLines = currentPageLines.slice(0, linesHeight);
  
              linesNextPage = linesNextPage.slice(linesHeight);
  
              if (currentPageLines.length !== 0) {
                // Put lines that didn't fit on previous page
                handleFormatting(newPDF, {
                  horizontalOffset,
                  verticalOffset,
                  linesCurrentPage: currentPageLines,
                  linesWidth,
                  lineHeight
                })
                // Update progress through current text block
                currentTextBlockProgressIndex += currentPageLines.join(' ').length;
  
                // Update linesVerticalOffset so it only includes height of lines on next page
                linesVerticalOffset = (linesNextPage.length + 0.5) * lineHeight;
  
                // Set verticalOffset for any image blocks that may come after (should be set to where the lines for the current page end)
                verticalOffset = currentPageLines.length * lineHeight + topMargin;
              } else {
                // Update linesVerticalOffset so it only includes height of lines on next page, 0 in this case
                linesVerticalOffset = 0;
              }
            }
  
          } else {
            handleFormatting(newPDF, {
              horizontalOffset,
              verticalOffset,
              linesCurrentPage: lines,
              linesWidth,
              lineHeight
            })
          }
  
        }
  
        if (shiftStatus) {
          verticalOffset += linesVerticalOffset;
        }
  
      } else {
        // If there's an non-string (image or item-table) input
        const nonStringInput = actionText[j];
        const formElement = formData.fields.find(el => {
          if (el?.uid && nonStringInput?.Uid && el.uid === nonStringInput.Uid) {
            return true;
          } else return el.title === nonStringInput.Title;
        });
  
        switch (nonStringInput.Type) {
          case 'item-table':
            let table;
  
            if (formElement) {
              // Retrieve table data from appropriate form element
              let tableHead = formElement.headers.filter(x => x.value !== 'actions').map(header => header.text);
  
              let tableBody;
              if (formElement.variant === 'user-input-allowed') {
                tableBody = formElement.inputRows.map(row => {
                  // Remove action column from item row
  
                  const newRow = [];
  
                  for (let item of formElement.headers.filter(x => x.value !== 'actions')) {
                    newRow.push(expressionatorMethod(row[item.value], formData.fields) ?? '');
                  }
  
                  return newRow;
                });
              } else {
                const rowsReadOnly = formElement.readOnlyRows.map(row => {
                  // Remove action column from item row
                  const rowFiltered = Object.assign({}, row);
                  delete rowFiltered.actions;
  
                  const newRow = formElement.headers.filter(x => x.value !== 'actions').map(item => expressionatorMethod(rowFiltered[item.value], formData.fields) ?? '')
  
                  return newRow;
                });
                const rowsUserInput = formElement.inputRows.map(row => {
                  // Remove action column from item row
                  const rowFiltered = Object.assign({}, row);
                  delete rowFiltered.actions;
  
                  const newRow = [];
  
                  for (let item in formElement.headers.filter(x => x.value !== 'actions')) {
                    newRow.push(expressionatorMethod(rowFiltered[item.value], formData.fields) ?? '');
                  }
  
                  return newRow;
                });
  
                tableBody = [...rowsReadOnly, ...rowsUserInput];
              }
  
              // Now put table together
              table = {
                head: [tableHead],
                body: tableBody,
              };
  
              // Now that we've got the data from the table, place it on PDF
              if (table) {
                const verticalOffsetBeforeTable = verticalOffset - lineHeight;
                verticalOffset = verticalOffsetBeforeTable;
  
                table.margin = {
                  top: topMargin,
                  right: rightMargin,
                  bottom: bottomMargin,
                  left: leftMargin,
                };
                table.startY = verticalOffsetBeforeTable;
  
                let pagesDrawn = 0;
  
                table.didDrawPage = (data) => {
                  topMargin = (parseFloat(action.location?.laterPageMargins?.top) || 1.0) + lineHeight;
                  bottomMargin = (parseFloat(action.location?.laterPageMargins?.bottom) || 1.0);
                  rightMargin = (parseFloat(action.location?.laterPageMargins?.right) || 1.0);
                  leftMargin = (parseFloat(action.location?.laterPageMargins?.left) || 1.0);
                  horizontalOffset = (parseFloat(action.location?.laterPageMargins?.left) || 1.0);
                  linesWidth = pdfWidth - (parseFloat(action.location?.laterPageMargins?.left) + parseFloat(action.location?.laterPageMargins?.right) || 2.0);
                  data.settings.margin.top = topMargin;
                  data.settings.margin.bottom = bottomMargin;
                  pagesDrawn++;
                };
  
                newPDF.autoTable(table);
  
                if (pagesDrawn >= 1) {
                  verticalOffset = newPDF.lastAutoTable.finalY;
                } else {
                  newPDF.lastAutoTable.head.forEach(row => {
                    verticalOffset += row.height;
                  });
                  newPDF.lastAutoTable.body.forEach(row => {
                    verticalOffset += row.height;
                  });
                  newPDF.lastAutoTable.foot.forEach(row => {
                    verticalOffset += row.height;
                  });
                }
              }
            }
            break;
          case 'pet-signature-img':
          case 'resp-signature-img':
            const signature = signatureImgMap[nonStringInput.Type]
            const dimensions = getImageHeightWidth(signature);
            const { vo, pg } = addImgToPdf(
              dimensions,
              newPDF,
              signature,
              currentPageNum,
              verticalOffset,
              horizontalOffset,
              linesWidth / 3,
              lineHeight,
              topMargin,
              bottomMargin,
              pdfHeight,
              "PNG"
            );
            verticalOffset = vo;
            currentPageNum = pg;
            break;
          case 'image-input':
            let image;
  
            if (formElement) {
              // Retrieve image as dataURL from appropriate form element
              image = await getDataUrlFromImageFormElement(formElement);
  
              // Now that we've got the dataURL(s) from our selected source, place it on PDF
              if (!!image && !Array.isArray(image)) {
                const dimensions = getImageHeightWidth(image);
                //const { vo,pg } = addImgToPdf(dimensions, newPDF, image, currentPageNum, verticalOffset, horizontalOffset, linesWidth / 2, lineHeight, topMargin, bottomMargin, pdfHeight)
                const { vo, pg } = addImgToPdf(
                  dimensions,
                  newPDF,
                  image,
                  currentPageNum,
                  verticalOffset,
                  horizontalOffset,
                  linesWidth,
                  lineHeight,
                  topMargin,
                  bottomMargin,
                  pdfHeight
                );
                verticalOffset = vo
                currentPageNum = pg
              } else if (!!image && Array.isArray(image)) {
                // TODO: handle when multiple photos are selected
              }
            }
            break;
        }
      }
    }
  }
}

module.exports = textEditorPdfInjector