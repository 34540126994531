import axios from 'axios';
import { getProject } from './gcloud/getProject';

export default function autoSaveField(body, projectUrl = getProject(), httpRequest = axios) {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };

  return httpRequest({
    url: `${projectUrl}/saveField`,
    method: 'POST',
    headers: config,
    data: JSON.stringify(body)
  })  
}