<template>
  <v-row>
    <v-col cols="12" md="6" lg="3" xl="2">
      <ActionsList
        :current-page-number="currentPageNum"
        :default-height="actionListHeight"
        @page-change="updatePageNumber"
      />
      <BlockChecker 
        v-if="needsBlockChecker"
        :text-editor-data="activeAction.quillSavedData" />
    </v-col>
    <v-col v-if="activeAction" cols="12" md="6" lg="9" xl="10">
      <ActionsPdfPlacement
        v-if="types.includes(activeAction.type)"
        :parsed-image-form-elements-list="parsedImageFormElementsList"
        :parsed-text-questions-list="parsedTextQuestionsList"
        :parsed-question-list="parsedQuestionList"
        :current-action="currentAction"
        :current-rule="currentRule"
        :current-doc="currentDoc"
        :current-page="currentPageNum"
        :content-height="contentHeight"
        @page-change="updatePageNumber"
      />
      <ActionsTextEditor
        v-if="activeAction.type === 'text-editor'"
        :parsed-image-form-elements-list="parsedImageFormElementsList"
        :parsed-text-questions-list="parsedTextQuestionsList"
        :parsed-question-list="parsedQuestionList"
        :content-height="contentHeight"
      />
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-case-declarations */
import actionsConfigurationMixin from '@/mixins/components/actionsConfiguration.mixin'

export default {
  name: "ActionsConfiguration",

  mixins: [actionsConfigurationMixin]
};
</script>
