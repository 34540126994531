import axios from 'axios';

// const apiClient = axios.create({
//   baseURL: 'https://www.googleapis.com/youtube/v3/videos',
//   withCredentials: false, // This is the default
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'application/json'
//   }
// });

export default {
  getVideo (videoID, httpRequest = axios) {
    return httpRequest.get('https://www.googleapis.com/youtube/v3/videos?part=snippet&key=AIzaSyD_EJ7qsuOvKCFFWpx2isY1lQxNNsM9WFc&id=' + videoID);
  }
};
