<template>
    <div id="wait">
        <h1>Sorry, please wait for the petitioner to start the process.</h1>
        <img :src="require(`@/assets/images/almost-there-bg.png`)" alt="img" width="300" class="mb-10">        
        <article>
            You will receive an email as soon as your spouse has completed the questionnaire.
If you do not see the questionnaire. Please communicate with your spouse to start the initial process.
        </article>
        <article>
            If it has been some time, please follow-up with your spouse.
        </article>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: "RespondentWait",

    async created() {
        await this.establishUserAuth()
    },

    methods: {
        ...mapActions("profile", ["establishUserAuth"]),
    }
}
</script>

<style lang="scss">
    #wait {
        margin: 40px 100px;

        article {
            margin-bottom: 20px;
        }
    }
</style>