<template>
  <div>done</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "RunDBScripts",

  computed: {
    ...mapGetters(["getFirebaseConfig"])
  },

  async mounted() {
    const formRef = this.getFirebaseConfig.db.collection("forms").doc('BrRGyJPdzM7HVH8FQisU')
    const fieldsRef = formRef.collection('fields')
    const docsRef = formRef.collection('docs')
    const fields = await fieldsRef.get()
    const docs = await docsRef.get()
    fields.forEach(async f => {
      await fieldsRef.doc(f.id).delete()
    })

    docs.forEach(async d => {
      await docsRef.doc(d.id).delete()
    })

    this.getFirebaseConfig.db.collection("workflows-user").doc("zGYl9W45pVCdigNjIbL7").collection('fields').get().then(fields => {
      fields.forEach(f => {
        const field = f.data()
        fieldsRef.doc(field.uid).set(field)
      })
    })

    this.getFirebaseConfig.db.collection("workflows-user").doc("zGYl9W45pVCdigNjIbL7").collection('docs').get().then(docs => {
      docs.forEach(d => {
        const doc = d.data()
        docsRef.doc(doc.uid).set(doc)
      })
    })    
  }
}
</script>