export const alert = {
  namespaced: true,

  state: () => ({
    type: "error",
    message: "",
    show: false,
  }),

  getters: {
    alert: (state) => ({
      type: state.type,
      message: state.message,
    }),

    showAlert: (state) => state.show,
  },

  mutations: {
    SET_SHOW(state, payload) {
      state.show = payload;
    },

    SET_TYPE(state, payload) {
      state.type = payload;
    },

    SET_MESSAGE(state, payload) {
      state.message = payload;
    },
  },

  actions: {
    async setAlert({ commit }, { type, message, show = true }) {
      commit("SET_TYPE", type);
      commit("SET_SHOW", show);
      commit("SET_MESSAGE", message);
    },

    async setShow({ commit }, show) {
      commit("SET_SHOW", show);
    },
  },
};
