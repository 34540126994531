import axios from 'axios';
import { getProject } from '../gcloud/getProject';

const convert = (file, httpRequest = axios, projectUrl = getProject()) => {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'multipart/form-data'
  };

  const fd = new FormData()
  fd.append("pdf", file)

  return httpRequest({
    url: `${projectUrl}/convertPdfToPng`,
    method: 'POST',
    headers: config,
    data: fd
  })
}

export default { convert }