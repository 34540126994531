import axios from 'axios';
import { getProject } from './gcloud/getProject';

export default async function getErrorPage(type = '500', number = '503', projectUrl = getProject(), httpRequest = axios) {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };

  return await httpRequest({
    url: `${projectUrl}/getErrorPage`,
    method: 'POST',
    headers: config,
    data: JSON.stringify({ type, number })
  })
}