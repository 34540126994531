<template>
  <div ref="signature-pad-container" class="signature-pad-container">
    <div v-if="!saved && !hasImg" class="header">
      <div
        :class="{ 'red--text': error }"
        class="
          v-input v-input--is-label-active v-input--is-dirty
          theme--light
          v-text-field v-text-field--is-booted
        "
        style="color: #949494"
      >
        {{ label }}
      </div>
      <div v-if="readOnly" class="disabled-overlay" />
      <canvas
        ref="sign-here"
        :width="width"
        data-canvas-count
        height="100"
        :class="{ 'signature-pad-error': error }"
        class="sign-here"
      />
      <div>
        <p v-if="required" :class="{ 'red--text': error }">Required</p>
      </div>
      <div v-if="hideSaveBtn === false" class="actions">
        <div v-show="!isEmpty && !readOnly" class="signature-buttons">
          <v-btn
            color="primary"
            @click="saveSignaturePad"
          >
            <v-icon>check</v-icon>
            Save
          </v-btn>
          <v-btn
            @click="clearSignaturePad"
          >
          <v-icon>close</v-icon>
            Reset
          </v-btn>
        </div>
      </div>
    </div>
    <v-card v-else tile @dblclick="changeSignature" class="finished-signature">
      <v-card-title class="header">
        <v-icon class="pl-1">image</v-icon>
        {{ field.label || "SIGNATURE PAD" }}
        <v-spacer />
        <v-btn
          v-if="!readOnly"
          title="Clear signature"
          @click="deleteSignaturePadClick"
        >
          <v-icon>clear</v-icon>
          Clear
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <img :src="getSignaturePadImg" width="100%" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SignaturePad from "signature_pad";
import { mapActions } from "vuex";
import { urlExists } from "@/utils/file.util";

export default {
  name: "SignaturePad",

  props: {
    label: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      default: () => {},
    },

    error: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: "",
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    hideSaveBtn: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      signaturePad: null,
      saved: false,
      width: 0,
      exists: false,
    };
  },

  computed: {
    isEmpty() {
      return this?.signaturePad?.isEmpty ? this.signaturePad.isEmpty() : true;
    },

    hasImg() {
      return ((this.field?.signature?.img || this.field.signature?.url) &&
        this.field?.signature?.signed) || false
    },

    getSignaturePadImg() {
      return (this.field.signature?.img || this.field.signature?.url)
    },
  },

  mounted() {
    this.fileChecker();

    setTimeout(() => {
      this.loadCheck();
    }, 1000);

    window.addEventListener("resize", this.setWidth);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth);
  },

  methods: {
    ...mapActions(["setSignaturePadImage", "readSignaturePadImage"]),

    ...mapActions("alert", ["setAlert"]),

    ...mapActions("confirmation", ["confirm"]),

    async fileChecker(urlCheck = urlExists) {
      if (this.field.signature?.ref) {
        this.readSignaturePadImage({ field: this.field, formId: this.formId });
        const urlFound = await urlCheck(this.getSignaturePadImg);
        if (urlFound) {
          this.exists = true;
        }
      }
    },

    clearSignaturePad() {
      this.confirm({
        title: 'Are you sure you want to delete this signature?',
        accepted: () => {
          this.signaturePad.clear();
          this.$emit('signed', false)
          this.$emit("change", {
            img: this.signaturePad.toDataURL(),
            dateStamp: Date.now(),
            signed: false,
          });          
        }
      })
    },

    deleteSignaturePadClick() {
      this.confirm({
        title: 'Are you sure you want to delete this signature?',
        accepted: () => this.deleteSignaturePad()
      })
    },

    deleteSignaturePad() {
      this.loadCheck();
        if (this.signaturePad) {
          this.signaturePad.clear();
        }
        this.field.signature = {};
        this.saved = false;
        this.$emit('signed', false)      
    },

    changeSignature() {
      this.loadCheck();
      this.saved = false;
    },

    saveSignaturePad() { 
      const signatureData = this.signaturePad.toDataURL();
      if(signatureData.length < 5000) {
        this.setAlert({
          type: 'info',
          message: 'Please sign your stipulation with more text'
        })
        
        this.deleteSignaturePad()
        return
      }

      const signature = {
        img: signatureData,
        dateStamp: Date.now(),
        signed: true,
      };

      this.field.signature = signature;

      this.signaturePad.clear();
      this.saved = true;
      this.$emit('signed', true)
    },

    loadCheck() {
      const canvas = this.$refs["sign-here"];

      if (canvas) {
        canvas.width = this.$refs["signature-pad-container"].offsetWidth;
        this.signaturePad = new SignaturePad(canvas, { dotSize: 0.5 });
        // this.signaturePad.addEventListener("endStroke", () => {
        //   //this.saveSignaturePad()
        //   //this.$emit('signed', true)
        // }, { once: true })
      } else {
        setTimeout(() => {
          this.loadCheck();
        }, 500);
      }
    },

    setWidth() {
      const canvas = this.$refs["sign-here"];

      if (canvas) {
        canvas.width = this.$refs["signature-pad-container"].offsetWidth;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signature-pad-error {
  border: red solid 1px !important;
}

.signature-pad-container {
  & > .header {
    position: relative;

    .disabled-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .finished-signature {
    .header {
      padding: 10px;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.375rem;
      letter-spacing: 0.0071428571em;
    }
  }

  .sign-here {
    border: 1px solid #949494;
  }

  .actions {
    position: relative;
    margin-top: 20px;    

    .signature-buttons {
      display:flex;
      justify-content: space-between;
      position: absolute;
      bottom: 15px;
      right: 0;
      width: 200px;

      .signature-button {
        width: 30px;
        cursor: pointer;
        float: left;

        i {
          font-weight: bold;
        }

        &.close i {
          color: red;
        }

        &.check {
          color: green;
        }
      }
    }
  }
}
</style>
