import axios from 'axios';
import { getProject } from '../gcloud/getProject';

export default async (workflowEditId, workflowPublishedId, httpRequest = axios, project = getProject()) => {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }

  const qry = `?oldId=${workflowEditId}&newId=${workflowPublishedId}`
  const url = `${project}/publishedWorkflowCopy${qry}`;

  return await httpRequest({
    url: url,
    method: 'GET',
    headers: config
  });
}