import axios from 'axios';
import { getProject } from '../gcloud/getProject';

export default function pdfGenerator(projectUrl = getProject(), httpRequest = axios) {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };

  return {
    run: async (formId, fileName) => {
      const url = `${projectUrl}/generatePdfDoc?formId=${formId}&fileName=${fileName}`

      const pdfFile = await httpRequest({
        method: 'GET',
        url,
        headers: config
      })
    
      return pdfFile.data
    }
  }
}
