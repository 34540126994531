<template>
  <div class="comments-frame">
    <v-badge
      v-if="currentStep !== 1"
      bordered
      color="error"
      overlap
      offset-x="20"
      :title="`${unreadComments} Unread Comments`"
      :content="unreadComments"
      :value="unreadComments != '0'"
    >
      <v-btn
        color="primary a-btn"
        dark
        fab
        title="Comments"
        @click.stop="drawer = !drawer"
      >
        <v-icon>comment</v-icon>
      </v-btn>
    </v-badge>

    <v-navigation-drawer
      id="comments-dialog"
      v-model="drawer"
      fixed
      right
      hide-overlay
      temporary
      width="500px"
      height="75vh"
      style="top: 110px;"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-content class="text-left">
            <v-list-item-title class="text-h6">Comments</v-list-item-title>
            <v-list-item-subtitle>Add comments below to discuss with your spouse</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-btn
              color="gray"
              icon
              @click.stop="drawer = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-list-item-avatar>
        </v-list-item>
      </template>

      <v-divider></v-divider>
        <v-card-text v-if="pageNames.length > 0" class="pa-3 pb-0">
        <v-tabs
            v-model="tab"
            background-color="background-gradient-light-blue-to-green"
            center-active
            show-arrows
            dark
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab
            v-for="(page, index) in pageNames.length"
            :key="index"
            :href="`#tab-${page}`"
            @click="scrollToFirstUnread(index), saveProgress()"
          >
            {{ pageNames[index] }}
            <v-icon
              v-if="showUnreadCommentWarning(index)"
              class="pl-1 pb-5" color="white">
              error
            </v-icon>                
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(page, index) in pageNames.length"
            :key="index"
            :value="'tab-' + page"
          >
            <div v-if="!isOwner && (commentButton && !commentButton.commentsDisabled)">
              <!-- RESPONDENT-->
              <v-card-text class="px-0">
                <quill-editor
                  :disable="disableComments"
                  @input="updateComments"
                  @blur="saveProgress"
                  @focus="onEditorFocus($event)"
                  v-model="comments[index] && comments[index].comment"
                  :options="editorOption"
                  class="o-quill-editor"
                  placeholder="page one"
                />
              </v-card-text>

              <div>
                <v-card-text class="d-flex justify-space-between pa-0 pb-4">
                  <small :class="{'text-danger' : charachersUsed(comments[index] && comments[index].comment) > characterLimit }">
                    characters: {{ charachersUsed(comments[index] && comments[index].comment) }} / {{ characterLimit }}
                  </small>

                  <v-btn
                    v-if="!limitExceded"
                    @click="saveProgress"
                    :disable="loading || disableComments"
                    :class="{'primary': !loading}"
                    style="min-width: 150px"
                    text
                    small
                  >
                    <span v-if="!loading">
                      Save Progress
                    </span>

                    <v-progress-circular
                      v-else
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-btn>

                  <small v-else class="text-danger">
                    Character limit exceded on one of the pages!
                  </small>
                </v-card-text>
              </div>
            </div>

            <div v-else-if="isOwner && (commentButton && commentButton.commentsDisabled)">
              <!-- PETITIONER-->
              <v-card-text class="px-0">
                <quill-editor
                  v-model="comments[index] && comments[index].comment"
                  @input="updateComments"
                  @focus="onEditorFocus($event)"
                  :options="editorOption"
                  :disable="disableComments"
                  class="o-quill-editor"
                  placeholder="page one"
                  contentType="html"
                />
              </v-card-text>

              <div>
                <v-card-text class="d-flex justify-space-between pa-0 pb-4">
                  <small :class="{'text-danger' : charachersUsed(comments[index] && comments[index].comment) > characterLimit }">
                    characters: {{ charachersUsed(comments[index] && comments[index].comment) }} / {{ characterLimit }}
                  </small>

                  <v-btn
                    v-if="!limitExceded"
                    @click="saveProgress"
                    :class="{'primary': !loading}"
                    :disabled="loading"
                    style="min-width: 150px"
                    small
                    text
                  >
                      <span v-if="!loading">
                      Save Progress
                    </span>

                    <v-progress-circular
                      v-else
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-btn>

                  <small v-else class="text-danger">
                    Character limit exceded!
                  </small>
                </v-card-text>
              </div>
            </div>

            <v-expansion-panels v-if="results.length > 1" v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h3>Comments History</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content style="margin-bottom: 120px;">
                  <div
                    v-for="(result,i) in results"
                    :id="`comment-${result.commentId}`"
                    :key="i"
                    :class="[`comment-buble ${result.commentOwner}`, { 'd-none' : i===0 }]"
                  >
                    <span>{{result.commentOwnerName || 'N/A'}}</span>
                    <p v-if="hasEmptyComment(result, index)" style="opacity: .5">No comments were added for this page</p>
                    <div v-else class="content" v-html="result.comments[index] && result.comments[index].comment"></div>
                    <time>{{ dateFormat(result) }} - {{ timeFormat(result) }}</time>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-snackbar
        v-model="snackbar"
        timeout="3000"
        elevation="3"
        :color="msgColor"
        absolute
        bottom
        right
        class="mb-5"
      >
        {{ msgText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            <v-icon small color="white"> close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-navigation-drawer>

  </div>
</template>

<script>
import commentSheetMixin from '../../mixins/components/commentSheet.mixin';

export default {
  name: 'CommentSheet',

  mixins: [commentSheetMixin]
};
</script>

<style lang="scss">
.comment-buble {
  padding: 15px 15px 5px;
  margin: 15px 0;
  color: #fff;
  max-width: 80%;
  text-align: left;
  position: relative;
  box-shadow: 3px 6px 18px 3px rgba(0,0,0,0.05);

  span {
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    padding-bottom: 5px;
    text-transform: capitalize;
    border-bottom: 1px solid rgba(72, 208, 200, 0.39);
    opacity: 0.85;
  }

  time {
    text-align: right;
    font-size: 12px;
    display: block;
    margin-top: 5px;
  }

  .content {
    p {
      margin-bottom: 0;
    }
  }

  &.respondent {
    background: #3F3D56;
    border-radius: 0 10px 10px 10px;
    span {
      border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    }
  }

  &.petitioner {
    border-radius: 10px 0 10px 10px;
    background: #faffff;
    margin-left: auto;
    text-align: left;
    color: black;
    border: 1px solid rgba(63, 208, 201, 0.48);
  }
}
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
  left: 11px!important;
  right: initial!important;
}

.v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 50px) !important;
}
.v-expansion-panel-content__wrap {
  padding: 5px;

  img {
    width: 100%;
  }
  p {
    img {
      width: 100%;
    }
  }
}
.v-timeline:before {
  display: none;
}

.ql-container {
  &.ql-snow {
    display: flex;

    .ql-editor {
      height: auto;
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  #comments-dialog {
    width: 100vh !important;
    height: 100vh !important;
    padding-bottom: 100px;
    overflow-y: scroll;
  }
}
</style>
