const { getDateFormat } = require('./getDateFormat.util')
const expressionatorMethod = require('./expressionator.util')

const getValueFromQuestion = (fields, question) => {
    if(!question) return ''
    let liveQuestion = fields.find(field => field.title === question?.title && field.type === question?.type);
    let textFromQuestion = '';
  
    if (liveQuestion === undefined || liveQuestion === null || question === undefined || question === null) {
      return textFromQuestion;
    }
  
    if(question.type === 'number-input' && question?.useCommas === true) {
      textFromQuestion = liveQuestion.content.toLocaleString('en-US')
    } else if (['text-input-short', 'text-input-long', 'number-input'].indexOf(liveQuestion.type) > -1) {
      textFromQuestion = liveQuestion.content;
    } else if (['computed-property'].indexOf(question.type) > -1) {
      textFromQuestion = expressionatorMethod(liveQuestion.label, fields);
    } else if (['grouped-options','state-select'].indexOf(question.type) > -1) {
      textFromQuestion = liveQuestion.selection;
    } else if (['single-select'].indexOf(question.type) > -1) {
      switch (liveQuestion.variant) {
        case 'radio':
          textFromQuestion =
            liveQuestion.selection;
          break;
        case 'drop-down':
          textFromQuestion = liveQuestion.selection;
          break;
        case 'slider':
          textFromQuestion =
            liveQuestion.content[liveQuestion.selection];
          break;
        default:
          textFromQuestion = liveQuestion.selection;
      }
    } else if (['multi-select'].indexOf(liveQuestion.type) > -1) {
      textFromQuestion = '';
  
      for (let i = 0; i < liveQuestion.selection.length; i++) {
        if (i < liveQuestion.selection.length - 1) {
          textFromQuestion =
            textFromQuestion +
            liveQuestion.selection[i].toString() +
            ', ';
        } else if (i === liveQuestion.selection.length - 1) {
          textFromQuestion =
            textFromQuestion +
            liveQuestion.selection[i].toString();
        }
      }
  
      if (textFromQuestion === '') {
        textFromQuestion = 'NO RESPONSE';
      }
    } else if (['date-picker'].indexOf(liveQuestion.type) > -1) {
      switch (liveQuestion.variant) {
        case 'single-date':
          let date = new Date(liveQuestion.dateValue);
          textFromQuestion = date.toString() === 'Invalid Date' ? '' : getDateFormat(date, liveQuestion.format)
          break;
        case 'multiple-dates':
          liveQuestion.dateMultiple.forEach((date, index) => {
            let formattedDate = new Date(date);
            formattedDate = formattedDate.toLocaleDateString('en-US', {timeZone: 'UTC'});
            if (index !== liveQuestion.dateMultiple.length - 1) {
              textFromQuestion += formattedDate + ", ";
            } else {
              textFromQuestion += formattedDate;
            }
          })
          break;
        case 'date-range':
          if (liveQuestion.dateMultiple.length === 2) {
            let dateA = new Date(liveQuestion.dateMultiple[0]);
            let dateB = new Date(liveQuestion.dateMultiple[1]);
  
            if (dateA > dateB) {
              textFromQuestion = dateB.toLocaleDateString('en-US', {timeZone: 'UTC'}) + " through " + dateA.toLocaleDateString('en-US', {timeZone: 'UTC'});
            } else {
              textFromQuestion = dateA.toLocaleDateString('en-US', {timeZone: 'UTC'}) + " through " + dateB.toLocaleDateString('en-US', {timeZone: 'UTC'});
            }
          }
          break;
      }
    }
  
    return textFromQuestion ?? '';
}

module.exports = getValueFromQuestion