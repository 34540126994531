const setTextEditorFonts = (newPDF, action) => {
    switch (action.fontFamily) {
        case 'Times New Roman':
          newPDF.setFont('Times', 'Roman');
          break;
        case 'Calibri':
          // newPDF.addFont('Calibri');
          // newPDF.setFont('Calibri');
          break;
        case 'Helvetica':
          newPDF.setFont('Helvetica');
          break;
        case 'Arial':
          newPDF.addFont('Arial');
          newPDF.setFont('Arial');
          break;
        default:
          newPDF.setFont('Times');
      }
}

module.exports = { setTextEditorFonts }