<template>
  <v-container v-if="showPayWall">
    <v-row class="align-items-start">
      <v-col>
        <v-alert 
          v-if="!isGoodToken" 
          type="error" 
          class="flex-column align-items-center just-content-center"
          style="width:90%;"
        >
          Sorry, incorrect Paypal token
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col class="col-12 col-md-7">
        <div 
          v-if="costInfoData"
          class="top" 
          :style="{ backgroundColor: costInfoData.bg, color: costInfoData.fg }"
          style="padding:0 20px;width:100%;height:55px;border-radius:10px 10px 0 0;display:flex;align-items: center; justify-content: space-between; font-weight: bold;">
          <div>{{ $route.params.level == 1 ? 'Testimonials' : 'Final Payment' }}</div>
        </div>
        <v-card
          elevation="5"
          class="pa-10"
        >
          <Carousel v-if="this.$route.params.level === '1'"/>
          <div v-else>
            <img src="@/assets/images/court-1.png" alt="court image">
            <h2 class="text-center text-uppercase">
              <span>Final Payment</span>
            </h2>
            <p>Congratulations!</p>
            <p>In order to complete your divorce, sign and receive your documents, you'll need to finalize the payment process.</p>
          </div>
        </v-card>
      </v-col>
      <v-col class="col-12 col-md-5 justify-center">
        <CostInfo
          v-if="costInfoData"
          :image="costInfoData.img"
          :price="costInfoData.price"
          :show-select-btn="!dialog"
          :summary="costInfoData.summary"
          :tab="{
                title: costInfoData.title,
                bg: costInfoData.bg,
                fg: costInfoData.fg
              }"
          :items="costInfoData.items"
          @selected="showDialog()"
        />
        <v-btn
          v-if="payInfo.level == 2"
          @click="goBackToQuestionnaire"
          style="margin-top: 20px"
          color="primary"
          :loading="loading"
          :class="
            !loading
              ? 'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default green'
              : 'disable-events'
          "
        >
          Back to Questionnaire
        </v-btn>        
      </v-col>
    </v-row>
    <v-row class="mb-10 mt-10">
      <v-col class="col-12 d-flex align-items-center mt-10 mt-md-0 justify-center">
        <v-dialog
          v-if="dialog"
          v-model="dialog"
          :retain-focus="false"
          :persistent="false"
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Order summary
            </v-card-title>

            <v-list-item two-line style="padding: 30px 20px 20px 20px" class="d-flex text-start align-items-center">
              <img src="/img/solo.svg" alt="solo image"
                   style="width: 80px; height: 80px; border-radius: 50%; border: 1px solid lightgray; padding: 5px; margin-right: 15px">
              <v-list-item-content>
                <v-list-item-title><b>{{costInfoData.title}}</b></v-list-item-title>
                <v-list-item-subtitle>{{costInfoData.header}}</v-list-item-subtitle>
              </v-list-item-content>
              <h3 class="font-weight-600">${{ originalCost || cost }}</h3>
            </v-list-item>

            <v-divider></v-divider>

            <FindCoupon
              ref="childComponentRef"
              @click="cancelPayment"
              :payment-level="payInfo.level"
              :search="!couponSaved"
              @success="deductAmount"/>

            <v-alert v-show="message" type="error">{{ message }}</v-alert>

            <v-divider></v-divider>

            <div class="d-flex align-items-start" style="padding: 10px 20px 0 20px">
              <p>Discount</p>
              <p class="ms-auto">${{ originalCost - cost }}</p>
            </div>

            <v-divider></v-divider>

            <div class="d-flex align-items-start" style="padding: 20px 20px 0 20px">
              <h3>Total</h3>
              <p class="ms-auto font-weight-600">${{ cost }}</p>
            </div>

            <v-divider></v-divider>

            <div :style="{ 'pointer-events': loadingStatus ? 'none' : 'auto' }" class="d-flex align-items-start justify-center my-5" style="padding: 20px 20px 0 20px">
              <div v-if="currentCoupon.amount !== '100'" id="paypal-button-container">
                <div v-if="paypalBtnsLoaded === false">
                  <v-progress-circular indeterminate :value="20" color="primary" />
                  Loading...
                </div>                
              </div>
              <v-btn v-else color="primary" @click="skipPayment">Proceed</v-btn>
            </div>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="cancelPayment()"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import payForFormMixin from '@/mixins/views/payForForm.mixin'

export default {
  name: "PayForForm",

  mixins: [payForFormMixin]
};
</script>
