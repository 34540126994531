const dayjs = require('dayjs')

const getWorkflowId = (key) => {
    if(localStorage.getItem(key)) {
        const wf = JSON.parse(localStorage.getItem(key))
        if(dayjs().diff(wf.ttl, 'month') > 6) {
            localStorage.removeItem(key)
        } else {
            return wf.wfid
        }
    }
    return ''
}

const storeWorkflowId = (key, val) => {
    const obj = {
        wfid: val,
        ttl: Date.now()
    }
    localStorage.setItem(key, JSON.stringify(obj))
}

module.exports = {
  getWorkflowId,
  storeWorkflowId
}