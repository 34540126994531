<template>
  <v-container fluid class="full-height">
    <v-row justify="center">
      <v-alert :type="alert.type">
        {{ alert.message }}
      </v-alert>
      <a href="/paypal/test">Try again?</a>
    </v-row>
  </v-container>
</template>

<script>
import payPalService from '../services/paypal';
import adminCheckerMixin from '@/mixins/adminChecker.mixin'

export default {
  name: 'PayPalSuccess',

  mixins: [adminCheckerMixin],

  data() {
    return {
      alert: {
        type: 'success',
        message: 'Successfully processed Paypal transaction'
      },
    }
  },

  async mounted () {
    const paypal = payPalService();
    const { PayerID, paymentId } = this.$route.query;
    const amount = localStorage.getItem("paypalAmount")

    try {
      const transaction = await paypal.success(PayerID, paymentId, amount)
    } catch(e) {
      this.$logger.warn(error)
      this.alert = {
        type: 'error',
        message: 'Sorry, could not complete transaction'
      }
    } finally {
      localStorage.removeItem("paypalAmount")
    } 
  }
};
</script>
