const getSignatureType = (getDateFormat, formData, tagType) => {
    const signatureType = tagType.indexOf('pet-') === 0 ? 'esign' : 'spouseEsign'
    let dateTemp = 'this **th day of Month 20**'
    let value = ''
    let tag = tagType
    if(tagType.indexOf('-img') > 0) {
        tag = !formData?.[signatureType] ? tagType.replace('-img', '-txt') : tagType
        if(formData.hasOwnProperty('collaborate') && !formData.collaborate) {
            value = formData?.[signatureType] ? formData[signatureType].signature.img : 'Signature:\n\n\t\t\t\t'
        } else {
            value = formData?.[signatureType] ? formData[signatureType].signature.img : '<FUTURE SIGNATURE HERE>'
        }
    } else {
        if(formData.hasOwnProperty('collaborate') && !formData.collaborate) {
            value = 'Date:_______________\n'
        } else {
            const ds = formData?.[signatureType]?.signature?.dateStamp ? getDateFormat(formData[signatureType].signature.dateStamp, dateTemp) : dateTemp
            value = 'Dated: ' + ds + '\n'
        }        
    }

    return {
        value,
        tag
    }
}

module.exports = { getSignatureType }