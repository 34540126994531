import axios from 'axios';
import { getProject } from '../gcloud/getProject';

export default async (payload, httpRequest = axios, project = getProject()) => {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }

  const resp = await httpRequest({
    url: `${project}/saveWorkflow`,
    method: 'POST',
    headers: config,
    data: JSON.stringify(payload)
  })

  return resp.data
}