<template>
  <div :id="formId" class="petitioner-guide">
    <teap-alert />
    <div class="left-nav">
      <v-stepper v-model="step" vertical>
        <v-stepper-step
          :complete="step > 1"
          :style="isAdmin ? 'cursor:pointer;' : ''"
          step="1"
          @click="stepClick(1)"
        >
          {{ steps[1] }}
        </v-stepper-step>

        <v-divider />
        <template v-if="collaborate">
          <v-stepper-step
            :complete="step > 2"
            :style="isAdmin ? 'cursor:pointer;' : ''"
            step="2"
            @click="stepClick(2)"
          >
            {{ steps[2] }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="step > 3"
            :style="isAdmin ? 'cursor:pointer;' : ''"
            step="3"
            @click="stepClick(3)"
          >
            {{ steps[3] }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="step > 4"
            :style="isAdmin ? 'cursor:pointer;' : ''"
            step="4"
            @click="stepClick(4)"
          >
            {{ steps[4] }}
          </v-stepper-step>

          <v-divider />
        </template>

        <v-stepper-step
          :complete="step > 5"
          :style="isAdmin ? 'cursor:pointer;' : ''"
          :step="collaborate ? 5 : 2"
          @click="stepClick(5)"
        >
          {{ steps[5] }}
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          v-if="collaborate"
          :complete="step > 6"
          :style="isAdmin ? 'cursor:pointer;' : ''"
          step="6"
          @click="stepClick(6)"
        >
          {{ steps[6] }}
        </v-stepper-step>

        <v-divider v-if="collaborate" />

        <v-stepper-step
          id="last-step"
          :complete="step === 7"
          :style="isAdmin ? 'cursor:pointer;' : ''"
          :step="collaborate ? 7 : 3"
          @click="stepClick(7)"
        >
          {{ steps[7] }}
        </v-stepper-step>
      </v-stepper>

      <v-dialog
        v-model="warningErrorsDialog"
        id="warningErrorsDialog"
        persistent
        max-width="600"
      >
        <v-card class="pb-2">
          <v-card-title class="text-h5 d-flex justify-center">
            Some of the fields require additional attention!
          </v-card-title>
          <v-card-text
            >You can go on the third step and do the comments with your spouse
            but you cannot go further than 3 step.</v-card-text
          >
          <v-card-text
            >You can come back here and fill the missing fields.</v-card-text
          >
          <v-card-actions>
            <v-btn color="error darken-1" @click="activateTipsWarning">
              Review Missing Information
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary darken-1"
              text
              @click="closeWarningDialogAndMoveToTheNextStep"
            >
              Proceed Anyway
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <span
        v-if="step === 1 && visibleRequiredFields.length"
        class="stepper-btn"
      >
        <v-btn
          color="error a-btn-missing-entries"
          :disabled="!collaborate"
          @click="moveForwardOnTheNextStep"
        >
          <span class="me-2">Missing entries</span>
          <v-icon color="white"> error </v-icon>
        </v-btn>
      </span>

      <span
        v-else-if="step === 1 && !visibleRequiredFields.length"
        class="stepper-btn"
      >
        <!-- Object.keys(warningIssues).length === 0 &&  -->
        <v-btn
          :class="
            userHasPaid
              ? 'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary'
              : 'disable-events'
          "
          style="height: 10%; width: 100%"
          @click="buttonClick"
          :loading="btnLoading"
        >
          {{ buttonText }}
        </v-btn>
      </span>

      <span v-else-if="step === 2" class="stepper-btn">
        <v-btn
          :class="
            partner.email
              ? 'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary'
              : 'disable-events'
          "
          :loading="btnLoading"
          @click="confirmSpouseEmailDialog = true"
        >
          {{ buttonText }}
        </v-btn>
      </span>

      <span
        v-else-if="step === 3 && agreedWithSpouse(false)"
        class="stepper-btn"
      >
        <v-btn
          dark
          color="primary"
          class="stepper-btn"
          @click="buttonClick"
          :loading="btnLoading"
        >
          I Agree
        </v-btn>
      </span>

      <span
        v-else-if="step === 3 && !agreedWithSpouse(false)"
        class="stepper-btn"
      >
        <v-btn
          dark
          :color="readOnly || visibleRequiredFields.length > 0 ? 'normal' : 'primary'"
          class="stepper-btn"
          @click="submitResponseDialog = true"
          :loading="btnLoading"
          :class="showFinishReplyingBtn"
        >
          Finish Replying
        </v-btn>
      </span>

      <span v-else-if="step === 4" class="stepper-btn">
        <v-btn
          :class="
            userHasFinalPayment
              ? 'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary'
              : 'disable-events'
          "
          :loading="btnLoading"
          @click="buttonClick"
        >
          {{ buttonText }}
        </v-btn>
      </span>

      <span v-else-if="step === 5" class="stepper-btn">
        <v-btn
          :class="
            !collaborate || isSigned
              ? 'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary'
              : 'disable-events'
          "
          :loading="btnLoading"
          @click="buttonClick"
        >
          {{ buttonText }}
        </v-btn>
      </span>

      <v-btn
        v-else
        dark
        color="primary"
        class="stepper-btn"
        :loading="btnLoading"
        @click="buttonClick"
        :class="
          !disableBtn
            ? 'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary'
            : 'disable-events'
        "
      >
        {{ buttonText }}
      </v-btn>
    </div>
    <div class="right-content">
      <v-stepper v-model="step" tile>
        <v-stepper-content step="1" class="pa-0">
          <template v-if="step === 1">
            <WorkflowGuide
              v-if="formData"
              form-type="petitioner"
              :disable-comments="disableComments"
              :can-save="true"
              :is-owner="isOwner"
              :is-out-dated="isOutDated"
              :form-fields="formData.fields"
              :state="formData.state"
              :parent-id="formData.parentId"
              :form-id="formData.id"
              :hasDisplayLogic="hasDisplayLogic"
              :shared-user-props="sharedUserProps"
              :current-step="step"
              :activateWarningDialog="tipsActivation"
              @ignoreFormDataChanges="handleIgnoreFormDataChanges($event)"
              @errorWorkflow="disableProceedButton"
              @modalCloseOnParent="tipsActivation = false"
            />
          </template>
        </v-stepper-content>

        <v-stepper-content step="2">
          <template v-if="step === 2">
            <div class="share-container">
              <div class="text-left">
                <h1>Share Questionnaire with Spouse For Review</h1>
                <p>
                  We need your spouse to sign off on the documents to make sure
                  that the divorce goes through.
                </p>
              </div>

              <v-btn
                @click="goBackToQuestionnaire"
                color="primary"
                :loading="loading"
                :class="
                  !loading
                    ? 'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary'
                    : 'disable-events'
                "
              >
                Back to Questionnaire
              </v-btn>
            </div>

            <v-form ref="form">
              <v-row
                id="sharing-info"
                class="my-5 text-left"
                style="padding: 10px"
              >
                <v-col cols="6" sm="12">
                  <p>Spouse name and lastname</p>
                  <p v-if="!partner.firstName" class="font-weight-600">
                    <span class="text-danger">Legal First name is missing</span>
                    {{ partner.lastName }}
                  </p>
                  <p v-else-if="!partner.lastName" class="font-weight-600">
                    {{ partner.firstName }}
                    <span class="text-danger">Legal Last name is missing</span>
                  </p>
                  <p v-else class="font-weight-600">
                    {{ partner.firstName + " " + partner.lastName }}
                  </p>
                </v-col>

                <v-col cols="6" sm="12">
                  <p>Spouse email</p>
                  <p v-if="!partner.email" class="font-weight-600 text-danger">
                    Email is missing
                  </p>
                  <p v-else class="font-weight-600">{{ partner.email }}</p>
                </v-col>
              </v-row>
            </v-form>
            <v-row style="padding: 10px">
              <v-col class="text-left">
                <h5>Note:</h5>
                <p>
                  Spouse will be able to view and add comments to the document.
                </p>
              </v-col>
            </v-row>
          </template>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div v-if="formData && agreedWithSpouse()" class="my-15">
            <img
              :src="require(`@/assets/images/Agreement.png`)"
              alt="img"
              width="350"
              class="my-10"
            />
            <h2 class="mb-3 text-center">
              Your spouse has agreed to all terms
            </h2>
            <p>Please proceed to the next step</p>
          </div>

          <template v-if="step === 3 && formData && !agreedWithSpouse()">
            <WorkflowGuide
              v-if="formData"
              form-type="petitioner"
              :disable-comments="disableComments"
              :step="formData.step"
              :state="formData.state"
              :parent-id="formData.parentId"
              :can-save="!readOnly"
              :is-owner="isOwner"
              :read-only="readOnly"
              :is-out-dated="isOutDated"
              :form-fields="formData.fields"
              :form-id="formData.id"
              :hasDisplayLogic="hasDisplayLogic"
              :shared-user-props="sharedUserProps"
              :comment-owner-name="userInfo.first_name"
              :activateWarningDialog="false"
              @errorWorkflow="disableProceedButton"
              @limit="characterLimit = $event"
            />
          </template>
        </v-stepper-content>

        <v-stepper-content step="4">
          <template v-if="step === 4"> Redirecting ... </template>
        </v-stepper-content>

        <v-stepper-content step="5">
          <template v-if="step === 5">
            <div class="bottomOfPage">
              <v-btn
                v-if="collaborate"
                id="go-to-signature"
                title="Go to signature area"
                color="primary"
                @click="scrollToBottom"
              >
                Go to Signature
              </v-btn>
              <v-btn
                style="margin-left: 10px"
                title="restart the process"
                color="primary"
                @click="restart"
              >
                Return to Questionnaire
              </v-btn>
            </div>
            <h1 v-if="collaborate">
              Review &amp; E-Sign Your Completed Documents
            </h1>
            <h1 v-else>Review</h1>
            <FileRenderer :pdf-file="processedFile">
              <template #card-title>Finished Computed File</template>
            </FileRenderer>
            <template v-if="collaborate">
              <p style="margin-top: 20px">
                After e-signing, you will be able to generate and download your
                documents. They will be ready to file with the court.
              </p>
              <div class="signature-pad" id="signature-container">
                <signature-pad
                  v-if="step === 5 && formData"
                  id="e_sign_petitioner"
                  label="Sign Here"
                  :hide-save-btn="false"
                  :form-id="formData.id"
                  :required="true"
                  :field="esign"
                  @signed="handleSignature($event)"
                />
              </div>
            </template>
          </template>
        </v-stepper-content>

        <v-stepper-content step="6" class="pa-15">
          <template v-if="step === 6">
            <h1>Spousal Review &amp; E-Signature</h1>
            <img
              class="e-sign-img"
              :src="require(`@/assets/images/almost-there-bg.png`)"
              alt="img"
            />
            <p style="margin-top: 20px">
              After e-signing, you will be able to generate and download your
              documents once your spouse has had a chance to also sign and
              review the stipulation. Once your spouse signs, your files will be
              ready to download and submit to the court.
            </p>
            <p class="mt-10">
              By clicking <strong>notify spouse</strong> we will send email
              notification <br />
              to your spouse that you have finished reviewing and signing the
              document.
            </p>
          </template>
        </v-stepper-content>

        <v-stepper-content step="7">
          <div
            v-if="collaborate === false"
            class="bottomOfPage"
            style="margin-bottom: 10px"
          >
            <v-btn
              style="margin-left: 10px"
              title="restart the process"
              color="primary"
              @click="restart"
            >
              Return to Questionnaire
            </v-btn>
          </div>
          <template v-if="step === 7 && formData.id">
            <h1>Final Documents</h1>
            <generate-doc :final-pdf="formData.finalPDF" />
          </template>
        </v-stepper-content>
      </v-stepper>
    </div>

    <!-- Dialog that pops up when user wants to confirm spouse email -->
    <v-dialog v-model="confirmSpouseEmailDialog" max-width="600">
      <v-card class="pa-3">
        <v-card-title class="headline mt-5 mb-3">
          <v-icon color="primary" class="mr-2">email</v-icon>
          Ready to Share?
        </v-card-title>

        <v-card-text>
          <p style="max-width: 80%; margin: 20px auto">
            You are about to share this document with your spouse. Please
            confirm that this is the correct email address.
          </p>

          <p class="text-capitalize mb-1 font-weight-600">
            {{ partner.firstName }} {{ partner.lastName }}
          </p>
          <strong>{{ partner.email }}</strong>

          <p class="mt-5 mb-0" style="opacity: 0.6">
            *If not, you can go back to questionnaire and update it.
          </p>
          <p class="mt-3 mb-0 text-danger">
            Before sending this to your spouse, please ensure that all available
            questions have been answered.
          </p>
        </v-card-text>

        <v-card-actions id="ready-to-share">
          <v-btn
            v-if="!loading"
            color="primary"
            @click="confirmSpouseEmailDialog = false"
            text
            style="min-width: 150px"
          >
            Go Back
          </v-btn>

          <v-spacer />

          <div class="d-flex align-items-center">
            <v-checkbox
              v-if="!loading"
              v-model="checkbox"
              :label="!checkbox ? `Yes, it's correct` : ``"
              class="confirm-email"
            />
            <v-btn
              v-if="checkbox"
              @click="buttonClick"
              :class="{ primary: !loading }"
              text
              style="min-width: 132px"
              :disabled="loading"
            >
              <span v-if="!loading"> Send Invite </span>

              <v-progress-circular v-else indeterminate color="primary" />
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog that pops up when user wants to submit response (by clicking the Send Reply button) -->
    <v-dialog v-model="submitResponseDialog" max-width="600">
      <v-card class="pa-3">
        <v-card-title class="headline mt-5 mb-3">
          <v-icon color="error" class="mr-2">error</v-icon>
          Finished Replying to Comments?
        </v-card-title>

        <v-card-text>
          <p style="max-width: 80%; margin: 20px auto">
            If you are finished adding replies, you can send your feedback to
            your spouse, or you can continue editing your replies.
          </p>
        </v-card-text>

        <v-card-actions id="finish-replying">
          <v-btn v-if="!replyLoading" color="primary" text style="min-width: 200px">
            <span @click="submitResponseDialog = false">
              Continue editing
            </span>
          </v-btn>
          <v-spacer />
          <v-btn
            :class="{ primary: !replyLoading }"
            text
            @click="sendReply"
            style="min-width: 200px"
            :disabled="replyLoading"
          >
            <span v-if="!replyLoading"> Yes, Send Reply </span>

            <v-progress-circular
              v-else
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import petitionerGuideMixin from '../mixins/views/petitionerGuide.mixin';

export default {
  name: "PetitionerGuide",

  mixins: [petitionerGuideMixin]
};
</script>

<style lang="scss">
.confirm-email {
  label {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: #3fd0c9 !important;
  }
}
.v-stepper__content {
  height: 100% !important;
}

.petitioner-guide {
  height: calc(100vh - 120px);
  display: flex;

  #form-alert {
    position: absolute;
    z-index: 99;
    bottom: 0;
    right: 13px;
    width: 50%;
  }

  .share-container {
    display: flex;
    justify-content: space-between;
  }

  .bottomOfPage {
    position: fixed;
    right: 20px;
    bottom: 60px;
    z-index: 20;

    button {
      margin-bottom: 10px;
    }
  }

  .left-nav {
    width: 25%;
    height: 100%;
    border-right: solid 1px rgba(0, 0, 0, 0.12);
    overflow-y: auto;

    .v-stepper {
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-bottom: 0 !important;

      .v-stepper__content {
        height: 100% !important;

        .v-stepper__wrapper {
          height: 100% !important;
        }
      }
    }

    .stepper-btn {
      width: 100%;
      height: 10% !important;

      button {
        width: 100%;
        height: 80px;
      }
    }
  }

  .right-content {
    width: 75%;
    height: 100%;
    overflow-y: auto;

    .e-sign-img {
      width: 300px;
    }

    .v-stepper__content {
      height: 100% !important;

      .v-stepper__wrapper {
        height: 100% !important;
      }
    }

    .signature-pad {
      width: 75%;
      margin: 0 auto;
      padding-bottom: 60px;
    }
  }

  .disable-events {
    pointer-events: none;
    opacity: 0.8;
    background-color: gray !important;
  }
}

@media (max-width: 900px) {
  .petitioner-guide {
    .v-stepper {
      height: fit-content !important;
    }

    .stepper-btn {
      height: 20% !important;
    }
  }
}

@media (max-width: 700px) {
  #finish-replying,
  #ready-to-share {
    flex-direction: column;
  }

  .petitioner-guide {
    display: block;

    #form-alert {
      width: 90%;
    }

    #sharing-info {
      display: flex;
      flex-direction: column;
    }

    .share-container {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding: 10px;

      .text-left {
        width: 100%;
        float: left;
      }
    }

    .bottomOfPage {
      position: fixed;
      right: 0px;
      bottom: 10px;
      width: 100%;
      z-index: 100;

      button {
        margin-bottom: 10px;
      }
    }

    .left-nav {
      margin-bottom: 5px;
      width: 100%;
      display: block;
      height: 80px;
      float: left;
      border: 1px solid silver;
      overflow-y: auto;

      .v-stepper {
        width: 50%;
        height: 100%;
        display: block;
        float: left;

        .v-stepper__step {
          padding: 18px 18px 12px !important;
        }

        .v-stepper__step--inactive,
        .v-stepper__step--complete,
        .v-divider {
          display: none;
        }

        #last-step {
          display: flex;
        }
      }

      .stepper-btn {
        display: block;
        float: left;
        height: 100% !important;
        width: 50%;

        button {
          padding: 0 8px !important;
          height: 100% !important;
          display: block;
          white-space: break-spaces !important;
        }
      }
    }

    .right-content {
      width: 100%;
      display: block;
      float: left;

      .e-sign-img {
        width: 150px;
      }

      .v-stepper__content {
        padding: 0 !important;
      }

      .signature-pad {
        width: 90%;
      }
    }
  }
}
</style>
