import {doc, getDoc, setDoc, updateDoc} from "firebase/firestore";

export const prices = {
  namespaced: true,

  state: () => ({
    levels: {},
    prices: [],
    unilateral: {},
    collaborative: {}
  }),

  getters: {
    getAllPrices: state => state,

    getPriceByLevel: state => (type,level) => state[type][level],

    getCardCosts: state => state.prices

  },

  actions: {
    async setPrices({ commit, rootState }) {
      const priceData = (await rootState.firebaseConfig.db.collection('prices').get()).docs.filter(p => ['unilateral','collaborative'].includes(p.id)).map(p => ({ id: p.id, data: p.data() }))
      const payload = {}

      priceData.forEach(p => {
        payload[p.id] = p.data
      })

      commit('SET_PRICES', payload)
      return
    },

    async saveListItems({ rootState }, payload) {
      const priceColl = await rootState.firebaseConfig.db.collection('prices').doc(payload.variant)
      if (payload.variant === 'collaborative') {
        await updateDoc(priceColl, {
          WithSpouse: payload.data.WithSpouse,
          level_1: payload.data.level_1,
          level_2: payload.data.level_2
        })
      } else {
        await updateDoc(priceColl, {
          WithoutSpouse: payload.data.WithoutSpouse,
          level_1: payload.data.level_1,
          level_2: payload.data.level_2
        })
      }
    },

    async getPrices({commit, rootState}) {
      const costPrices = (await rootState.firebaseConfig.db.collection('prices').get()).docs.map(e => ({...e.data()}))
      commit('SET_COSTS', costPrices)
      return costPrices
    }
  },

  mutations: {
    SET_PRICES(state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key]
      })
    },
    SET_COSTS(state, payload) {
      state.prices = payload
    }
  }
}
