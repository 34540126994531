export const actionSize = {
  'xs': {
    width: 4,
    height: 4,
    left: 0.075,
    top: 0.25,
  },
  'sm': {
    width: 8,
    height: 8,
    left: 0.13,
    top: 0.2,
  },
  'md': {
    width: 10,
    height: 10,
    left: 0.16,
    top: 0.16,
  },
  'lg': {
    width: 14,
    height: 14,
    left: 0.22,
    top: 0.12,
  },
  'xl': {
    width: 20,
    height: 20,
    left: 0.3,
    top: 0.03,
  }
}