import axios from 'axios';
import { getProject } from '@/services/gcloud/getProject';

export const downloadFileContent = async (url, httpRequest = axios) => {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/pdf'
  };

  const resp = await httpRequest({
    url,
    method: 'GET',
    responseType: 'blob'
  })

  return window.URL.createObjectURL(resp.data)
}