<template>
  <v-card tile class="mt-4">
    <v-card-title class="green lighten-1 white--text">
      Auto-Numbering
      <v-spacer />
      <v-btn @click="addNumberingLevel">
        <v-icon class="pr-1">add</v-icon>
        Add Numbering Level
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-tabs v-model="activeNumberingLevel" dark>
        <!--    Tabs    -->
        <v-tab v-for="(level, i) in numberingLevels" :key="i">
          [[{{ level.tag }}]]
        </v-tab>

        <!--    Tab Items     -->
        <v-tab-item v-for="(level, j) in numberingLevels" :key="j">
          <v-card>
            <v-card-actions>
              <v-spacer />
              <v-btn icon outlined @click="deleteNumberingLevel(j)">
                <v-icon>delete</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-text>
              <v-row>
                <v-col cols="0" md="4" />
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="'[[' + level.tag + ']]'"
                    :id="`level-tag-${j}`"
                    label="Level Tag"
                    readonly
                    append-icon="content_copy"
                    @click:append="copyTag(j)"
                  />
                </v-col>
                <v-col cols="0" md="4" />
              </v-row>
              <v-row>
                <v-col cols="0" md="1" />
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="level.name"
                    label="Level Name"
                    @input="level.tag = createTag(level.name)"
                  />
                </v-col>
                <v-col cols="12" md="5">
                  <v-select
                    v-model="level.type"
                    :items="levelTypes"
                    label="Numbering Type"
                  />
                </v-col>
                <v-col cols="0" md="1" />
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { nanoid } from "nanoid";

export default {
  name: "AutoNumbering",
  props: {
    activeRule: {
      type: Number,
      required: true,
    },
    activeAction: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      activeNumberingLevel: null,

      levelTypes: [
        "Numbers",
        "Uppercase Letters",
        "Lowercase Letters",
        "Uppercase Roman Numerals",
        "Lowercase Roman Numerals",
      ],
    };
  },
  computed: {
    ...mapGetters({
      activeWorkflow: "getActiveForm",
      activeDocIndex: "getFormBuilderDocIndex",
    }),

    numberingLevels: {
      get() {
        return (
          this.activeWorkflow.docs[this.activeDocIndex].rules[this.activeRule]
            .actions[this.activeAction]?.autoNumberingLevels ?? []
        );
      },
      set(newLevel) {
        const autoNumberingLevels =
          this.activeWorkflow.docs[this.activeDocIndex].rules[this.activeRule]
            .actions[this.activeAction]?.autoNumberingLevels;

        if (!autoNumberingLevels) {
          this.setAutoNumberingLevels({
            activeRule: this.activeRule,
            activeAction: this.activeAction,
            newLevels: newLevel,
          });
        }
      },
    },
  },
  methods: {
    ...mapActions(["addAutoNumberingLevel", "deleteAutoNumberingLevel", "setAutoNumberingLevels"]),

    addNumberingLevel() {
      const newId = nanoid(6);
      this.addAutoNumberingLevel({
        activeRule: this.activeRule,
        activeAction: this.activeAction,
        newLevel: {
          uid: newId,
          name: `Level ${newId}`,
          type: "Numbers",
          tag: this.createTag(`Level ${newId}`),
        },
      });
    },

    deleteNumberingLevel(index) {
      this.deleteAutoNumberingLevel({
        activeRule: this.activeRule,
        activeAction: this.activeAction,
        levelToDelete: index,
      });
    },

    createTag(name) {
      return name.toUpperCase().replace(/\s/g, "_");
    },

    copyTag(index) {
      const ref = `level-tag-${index}`;
      const copyText = document.getElementById(ref);

      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
    },
  },
};
</script>
