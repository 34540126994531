<template>
  <div id="email-page">
    <emailer />
  </div>
</template>

<script>
import Emailer from "@/components/EmailTemplate/Emailer.vue";
import adminCheckerMixin from '@/mixins/adminChecker.mixin'

export default {
  mixins: [adminCheckerMixin],
    
  components: {
    Emailer,
  },
};
</script>