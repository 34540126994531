import axios from 'axios';
import { getProject } from './gcloud/getProject';

export default async function userExists(email, projectUrl = getProject(), httpRequest = axios) {
    const config = {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    };
  
    const resp = await httpRequest({
      url: `${projectUrl}/userExists`,
      method: 'POST',
      headers: config,
      data: { email }
    })
    
    return resp.data
}