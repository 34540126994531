<template>
    <div v-if="alert" class="form-builder-alert">
        <v-alert type="error">
            {{ errorMsg }}
        </v-alert>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "TeapAlert",

    data() {
        return {
            alert: false,
        }
    },

    watch: {
        errorMsg: {
            handler(newValue) {
                this.alert = newValue.length > 0
            }
        },

        alert: {
            handler(newValue) {
                if(newValue) {
                    setTimeout(() => {
                        this.setErrorMsg('')
                        this.alert = false
                    }, 5000)
                }
            }
        }
    },

    computed: {
        ...mapGetters("teap", ["errorMsg"])
    },

    methods: {
        ...mapActions("teap", ["setErrorMsg"]),
    }
}
</script>

<style>
  .form-builder-alert {
    position: absolute;
    z-index:300;
    margin-top: 20px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
</style>