const axios = require("axios");
const { getProject } = require("../services/gcloud/getProject");
const getSecret = require("./get-secret.service");

const addMailChimpMember = async (email_address) => {
  const { listId, apiKey } = await getSecret("mail-chimp-config")
  
  const resp = await axios({
    url: `${getProject()}/addMailChimpMember`,
    method: "POST",
    data: {
      listId,
      apiKey,
      email_address,
    },
  });
  return resp;
};

module.exports = addMailChimpMember;
