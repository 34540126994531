<template>
  <v-carousel
    v-model="model"
    hide-delimiters
    :cycle="cycle"
    show-arrows-on-hover
    style="height: auto;"
    interval="12000"
    @change="carouselChange"
  >
    <v-carousel-item
      v-for="(attorney, i) in attorneys"
      :key="i"
      height="auto"
    >
      <div v-if="attorney.image"
           class="img-frame">
        <img
          :src="require(`@/assets/images/${attorney.image}`)"
          class="mb-5"
          alt="attorney"/>
        <h3 v-if="attorney.name" class="text-center">{{ attorney.name }}</h3>
        <div class="stars">★ ★ ★ ★ ★</div>
        <p v-if="attorney.desc" class="text-center">{{ attorney.desc }}</p>
      </div>
      <div v-else>
        <youtube :fit-parent="true" :video-id="attorney.youtubeId" ref="tutorialVideo" @playing="handlePlaying" />
      </div>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  data: () => ({
    cycle: true,
    model: 0,
    attorneys: [
      {
        image: 'gayle.png',
        youtubeLink: '',
        name: 'Gayle W.',
        desc: 'As a therapist, I\'ve had several clients who have expressed they do not want to use an attorney as they have been able to work amicably with their spouse when navigating the tough decisions that divorce brings.  Before SimpleEnding.com came along, there was not an affordable place to get attorney-quality documents online. SimpleEnding.com is exactly what is needed to help those who are ending their marriage to draft their divorce documents on their own. I highly recommend this product. '
      },
      {
        image: 'krista.png',
        youtubeLink: '',
        name: 'Dr. Krista Andersen',
        desc: 'As a mediator, I often mediate cases to clean up messy and ambiguous divorce documents that have created confusion, conflict, and distrust. These documents were often completed by divorcing couples who opted not to use attorneys. SimpleEnding generates clear, complete, well-defined, attorney-quality documents without needing an attorney. SimpleEnding alleviates the high cost of divorce attorneys and the future court costs of trying to fix earlier mistakes. SimpleEnding has made my role as a mediator more effective, with excellent documents and relieved clients.'
      },
      {
        image: 'kara.png',
        youtubeLink: '',
        name: 'Kara H. North',
        desc: '"The reality is, hiring an attorney for a divorce is not financially possible for everyone. However, I\'ve had several past clients who used the court\'s do-it-yourself forms, and still made mistakes that cost them. What I like about Simple Ending is that it takes the guesswork out of what forms you need, what information to put in, and how to actually get divorced without paying a fortune. I was impressed with the quality of the program and the cost-effective price. If hiring a lawyer isn\'t realistic, use Simple Ending." '
      },
      {
        image: 'morgan.png',
        youtubeLink: '',
        name: 'T.R. Morgan',
        desc: 'Divorce and custody issues can be difficult even in the best of circumstances.  They become more complicated if you don’t have the means to hire a competent attorney.  The court process and forms can be confusing and seemingly contradictory.  Even the Courts’ own “self-help” forms are overly complicated.  SimpleEnding.com makes it simple.  Follow the prompts and answer the questions and you\'re done.  For those that want to quit fighting and get it done in an easy and timely fashion, SimpleEnding.com is the way to go.'
      },
      {
        image: '',
        youtubeId: 'ZVH6d8h8kMw',
        name: '',
        desc: ''
      },
    ],
  }),

  methods: {
    handlePlaying() {
      this.cycle = false
    },

    async carouselChange() {
      setTimeout(() => {
        if(this.$refs?.tutorialVideo?.[0]?.player)
          this.$refs.tutorialVideo[0].player.pauseVideo()

        this.cycle = true
      },250)
    }
  }
}
</script>
<style lang="scss" scoped>
.img-frame {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 30px;

  img {
    width: 100%;
    max-width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
  }
  .v-responsive {
    overflow: auto;
  }
}

.stars {
  color: gold
}
</style>
