<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="bg-primary white--text">My Pending Approvals</v-card-title>
          <v-divider />
          <v-card-text class="form-container">
            <template v-if="getPendingApprovals.length > 0">
              <v-card class="m-form-card" v-for="(form, index) in getPendingApprovals" :key="index" @click="goToApprovalForm(form.id)">
                <v-card-title>{{ form.name }}</v-card-title>
                <v-card-subtitle class="subtitle"><b>Owner: </b>{{ form.owner }} <br/> <b>State: </b>{{ form.state }}</v-card-subtitle>
                <v-card-actions class="actions">
                  <v-spacer />
                  <v-icon>mdi-chevron-right</v-icon>
                </v-card-actions>
              </v-card>
            </template>
            <template v-else>
              <v-alert type="warning">
                No pending approvals are assigned to you at this time
              </v-alert>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import adminCheckerMixin from '@/mixins/adminChecker.mixin'

export default {
  name: 'PendingApprovals',

  mixins: [adminCheckerMixin],

  created() {
    this.setForms()
  },

  computed: {
    ...mapGetters(["getPendingApprovals"]),
  },

  methods: {
    ...mapActions(["setForms"]),

    goToApprovalForm(id) {
      this.$router.push(`/pending-approvals/${id}`).catch(() => {})
    }
  },
};
</script>
