import WarningModal from '@/components/WarningModal.vue';
import CommentSheet from "@/components/Comments/CommentSheet";
import CommentMessage from "@/components/Comments/CommentMessage";
import CommentChanges from "@/components/Comments/CommentChanges.vue";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/plugins/eventBus";
import { getEnvironment } from '@/services/gcloud/getProject'
import workflowMixin from "@/mixins/workflow.mixin"
import commentsMixin from "@/mixins/comments.mixin"
import updateSubCollection from "@/services/updateSubCollection.service"
import { removeSubCollection, removeAllFormSubCollections } from "@/services/removeSubCollection.service"
import { getDateFormat } from "@/utils/getDateFormat.util"
import WorkflowHelpDialog from "@/components/Dialogs/WorkflowHelpDialog.vue";
import monthPickerMixin from "@/mixins/monthPicker.mixin"
import autoSaveField from '../../services/autoSaveField.service';
import debounce from 'lodash.debounce'

export default {
  mixins: [workflowMixin,commentsMixin,monthPickerMixin],

  components: {
    WorkflowHelpDialog,
    CommentChanges,
    WarningModal,
    CommentSheet,
    CommentMessage,
  },

  props: {
    hasDisplayLogic: {
      type: Boolean,
      default: false
    },
    
    step: {
      type: Number,
      default: 1
    },

    disableComments: {
      type: Boolean,
      default: false
    },

    canSave: {
      type: Boolean,
      default: false
    },

    isOwner: {
      type: Boolean,
      required: false,
      default: true,
    },

    formType: {
      type: String,
      default: 'petitioner'
    },

    state: {
      type: String,
      default: 'Utah'
    },

    parentId: String,

    sharedUserProps: {
      type: Object,
      required: false,
      default: () => {},
    },

    isOutDated: {
      type: Boolean,
      default: false
    },

    activateWarningDialog: {
      type: Boolean,
      default: false
    },
    commentOwnerName: String,
    currentStep: [String, Number],
    parentId: String,
    formId: String
  },

  data() {
    return {
      canAutoSave: true,
      isLoading: true,
      updateFieldAction: 'workflowPublished/updateFormDataField',
      commentChangesActive: false,
      showCommentMessage: false,
      hideWarningBtn: false,
      isSurveyDetail: false,
      checkAnswerCalled: false,
      tabsWarnings: {},
      pageWarnings: {},
      componentKey: 0,
      defaultSurveyTab: 0,
      currentTab: 0,
      currentPage: null,
      formResponse: { form: this.formFields },
      fab: false,
      hidden: false,
      tabs: null,
      childCalculatorInfo: {
        desciption: "Default Description",
        website: "Default URL",
      },
      saveSnackbar: false,
      saveSnackbarText: `Progress is saved`,
      helpDialog: false,
    };
  },

  watch: {
    userInfo: {
      handler() {
        this.prepopulateUserInfo();
      },
      deep: true,
    },

    currentPage: {
      handler: function(newVal) {
        this.$emit("update-current-page", newVal);
      },
    },

    currentTab: {
      handler: function() {
        setTimeout(() => {
          const pageDiv = document.getElementById('currentTab' + this.currentTab);
          if (pageDiv && pageDiv.getElementsByClassName('error--text').length > 0) {
            window.scrollTo({
              top: pageDiv.getElementsByClassName('error--text')[0].getBoundingClientRect().top - 100,
              left: pageDiv.getElementsByClassName('error--text')[0].getBoundingClientRect().left,
              behavior: 'smooth'
            });
          }
        }, 100);
      }
    },

    formId: function() {
      this.setupTabsWarnings();
    },
  },

  beforeMount() {
    EventBus.$once('save-form-progress', async () => {
      this.saveClick();
    })
  },

  async mounted() {
    await this.setupForm()
    this.isLoading = false
    this.validateTabs()
    return
  },

  computed: {
    ...mapGetters(["getStates"]),

    ...mapGetters("profile", ["userInfo", "isAdmin"]),

    ...mapGetters("workflowPublished", ["requiredFields"]),

    ...mapGetters('displayLogic', ['visiblyRequired']),

    showTabWarning() {
      return (index) => index < this.currentTab && Object.values(this.tabsWarnings[index] || {}).length
    },

    showPageWarning() {
      return (pageIndex) => {
        return this.showTabWarning(pageIndex) || this.showUnreadCommentWarning(pageIndex)
      }
    },

    visibleRequiredFields() {
      return this.visiblyRequired(this.requiredFields).filter(f => f.answeredStatus === false)
    },

    isProd() {
      return getEnvironment() === "prod"
    },

    disableChecker() {
      return (field) => {
        return !this.isAdmin && (this.isSpouseEmail(field) || (!this.isOwner && this.sharedUserProps && this.sharedUserProps.reviewType != 'edit'))
      }
    },

    showForm() {
      const rval = (this?.sharedUserProps?.commentsDisabled) || (this?.sharedUserProps && !this?.sharedUserProps?.commentsDisabled && !this.isOwner)
      return true
    },

    //
    // Display Logic
    //

    displayStatus() {
      return (field) => {
        const dt = this.displayTemplate(field.uid)
        if(this.displayTemplate && field.uid && dt) {
          if(dt.display === false) {
            if(["text-input-short", "text-input-long", "number-input"].indexOf(field.type) > -1) {
              field.content = ''
              field.answeredStatus = false
            } else if(field.type === 'date-picker') {
              field.dateSingle = ''
              field.dateMultiple = ''
              field.dateValue = ''
              field.answeredStatus = false
            } else if(["single-select","state-select"].indexOf(field.type) > -1) {
              field.selection = ''
              field.answeredStatus = false
            }
          }
          return !dt.display
        } else {
          const defaultLogic = this.defaultDisplayLogic[field.uid]
          return defaultLogic?.hideByDefault ? !defaultLogic.hideByDefault : false
        }
      };
    },
  },

  methods: {
    ...mapActions(["toggleLoadingOverlayStatus", "setOverlayMessage"]),

    ...mapActions("workflowPublished", [
      "acceptReview",
      "declineReview",
      "loadForm",
      "setFields",
      "saveRawForm",
      "getRawForm",
      "updateRawForm",
      "getSubCollection",
      "clearForm",
      "getPublishedWorkflowById"
    ]),

    ...mapActions("comments", ["setComments"]),

    ...mapActions("profile", [
      "getMyStates",
      "removeFormById",
    ]),

    ...mapActions("displayLogic", ["setOverrideStatus"]),

    ...mapActions("users", ["getUsers"]),

    ...mapActions("confirmation", ["confirm"]),

    fixDateFormat(value) {
      return getDateFormat(value, 'YYYY-MM-DD')
    },

    async setupForm() {
      if(this.formType === 'petitioner') {
        const setupDefaults = debounce(async () => {
          const defaultFields = this.setDefaults()
          const fieldsToUpdate = []
          for(var f = 0; f < defaultFields.length; f++) {
            const field = defaultFields[f]
            fieldsToUpdate.push(autoSaveField({ formId: this.formId, fieldId: field.uid, field }))
          }
          await Promise.all(fieldsToUpdate)
        },2000)
        setupDefaults()
      }
      this.commentChangesActive = true
      this.setOverrideStatus(false)
      await this.prepopulateUserInfo();

      if(this.$route.name === 'survey-detail') {
        this.isSurveyDetail = true
      }

      this.setupTabsWarnings();
      await this.getChildSupportCalculatorForState()
      this.setPageNames()
      this.setPages()
      this.replaceNames()
      return
    },

    printEmbeddedImage(img) {
      this.confirm({
        title: 'Are you sure you want to print this image?',
        accepted: () => {
          const popup = window.open(img.title)
          popup.document.write(`<img src='${img.url}' height='100%' />`)
          popup.document.title = img.title
          popup.focus()
          popup.print()
          setTimeout(() => {
            popup.close()
          }, 1)
        }
      })
    },

    printDialog(id) {
      const toPrint = document.querySelector(`#dialog-questions-${id}`).innerHTML.replace(/textarea/g, 'pre class="ta"')
      const textAreas = []
      document.querySelectorAll(`#dialog-questions-${id} textarea`).forEach(ta => {
        textAreas.push(ta.value)
      })
      const iframe = document.querySelector(`#dialog-questions-print-${id}`)
      iframe.contentDocument.write(`${toPrint}<style>textarea {font-family:Montserrat;font-size:16px;display:inline-block;width:95%;border-width:0px;overflow:hidden;}</style>`)
      setTimeout(() => {
        iframe.focus()
        iframe.contentDocument.querySelectorAll(`pre.ta`).forEach((ta, i) => {
          ta.innerHTML = textAreas[i]
        })
        iframe.contentWindow.print()
        iframe.contentDocument.body.innerHTML = ''
      },1000)
    },

    async autoSave(field, value) {
      if(['text-input-short','text-input-long'].includes(field.type)) {
        field.content = value.replace(/\s+/g, ' ').trim() || ''
      } else if(['single-select','state-select', 'multi-select'].includes(field.type)) {
        field.selection = value
      }

      if(field.title === 'RES_EADD' && this.sharedUserProps) {
        this.sharedUserProps.user.email = field.content
        await this.saveRawForm({ id: this.formId, sharingUser: field.content, sharingProps: [this.sharedUserProps] })
      }

      await Promise.all([
        this.saveRawForm({ id: this.formId, modifiedDate: Date.now() }),
        autoSaveField({ formId: this.formId, fieldId: field.uid, field })
      ])
      this.replaceNames()
      this.setupPageWarnings()
    },

    hideWarBtn() {
      this.$emit('modalCloseOnParent')
      this.hideWarningBtn = true
    },

    async prepopulateUserInfo() {
      if(this.step === 1 && this.formType === 'petitioner') {
        const autoFills = ['RES_EADD','RES_FN','RES_LN','PET_EADD','PET_FN','PET_LN','PET_BD','PET_GENDER','PET_PHONE','PET_STADD','PET_CITY','PET_ST','PET_POST-CODE']

        await this.formFields.filter(f => f.page <= 2 && !f?.answeredStatus && autoFills.findIndex(a => a === f.title) >= 0).forEach(async field => {
          if(field.title === 'RES_EADD') field.content = this.userInfo?.petitioner_data?.respondent_email.toLowerCase() || ''
          if(field.title === 'RES_FN') field.content = this.userInfo?.petitioner_data?.respondent_first_name || ''
          if(field.title === 'RES_LN') field.content = this.userInfo?.petitioner_data?.respondent_last_name || ''
          if(field.title === 'PET_EADD') field.content = this.userInfo.email.toLowerCase();
          if(field.title === 'PET_FN') field.content = this.userInfo.first_name;
          if(field.title === 'PET_LN') field.content = this.userInfo.last_name;
          if(field.title === 'PET_BD') {
            field.dateValue = getDateFormat(this.userInfo.date_of_birth,'MM/DD/YYYY')
            field.dateSingle = getDateFormat(this.userInfo.date_of_birth,'MM/DD/YYYY')
          }
          if(field.title === 'PET_GENDER') field.selection = this.userInfo.gender;
          if(field.title === 'PET_PHONE') field.content = this.userInfo.phone_number;
          if(field.title === 'PET_STADD') field.content = this.userInfo.address_street_1;
          if(field.title === 'PET_CITY') field.content = this.userInfo.address_city;
          if(field.title === 'PET_ST') field.selection = this.userInfo.address_state;
          if(field.title === 'PET_POST-CODE') field.content = this.userInfo.address_zip_code;
          field.answeredStatus = field?.content?.length > 0 || field?.selection?.length > 0 || field?.dateSingle?.length > 0
          await autoSaveField({ formId: this.formId, fieldId: field.uid, field })
        })
      }
      return
    },

    downloadFormData() {
      const downloadFields = this.getWorkflowFieldsAll.map(f => ({
        uid: f.uid,
        type: f.type,
        title: f.title,
        content: f?.selection || f?.inputRows || f?.dateValue || (f?.type !== 'single-select' ? f.content : '') || ''
      }))
      let dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(downloadFields, null, 4))
      const downloadLnk = document.getElementById('downloadFormDataLink')
      downloadLnk.setAttribute("href", dataStr)
      downloadLnk.click()
    },

    isSpouseEmail(field) {
      return this.step >= 3 && field.title === 'RES_EADD'
    },

    uploadFormData() {
      document.getElementById('uploadFormData').click()
    },

    validateTabs() {
      const checkFields = debounce(() => this.checkRequired(), 1000)
      checkFields()
    },

    uploadFieldAnswerStatus(fld) {
      return fld?.content || fld?.selection || fld?.inputRows?.length > 0 || fld?.dateValue
    },

    uploadFieldProperToUpdate(type, fld) {
      const props = {
        'date-picker': ['dateValue','dateSingle'],
        'month-picker': ['dateValue','dateSingle'],
        'single-select': 'selection',
        'item-table': 'inputRows',
      }
      
      let prop = props?.[type]
      if(typeof prop === 'string') {
        fld[prop] = fld.content
        fld.content = ''
      } else if(Array.isArray(prop)) {
        prop.forEach(p => {
          fld[p] = fld.content
        })
        fld.content = ''
      }
    },

    async uploadJsonFields(file) {
      if(file === null) return
      this.toggleLoadingOverlayStatus(true)
      const reader = new FileReader();
      reader.readAsText(file)
      reader.onload = async () => {
        const json = JSON.parse(reader.result)
        const fields = this.formFields.map(f => {
          const fieldToUpdate = json.find(j => j.uid === f.uid) || {}
          this.uploadFieldProperToUpdate(f.type, fieldToUpdate)
          return {
            ...f,
            ...fieldToUpdate,
            answeredStatus: this.uploadFieldAnswerStatus(fieldToUpdate)
          }
        })
        this.setFields(fields)
        const payload = { parent: 'workflows-user', id: this.formId, sub: 'fields' }
        // await removeSubCollection(payload)
        await updateSubCollection({ ...payload, data: fields })
        await this.setupForm()
        this.validateTabs()
        file = null
        this.toggleLoadingOverlayStatus(false)
      }
    },

    addComment() {
      this.$emit('add-comments', this.pages );
    },

    async getChildSupportCalculatorForState() {
      const response = await this.$store.dispatch('childCalculator/getCCLogic', this.state);
      if(response) this.childCalculatorInfo = response;
    },

    saveClick() {
      this.saveSnackbar = true
      this.$emit('update');
    },

    async updateForm() {
      this.confirm({
        title: 'This form is not up to date. Clicking ok will erase all your work, but you will have the latest form data',
        accepted: async () => {
          this.setOverlayMessage('Rebuilding your form, please wait ...')
          this.toggleLoadingOverlayStatus(true)
          const newForm = await this.getRawForm({ formName: 'workflows-published', formId: this.parentId })
          newForm.id = this.formId
          newForm.parentId = this.parentId
          newForm.modifiedDate = Date.now()
          newForm.owner = {
            name: this.userInfo.full_name,
            email: this.userInfo.email
          }
          newForm.step = 1
          newForm.respondentStep = 1
          newForm.sharingProps = []
          newForm.sharingUser = ''
          await Promise.all([
            this.updateRawForm({ formId: this.formId, data: newForm }),
            removeAllFormSubCollections({ parent: 'workflows-user', id: this.formId })
          ])
          const subs = ["docs","blocks","fields","display-logic-elements","display-logic-library"]
          const subCollectionUpdates = []
          for(let s = 0; s < subs.length; s++) {
            const sub = subs[s]
            const data = await this.getSubCollection({ parent: 'workflows-published', id: this.parentId, sub })
            if(sub === 'fields') {
              this.clearForm({ fields: data })
            }
            subCollectionUpdates.push(updateSubCollection({ parent: 'workflows-user', id: newForm.id, sub, data }))
          }
          await Promise.all(subCollectionUpdates)
          setTimeout(() => {
            location.reload()
          },1000)
        }
      })
    },

    setupTabsWarnings() {
      let tabs = {};
      Object.values(this.pages).forEach(function(fields, index) {
        tabs[index] = {};
        fields.forEach(function(field) {
          if (field.required && !field.answeredStatus) {
            tabs[index][field.uid] = {};
            tabs[index][field.uid].title = field.label;
            tabs[index][field.uid].uid = field.uid;
          }
        });

        if (!Object.keys(tabs[index]).length) {
          delete tabs[index]
        }
      });

      this.tabsWarnings = tabs;
      this.$emit('errorWorkflow', this.tabsWarnings)
    },

    setupPageWarnings() {
      const currentPageWarnings = {}
      this.pageNames.forEach((pg, pgIndex) => {
        const count = this.visibleRequiredFields.filter(v => v.page === (pgIndex + 1)).length
        currentPageWarnings[pg] = count
      })
      this.pageWarnings = currentPageWarnings
    },

    renderWarningButton() {
      // return this.currentTab === Object.keys(this.pages).length - 1 && Object.keys(this.tabsWarnings).length
      return Object.keys(this.tabsWarnings).length
    },

    takeQuestionsFromModal(data) {
      this.questionsData = data;
    },

    // GENERAL Methods

    clickedTab() {      
      this.checkRequired()
      this.muteVideo();
    },

    previousTab() {
      this.checkRequired()
      this.scrollToTop()
      this.currentTab--
    },

    nextTab() {
      this.checkRequired()
      this.scrollToTop()
      this.currentTab++
    },

    checkRequired() {
      this.$emit('errorWorkflow', this.tabsWarnings)
      this.setupPageWarnings()
    },
    
    scrollToTop() {
      document.querySelector('.right-content').scrollTop = 0
    },

    inputHandler(field, event) {
      if (field.required) {
        if ((event || event === 0) && this.tabsWarnings.length > 0 && this.tabsWarnings[this.currentTab]?.[field.uid]) {
          delete this.tabsWarnings[this.currentTab][field.uid];
        }

        if (!event && this.tabsWarnings.length > 0) {
          this.tabsWarnings[this.currentTab][field.uid] = {};
          this.tabsWarnings[this.currentTab][field.uid].title = field.label;
          this.tabsWarnings[this.currentTab][field.uid].uid = field.uid;
        }
      }

      let value = event
      if(typeof event === 'object' && event?.target)
        value = event.target.value

      const toUpdate = this.updateField(field, value)
      const saveField = debounce(async () => {
        await this.$store.dispatch(this.updateFieldAction,toUpdate)
        await this.autoSave(field, value)
      },250)
      saveField()
    },

    // Share Survey Methods

    onSubmit() {
      if (!this.previewMode) {
        this.formResponse.form = this.formFields;

        const formResponse = JSON.parse(JSON.stringify(this.formResponse));

        this.$store.dispatch("addFormResponse", formResponse);
      }

      this.formFields.forEach((field) => {
        if (field.type === "multi-select") {
          field.selection = [];
        } else if (field.type === "text-field") {
          field.content = "";
        } else {
          field.selection = null;
        }
      });
    },

    processAcceptReview(fieldsIndex, data) {
      const payload = { fieldsIndex, data };
      this.acceptReview(payload).then(() => {
        this.componentKey++;
      });
    },

    processDeclineReview(fieldsIndex, email) {
      const payload = { fieldsIndex, email };
      this.declineReview(payload).then(() => {
        this.componentKey++;
      });
    },
  },  
}