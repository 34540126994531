<template>
  <div class="backup">
    <v-btn 
      :loading="btnLoading"
      color="primary"
      title="Run Backup"
      @click="runBackup"
    >
      Run Backup
    </v-btn>
  </div>
</template>

<script>
import backupDatabaseService from '../services/backupDatabase.service'
import adminCheckerMixin from '@/mixins/adminChecker.mixin'
import { mapActions } from 'vuex'

export default {
  name: "BackupDatabase",

  mixins: [adminCheckerMixin],

  data() {
    return {
      btnLoading: false
    }
  },

  methods: {
    ...mapActions("alert", ["setAlert"]),

    async runBackup() {
      this.btnLoading = true;
      await backupDatabaseService()
      this.btnLoading = false;
      this.setAlert({
        type: 'success',
        show: true,
        message: 'Database backup successfully started, it may take a few minutes to complete.'
      })
    }
  }
}
</script>

<style>
  .backup {
    margin-top: 20px;
  }
</style>