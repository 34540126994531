import { firebaseStorageDeleteDirectory } from "@/services/workflow";
import { nanoid } from "nanoid";
import $logger from '@/utils/logger.util'

export const handleSignaturePads = async ({ state, form, formId, isAdmin = true, fieldsName = "fields" }) => {
  let signaturePads = [];
  if (form?.[fieldsName]) {
    signaturePads = form[fieldsName].filter(f => f.type === 'signature-pad');
  }

  for (let s = 0; s < signaturePads.length; s++) {
    const sp = signaturePads[s];
    if (sp.signature?.img && sp.signature.img.indexOf("undefined") < 0) {
      const alteredSignature = await handleSignaturePad({ state, formId, formState: form.state, spField: sp, isAdmin });
      form[fieldsName][sp.fieldsIndex] = { ...alteredSignature };
    } else if (sp.signature && sp.signature.url != null) {
      form[fieldsName][sp.fieldsIndex].signature = { ...sp.signature };
    } else {
      form[fieldsName][sp.fieldsIndex].signature = {};
    }
  }
}

export const handleSignaturePad = async ({ state, formId, formState, spField, isAdmin = true }) => {
  const storageRef = state.firebaseConfig.storage.ref();
  const now = new Date().getTime();
  // Iterate over files and push them into storage request at appropriate storage location
  if (spField.uid === undefined) spField.uid = nanoid(12);
  const adminPath = `workflows-in-edit/${formId}/form-elements/signature-pad/${spField.uid}`;
  const userPath = `workflows-user/${formState}/${formId}/signature-pad/${spField.uid}`;
  const storagePath = isAdmin ? adminPath : userPath;

  if (spField.signature?.ref && spField.signature?.signed === false) {
    await firebaseStorageDeleteDirectory(storageRef.child(spField.signature.ref));
    spField.signature = {};
  } else if (spField.signature?.ref === undefined) {
    const filePath = `${storagePath}/${now}.png`;
    await firebaseStorageDeleteDirectory(storageRef.child(storagePath));
    const snapshot = await storageRef.child(filePath).putString(spField.signature.img, 'data_url', { contentType: "image/png" })

    const downloadURL = await snapshot.ref.getDownloadURL();
    spField.signature.url = downloadURL;
    spField.signature.img = null;
    spField.signature["ref"] = snapshot.metadata.fullPath
    return spField
  }
}
