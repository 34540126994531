<template>
  <v-container fluid class="full-height" style="margin-top:80px;">
    <v-btn color="primary" @click="redirectClick">Home</v-btn>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "PayPalCancel",

  async mounted() {
    await this.establishUserAuth()

    await this.setAlert({
      message: "Sorry, could not process your PayPal request",
      type: "error"
    })
  },

  methods: {
    ...mapActions("alert", ["setAlert"]),

    ...mapActions('profile', ['establishUserAuth']),

    redirectClick() {
      this.setAlert({
        type: '',
        message: '',
        show: false
      })
      this.$router.push('/legal-form-guide')
    }
  }
};
</script>