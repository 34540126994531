<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="py-2 background-light-grey">
          <span class="my-2 black--text font-size-large font-weight-600">Library</span>
        </div>

        <v-row>
          <v-col cols="9">

            <div v-if="activeRule" class=" my-4 d-flex justify-space-between align-center">
              <label class="px-4"><span class="text-primary">Active Rule</span> Name</label>
              <v-text-field v-model="activeRuleName" placeholder="Rule Name" solo dense class="solo-height-fix-dense" />
            </div>

            <v-divider v-if="activeRule" />

            <!-- Levels -->
            <DisplayLogicLevel v-if="activeRule" :level="activeNodeLevelOne" :library="true" :lowest-level="lowestActiveLevel" :level-position="1" ref="level-1"
                               @active-node-change="activateNode($event, 1)"/>
            <DisplayLogicLevel v-if="activeNodeLevelTwo" :library="true" :level="activeNodeLevelTwo" :lowest-level="lowestActiveLevel" :level-position="2" ref="level-2"
                               @active-node-change="activateNode($event, 2)"/>
            <DisplayLogicLevel v-if="activeNodeLevelThree" :library="true" :level="activeNodeLevelThree" :lowest-level="lowestActiveLevel" :level-position="3" ref="level-3"
                               @active-node-change="activateNode($event, 3)"/>
            <DisplayLogicLevel v-if="activeNodeLevelFour" :library="true" :level="activeNodeLevelFour" :lowest-level="lowestActiveLevel" :level-position="4" ref="level-4"
                               @active-node-change="activateNode($event, 4)"/>
            <DisplayLogicLevel v-if="activeNodeLevelFive" :library="true" :level="activeNodeLevelFive" :lowest-level="lowestActiveLevel" :level-position="5" ref="level-5"
                               @active-node-change="activateNode($event, 5)"/>
            <DisplayLogicLevel v-if="activeNodeLevelSix" :library="true" :level="activeNodeLevelSix" :lowest-level="lowestActiveLevel" :level-position="6" ref="level-6"
                               @active-node-change="activateNode($event, 6)"/>
            <DisplayLogicLevel v-if="activeNodeLevelSeven" :library="true" :level="activeNodeLevelSeven" :lowest-level="lowestActiveLevel" :level-position="7" ref="level-7"
                               @active-node-change="activateNode($event, 7)"/>
            <DisplayLogicLevel v-if="activeNodeLevelEight" :library="true" :level="activeNodeLevelEight" :lowest-level="lowestActiveLevel" :level-position="8" ref="level-8"
                               @active-node-change="activateNode($event, 8)"/>
            <DisplayLogicLevel v-if="activeNodeLevelNine" :library="true" :level="activeNodeLevelNine" :lowest-level="lowestActiveLevel" :level-position="8" ref="level-8"
                               @active-node-change="activateNode($event, 9)"/>
            <DisplayLogicLevel v-if="activeNodeLevelTen" :library="true" :level="activeNodeLevelTen" :last-level="true" :lowest-level="lowestActiveLevel" :level-position="9" ref="level-9"
                               @active-node-change="activateNode($event, 10)"/>

            <!-- Expression Configuration -->
            <DisplayLogicExpression 
              v-if="activeNode && activeNode.node_type === 'expression'"
              :rule="activeNode"
              :parsed-question-list="parsedQuestionList" @update-active-node="updateActiveNode"/>

          </v-col>
          <v-col cols="3">
            <v-row>
              <v-col cols="12" class="mt-2 pb-0">
                <!-- Add New Rule -->
                <v-btn
                    class="d-inline ma-2"
                    outlined
                    title="Add New Rule"
                    @click="addRule"
                >
                  Add New Rule
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <!-- List Rules in LogicLibrary -->
                <v-list dense>
                  <v-list-item-group
                    :value="logicLibrary[activeRule]"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in logicLibrary"
                      :key="i"
                      :class="{ 'm-active-library-rule': activeRule === i }"
                      class="border-solid-1 text-left my-1"
                      @click="activateRule(i)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                      </v-list-item-content>
                      <v-btn icon x-small @click.stop="removeRule(item.uid)">
                        <v-icon>cancel</v-icon>
                      </v-btn>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {nanoid} from "nanoid";

import DisplayLogicLevel from "@/components/WorkflowBuilder/DisplayLogic/DisplayLogicLevel";
import DisplayLogicExpression from "@/components/WorkflowBuilder/DisplayLogic/DisplayLogicExpression";

export default {
  name: 'DisplayLogicLibrary',

  components: {
    DisplayLogicLevel,
    DisplayLogicExpression,
  },

  props: {
    ruleUid: {
      type: String,
      required: false,
      default: null,
    },
    parsedQuestionList: {
      type: Array,
      required: true,
      default: () => [],
    }
  },

  data() {
    return {
      activeRule: null,
      activeRuleNameDebounce: null,

      activeNode: null,
      lowestActiveLevel: 1,

      activeNodeLevelOne: {
        uid: nanoid(12),
        name: '',
        node_type: 'compound',
        logical_operator: 'and',
        not: false,
        contains: [],
      },
      activeNodeLevelTwo: null,
      activeNodeLevelThree: null,
      activeNodeLevelFour: null,
      activeNodeLevelFive: null,
      activeNodeLevelSix: null,
      activeNodeLevelSeven: null,
      activeNodeLevelEight: null,
      activeNodeLevelNine: null,
      activeNodeLevelTen: null,

      numConversion: {
        1: 'One',
        2: 'Two',
        3: 'Three',
        4: 'Four',
        5: 'Five',
        6: 'Six',
        7: 'Seven',
        8: 'Eight',
        9: 'Nine',
        10: 'Ten',
      }
    }
  },

  computed: {
    ...mapGetters({
      workflowId: 'getActiveFormId',
      logicLibrary: 'displayLogic/logicLibrary',
    }),

    activeRuleName: {
      get() {
        return this.logicLibrary[this.activeRule]?.name ?? null;
      },
      set(name) {
        clearTimeout(this.activeRuleNameDebounce);
        this.activeRuleNameDebounce = setTimeout(() => {
          this.$store.dispatch('displayLogic/editLogicLibraryEntry', { updates: { name: name }, uid: this.activeRule });
        }, 500);
      },
    }
  },

  mounted() {
    // Activate rule when library node is clicked
    if (this.ruleUid) {
      this.activateRule(this.ruleUid);
    }
  },

  methods: {
    activateNode(activeNode, level) {
      const node = Object.assign({}, activeNode);
      let newLevel = level;
      this.lowestActiveLevel = level;


      if (node.node_type === 'compound' && newLevel <= 10) {
        newLevel++;
        const propertyName = 'activeNodeLevel' + this.numConversion[newLevel];
        this[propertyName] = node;
      } else if (node.node_type === 'expression') {
        // this.activeNode = node;
      } else if (node.node_type === 'library') {
        // this.activeNode = node;
      }

      this.activeNode = node;

      // Clear deeper nodes
      newLevel++;
      while (newLevel <= 10) {
        const propertyName = 'activeNodeLevel' + this.numConversion[newLevel];
        this[propertyName] = null;
        newLevel++;
      }
    },

    async updateActiveNode(payload) {
      this.activeNode = payload
      await this.$store.dispatch('displayLogic/editLogicLibraryEntry', payload);
    },

    activateRule(i) {
      this.activeRule = i;
      this.activeNodeLevelOne = this.logicLibrary[i];
      this.$store.dispatch('displayLogic/editLogicLibraryEntry', { updates: { name: this.logicLibrary[i].name }, uid: i });
      this.$emit('active-rule-updated', { uid: i, name: this.logicLibrary[i].name});
    },

    addRule() {
      const newRule = {
        uid: nanoid(12),
        name: 'New Rule',
        node_type: 'compound',
        logical_operator: 'and',
        not: false,
        contains: [],
      };
      this.$store.dispatch('displayLogic/addLogicLibraryEntry', newRule);
    },

    removeRule(uid) {
      this.$store.dispatch('displayLogic/removeLogicLibraryEntry', uid).then(() => {
        if (uid === this.activeRule) {
          this.activeRule = null;

          // Clear nodes
          let newLevel = 1;
          while (newLevel <= 10) {
            const propertyName = 'activeNodeLevel' + this.numConversion[newLevel];
            this[propertyName] = null;
            newLevel++;
          }
        }
      });
    },
  },
}
</script>