const questionMatcher = ({ collaborate, fields }, logicQuestion) => {
  if (!fields || !logicQuestion) return false;
  
  if (logicQuestion.type === "participating") {
    if (logicQuestion.selection.answer === "collaborating" && collaborate) {
      return true;
    }
      
    if (logicQuestion.selection.answer === "unilateral" && !collaborate) {
      return true;
    }

    return false
  }
  let liveQuestion = fields.find(
    (f) =>
      (f.title === logicQuestion.title || f.uid === logicQuestion.uid) &&
      f.type === logicQuestion.type
  );
  let questionMatch = false;
  if (liveQuestion === undefined) return false;

  switch (logicQuestion.type) {
    case "single-select":
    case "state-select":
      if (liveQuestion) {
        const liveQuestionSelection =
          liveQuestion.selection || liveQuestion.default;
        switch (liveQuestion.variant) {
          case "drop-down":
            questionMatch =
              liveQuestionSelection === logicQuestion.selection.answer;
            break;
          case "radio":
          case "slider":
            questionMatch =
              liveQuestion.content[liveQuestionSelection] ===
              logicQuestion.selection.answer;
            break;
        }
      }
      break;
    case "multi-select":
      if (logicQuestion.selection?.orLogic) {
        questionMatch = logicQuestion.selection.answer.some((response) =>
          liveQuestion.selection.includes(response)
        );
      } else {
        questionMatch = logicQuestion.selection.answer.every((response) =>
          liveQuestion.selection.includes(response)
        );
      }
      break;
    case "signature-pad":
      if (logicQuestion.selection.answer === "signed") {
        questionMatch = liveQuestion.signature.signed;
      } else {
        questionMatch = !liveQuestion.signature.signed;
      }
      break;
    case "number-input":
      let number_input = logicQuestion.selection;
      if (number_input.comparison === "between") {
        questionMatch =
          parseFloat(liveQuestion.content) >=
            parseFloat(number_input.lowerLimit) &&
          parseFloat(liveQuestion.content) <=
            parseFloat(number_input.upperLimit);
      } else if (number_input.comparison === "less-than") {
        questionMatch =
          parseFloat(liveQuestion.content) <=
          parseFloat(number_input.upperLimit);
      } else if (number_input.comparison === "equal-to") {
        questionMatch =
          parseFloat(liveQuestion.content) === parseFloat(number_input.equalTo);
      } else if (number_input.comparison === "greater-than") {
        questionMatch =
          parseFloat(liveQuestion.content) >=
          parseFloat(number_input.lowerLimit);
      }
      break;
    case "text-input-long":
    case "text-input-short":
      if (logicQuestion.selection.answer === "answered") {
        questionMatch =
          liveQuestion.content && liveQuestion.answeredStatus === true;
      } else {
        questionMatch =
          !liveQuestion.content && liveQuestion.answeredStatus === false;
      }
      break;
    case "file-input":
    case "youtube-video":
    case "dialog-window":
      // Need to update
      questionMatch = true;
      break;
    case "page-break":
      if (logicQuestion.selection.answer === "before-page-enter") {
        questionMatch =
          parseInt(this.currentPreviewPage) <
          parseInt(logicQuestion.pageNumber);
      } else if (logicQuestion.selection.answer === "after-page-enter") {
        questionMatch =
          parseInt(this.currentPreviewPage) ===
          parseInt(logicQuestion.pageNumber);
      } else if (logicQuestion.selection.answer === "after-page-exit") {
        questionMatch =
          parseInt(this.currentPreviewPage) >
          parseInt(logicQuestion.pageNumber);
      }
      break;
    case "date-picker":
      if (
        ["after", "equal-to", "before", "between"].indexOf(
          logicQuestion.selection.answer
        ) > -1
      ) {
        questionMatch = true;
      }
      break;
    case "grouped-options":
      questionMatch = liveQuestion.selection === logicQuestion.selection.answer;
      break;
  }

  return questionMatch;
};

module.exports = questionMatcher;
