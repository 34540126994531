<template>
  <v-row>
    <v-col cols="12" lg="6" xl="5">
      <v-card tile>
        <v-card-title class="bg-primary white--text"
          >Current Action</v-card-title
        >

        <v-card-text>
          <template
            v-if="activeRule.type === 'Action Logic' && currentAction !== null"
          >
            <v-row class="mt-2">
              <v-col cols="12">
                <template
                  v-if="
                    activeAction.type === 'add-x' ||
                    activeAction.type === 'add-check-mark'
                  "
                >
                  <v-select
                    v-model="activeAction.size"
                    :items="actionOptions.size"
                    label="Action Size"
                  />
                </template>

                <template v-if="activeAction.type === 'custom-image'">
                  <v-row>
                    <v-col cols="0" sm="2" md="3"></v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="3"
                      class="d-flex align-center"
                    >
                      <v-select
                        v-model="activeAction.variant"
                        :items="customImageVariants"
                        label="Variant"
                        solo
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="3"
                      class="d-flex align-center"
                    >
                      <v-autocomplete
                        v-if="activeAction.variant === 'image-from-survey'"
                        v-model="activeAction.question"
                        :items="parsedImageFormElementsList"
                        label="Form Element To Get Image From"
                        item-text="questionListTitle"
                        return-object
                        solo
                      />
                      <v-file-input
                        v-else-if="activeAction.variant === 'image-from-upload'"
                        id="add-next-page"
                        label="Upload Image"
                        outlined
                        prepend-icon="mdi-camera"
                        :accept="getAcceptedUploadFileTypes"
                        @change="
                          customImageUpload($event, {
                            activeRule: activeRule,
                            activeAction: currentAction,
                          })
                        "
                        @click:clear="
                          customImageClear($event, {
                            activeRule: activeRule,
                            activeAction: currentAction,
                          })
                        "
                      />
                    </v-col>
                    <v-col cols="0" sm="2" md="3"></v-col>
                  </v-row>
                  <v-divider />
                </template>              

                <template v-if="activeAction.type === 'text-from-question'">
                  <v-row>
                    <v-col cols="12">
                      <label>Question To Get Text From</label>
                      <v-autocomplete
                        v-model="activeAction.question"
                        :items="parsedTextQuestionsList"
                        label="Question To Get Text From"
                        item-text="questionListTitle"
                        return-object
                        solo
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <label>Text Alignment</label>
                      <v-select
                          v-model="activeAction.textAlign"
                          :items="['left', 'center', 'right']"
                          label="Text Alignment"
                          solo
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <label>Text Color</label>
                      <v-select
                          v-model="activeAction.textColor"
                          :items="actionOptions.textColor"
                          label="Text Color"
                          solo
                      />
                      <label>Font Size</label>
                      <v-text-field
                          v-model="activeAction.fontSize"
                          label="Font Size"
                          type="number"
                          solo
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <label>Text Decoration</label>
                      <v-select
                          v-model="activeAction.textDecoration"
                          :items="actionOptions.textDecoration"
                          label="Text Decoration"
                          solo
                      />
                      <label>Font Family</label>
                      <v-select
                          v-model="activeAction.fontFamily"
                          :items="actionOptions.fontFamily"
                          label="Font Family"
                          solo
                      />
                    </v-col>
                  </v-row>
                </template>

                <template v-if="activeAction.type === 'text-static'">
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="activeAction.staticText"
                        label="Action Text"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <label>Text Alignment</label>
                      <v-select
                          v-model="activeAction.textAlign"
                          :items="['left', 'center', 'right']"
                          label="Text Alignment"
                          solo
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <label>Text Color</label>
                      <v-select
                        v-model="activeAction.textColor"
                        :items="actionOptions.textColor"
                        label="Text Color"
                        solo
                      />
                      <label>Font Size</label>
                      <v-text-field
                        v-model="activeAction.fontSize"
                        label="Font Size"
                        type="number"
                        solo
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <label>Text Decoration</label>
                      <v-select
                        v-model="activeAction.textDecoration"
                        :items="actionOptions.textDecoration"
                        label="Text Decoration"
                        solo
                      />
                      <label>Font Family</label>
                      <v-select
                        v-model="activeAction.fontFamily"
                        :items="actionOptions.fontFamily"
                        label="Font Family"
                        solo
                      />
                    </v-col>
                  </v-row>
                </template>

                <template v-if="activeAction.type === 'text-editor'">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="activeAction.textColor"
                        :items="actionOptions.textColor"
                        label="Text Color"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="activeAction.fontSize"
                        label="Font Size"
                        type="number"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        v-model="activeAction.fontFamily"
                        :items="actionOptions.fontFamily"
                        label="Font Family"
                      />
                    </v-col>
                  </v-row>
                </template>

                <v-row>
                  <template v-if="['add-x', 'add-check-mark'].indexOf(activeAction.type) > -1">
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="activeAction.location.x"
                        label="Horizontal Position"
                        type="number"
                        :rules="[rules.hPosition]"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="activeAction.location.y"
                        label="Vertical Position"
                        type="number"
                        :rules="[rules.vPosition]"
                      />
                    </v-col>
                  </template>
                  <template v-if="['text-static','text-from-question','custom-image','resp-signature-img','resp-signature-date','pet-signature-img','pet-signature-date'].indexOf(activeAction.type) > -1">
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="activeAction.location.startX"
                          label="Horizontal Position"
                          type="number"
                          :rules="[rules.hPosition]"
                      />
                      <v-text-field
                          v-model="activeAction.location.startY"
                          label="Vertical Position"
                          type="number"
                          :rules="[rules.vPosition]"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="activeAction.location.width"
                        label="Width"
                        type="number"
                      />
                      <v-text-field
                        v-model="activeAction.location.height"
                        label="Height"
                        type="number"
                      />
                    </v-col>
                  </template>
                  <template v-if="activeAction.type === 'text-editor' && !activeAction.pageIsBlank">
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="activeAction.location.startX"
                        label="Horizontal Position"
                        type="number"
                        :rules="[rules.hPosition]"
                      />
                      <v-text-field
                        v-model="activeAction.location.startY"
                        label="Vertical Position"
                        type="number"
                        :rules="[rules.vPosition]"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="activeAction.location.width"
                        label="Width"
                        type="number"
                      />
                      <v-text-field
                        v-model="activeAction.location.height"
                        label="Height"
                        type="number"
                      />
                    </v-col>
                  </template>
                  <template v-if="activeAction.type === 'text-editor'">
                    <v-col cols="12">
                      <RuleConfiguration
                        :parsed-question-list="parsedQuestionList"
                        :parsed-text-questions-list="parsedTextQuestionsList"
                        :parsed-image-form-elements-list="
                          parsedImageFormElementsList
                        "
                        :parent-active-rule="activeRule"
                        :parent-active-action="currentAction"
                        :doc-id="currentDocIndex"
                      />
                    </v-col>
                    <v-col cols="12">
                      <AutoNumbering
                        :active-rule="activeRule"
                        :active-action="currentAction"
                      />
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="justify-content-space-evenly">
                <v-btn tile dark color="green" @click="updateAction"
                  >Update PDF File
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" xl="7">
      <FileRenderer
        :checker="selectedAction"
        :pdf-file="templateFile"
        :current-page-num="activeAction.location.page || 1"
        @page-change="updatePageNumber"
        @mouse-position="trackMousePosition"
      >
        <template #card-title> Placement </template>
        <template #vue-resizable>
          <VueDraggableResizable
            v-show="activeAction.location.page === currentPage"
            :x="actionLeft"
            :y="actionTop"
            :h="scaleHeight"
            :w="actionWidth || 1"
            class="draggable-action"
            @dragstop="moveAction"
            style="cursor: pointer; position: absolute"
          >
            <div
              v-show="selectedAction"
              id="selectedAction"
              style="
                background-color: rgba(192,192,192,0.3);
                border: 1px solid gray;
              "
            >
              <div class="filler" />
              <img
                v-if="chosenImage"
                id="action-image"
                :src="chosenImage"
                width="100%"
                height="100%"
                style="display: block"
                :alt="activeAction.title || ''"
              />
              <div
                v-if="1 === 0"
                class="delete-button"
                title="Delete Action?"
                @click="handleDeleteAction"
              >
                <v-icon dense style="width: 100%">mdi-delete </v-icon>
              </div>
              <div class="move-button" title="Move Action?">
                <v-icon dense style="width: 100%">mdi-cursor-move</v-icon>
              </div>
              <div
                v-show="chosenImage === null"
                class="resize-button"
                title="Resize?"
                @click="resizeAction"
              >
                <v-icon>mdi-resize-bottom-right</v-icon>
              </div>
            </div>
          </VueDraggableResizable>
        </template>
      </FileRenderer>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueDraggableResizable from "vue-draggable-resizable";

import pdfMixin from "@/mixins/pdf.mixin";
import pdfToPng from "@/services/pdfToPng";

import FileRenderer from "@/components/FileRenderer";
import RuleConfiguration from "@/components/TextEditorAction/RuleConfiguration";
import AutoNumbering from "@/components/TextEditorAction/AutoNumbering";
import { actionSize } from "@/constants/actionSizes";
import xMark from "@/assets/clear-24px.png";
import checkMark from "@/assets/check-transparent-214.png";
import wait from "@/utils/wait.util";

export default {
  name: "ActionsPdfPlacement",

  components: {
    FileRenderer,
    RuleConfiguration,
    AutoNumbering,
    VueDraggableResizable,
  },

  mixins: [pdfMixin],

  props: {
    contentHeight: {
      type: Number,
      default: 500,
    },
    parsedQuestionList: {
      type: Array,
      default: () => [],
    },

    parsedTextQuestionsList: {
      type: Array,
      default: () => [],
    },

    parsedImageFormElementsList: {
      type: Array,
      default: () => [],
    },

    currentAction: {
      type: Number,
      default: -1,
    },

    currentRule: {
      type: Number,
      default: -1,
    },

    currentDoc: {
      type: Object,
      default: () => {},
    },

    currentPage: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      selectedAction: 0,
      actionCorners: ["rb"],
      actionTop: 0,
      actionLeft: 0,
      actionWidth: 1,
      actionHeight: 1,
      scaleHeight: 100,
      scaleWidth: 100,
      canvasWidth: 0,
      canvasHeight: 0,
      mouseX: 0,
      mouseY: 0,
      actionOptions: {
        textDecoration: ["bold", "italic", "underlined"],
        size: ["xs", "sm", "md", "lg", "xl"],
        fontFamily: ["Calibri", "Times New Roman", "Arial", "Helvetica"],
        textColor: [
          "black",
          "white",
          "red",
          "orange",
          "yellow",
          "green",
          "blue",
          "violet",
          "light-grey",
          "grey",
          "dark-grey",
        ],
      },

      customImageVariants: ["image-from-survey", "image-from-upload"],

      editMode: false,
      chosenImage: xMark,

      rules: {
        hPosition: (value) =>
          value <= 100 && value > 0
            ? true
            : "Must be a value between 0 and 100",
        vPosition: (value) =>
          value <= 120 && value > 0
            ? true
            : "Must be a value between 0 and 120",
      },
    };
  },

  computed: {
    ...mapGetters({
      getAcceptedUploadFileTypes: "getAcceptedUploadFileTypes",
    }),

    activeRule() {
      return this.currentDoc ? this.currentDoc.rules[this.currentRule] : null;
    },

    activeAction() {
      return this.currentDoc
        ? this.currentDoc.rules[this.currentRule].actions[this.currentAction]
        : null;
    },
  },

  watch: {
    currentAction: {
      handler: function (newIndex) {
        if (newIndex >= 0 && this.activeAction) {
          setTimeout(() => {
            this.selectAction(this.activeAction, newIndex);
          }, 300);
        }
      },
    },
  },

  mounted() {
    this.setupCanvas();
  },

  methods: {
    ...mapActions("workflow", ["deleteAction"]),

    ...mapActions(["updateDocumentRender", "toggleLoadingOverlayStatus"]),

    ...mapActions("confirmation", ["confirm"]),

    draggableAction() {
      return document.querySelector(".draggable-action #action-image") || null;
    },

    trackMousePosition(mouseEvent) {
      this.mouseX = mouseEvent.clientX;
      this.mouseY = mouseEvent.clientY;
    },

    async setupCanvas() {
      await wait(100);
      const canvas = await this.getCanvas();
      this.canvasWidth = canvas.width;
      this.canvasHeight = canvas.height;
      this.selectAction(this.activeAction, this.currentAction);
    },

    async getCanvas() {
      let canvas = null;
      while (!canvas) {
        await wait(250);
        canvas = document.getElementById(`dfr-canvas-${this.selectedAction}`);
      }
      this.canvasHeight = canvas.height;
      this.canvasWidth = canvas.width;
      return {
        height: this.canvasHeight || 1,
        width: this.canvasWidth || 1,
      };
    },

    updatePageNumber(newValue) {
      this.$emit("page-change", newValue);
    },

    getCorrectSize: (pos, layout, canvasSize, sub) =>
      pos === 0 ? layout : (pos / layout) * canvasSize - sub,

    setupAction() {
      const moveAction = this.draggableAction();

      moveAction.style.width = "1px";
      moveAction.style.height = "1px";
      this.actionWidth = 1;
      this.actionHeight = 1;
    },

    async moveAction(left, top) {
      await wait(100);
      const actionObj = this.activeAction;
      const cachePageNum = actionObj.location.page;
      actionObj.location.page = 0;
      if (!actionObj) return;
      const canvas = await this.getCanvas();
      this.canvasWidth = canvas.width;
      this.canvasHeight = canvas.height;
      if (
        ["text-static", "text-from-question", "pet-signature-date", "resp-signature-date", "pet-signature-img", "resp-signature-img", "custom-image"].indexOf(
          actionObj.type
        ) > -1
      ) {
        const x = parseFloat(left);
        const y = parseFloat(top);
        actionObj.location.startX = (x / this.canvasWidth) * this.scaleWidth;
        actionObj.location.startY = (y / this.canvasHeight) * this.scaleHeight;
        if (actionObj.location.startX < 0) actionObj.location.startX = 0;
        if (actionObj.location.startY < 0) actionObj.location.startY = 0;

        if (
          actionObj.location.startX + actionObj.location.width >
          this.scaleWidth
        ) {
          actionObj.location.startX =
            this.scaleWidth - actionObj.location.width;
        }

        if (
          actionObj.location.startY + actionObj.location.height >
          this.scaleHeight
        ) {
          actionObj.location.startY =
            this.scaleHeight - actionObj.location.height;
        }
      } else {
        const marginTop = 15;
        // const x = parseFloat(left);
        // const y = parseFloat(top);
        const x =
          parseFloat(left) +
          actionSize[actionObj.size].width -
          actionSize[actionObj.size].width / 2;
        const y =
          parseFloat(top) +
          actionSize[actionObj.size].height -
          actionSize[actionObj.size].height / 2;
        actionObj.location.x = (x / this.canvasWidth) * this.scaleWidth;
        actionObj.location.y = (y / this.canvasHeight) * this.scaleHeight;
        if (actionObj.location.x < 0) actionObj.location.x = 1;
        if (actionObj.location.y < 0) actionObj.location.y = 1;
      }

      // _self.updatePdfTemplateAction(actionObj, _self.selectAction)
      actionObj.location.page = cachePageNum;
      this.updateAction();
    },

    async resizeAction(data) {
      await wait(100);
      const movedAction = this.draggableAction();
      const actionObj = this.activeAction;
      let w = (data.width / this.canvasWidth) * this.scaleWidth;
      let h = (data.height / this.canvasHeight) * this.scaleHeight;

      if (actionObj.type === "signature-pad") {
        w = w / 2.45;
      }

      if (
        parseFloat(movedAction.style.height) +
          parseFloat(movedAction.style.top) >
        this.canvasHeight
      ) {
        const elemHeight =
          this.canvasHeight - parseFloat(movedAction.style.top);

        movedAction.style.height = elemHeight + "px";
        h = this.scaleHeight - actionObj.location.startY;
      }

      if (
        parseFloat(movedAction.style.width) +
          parseFloat(movedAction.style.left) >
        this.canvasWidth
      ) {
        const elemWidth = this.canvasWidth - parseFloat(movedAction.style.left);

        movedAction.style.width = elemWidth + "px";
        w = this.scaleWidth - actionObj.location.startX;
      }

      actionObj.location.width = w;
      actionObj.location.height = h;

      this.updateAction();
    },

    async handleDeleteAction(ask = true) {
      if (ask) {
        this.confirm({
          title: 'Are you sure you want to delete this action?',
          accepted: () => removeAction()
        })
      } else {
        removeAction()
      }
      
      const removeAction = async () => {
        await this.deleteAction(this.selectedAction);
        await Promise.all([
          this.toggleLoadingOverlayStatus(true),
          this.updateDocumentRender()
        ])
        await this.toggleLoadingOverlayStatus(false)
        //document.querySelector("#selectedAction").style.display = "none";
        this.selectedAction = this.selectedAction - 1;
        const actionObj = this.activeRule.actions[this.selectedAction];

        this.selectAction(actionObj, this.selectedAction);
      }
    },

    updateAction() {
      const currentAction = this.activeAction;
      const currentActionIndex = this.currentAction;

      this.selectedAction = undefined;

      if (
        ["text-static", "text-from-question"].indexOf(currentAction.type) > -1
      ) {
        this.activeAction.location.startX = parseFloat(
          currentAction.location.startX
        );
        this.activeAction.location.startY = parseFloat(
          currentAction.location.startY
        );
        this.activeAction.location.width = parseFloat(
          currentAction.location.width
        );
        this.activeAction.location.height = parseFloat(
          currentAction.location.height
        );
      } else if (["add-x", "add-check-mark"].indexOf(currentAction.type) > -1) {
        this.activeAction.location.x = parseFloat(currentAction.location.x);
        this.activeAction.location.y = parseFloat(currentAction.location.y);
      }

      const actionSelected = document.querySelector("#selectedAction");

      actionSelected.style.display = "none";
      this.toggleLoadingOverlayStatus(true)
      this.updateDocumentRender().then(() => {
        this.currentPdfFile = this.pdfFile;
        this.toggleLoadingOverlayStatus(false)
      });

      if (currentActionIndex !== null) {
        this.selectAction(this.activeAction, currentActionIndex);
      }
    },

    selectAction(actionObj, i, action = false) {
      this.editMode = action;

      this.selectedAction = i;

      const actionSelected = document.querySelector("#selectedAction");

      switch (actionObj.type) {
        case "add-check-mark":
          this.chosenImage = checkMark;
          break;
        case "add-x":
          this.chosenImage = xMark;
          break;
        default:
          this.chosenImage = undefined;
          break;
      }

      this.actionWidth = 42;
      this.actionHeight = 42;

      if (
        action === "select" &&
        this.selectedAction.style.display === "block" &&
        i === this.selectedAction
      ) {
        this.selectedAction = undefined;
        actionSelected.style.display = "none";
      } else if (["add-x", "add-check-mark"].indexOf(actionObj.type) > -1) {
        const ppi = 96;

        this.actionWidth =
          (actionSize[actionObj.size].width / this.scaleWidth) *
          (this.canvasWidth / ppi) *
          (this.scaleWidth / 2);
        
        this.actionHeight =
          (actionSize[actionObj.size].height / this.scaleHeight) *
          (this.canvasHeight / ppi) *
          (this.scaleHeight / 2);
        
        this.actionLeft =
          (actionObj.location.x * this.canvasWidth) / this.scaleWidth -
          this.actionWidth / 4;

        this.actionTop =
          (actionObj.location.y * this.canvasHeight) / this.scaleHeight -
          this.actionWidth / 4;

        actionSelected.style.display = "block";
        this.actionCorners = [];
      } else {
        this.actionLeft = this.getCorrectSize(
          actionObj.location.startX,
          this.scaleWidth,
          this.canvasWidth,
          0
        );
        this.actionTop = this.getCorrectSize(
          actionObj.location.startY,
          this.scaleHeight,
          this.canvasHeight,
          0
        );
        this.actionWidth = this.getCorrectSize(
          actionObj.location.width,
          this.scaleWidth,
          this.canvasWidth,
          0
        );
        this.actionHeight = this.getCorrectSize(
          actionObj.location.height,
          this.scaleHeight,
          this.canvasHeight,
          0
        );
        this.actionCorners = ["rb"];
        if(actionSelected) {
          actionSelected.style.display = "block";
        }
      }
    },

    async customImageUpload(file, payload) {
      if (element.type === "application/pdf") {
        const pdfImage = await pdfToPng.convert(element);
        file = pdfImage.data;
      }

      this.activeRule.actions[payload.activeAction].image = file;
    },

    customImageClear(event, payload) {
      this.activeRule.actions[payload.activeAction].image = undefined;
    },
  },
};
</script>
