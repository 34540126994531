import { mapGetters } from "vuex"

export default {
  data: () => ({
    lostBlocks: []
  }),

  props: {
    textEditorData: String
  },

  computed: {
    ...mapGetters("blocks", ["blocks"]),

    blockIds() {
      return this.blocks.map(b => b.tagName) || []
    }
  },

  methods: {
    async copyText(block) {
      const needle = `[*${block}*`
      await navigator.clipboard.writeText(needle)
    },

    runCheck() {
      const inputString = this.textEditorData.replaceAll('&lt;', '<').replaceAll('&gt;', '>')

      // Define the regex pattern
      const regexPattern = /\[\*([A-Za-z0-9_\-]+)\*\]|\[\*([A-Za-z0-9_\-]+)\*\|/g;
      
      // Initialize an array to store occurrences
      const occurrences = [];
      
      // Use the regex pattern with the `exec` method to find matches
      let match;
      while ((match = regexPattern.exec(inputString)) !== null) {
        // Determine which capturing group was matched
        const matchText = match[1] || match[2];
        
        // Push the matched text to the occurrences array
        occurrences.push(matchText);
      }

      const set = new Set(occurrences.filter(o => !this.blockIds.includes(o)))
      this.lostBlocks = [...set]
    }
  }
}