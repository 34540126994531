<template>
  <div>
    <v-row no-gutters>
      <v-col cols="1" class="d-flex align-center justify-center py-2">

        <!-- Add Node -->
        <v-menu offset-y>
          <template v-slot:activator="{ on: on_click, attrs: attrs_menu }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: on_hover, attrs: attrs_tooltip}">
                <v-btn
                    v-bind="{...attrs_menu, ...attrs_tooltip}"
                    v-on="{...on_click, ...on_hover}"
                    class="d-inline mx-2"
                    fab
                    small
                    title="Add New Node"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Node</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item @click="addNode('expression')">
              <v-list-item-title>Expression</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!lastLevel" @click="addNode('compound')">
              <v-list-item-title>Compound</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!library" @click="addNode('library')">
              <v-list-item-title>Library</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-col>

      <v-divider vertical/>

      <v-col :cols="library ? 6 : 8" :xl="library ? 8 : 9" class="d-flex align-center justify-center pa-2">

        <div v-if="level.not" class="border-solid-1 d-flex align-center ma-2">
          <span class="pa-2 bg-info white--text font-size-large">not</span>
        </div>

        <v-chip-group column>
          <template v-for="( node, index ) in level.contains">
            <v-chip class="m-chip" :class="{ 'm-active-node': index === activeNode, 'm-active-level': isActiveLevel && index === activeNode }" outlined close @click.stop="activateNode(node, index)"
                    @click:close="deleteNode(node, index)">
              <span class="font-size-x-small font-weight-bold">{{ node.node_type }}</span>
              <v-divider vertical class="mx-2"/>
              {{ nodeName(node) }}
            </v-chip>
            <div v-if="index < level.contains.length - 1" class="full-height d-flex align-center">
              <v-chip small disabled>
                {{ level.logical_operator }}
              </v-chip>
            </div>
          </template>
        </v-chip-group>

      </v-col>

      <v-divider vertical/>

      <v-col :cols="library ? 5 : 3" :xl="library ? 3 : 2" class="d-flex align-center justify-center py-2 bg-light">

        <!-- Logical Conjoining Operator -->
        <div>
          <v-select v-model="level.logical_operator" :items="logicalOperators" label="Joining Operator" solo
                    class="solo-height-fix ml-2 px-2"/>
        </div>

        <!-- Not Selection -->
        <v-switch v-model="level.not" label="Not" class="px-4"/>

      </v-col>
    </v-row>
    <v-divider/>
  </div>
</template>

<script>
import {nanoid} from "nanoid";

export default {
  name: 'DisplayLogicLevel',

  props: {
    level: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    levelPosition: {
      type: Number,
      default: 1,
    },
    lowestLevel: {
      type: Number,
      default: 1,
    },
    lastLevel: {
      type: Boolean,
      default: false,
    },
    library: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      activeNode: null,
      logicalOperators: ['and', 'or', 'x-or'],
    }
  },

  computed: {
    isActiveLevel() {
      return this.lowestLevel === this.levelPosition;
    },
  },

  methods: {
    nodeName(node) {
      return node.name ?? (node.node_type === 'compound' ? `${node.node_type.toUpperCase() + node.logical_operator} - ${node.contains.length}` : `${node.node_type.toUpperCase()}`);
    },

    activateNode(node, index) {
      this.activeNode = index;
      this.$emit('active-node-change', node);
    },

    resetLevel() {
      this.activeNode = null;
    },

    addNode(type) {

      const nodeTemplate = {
        uid: nanoid(12),
        name: '',
        parent_node: this.level.uid,
      };

      let newNode 

      switch (type) {
        case 'expression':
          newNode = {
            ...nodeTemplate,
            node_type: 'expression',
            conditional: null,
            logical_operator: null,
            questions: [],
          };
          break;
        case 'compound':
          newNode = {
            ...nodeTemplate,
            node_type: 'compound',
            logical_operator: 'and',
            not: false,
            contains: [],
          };
          break;
        case 'library':
          newNode = {
            ...nodeTemplate,
            node_type: 'library',
            not: false,
            library_uid: '',
          };
          break;
      }

      this.level.contains.push(newNode);
    },

    deleteNode(node, index) {
      if (index === this.activeNode) {
        this.$emit('active-node-deleted', node);
      }
      this.level.contains.splice(index, 1);
    }
  }
}
</script>