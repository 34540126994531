//
// Functions that are general to workflows
//
import $logger from '@/utils/logger.util'
import { nanoid } from "nanoid";

export function addActionsToArray(actionsFromRule, generalActionsArray) {
  for (let i = 0; i < actionsFromRule.length; i++) {
    generalActionsArray.push(actionsFromRule[i]);
  }
}

export function blobToDataURL(blob, callback) {
  const a = new FileReader();
  a.onload = function (e) {
    callback(e.target.result);
  }
  a.readAsDataURL(blob);
}

export function promiseBlobToDataURL(blob) {
  return new Promise((resolve) => {
    const a = new FileReader();
    a.onload = function (e) {
      resolve(e.target.result);
    }
    a.readAsDataURL(blob);
  });
}

// Form element types that are supported: (embedded-image, signature-pad, file-input)
export async function getDataUrlFromImageFormElement(formElement) {
  if (!formElement) return

  let image; // Initialize variable to hold value that will be returned

  switch (formElement.type) {
    case 'embedded-image':
      if (formElement.interactionType === 'multi-select') {
        const requests = [];

        // Initiate download requests for selected images
        const selectionMultipleLength = formElement.selectionMultiple.length;
        for (let i = 0; i < selectionMultipleLength; i++) {

          const downloadRequest = new Promise((resolve, reject) => {
            fetch(formElement.selectionMultiple[i].url, { method: 'GET' }).then(async snapshot => {
              snapshot.blob().then(blob => {
                // Convert blob of image to dataURL
                blobToDataURL(blob, dataURL => {
                  resolve(dataURL);
                });
              });
            });
          });

          requests.push(downloadRequest);
        }

        // Wait until all selected images have been downloaded, then write to images
        await Promise.all(requests).then(dataURLs => {
          image = dataURLs;
        });

      } else if (formElement.interactionType === 'single-select') {
        await new Promise(resolve => {
          fetch(formElement.selectionSingle.url, { method: 'GET' }).then(async snapshot => {
            snapshot.blob().then(async blob => {
              // Convert blob of image to dataURL
              await promiseBlobToDataURL(blob).then(dataURL => {
                image = dataURL;
                resolve();
              });
            });
          });
        });
      }
      break;
    case 'signature-pad':
      await new Promise(resolve => {
        if (formElement.signature?.url) {
          fetch(formElement.signature?.url, { method: 'GET' }).then(async snapshot => {
            snapshot.blob().then(async blob => {
              // Convert blob of image to dataURL
              await promiseBlobToDataURL(blob).then(dataURL => {
                image = dataURL;
                resolve();
              });
            });
          });
        } else {
          image = formElement.signature?.img || '';
        }
      });
      break;
    case 'file-input':
      if (formElement.content && formElement.content.file instanceof Blob) {
        image = await promiseBlobToDataURL(formElement.content.file);
      }
      break;
  }

  return image;
}

export function getImageHeightWidth(image) {
  const jsImage = new Image();
  return new Promise(resolve => {
    jsImage.onload = () => {
      resolve({ height: jsImage.height, width: jsImage.width, ratio: jsImage.width / jsImage.height });
    };
    jsImage.src = image;
  });
}

function getPrefixesDeleteItems(ref) {
  return ref.listAll().then(res => {
    res.items.forEach(async itemRef => {
      await itemRef.delete();
    });
    if (res.prefixes) {
      res.prefixes.forEach(prefixRef => {
        getPrefixesDeleteItems(prefixRef);
      });
    }
  });
}

export async function firebaseStorageDeleteDirectory(ref) {
  await getPrefixesDeleteItems(ref);
  return
}

export function objectIsEmpty(obj) {
  for (let i in obj) return false;
  return true;
}

export function allLoaded({ commit, dispatch, state }, formToSet, formDocId) {
  return new Promise((resolveMain, rejectMain) => {

    commit('SET_FORM_BUILDER_ACTIVE_FORM', formToSet);

    if (formToSet?.docs && formToSet.docs.length > formDocId) {
      const doc = formToSet.docs[formDocId];

      dispatch('workflow/setPdfTemplateImages', doc.existingPages).then(() => {
        return dispatch('workflow/generatePdfFile', {
          type: 'blob',
          templateFile: true,
          docIndex: state.formBuilderDocIndex
        }).catch(error => {
          rejectMain(error)
        })
      }).then(() => {
        return dispatch('workflow/generatePdfFile', {
          type: 'blob',
          processedFile: true,
          docIndex: state.formBuilderDocIndex
        }).catch(error => {
          rejectMain(error)
        })
      });
    }

    setTimeout(() => {
      // Add uid to actions
      if (state.formBuilderActiveForm?.docs) {
        const docs = state.formBuilderActiveForm.docs;
        const docsLength = docs.length;
        for (let i = 0; i < docsLength; i++) {
          const rules = docs[i].rules;
          const rulesLength = rules.length;

          for (let j = 0; j < rulesLength; j++) {
            const actions = rules[j].actions;
            const actionsLength = actions.length;

            for (let k = 0; k < actionsLength; k++) {
              if (!actions[k]?.uid) {
                actions[k].uid = nanoid(12);
              }
            }
          }
        }
      }

      // Add uid to form elements, rules
      const fields = state.formBuilderActiveForm.fields;
      const fieldsLength = fields.length;

      for (let i = 0; i < fieldsLength; i++) {
        if (!state.formBuilderActiveForm.fields[i]?.uid) {
          state.formBuilderActiveForm.fields[i].uid = nanoid(12);
        }
      }

      if (state.formBuilderActiveForm.docs.length > 0) {
        for (let i in state.formBuilderActiveForm.docs[formDocId].rules) {
          if (!state.formBuilderActiveForm.docs[formDocId].rules[i]?.uid) {
            state.formBuilderActiveForm.docs[formDocId].rules[i].uid = nanoid(12);
          }

          for (let j = 0; j < state.formBuilderActiveForm.docs[formDocId].rules[i].questions.length; j++) {
            const question = state.formBuilderActiveForm.docs[formDocId].rules[i].questions[j];
            const fe = state.formBuilderActiveForm.fields.find(el => el.title === question.title);

            if (fe && fe?.uid) {
              state.formBuilderActiveForm.docs[formDocId].rules[i].questions[j].uid = fe.uid;
            } else if (!fe) {
              $logger.warn('Form element used in logic is no longer available: ' + question.title)
            } else if (fe && !fe?.uid) {
              $logger.info(`Form element (${question.title}) did not have UID. Adding now.`);
              state.formBuilderActiveForm.docs[formDocId].rules[i].questions[j].uid = nanoid(12);
            }
          }

          for (let j = 0; j < state.formBuilderActiveForm.docs[formDocId].rules[i].displayLogicQuestions.length; j++) {
            const question = state.formBuilderActiveForm.docs[formDocId].rules[i].displayLogicQuestions[j];
            const fe = state.formBuilderActiveForm.fields.find(el => el.title === question.title);

            if (fe && fe?.uid) {
              state.formBuilderActiveForm.docs[formDocId].rules[i].displayLogicQuestions[j].uid = fe.uid;
            } else if (!fe) {
              throw 'Form element to be hidden/shown by display logic rule may have been deleted or changed: ' + question.title
            } else if (fe && !fe?.uid) {
              $logger.info(`Form element (${question.title}) did not have UID. Adding now.`);
              state.formBuilderActiveForm.docs[formDocId].rules[i].displayLogicQuestions[j].uid = nanoid(12);
            }
          }
        }
      }

      // Get all display logic rules from each docs rules (in future display logic rules should have their own property on the form, making this unnecessary)
      let displayLogicRules = [];
      for (let j = 0; j < state.formBuilderActiveForm.docs.length; j++) {
        let filteredRules = undefined
        if (Array.isArray(state.formBuilderActiveForm.docs[j]?.rules)) {
          filteredRules = state.formBuilderActiveForm.docs[j]?.rules?.filter(rule => Object.keys(rule).length > 0 && rule.type === 'Display Logic');
        }

        if (filteredRules !== undefined) displayLogicRules = [...displayLogicRules, ...filteredRules];
      }

      dispatch('displayLogic/setLogicLibrary', { workflowId: formToSet.id }).then(() => {
        return dispatch('displayLogic/buildTemplates', {
          workflowId: formToSet.id,
          workflowType: 'in-edit',
          formElements: state.formBuilderActiveForm.fields,
          rules: displayLogicRules
        });
      }).then(() => {
        commit('SET_FORM_BUILDER_PDF_PAGES_LOADED', true);
        resolveMain();
      }).catch(error => {
        rejectMain(error);
      });

      // Run checkForUniqTitle

    }, 150);
  });
}
