const addImgToPdf = (dimensions, newPDF, image, currentPageNum, verticalOffset, horizontalOffset, linesWidth, lineHeight, topMargin, bottomMargin, pdfHeight, ext = 'JPEG', scale = 1) => {
  const imageInputHeight = (dimensions.height * (linesWidth / dimensions.width)) / scale            
  const location = {
    x: horizontalOffset,
    y: verticalOffset,
    width: linesWidth,
    height: imageInputHeight,
  }
  // If the image would extend into or beyond the bottom margin, put it on new page
  if (verticalOffset + imageInputHeight > (pdfHeight - bottomMargin)) {
    // Add new page & set vertical offset
    newPDF.addPage();
    currentPageNum++;
    verticalOffset = topMargin + imageInputHeight;

    location.y = verticalOffset
  }

  newPDF.addImage(image, ext.toUpperCase(),
    location.x,
    location.y,
    location.width,
    location.height
  );    

  verticalOffset += imageInputHeight + lineHeight;
  return {
    vo: verticalOffset,
    pg: currentPageNum
  }
}

module.exports = { addImgToPdf }