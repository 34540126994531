<template>
  <v-container fluid class="full-height">
    <v-form v-model="createProfileValidity">
      <v-card elevation="2" class="pa-5 my-5">
        <v-row class="justify-center">
          <v-col cols="12">
            <h1>Has your spouse started the process?</h1>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="12" style="padding: 0 40%;">
            <v-radio-group
              v-model="userProfile.is_petitioner"
              row
              @change="isPetitionerChange($event)"
            >
              <v-radio label="Yes" :value="false" />
              <v-radio label="No" :value="true" />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="userProfile.is_petitioner === true" class="justify-center">
          <v-col cols="12">
            <b>Spouse Info</b>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
                v-model="userProfile.petitioner_data.respondent_first_name"
                :rules="[rules.required]"
                :readonly="false"
                type="text"
                label="Spouse's Legal First Name"
                outlined
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
                v-model="userProfile.petitioner_data.respondent_last_name"
                :rules="[rules.required]"
                :readonly="false"
                type="text"
                label="Spouse's Legal Last Name"
                outlined
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
                v-model="userProfile.petitioner_data.respondent_email"
                :rules="[rules.required, rules.email]"
                :readonly="false"
                type="email"
                placeholder="username@email.com"
                label="Spouse's Email"
                outlined
                loader-height="10"
                :loading="findingPetitioner"
                class="emailText"
                @blur="respondentEmailCheck(userProfile.petitioner_data.respondent_email)"
            />
          </v-col>
        </v-row>
        <v-row v-else class="justify-center">
          <v-col cols="12">
            <v-text-field
                v-model="userProfile.respondent_data.petitioner_email"
                :rules="[rules.required, rules.email]"
                :readonly="false"
                type="email"
                placeholder="username@example.com"
                label="Enter your spouse's email"
                outlined
                loader-height="10"
                :loading="findingPetitioner"
                class="emailText"
                @blur="petitionerEmailCheck(userProfile.respondent_data.petitioner_email)"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-row class="justify-center">
        <v-col cols="12">
          <h1>Let's create your account</h1>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="userProfile.email"
            :rules="[rules.required, rules.email, emailMatch]"
            :readonly="false"
            type="email"
            placeholder="username@example.com"
            label="Email Address"
            outlined
            class="emailText"
            @blur="userProfile.email = userProfile.email.toLowerCase()"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="userProfile.password"
            :rules="[rules.required, rules.password, rules.lengthPassword]"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            label="Password"
            name="input-10-1"
            hint="At least 10 characters"
            counter
            required
            @click:append="show1 = !show1"
            outlined
          />
        </v-col>
      </v-row>
    </v-form>
    <ProfileForm
      :user-profile="userProfile"
      :rules="rules"
      @formValid="userFormValidity = $event"
    >
      <template v-slot:acceptance>
        <v-row class="justify-center">
          <v-col cols="12">
            <v-checkbox
              v-model="userProfile.permission_to_contact"
              label="I agree to allow SimpleEnding™ to contact me by email regarding promotions, discounts, and critical account information."
            />

            <div class="d-flex">
              <v-checkbox
                v-model="acceptTermsOfService"
                :rules="[rules.required]"
                class="d-flex justify-center align-center"
                @change="accept"
              >
              </v-checkbox>

              <p class="termsServicesLink text-left">
                I have read and agree to
                <a href="https://simpleending.com/privacy-policy" target="_blank">privacy policy</a>
                and the
                <a href="https://simpleending.com/terms-of-service" target="_blank" @click.stop="termsOfServiceDialog = true">terms of service</a>
              </p>
            </div>

            <VueRecaptcha
              :load-recaptcha-script="true"
              sitekey="6LcOidEZAAAAAJOl7SoPCcqrzHZrAeS4lUFvtxIH"
              @verify="verified = true"
              @expired="verified = false"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:buttons>
        <v-row class="justify-center">
          <v-col cols="12" sm="10" md="8" lg="6" xl="5">
            <v-btn
              class="px-10 mt-10 text-center"
              color="primary"
              x-large
              :loading="loading"
              @click.prevent="createProfile"
              :disabled="disableSubmitButton"
            >
              Create User
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ProfileForm>
  </v-container>
</template>

<script>
import createAccountMixin from '../mixins/views/createAccount.mixin';

export default {
  name: "CreateUser",

  mixins: [createAccountMixin]
};
</script>

<style lang="scss">
  .emailText {
    input {
      text-transform: lowercase !important;
    }
  }
</style>

<style lang="scss" scoped>
.full-height {
  padding: 0px 20%;
  margin-bottom: 75px;
}

.cursor-pointer-interior > div > div.v-input__slot {
  cursor: pointer !important;
}
.background-light-grey {
  padding: 1rem 0;
  margin-bottom: 2rem;
  background: rgba(0, 0, 0, 0.1);
}

.termsServicesLink {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  padding: 1.5em 0;
  margin: 0;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media(max-width: 600px) {
  .full-height {
    padding: 0px 10px;
  }
}
</style>
