import { getDateFormat } from "@/utils/getDateFormat.util"

export default {
  data() {
    return {
      datePickerVariants: ["single-date", "multiple-dates", "date-range"],
      datePickerFormats: [
        {
          key: "YYYY-MM-DD",
          value: "YYYY-MM-DD"
        }, 
        {
          key: "MM-DD-YYYY",
          value: "MM-DD-YYYY"
        }, 
        {
          key: "MMMM Do, YYYY",
          value: "Month Day, Year"
        }, 
        {
          key: "[this ]Do day of MMMM YYYY",
          value: "this **th day of Month 20**"
        }
      ],
    }
  },

  computed: {    
    formatDate() {
      return (field) => {
        return getDateFormat(field.dateSingle, this.validateDateFormat(field.format))
      }
    },
  },

  methods: {
    setDateFormat(field) {
      field.dateSingle = getDateFormat(field.dateValue, this.validateDateFormat(field.format))
    },

    validateDateFormat(format) {
      if(!this.isValidDateFormat(format)) {
        return this.foundFixForDateFormat(format)
      }
      return format
    },

    foundFixForDateFormat(format) {
      return this.datePickerFormats.find(f => f.value === format)?.key || 'MMMM Do, YYYY'
    },

    isValidDateFormat(format) {
      return this.datePickerFormats.findIndex(f => f.key === format) >= 0
    },

    handleDateChanges(field, value, actionStr) {
      this.$emit('ignoreFormDataChanges', false)
      this.setDateFormat(field)
      const toUpdate = this.updateField(field, value)
      this.$store.dispatch(actionStr, toUpdate)
      if(field.monthPicker) {
        field.dateValue = getDateFormat(field.dateSingle, 'MM/DD/YYYY')
      } else {
        field.dateValue = value 
      }

      if(this?.autoSave) {
        this.autoSave(field)
      }      
    },
  }
}