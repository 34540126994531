const Decimal = require('decimal.js')
const dayjs = require('dayjs')
const { getDateFormat } = require('./getDateFormat.util')
const advancedFormat = require('dayjs/plugin/advancedFormat');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const acceptableDateFormats = ['MMMM Do, YYYY', '[this] Do [day of] MMMM YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'MM-DD-YYYY'];

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

const expressionatorMethod = (val, fields) => {
  let newVal = val;
  const preExpression = '[*';
  const postExpression = '*]';
  const todayStr = `${preExpression}TODAY${postExpression}`
  const countPreStr = `${preExpression}COUNT(`;
  const countPostStr = `)${postExpression}`;
  const dateDiffPreStr = `${preExpression}DATEDIFF(`;
  const dateDiffPostStr = `)${postExpression}`
  const evalPreStr = `${preExpression}EVAL(`;
  const evalPostStr = `)${postExpression}`;

  if (val) {
    if(val.indexOf(todayStr) >= 0) {
      newVal = val.replaceAll(todayStr, getDateFormat())
    } else if ((val.indexOf(countPreStr) >= 0) && (val.indexOf(countPostStr) > 0)) {
      newVal = stripValueFromKey(countPreStr, countPostStr, val, 'count', fields);
    } else if ((val.indexOf(dateDiffPreStr) >= 0) && (val.indexOf(dateDiffPostStr) > 0)) {
      newVal = stripValueFromKey(dateDiffPreStr, dateDiffPostStr, val, 'date-diff', fields);
    } else if ((val.indexOf(evalPreStr) >= 0) && (val.indexOf(evalPostStr) > 0)) {
      newVal = stripValueFromKey(evalPreStr, evalPostStr, val, 'eval', fields);
    } else if ((val.indexOf(preExpression) >= 0) && (val.indexOf(postExpression) > 0)) {
      newVal = stripValueFromKey(preExpression, postExpression, val, 'value', fields);
    }
  } else {
    newVal = '';
  }

  return newVal;
}

const stripValueFromKey = (prefix, postFix, val, action = 'value', fields) => {
  let newVal = val;

  let startPos = val.indexOf(prefix) + prefix.length;
  let endPos = val.indexOf(postFix) - startPos;

  let fieldItem = {};
  if (action === 'value') {
    while ((newVal.indexOf(prefix) >= 0) && (newVal.indexOf(postFix) >= 0)) {
      startPos = newVal.indexOf(prefix) + prefix.length;
      endPos = newVal.indexOf(postFix) - startPos;

      const fieldName = newVal.substr(startPos, endPos);

      fieldItem = fields.find(f => f.title === fieldName);
      let fieldValue = '';

      if (!fieldItem) {
        newVal = ""
        break;
      } else if (fieldItem.type === 'computed-property') {
        fieldValue = expressionatorMethod(fieldItem.label, fields);

        newVal = newVal.replace(prefix + fieldName + postFix, fieldValue);
      } else if (fieldItem.type === 'grouped-options') {
        fieldValue = expressionatorMethod(fieldItem.selection, fields);

        newVal = newVal.replace(prefix + fieldName + postFix, fieldValue);        
      } else if (fieldItem.type === 'multi-select') {
        newVal = newVal.replace(prefix + fieldName + postFix, fieldItem.selection);
      } else if (fieldItem.type === 'number-input' && fieldItem?.useCommas === true) {
        fieldValue = fieldItem.content.toLocaleString('en-US') ?? '0';

        // If the question is unanswered and has a default value, use the default value
        if (fieldItem?.default && [null, undefined, ''].indexOf(fieldValue) > -1) {
          fieldValue = fieldItem.default ?? '';
        }

        newVal = newVal.replace(prefix + fieldName + postFix, fieldValue);        
      } else if (['text-input-short', 'text-input-long', 'number-input', 'heading', 'subheading', 'paragraph'].indexOf(fieldItem.type) >= 0) {
        fieldValue = fieldItem.content ?? '';

        // If the question is unanswered and has a default value, use the default value
        if (fieldItem?.default && [null, undefined, ''].indexOf(fieldValue) > -1) {
          fieldValue = fieldItem.default ?? '';
        }

        newVal = newVal.replace(prefix + fieldName + postFix, fieldValue);
      } else if (fieldItem.type === 'label') {
        fieldValue = fieldItem.label ? fieldItem.label : fieldItem.name;

        newVal = newVal.replace(prefix + fieldName + postFix, fieldValue);
      } else if (['single-select','state-select'].indexOf(fieldItem.type) > -1) {
        if (fieldItem.variant === 'radio') {
          fieldValue = fieldItem.selection ?? '';
        } else {
          fieldValue = fieldItem.selection ?? '';
        }

        // If the question is unanswered and has a default value, use the default value
        if (fieldItem?.default && [null, undefined, ''].indexOf(fieldValue) > -1) {
          fieldValue = fieldItem.default ?? '';
        }

        newVal = newVal.replace(prefix + fieldName + postFix, fieldValue);
      } else {
        newVal = "Could not find field type"
        break;
      }
    }
  } else if (action === 'count') {
    newVal = expressionatorCounter({ val, startPos, endPos, fields, prefix, postFix });
  } else if (action === 'date-diff') {
    newVal = expressionatorDateDiff({ val, startPos, endPos, fields })
  } else if (action === 'eval') {
    newVal = expressionatorHandleEval(newVal, fields);
  }

  return newVal;
}

const expressionatorCounter = ({ val, startPos, endPos, fields}) => {
  const fieldName = val.substr(startPos, endPos);

  const fieldItem = fields.find(f => f.title === fieldName);

  return fieldItem.selection.length
}

const expressionatorDateDiff = ({ val, fields, startPos, endPos }) => {
  const values = val.substr(startPos, endPos).split(',');
  values.forEach((fn, i) => {
    values[i] = fn.trim()
  });

  const numOfParams = values.length;
  let type = values[numOfParams - 1] || "d";
  let dateLeft = new Date();
  dateLeft.setHours(0, 0, 0, 0);
  let dateRight = new Date();
  dateRight.setHours(0, 0, 0, 0);

  if (numOfParams === 3 || numOfParams === 2) {

    const TODAY = /^TODAY((\s*-+\s*[0-9]+\s*)|(\s*\++\s*[0-9]+\s*)|\s*)$/i

    // Iterate over first two parameters to see if they reference the TODAY keyword or not
    for (let i = 0; i < numOfParams - 1; i++) {
      if (TODAY.test(values[i])) {
        // Has TODAY keyword
        if (i === 0) {
          dateLeft = new Date(calculatedDate(values[i], type));
          dateLeft.setHours(0, 0, 0, 0);
        } else {
          dateRight = new Date(calculatedDate(values[i], type));
          dateRight.setHours(0, 0, 0, 0);
        }
      } else {
        // Not TODAY keyword
        const formElement = fields.find(f => f.title === values[i]);
        // Make sure the Form Element referenced by DATEDIFF is a single date variant of date-picker
        if (formElement && formElement.type === 'date-picker' && formElement.variant === 'single-date' && formElement.dateSingle) {
          //const dateSingle = dayjs(formElement.dateSingle, acceptableDateFormats).toISOString()
          const dateFormat = acceptableDateFormats.indexOf(formElement.format) > -1 ? formElement.format : 'MM/DD/YYYY'
          const dateSingle = getDateFormat(formElement.dateValue,dateFormat)

          if (i === 0) {
            dateLeft = dateSingle
          } else {
            dateRight = dateSingle
          }
        }
      }
    }

    // Fix for when only one date is provided
    if (values.length === 2) {
      dateRight = dateLeft;
      dateLeft = new Date();
      dateLeft.setHours(0, 0, 0, 0);
    }

  } else {
    return
  }

  const diff = getDateDifference({ dateLeft, dateRight, type })

  return `${diff.value} ${diff.type}`
}

const calculatedDate = (today, type) => {
  let now = dayjs()
  let num = 0
  if (today.indexOf("+") > 0) {
    const arr = today.split("+")
    num = parseInt(arr[1].trim())
  } else if (today.indexOf("-") > 0) {
    const arr = today.split("-")
    num = parseInt(arr[1].trim()) * -1
  }

  switch (type) {
    case 'd':
      return now.add(num, 'day');
    case 'm':
      return now.add(num, 'month');
    case 'y':
      return now.add(num, 'year');
  }

  return 0;
}

const getDateDifference = ({ dateLeft, dateRight, type }) => {
  let value = 0
  const leftDate = getDateFormat(dateLeft,'MM/DD/YYYY')
  const rightDate = getDateFormat(dateRight,'MM/DD/YYYY')
  switch (type) {
    case 'd':
      value = dayjs(leftDate).diff(dayjs(rightDate), 'day');
      type = 'days'
      break
    case 'm':
      value = dayjs(leftDate).diff(dayjs(rightDate), 'month')
      type = 'months'
      break
    case 'y':
      const leftYear = getDateFormat(dateLeft,'YYYY')
      const rightYear = getDateFormat(dateRight,'YYYY')    
      value = parseInt(leftYear) - parseInt(rightYear)
      type = 'years'
      break;
  }

  if (isNaN(value)) value = 0

  value *= -1;
  return { value, type }
}

const expressionatorHandleEval = (evalStr, formFields) => {
  const dirtyEquation = evalStr;
  const eqPrefix = '[*EVAL(';
  const eqPostfix = ')*]';
  const eqStart = dirtyEquation.indexOf(eqPrefix) + eqPrefix.length;
  const eqEnd = dirtyEquation.indexOf(eqPostfix) - eqStart;
  let equation = dirtyEquation.substr(eqStart, eqEnd);
  const fieldPrefix = '<';
  const fieldPostfix = '>';

  while (equation.indexOf(fieldPrefix) >= 0) {
    const fieldStart = equation.indexOf(fieldPrefix) + 1;
    const fieldEnd = equation.indexOf(fieldPostfix) - fieldStart;
    const key = equation.substr(fieldStart, fieldEnd);
    const fieldObj = formFields.find(f => f.title === key);

    if (!key || !fieldObj) {
      break;
    }

    let content = fieldObj.content

    if(content?.length === 0) {
      content = '0'
    }    

    if (!(content || fieldObj.type === 'computed-property')) {
      break;
    }

    if (fieldObj.type === 'computed-property') {
      const computedPropertyValue = expressionatorMethod("[*" + key + "*]", formFields);
      equation = equation.replace(fieldPrefix + key + fieldPostfix, computedPropertyValue);
    } else {
      equation = equation.replace(fieldPrefix + key + fieldPostfix, content);
    }

  }

  // eslint-disable-next-line no-eval
  if(equation.indexOf('<') >= 0) {
    return equation
  } else {
    const regEx = /([-+]?[0-9]*\.?[0-9]+[\/\+\-\*])+([-+]?[0-9]*\.?[0-9]+)/
    equation = equation.replaceAll(',','').replaceAll(' ','')
    if(regEx.test(equation)) {
      const answer = eval(equation.replace(/[^0-9\s+\-*/()%]/g, ''))
      if(isNaN(answer)) {
        return equation
      } else {
        return new Decimal(answer).ceil().toNumber().toLocaleString('en-US')
      }
    } else {
      return equation
    }
  }
}

module.exports = expressionatorMethod