export default {
  computed: {
    parsedQuestionList() {
      const list = (this.formFields || this.form.fields).slice();
      let pageNumber = 0;

      for (let i = 0; i < list.length; i++) {
        let question = "";

        if (list[i].type === "page-break") {
          pageNumber++;
          question = "Page " + pageNumber + " | Title: " + list[i].title;
        } else {
          question = "Type: " + list[i].type + " | Title: " + list[i].title;
        }

        list[i].questionListTitle = question;
      }

      list.push({
        uid: "participating",
        title: "participating",
        type: "participating",
        questionListTitle: "Participating",
      });

      return list;
    },
  },
};
