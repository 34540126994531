<template>
  <div class="coupon-code-frame">
    <div v-if="(campaignCoupon.discount && hideCouponCode) || paymentLevel.level === '2'" class="badge-frame">
      <v-img
        :src="require(`@/assets/images/badge-discount.svg`)"
        height="55"
        contain
      />
      <p class="badge-content">
        {{ campaignCoupon.discount }} % <br>OFF</p>
    </div>
    <div class="coupon-code">
      <div v-if="hideCouponCode" class="d-flex align-items-center full-width">
        <div class="d-flex flex-column full-width">
          <v-text-field
            @input="() => (code = code.toUpperCase())"
            @focus="activateApplyBtn"
            v-model="code"
            style="width:100%; display:inline-block;"
            label="Enter Coupon Code..."
          />
          <small v-if="alert.message.length === 0" class="text-success text-start">Click to apply coupon.</small>
          <small v-else-if="alert.type === 'error'" class="text-danger text-start">{{alert.message}}</small>
        </div>
        <v-btn
          :disabled="code === ''"
          style="display:inline-block;margin-left:20px;"
          :loading="loading"
          @click="couponLookup"
        >
          Apply
        </v-btn>
      </div>
      <div v-if="alert.type === 'success'">
        <v-alert
          dense
          text
          :type="alert.type"
          class="flex-column align-items-center just-content-center"
        >
          {{ alert.message }}
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Decimal from 'decimal.js'

export default {
  name: "FindCoupon",

  props: {
    search: {
      type: Boolean,
      default: true
    },
    'child-click': {
      type: Function,
      default: () => {},
    },
    paymentLevel: {}
  },

  watch: {
    search(newVal) {
      if (newVal === false) {
        this.alert = {
          type: 'info',
          message: 'Your coupon code is still being applied'
        }
      } else {
        this.alert = {
          type: 'success',
          message: ''
        }
      }
    }
  },

  data() {
    return {
      code: '',
      loading: false,
      alert: {
        type: '',
        message: ''
      },
      applyButton: false,
      campaignCoupon: {
        discount: '',
        code: ''
      },
      hideCouponCode: true
    }
  },

  computed: {
    ...mapGetters("coupons", ["currentCoupon", "coupons"]),
  },

  async mounted() {
    await this.getCoupons()
    this.getCampaignCoupon()
  },

  methods: {
    ...mapActions("coupons", ["findCoupon", "getCoupons"]),

    activateApplyBtn() {
      if(this.paymentLevel === '2') {
        this.applyButton = true
      }
    },
    getCampaignCoupon() {
      let currentDate = new Date()
      this.coupons.map(e => {
        let providedDate = new Date(e.endDate)
        if (e.campaign === true && e.limit > e.count && currentDate.toDateString() !== providedDate.toDateString()) {
          this.campaignCoupon.discount = new Decimal(e.amount).toFixed(0)
          this.campaignCoupon.code = e.code
        }
      })
      this.coupons.map(e => {
        if (e.campaign === true) {
          this.code = e.code
        }
      })
    },
    cancelPayment() {
      this.alert.message = ''
      this.getCampaignCoupon()
    },
    async couponLookup() {
      this.loading = true
      const status = await this.findCoupon(this.code)
      if (status === 'success') {
        this.hideCouponCode = false
        this.alert = {
          type: 'success',
          message: 'Applied'
        }
        this.$emit('success')
      } else {
        this.cancelPayment()
        this.alert = {
          type: 'error',
          message: status
        }
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.coupon-code {
  width: 50%;
}
.badge-frame {
  position: relative;
  width: 55px;
  height: 55px;
  margin-right: 20px;

  .badge-content {
    position: absolute;
    top: 12px;
    left: 15px;
    color: $white;
    font-size: 11px;
    font-weight: bold;
    margin: 0;
    z-index: 1;
  }
}

.coupon-code-frame {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  .coupon-code {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: calc(100% - 20px);
  }
}

@media(max-width: 600px) {
  .coupon-code {
    width: 100%;
  }
}
</style>
