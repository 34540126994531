<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          class="help-icon"
          @click="dialog = true"
          v-bind="attrs"
          v-on="on"
          color="#00BCD2"
        >
          help
        </v-icon>
      </template>
      <span> Click for additional info </span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="600">
      <v-card class="px-2 py-5">
        <v-card-title class="justify-center mb-5">
          <v-icon color="accent" class="mr-2">
            contact_support
          </v-icon>
          Additional Help
        </v-card-title>
        <v-card-text>
          <iframe
            v-if="value.youtubeLink && isValidUrl(value.youtubeLink)"
            width="500"
            height="315"
            frameborder="0"
            allowfullscreen
            :src="value.youtubeLink"
          />
          <p class="mt-5 subtitle-1 font-weight-light">
            {{ value.text }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" class="mx-auto" large @click="muteVideo">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "HelpIcon",
  
  props: {
    value: Object,
    fieldId: String
  },

  data() {
    return {
      dialog: false,
    };
  },
  
  methods: {
    isValidUrl(url) {
      const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      return pattern.test(url);
    },

    muteVideo() {
      let videos = document.querySelectorAll("iframe, video");
      Array.prototype.forEach.call(videos, function(video) {
        if (video.tagName.toLowerCase() === "video") {
          video.pause();
        } else {
          let src;
          src = video.src;
          video.src = src;
        }
      });
      this.dialog = false;
      if(this.fieldId) {
        setTimeout(() => {
          document.getElementById(this.fieldId).focus()
        },100)
      }
    },
  },
};
</script>
