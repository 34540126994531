import Firebase from "firebase/compat/app";
import { getDoc, doc, updateDoc, setDoc } from "firebase/firestore";
import { handleFileInputs } from "@/stores/helpers/file-input";
import { handleSignaturePads } from "@/stores/helpers/signature-pad";
import { nanoid } from "nanoid";
import { EventBus } from "@/plugins/eventBus";
import smtp from "@/services/smtp/emailer";
import dayjs from "dayjs";
import { firebaseStorageDeleteDirectory } from "@/services/workflow";
import createAnalyticImagePath from "@/utils/create-analytic-img-path.util";
import fixUndefined from "@/utils/undefined.util";
import getFields from "@/utils/getFields.util";
import getDocs from "@/utils/getDocs.util";
import updateSubCollection from "@/services/updateSubCollection.service";
import { removeAllFormSubCollections } from "@/services/removeSubCollection.service";
import { pdfOptions } from "@/constants/pdfOptionColors.constants";
import sendEmailUtil from "@/utils/send-email.util";
import loadHtml from "@/utils/load-html.util";
import emailTemplate from "@/constants/email-templates";
import $logger from "@/utils/logger.util";
import getActionsFromRules from "@/stores/actions/rules.action";
import getQuestionConditionMet from "@/stores/actions/questions.action";
import questionMatcher from "@/utils/question-matcher.util";
import getValueFromQuestion from "@/utils/get-value-from-question.util";
import { getCloudStorage } from "@/services/gcloud/getProject";
import { pdfGenerator } from "@/stores/actions/pdfGenerator.action";
import { getDateFormat } from "@/utils/getDateFormat.util";
import userExists from "@/services/user-exists.service";
import clearFormFields from "@/utils/clear-form-fields.util"
import updateWorkflow from '@/services/workflow/updateWorkflow.service'
import { v4 as uuidv4 } from "uuid";

const defaultState = () => {
  return {
    formData: null,
    activeDocIndex: null,
    pdfTemplateImages: [],
    pdfOptions,
    publishedWorkflows: [],
    processedFile: undefined, // Holds latest version of fully processed PDF file
  };
};

export const workflowPublished = {
  namespaced: true,
  state: defaultState(),

  mutations: {
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },

    SET_DOCS_ON_FORM(state, docs) {
      state.formData["docs"] = docs;
    },

    SET_FIELDS_ON_FORM(state, payload) {
      state.formData.fields = payload;
    },

    SET_FORMDATA_STEP(state, { step = 1, type = "step" }) {
      state.formData[type] = step;
    },

    SET_FINAL_PDF(state, payload) {
      state.formData["finalPDF"] = payload;
    },

    SET_PUBLISHED_WORKFLOWS(state, payload) {
      state.publishedWorkflows = payload;
    },

    SET_SIGNATURE_PAD_IMAGE(state, { fieldsIndex, img }) {
      state.formData.fields[fieldsIndex].img = img;
    },

    SET_FILE_INPUT(state, { fieldsIndex, url }) {
      state.formData.fields[fieldsIndex].url = url;
    },

    RESET_WORKFLOW_PUBLISHED(state) {
      state.formData = null;
      state.activeDocIndex = null;
      state.pdfTemplateImages = [];
      state.processedFile = undefined;
    },

    ACCEPT_REVIEW(state, payload) {
      if (state.formData.fields[payload.fieldsIndex].selection) {
        state.formData.fields[payload.fieldsIndex].selection = payload.data;
        state.formData.fields[payload.fieldsIndex].reviews = {};
      } else {
        state.formData.fields[payload.fieldsIndex].content = payload.data;
        state.formData.fields[payload.fieldsIndex].reviews = {};
      }
    },

    DECLINE_REVIEW(state, payload) {
      delete state.formData.fields[payload.fieldsIndex].reviews[payload.email];
      state.formData.fields[payload.fieldsIndex].reviews = JSON.parse(
        JSON.stringify(state.formData.fields[payload.fieldsIndex].reviews)
      );
    },

    SET_FORM_DATA(state, formData) {
      state.formData = formData;
    },

    UPDATE_FORM_DATA_FIELD(state, payload) {
      const fieldIndex = state?.formData?.fields?.findIndex(
        (elem) =>
          elem.type === payload.field.type && elem.title === payload.field.title
      );

      if (fieldIndex > -1) {
        for (let property in payload.updates) {
          state.formData.fields[fieldIndex][property] =
            payload.updates[property];
        }
      }
    },

    SET_ACTIVE_DOC_INDEX(state, index) {
      state.activeDocIndex = index;
    },

    SET_PROCESSED_FILE(state, file) {
      state.processedFile = file;
    },

    SET_TEMPLATE_FILE(state, file) {
      state.templateFile = file;
    },

    SET_PDF_TEMPLATE_IMAGES(state, images) {
      state.pdfTemplateImages = images;
    },

    ADD_SHARE_DATA(state, sharing) {
      state.formData.sharingUser = sharing.user.email;
      state.formData.sharingProps = [sharing];
    },

    DELETE_SHARE_DATA(state, sharing) {
      if (state.formData.sharingUser) {
        state.formData.sharingUser = state.formData.sharingUser.filter(
          (x) => x != sharing.user.email
        );
      }
      state.formData.sharingProps = state.formData.sharingProps.filter(
        (x) => x.user.email != sharing.user.email
      );
    },
  },

  getters: {
    requiredFields: (state) => state?.formData?.fields?.filter((f) => f?.required === true) || [],

    finalPDF: (state) => state?.formData?.finalPDF || null,

    respondentStep: (state) => state?.formData?.respondentStep || null,

    petitionerStep: (state) => state?.formData?.step || 1,

    publishedWorkflows: (state) => state.publishedWorkflows,

    getPublishedWorkflowByState: (state) => (stateName) =>
      state.publishedWorkflows.find((p) => p.state === stateName),

    getPublishedWorkflowById: (state) => (id) =>
      state.publishedWorkflows.find((p) => p.parentId === id),

    getFormData: (state) => state.formData,

    getActiveDocIndex: (state) => state.activeDocIndex,

    getProcessedFile: (state) => state.processedFile,

    sharingData: (state) => {
      if (state.formData && state.formData.sharingProps) {
        return state.formData.sharingProps;
      }
      return [];
    },

    isFormSaved: (state, getters, rootState) => {
      const userData = rootState.profile.user;
      if (userData.email.length === 0) return;
      let formIndex = -1;
      if (userData.forms.length && state.formData)
        formIndex = userData.forms.findIndex(
          (element) => element.parentId === state?.formData?.parentId
        );

      if (~formIndex) return true;
      return false;
    },

    isOwner: (state, _, rootState) =>
      state?.formData?.owner?.email === rootState?.profile?.user?.email,

    formSharingLimits: (state) => {
      const sharingLimit = {
        readOnlyShareLimit: 1,
        commentShareLimit: 1,
        editShareLimit: 0,
      };
      let formHasSharingLimits;
      EventBus.$emit("form-changed");
      if (state.formData && state.formData?.rules && state.formData.rules) {
        formHasSharingLimits = Object.values(state.formData.rules).find(
          (rule) => rule.type === "Sharing Logic"
        );
      }
      return formHasSharingLimits || sharingLimit;
    },

    formAlreadyExistsForState: (state) => {
      return (id) => {
        const index = state.publishedWorkflows.findIndex((f) => f.id === id);
        return index >= 0;
      };
    },

    getWorkflowFieldsAll: (state) => {
      if (state?.formData?.fields) {
        state.formData.fields.sort((a, b) => a.fieldsIndex - b.fieldsIndex);
        const firstPageIndex = state.formData.fields.findIndex(
          (field) => field.type === "page-break"
        );
        if (firstPageIndex !== 0) {
          state.formData.fields.splice(
            0,
            0,
            ...state.formData.fields.splice(firstPageIndex, 1)
          );
        }
        return state.formData.fields;
      } else {
        return [];
      }
    },
  },

  actions: {
    getQuestionConditionMet,

    getActionsFromRules,

    resetState({ commit }) {
      commit("RESET_STATE");
    },

    setFormData({ commit }, payload) {
      commit("SET_FORM_DATA", payload);
    },

    async setFinalPDF({ rootState, commit }, { docId, finalPDF }) {
      await rootState.firebaseConfig.db
        .collection("workflows-user")
        .doc(docId)
        .update({ finalPDF })
      commit("SET_FINAL_PDF", finalPDF);
      return;
    },

    async setPublishedWorkflows({ commit, rootGetters }) {
      const db = rootGetters.getFirebaseConfig.db;
      const workflows = (
        await db.collection("workflows-published").get()
      ).docs.map((d) => ({ ...d.data() }));

      commit("SET_PUBLISHED_WORKFLOWS", workflows);
      return;
    },

    async clearPublishedWorkflows({ commit }) {
      commit('SET_PUBLISHED_WORKFLOWS', [])
      return
    },

    addPublishedWorkflows({ commit }, payload) {
      commit("SET_PUBLISHED_WORKFLOWS", payload);
    },
    // General Actions

    resetWorkflowPublished({ commit }) {
      commit("RESET_WORKFLOW_PUBLISHED");
    },

    async deletePublishedWorkflow(
      { rootState },
      { id, collection = "workflows-user" }
    ) {
      const publishedRef = rootState.firebaseConfig.db.collection(collection);
      await Promise.all([
        removeAllFormSubCollections({ id, parent: collection }),
        publishedRef.doc(id).delete()
      ])
      return;
    },

    async deleteUserFormById({ rootState }, id) {
      const collection = "workflows-user";
      const rootStorageRef = rootState.firebaseConfig.storage.ref();
      const docRef = rootStorageRef.child(`${collection}/${id}`);
      firebaseStorageDeleteDirectory(docRef);
      try {
        removeAllFormSubCollections({ id, parent: collection });
        return true;
      } catch (error) {
        return false;
      }
    },

    // Share Survey Actions

    acceptReview({ commit }, payload) {
      commit("ACCEPT_REVIEW", payload);
    },

    declineReview({ commit }, payload) {
      commit("DECLINE_REVIEW", payload);
    },

    generateDocument({ dispatch, state, rootState }, download) {
      return new Promise(async (resolve, reject) => {
        if (!state.formData?.docs) {
          state.formData.docs = (await rootState.firebaseConfig.db
            .collection("workflows-user")
            .doc(state.formData.id)
            .collection("docs")
            .get()).docs.map(d => ({...d.data()}))
        }

        if (state.formData.docs.length === 0) {
          $logger.warn(
            "*** doc size for form id: " + state.formData.id,
            state.formData.docs.length
          );
          reject("*** No form data docs found");
        }

        const docIndex = state.activeDocIndex
          ? state.formData.docs.findIndex(
              (doc) => doc.uid === state.activeDocIndex
            )
          : 0;
        dispatch("setPdfTemplateImages", state.formData.docs[docIndex].dbRefs)
          .then(() => {
            let type = "blob";
            if (download) type = "download";
            dispatch("generatePdfFile", { type: type }).then(() => {
              resolve();
            });
          })
          .catch((error) => {
            reject("Error generating document: ", error);
          });
      });
    },

    setPdfTemplateImages({ commit }, dbRefs) {
      return new Promise((resolveAll, rejectAll) => {
        const pdfTemplateImages = [];

        if (dbRefs && dbRefs.length > 0) {
          const dbRefsLength = dbRefs.length;
          const activeDownloadRequests = [];

          for (let i = 0; i < dbRefsLength; i++) {
            // Create space in array so that template images that get downloaded will be in right place
            pdfTemplateImages.push(null);
            const currentImage = dbRefs[i];
            if (currentImage.dbRef === "") {
              // If template is blank
              currentImage.file = null;
              pdfTemplateImages.splice(i, 1, currentImage);
            } else {
              // Downloading via fetch
              const downloadRequest = new Promise((resolve, reject) => {
                const dbRefUrl =
                  currentImage.dbRef.indexOf("http") < 0
                    ? `${getCloudStorage()}${currentImage.dbRef}`
                    : currentImage.dbRef;
                fetch(dbRefUrl, { method: "GET" })
                  .then((response) => {
                    if (!response.ok) {
                      throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.blob();
                  })
                  .then((blob) => {
                    if (blob !== undefined) {
                      let reader = new FileReader();

                      reader.onloadend = () => {
                        currentImage.file = reader.result;
                        pdfTemplateImages.splice(i, 1, currentImage);
                        resolve(currentImage);
                      };

                      reader.readAsDataURL(blob);
                    } else {
                      currentImage.file = null;
                      pdfTemplateImages.splice(i, 1, currentImage);
                      resolve(currentImage);
                    }
                  })
                  .catch((error) => {
                    reject(`fetch template image url error: ${error}`);
                  });
              });

              activeDownloadRequests.push(downloadRequest);
            }
          }

          Promise.all(activeDownloadRequests)
            .then((imageArray) => {
              commit("SET_PDF_TEMPLATE_IMAGES", pdfTemplateImages);
              resolveAll();
            })
            .catch((error) => {
              rejectAll();
            });
        } else {
          commit("SET_PDF_TEMPLATE_IMAGES", []);
          resolveAll();
        }
      });
    },

    async sendEmailToRespondent({ rootState }, { id, sharingData, pg }) {
      await sendEmailToSpouse(
        id,
        rootState.baseUrl,
        sharingData.user,
        pg
      )
      return
    },

    async addShareData({ commit, getters, rootState }, { sharingData, pg }) {
      if (!sharingData?.user?.email) return;
      await rootState.firebaseConfig.db
        .collection("workflows-user")
        .doc(getters.getFormData.id)
        .update({
          sharingUser: sharingData.user.email,
          sharingProps: [sharingData],
        });
      sendEmailToSpouse(
        getters.getFormData.id,
        rootState.baseUrl,
        sharingData.user,
        pg
      )
      commit("ADD_SHARE_DATA", sharingData);
      return;
    },

    deleteShare({ commit }, sharingData) {
      commit("DELETE_SHARE_DATA", sharingData);
    },

    async saveStep(
      { rootState, commit },
      { transaction, docId, step, set = true }
    ) {
      const workflowsUserRef = rootState.firebaseConfig.db
        .collection("workflows-user")
        .doc(docId);
      if (transaction) {
        await transaction.update(workflowsUserRef, { step });
      } else {
        await workflowsUserRef.update({ step });
      }

      if (set) {
        commit("SET_FORMDATA_STEP", { type: "step", step });
      }
      return;
    },

    async saveRespondentStep(
      { rootState, commit },
      { transaction, docId, respondentStep }
    ) {
      const workflowsUserRef = rootState.firebaseConfig.db
        .collection("workflows-user")
        .doc(docId);
      if (transaction) {
        await transaction.update(workflowsUserRef, { respondentStep });
      } else {
        await workflowsUserRef.update({ respondentStep });
      }
      commit("SET_FORMDATA_STEP", {
        type: "respondentStep",
        step: respondentStep,
      });
      return;
    },

    async saveSignature({ rootState }, { transaction, docId, esign }) {
      let dataToUpdate = { esign };
      if (esign.spouse) {
        dataToUpdate = { spouseEsign: esign };
      }

      const workflowsUserRef = rootState.firebaseConfig.db
        .collection("workflows-user")
        .doc(docId);
      if (transaction) {
        await transaction.update(workflowsUserRef, dataToUpdate);
      } else {
        await workflowsUserRef.update(dataToUpdate);
      }
      return;
    },

    async getRawForm({ rootState }, { formName = "workflows-user", formId }) {
      return (
        await rootState.firebaseConfig.db.collection(formName).doc(formId).get()
      ).data();
    },

    async updateRawForm(
      { rootState, commit },
      { formName = "workflows-user", formId, data }
    ) {
      await rootState.firebaseConfig.db
        .collection(formName)
        .doc(formId)
        .set(data);
      commit("SET_FORM_DATA", data);
      return;
    },

    async getRawFormDataByState(
      { rootState },
      { formName = "workflows-user", state }
    ) {
      return (
        await rootState.firebaseConfig.db
          .collection(formName)
          .where("state", "==", state)
          .get()
      ).docs.map((f) => ({ ...f.data() }));
    },

    async saveRawForm({ rootState }, payload) {
      return await rootState.firebaseConfig.db
        .collection("workflows-user")
        .doc(payload.id)
        .set(payload, { merge: true });
    },

    async loadForm({ commit, dispatch, rootState, rootGetters }, { parentId, id }) {
      const userData = rootState.profile.user;
      let formIndex = -1
      const isAdmin = rootGetters['profile/isAdmin']
      if(!isAdmin && userData.forms.length > 0 && id) {
        formIndex = userData.forms.findIndex(element => element.workflowsUserId === id)
      }

      if (!isAdmin && parentId && formIndex === -1) {
        const publishedWorkflowRef = rootState.firebaseConfig.db.collection("workflows-published").doc(parentId)
        const form = await getPublishedFormByState(rootState.firebaseConfig.db, parentId)
        form["fields"] = clearFormFields(await setFormFields(publishedWorkflowRef, form))
        form["docs"] = await setFormDocs(publishedWorkflowRef, form)
        
        let rules = [];
        form.docs.forEach((doc) => {
          if (doc?.rules) {
            rules = [...rules, ...doc.rules];
          }
        });

        let userForm = {
          id: nanoid(12),
          parentId,
          collaborate: JSON.parse(localStorage.getItem("collaborate")),
          state: form.state,
          name: form.name,
          description: form.description,
          fields: form.fields,
          docs: form.docs || [],
          rules: rules,
          sharingUser: [],
          sharingProps: [],
          step: 1,
        };

        localStorage.removeItem("collaborate");
        let formData = Object.assign({}, userForm);
        rootState.title = formData.name;
        commit("SET_FORM_DATA", formData);
        let docId = null;
        if (formData.docs.length > 0) docId = formData.docs[0].uid;
        await dispatch("setActiveDocIndex", docId);
      } else {
        const workflowsUserId = id
        await dispatch(
          "displayLogic/setLogicLibrary",
          { workflowId: workflowsUserId, collection: "workflows-user" },
          { root: true }
        );
        const userWorkflowRef = rootState.firebaseConfig.db
          .collection("workflows-user")
          .doc(workflowsUserId);
        const snapshot = await userWorkflowRef.get();
        if (!snapshot.exists) return;
        const formData = snapshot.data();
        rootState.title = formData.name;
        if (!formData?.id) formData.id = nanoid(12);

        if (!formData.step) {
          formData.step = 1;
        }

        if (!formData.sharingUser) {
          formData.sharingUser = [];
        }

        formData["fields"] = await setFormFields(userWorkflowRef, formData);
        formData["docs"] = await setFormDocs(userWorkflowRef, formData);

        commit("SET_FORM_DATA", formData)
      }
      return;
    },

    addFormToUser({ commit, dispatch, rootState }, payload) {
      const userData = rootState.profile.user;
      commit("profile/SET_UPDATE_USER", userData.forms.push(payload), {
        root: true,
      });
      dispatch("profile/userUpdate", userData, { root: true });
    },

    async loadSharedForm({ commit, rootState, dispatch }, payload) {
      const userWorkflowRef = rootState.firebaseConfig.db
        .collection("workflows-user")
        .doc(payload.workflowsUserId);
      dispatch(
        "displayLogic/setLogicLibrary",
        { workflowId: payload.workflowsUserId, collection: "workflows-user" },
        { root: true }
      );
      const snapshot = await userWorkflowRef.get();
      if (!snapshot.exists) return;
      const formData = snapshot.data();
      formData["fields"] = (
        await userWorkflowRef.collection("fields").get()
      ).docs.map((f) => ({ ...f.data() }));

      formData["docs"] = (
        await userWorkflowRef.collection("docs").get()
      ).docs.map((d) => ({ ...d.data() }));

      if (formData.docs.length === 0)
        $logger.info(
          "*** loadSharedForm-> docs for form id: " + payload.workflowsUserId,
          formData.docs.length
        );

      //formData.isOwner = formData.owner.email === rootState.profile.user.email;
      commit("SET_FORM_DATA", formData);
      if (!formData?.sharingProps) return;
      let userProp = formData.sharingProps.find(
        (item) => item.user.email === rootState.profile.user.email
      );
      if (userProp) {
        const now = new Date().getTime();
        if (!userProp.user.firstAccessed) userProp.user.firstAccessed = now;
        userProp.user.lastAccessed = now;
        await userWorkflowRef
          .update({
            sharingProps: formData.sharingProps,
          })
        }
      return;
    },

    async saveFormProperty({ rootState }, { formId, props }) {
      await rootState.firebaseConfig.db
        .collection("workflows-user")
        .doc(formId)
        .set(props, { merge: true });
    },

    autoSaveFields({ getters }, { formId, pageId }) {
      const fieldsByPage = getters.getWorkflowFieldsAll.filter(
        (f) => f.page === pageId
      );
      updateSubCollection({
        parent: "workflows-user",
        id: formId,
        sub: "fields",
        data: fieldsByPage,
      }).then(() => {
        $logger.info('Could not update sub collection')
      });
      return;
    },

    async saveForm(
      { commit, state, dispatch, rootGetters, rootState, getters },
      canEmail = true
    ) {
      const userData = rootState.profile.user;
      let formData = getters.getFormData;
      if (formData === null) return;
      const formIndex = userData.forms.findIndex(
        (element) => element.parentId === state?.formData?.parentId
      );
      state.user = { ...userData };
      state.firebaseConfig = { ...rootState.firebaseConfig };
      await Promise.all([
        handleSignaturePads({
          state,
          form: formData,
          formId: formData.id,
          isAdmin: false,
        }),
        handleFileInputs({
          state,
          form: formData,
          formId: formData.id,
          isAdmin: false,
        })
      ])

      const dialogWindowForm = formData.fields.filter(
        (f) => f.type === "dialog-window"
      );
      const signaturePadsToHandle = []
      for (let dwf = 0; dwf < dialogWindowForm.length; dwf++) {
        const spf = dialogWindowForm[dwf];
        signaturePadsToHandle.push(handleSignaturePads({
          state,
          form: spf,
          formId: formData.id,
          fieldsName: "dialogFields",
        }))
      }
      await Promise.all(signaturePadsToHandle)

      // Delete files saved on form because they can't be written to Cloud Firestore
      const pdfTemplatesLength = formData?.pdfTemplateImages?.length || 0;
      for (let i = 0; i < pdfTemplatesLength; i++) {
        delete formData.pdfTemplateImages[i].file;
      }

      formData["modifiedDate"] = Date.now();
      const requiredFields = formData.fields.filter((f) => f.required === true);
      const answeredFields = requiredFields.filter(
        (f) => f.answeredStatus === true
      );

      formData["completed"] = requiredFields.length === answeredFields.length;
      const userWorkflow = state.firebaseConfig.db.collection("workflows-user");
      const publishedWorkflow = state.firebaseConfig.db
        .collection("workflows-published")
        .doc(formData.parentId);

      if (!formData?.docs) {
        await setFormDocs(userWorkflow.doc(formData.id), formData);
      }

      const docs = [...formData.docs];
      delete formData.docs;

      if (!formData?.fields) {
        await getFormFields(userWorkflow.doc(formData.id), formData);
      }

      const fields = [...formData.fields];
      delete formData.fields;
      if (formIndex === -1) {
        // Add form to database
          formData.owner = { email: userData.email, name: userData.full_name };
          formData["createdOn"] = Date.now();
          delete formData.rules;

          await dispatch(
            "blocks/setBlocks",
            { id: formData.parentId, collectionName: "workflows-published" },
            { root: true }
          )

          const displayLogicData = (
            await publishedWorkflow.collection("display-logic-elements").get()
          ).docs.map((d) => ({ ...d.data() }))
          
          const logicLibraryData = (
            await publishedWorkflow.collection("display-logic-library").get()
          ).docs.map((d) => ({ ...d.data() }))

          formData['id'] = uuidv4()
          const updatePayload = {
            collection: 'workflows-user',
            formData: fixUndefined(formData),
            blocks: rootGetters["blocks/blocks"],
            displayLogicElements: displayLogicData,
            displayLogicLibrary: logicLibraryData,
            docs,
            fields,
          }
          
          await updateWorkflow(updatePayload)
          const payload = {
            workflowId: formData.id,
            workflowType: "published",
            formElements: fields,
            rules: formData.rules,
          };
          await dispatch("displayLogic/buildTemplates", payload, { root: true })

          if (canEmail) {
            await sendEmail(
              formData.id,
              state.user,
              rootState.baseUrl,
              formData.completed
            );
          }

          commit(
            "profile/SET_UPDATE_USER",
            userData.forms.push({ parentId: formData.parentId, workflowsUserId: formData.id
          }),
            { root: true }
          );
      } else {
        // If sharee user (owner user) made changes in its form and it has invitee users, then  unlock form for them
        formData.sharingProps.forEach(
          (inviteeUser) => (inviteeUser.access = true)
        );
        // Update form that is already in database
        await userWorkflow
          .doc(formData.id)
          .update(formData)
          .then(() => {
            if (canEmail) {
              sendEmail(
                formData.id,
                state.user,
                rootState.baseUrl,
                formData.completed
              );
            }
          })
        await Promise.all([
          updateSubCollection({
            parent: "workflows-user",
            id: formData.id,
            sub: "docs",
            data: docs,
          }),
          updateSubCollection({
            parent: "workflows-user",
            id: formData.id,
            sub: "fields",
            data: fields,
          })
        ])
      }

      formData["docs"] = docs;
      formData["fields"] = fields;

      commit("SET_FORM_DATA", { ...formData });

      return formData.id;
    },

    async agreedToTerms({ rootState, getters }) {
      await sendSharedEmail(getters.getFormData, "agreed", rootState.baseUrl);
      return;
    },

    async sendBackForReview({ rootState, getters }, payload) {
      await sendSharedEmail(
        getters.getFormData,
        "disagreement",
        rootState.baseUrl,
        payload.commentOwner
      );
      const array = [];
      for (let i = 0; i < payload.numberOfPages; i++) {
        array.push({ comment: "", page: "" });
      }

      const docId = getters.getFormData.id;
      const id = "c" + new Date().valueOf();
      const docRef = doc(
        rootState.firebaseConfig.db,
        "workflows-user",
        docId,
        "spouse-comments",
        id
      );
      await setDoc(docRef, {
        commentId: id,
        commentOwner: payload.commentOwner,
        commentOwnerName: payload.commentOwnerName,
        comments: array,
        createdAt: new Date(),
      });
      return;
    },

    async spouseAgreed({ rootState, getters }, docId) {
      const docRef = doc(rootState.firebaseConfig.db, "workflows-user", docId);
      const getData = await getDoc(docRef);
      const dataFromDoc = getData.data();
      let sharingUserEmail = dataFromDoc.sharingProps[0];
      sharingUserEmail.agreedWithSpouse = true;

      try {
        await updateDoc(docRef, { sharingProps: [sharingUserEmail], step: 3 });
        await sendSharedEmail(getters.getFormData, "agreed", rootState.baseUrl);
        return "success";
      } catch (error) {
        return "error";
      }
    },

    async saveInviteeResponse(
      { commit, state, rootState, getters, dispatch },
      payload
    ) {
      let formData = getters.getFormData;

      if (payload.response === "disagreed") {
        respondentSendsDisagreementEmail(formData);
      }

      let userProp = formData.sharingProps.find(
        (item) => item.user.email === rootState.profile.user.email
      );

      if (userProp) {
        const docId = formData.id;
        userProp.access = false;
        userProp.user.confirmation = {
          response: payload.response,
          time: new Date().getTime(),
        };

        // In case if user has read-only permission only update user prop
        if (userProp.reviewType === "read-only") {
          await rootState.firebaseConfig.db
            .collection("workflows-user")
            .doc(docId)
            .update({
              sharingProps: formData.sharingProps,
            })
        } else if (userProp.reviewType === "comment") {
          // In case user has comment permission update user prop along with add/update comments
          formData.fields.forEach((field) => {
            for (const property in field.comments)
              !field.comments[property].text
                ? delete field.comments[property]
                : null;
          });
          await rootState.firebaseConfig.db
            .collection("workflows-user")
            .doc(formData.id)
            .set(formData)
            .then(() => {
              commit("UPDATE_COMMENTS_SAVED_COUNTER");
            })
        } else if (userProp.reviewType === "edit") {
          // In case user has edit permission update user prop along with form fields
          const userData = rootState.profile.user;
          await rootState.firebaseConfig.db
            .collection("workflows-user")
            .doc(formData.id)
            .get()
            .then(async (snapshot) => {
              const saveFormData = snapshot.data();
              const changedData = formData.fields.filter((x, index) => {
                if (
                  x.type != "page-break" && x.selection
                    ? x.selection !== saveFormData.fields[index].selection
                    : x.content !== saveFormData.fields[index].content
                ) {
                  x.position = index;
                  return x;
                }
              });

              changedData.forEach((x) => {
                if (!saveFormData.fields[x.position]["reviews"]) {
                  saveFormData.fields[x.position]["reviews"] = {};
                }

                const review = {
                  email: userData.email,
                  name: userData.full_name,
                  time: new Date().getTime(),
                };

                if (x.selection) {
                  review.data = x.selection;
                  saveFormData.fields[x.position]["reviews"][userData.email] =
                    review;
                } else if (x.content) {
                  review.data = x.content;
                  saveFormData.fields[x.position]["reviews"][userData.email] =
                    review;
                }
              });

              // update user props in saveFormData
              saveFormData.sharingProps = formData.sharingProps;
              await rootState.firebaseConfig.db
                .collection("workflows-user")
                .doc(formData.id)
                .set(saveFormData)
                .then(() => $logger.info("User form updated!"))
            })
        }
      }

      formData = { ...formData };
      return;
    },

    async shareForm({ state, rootState, getters }) {
      const formData = getters.getFormData;
      const sharingProps = JSON.parse(JSON.stringify(formData.sharingProps)); // Deep copy of formData.sharingProps to send email only to new invitees

      formData.sharingProps.forEach((inviteeUser) => delete inviteeUser.isNew); // Delete isNew key from all users
      delete formData.pdfTemplateImages;
      await rootState.firebaseConfig.db
        .collection("workflows-user")
        .doc(formData.id)
        .set(formData, { merge: true })
        .then(async () => {
          const promises = [];

          for (var sp = 0; sp < sharingProps.length; sp++) {
            const user = sharingProps[sp];
            let emailType = "share";
            if (user.isNew) {
              emailType = "invite";
            }

            const baseUrl = rootState.baseUrl;

            promises.push(
              sendEmailUtil(
                baseUrl,
                user.user,
                "invite",
                "Divorce form Sharing"
              )
                .then(() => {
                  $logger.info(`Email sent to ${user.user.email}`);
                })
            );
          }

          Promise.all(promises)
            .then(() => {
              state.formData = { ...formData };
            })
            .catch(() => {
              state.formData = { ...formData };
            });
        })
      return;
    },

    async updateFormDataField({ commit, dispatch, getters }, payload) {
      if (getters?.getFormData) commit("UPDATE_FORM_DATA_FIELD", payload);

      await dispatch(
        "displayLogic/updateFormElementsDisplayStatus",
        {
          workflowType: "published",
          formElementsToUpdate: [payload.field.uid],
        },
        { root: true }
      )
      return true;
    },

    async setActiveDocIndex({ commit, dispatch }, index) {
      commit("SET_ACTIVE_DOC_INDEX", index);
      await dispatch("generateDocument", false);
      return;
    },

    async generatePdfFile({ commit, dispatch, state, rootGetters }, payload) {
      await pdfGenerator(
        { commit, dispatch, state, rootGetters },
        state.formData,
        payload,
        "workflows-user"
      );
      return
    },

    getQuestionValue({ state }, question) {
      return getValueFromQuestion(state.formData.fields, question);
    },

    checkQuestionForMatch({ state }, logicQuestion) {
      return questionMatcher(state.formData, logicQuestion);
    },

    setFields({ commit }, payload) {
      commit("SET_FIELDS_ON_FORM", payload);
    },

    async getFormFields({ rootState, commit }, { id, collection }) {
      const fields = (
        await rootState.firebaseConfig.db
          .collection(collection)
          .doc(id)
          .collection("fields")
          .get()
      ).docs.map((d) => ({ ...d.data() }));
      commit("SET_FIELDS_ON_FORM", fields);
      return;
    },

    async updateFormDocs({ rootState, commit }, { id, collection = 'workflows-user' }) {
      const docs = (
        await rootState.firebaseConfig.db
          .collection(collection)
          .doc(id)
          .collection("docs")
          .get()
      ).docs.map((d) => ({ ...d.data() }));

      if (docs.length === 0)
        $logger.info("*** getFormDocs->docs for form id: " + id, docs.length);

      commit("SET_DOCS_ON_FORM", docs);
      return;
    },

    async getSubCollection({ rootState }, { parent, id, sub }) {
      return (
        await rootState.firebaseConfig.db
          .collection(parent)
          .doc(id)
          .collection(sub)
          .get()
      ).docs.map((d) => ({ ...d.data() }));
    },
  },
};

async function getPublishedFormByState(db, state) {
  const ref = await db.collection("workflows-published").doc(state).get();
  return ref.data();
}

async function scheduleReadyToFileEmail(
  formId,
  to,
  headerHtml,
  bodyHtml,
  footerHtml,
  greeting,
  signature
) {
  const sendOn = dayjs().add(30, "day");
  let body = greeting + emailTemplate["ready"] + signature;
  body = body.replace("{{completionDate}}", getDateFormat());
  const message = bodyHtml
    .replace("{{header}}", headerHtml)
    .replace("{{footer}}", footerHtml)
    .replace("{{message}}", body);
  const subject = "SIMPLEENDING™ form is ready to be filed";
  await smtp().scheduleEmail({ to, subject, message, sendOn, formId });
}

async function sendEmailToSpouse(formId, baseUrl, user, pg = "sign-in") {
  const type = await userExists(user.email) === true ? "share" : "invite";
  const emailStatus = await sendEmailUtil(
    baseUrl,
    user,
    type,
    "Shared form status update",
    undefined,
    pg,
    undefined,
    formId
  );
  return emailStatus;
}

async function scheduleIncompleteEmail(
  formId,
  to,
  headerHtml,
  bodyHtml,
  footerHtml,
  greeting,
  signature
) {
  const sendOn = dayjs().add(7, "day");
  const body = greeting + emailTemplate["incomplete-form"] + signature;
  const message = bodyHtml
    .replace("{{header}}", headerHtml)
    .replace("{{footer}}", footerHtml)
    .replace("{{message}}", body);
  const subject = "SIMPLEENDING™ form entry reminder";
  await smtp().scheduleEmail({ to, subject, message, sendOn, formId });
}

async function sendEmail(formId, user, baseUrl, completed) {
  let footerHtml = await loadHtml("../email-templates/footer.html", baseUrl);
  let headerHtml = await loadHtml("../email-templates/header.html", baseUrl);
  headerHtml = headerHtml.data.replace(
    "logo~200",
    createAnalyticImagePath("logo~200", "greeting", "userid", user.email)
  );
  const bodyHtml = await loadHtml("../email-templates/body.html", baseUrl);
  const greeting = emailTemplate["greeting"].replace(
    "{{FirstName}}",
    user.full_name
  );
  const signature = emailTemplate["signature"];
  footerHtml = footerHtml.data.replace("~base_url~", baseUrl);
  if (completed) {
    await sendEmailUtil(
      baseUrl,
      user,
      "completed",
      "SIMPLEENDING™ form entry reminder"
    );
    scheduleReadyToFileEmail(
      formId,
      user.email,
      headerHtml,
      bodyHtml.data,
      footerHtml,
      greeting,
      signature
    );
  } else {
    scheduleIncompleteEmail(
      formId,
      user.email,
      headerHtml,
      bodyHtml.data,
      footerHtml,
      greeting,
      signature
    );
  }
}

const sendSharedEmail = async (
  formData,
  type,
  baseUrl,
  contact = "petitioner"
) => {
  const user = {
    name:
      contact === "petitioner"
        ? formData.owner.name
        : formData.sharingProps[0].user.name,
    email:
      contact === "petitioner"
        ? formData.owner.email
        : formData.sharingProps[0].user.email,
  };
  const emailStatus = sendEmailUtil(
    baseUrl,
    user,
    type,
    "SIMPLEENDING™ shared form status update",
    undefined,
    undefined,
    undefined,
    formData.state
  );
  return emailStatus;
};

const setFormFields = async (workflowRef, form) => {
  if (!form?.fields) {
    return (await getFields(workflowRef)).docs.map((ref) => ({...ref.data()}))
  }

  return [];
};

const setFormDocs = async (workflowRef, form) => {
  if (!form?.docs) {
    return (await getDocs(workflowRef)).docs.map((doc) => ({...doc.data()})).sort((a, b) => a.order - b.order);
  }

  return []
};
