import axios from 'axios'
import { getProject } from './gcloud/getProject';

export default async function mvFrmToStg(docId, projectUrl = getProject(), httpRequest = axios) {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };

  return await httpRequest({
    url: `${projectUrl}/migrateFormToStg`,
    method: 'POST',
    headers: config,
    data: JSON.stringify({ collection: 'forms', docId })
  })
}