<template>
  <tr>
    <td valign="top" class="headerContainer">
      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
          <tr>
              <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                  <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                      <tr>
                          <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0; text-align:center;">                    
                              <a href="https://simpleending.com/" title="" class="" target="_blank">
                                  <img align="center" alt="" src="https://mcusercontent.com/2fa5a274aed8bded578b4deb1/images/5638f921-2ed9-4adb-ccf2-1f80cfd32104.png" width="320" style="max-width:6000px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">
                              </a>
                          </td>
                      </tr>
                  </table>
              </td>
          </tr>
      </table>
      <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
        <tr>
            <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                    <tr>                        
                        <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">                        
                            <div style="text-align: center;">
                              <span style="font-size:30px;text-transform: capitalize;">
                                practice
                              </span>
                            </div>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
      </table>
    </td>
  </tr>
</template>

<script>
import { getProject } from '../../services/gcloud/getProject';
export default {
  name: "EmailHeader",

  data() {
    return {
      projectUrl: ''
    }
  }, 

  created() {
    this.projectUrl = getProject()
  }
};
</script>