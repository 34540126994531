module.exports = {
  "greeting": '{{FirstName}}<br /><br />',
  "signature": '<br /><br />From, Your <b>SIMPLE</b>ENDING™ Team',
  "welcome": 'Welcome to <b>SIMPLE</b>ENDING™ <br />',
  "incomplete-form": 'This is a reminder that your forms are incomplete. In order to proceed with your <b>SIMPLE</b>ENDING™ submission, please login and complete your documents. If you are in need of attorney support please contact Shaw Law',
  "invite": 'You have been invited to create a login account on the <b>SIMPLE</b>ENDING™ website. Please click the button below and you will be taken to the correct page.',
  "share": 'You have been invited to view a form on the <b>SIMPLE</b>ENDING™ website. Please click the button below and you will be taken to the correct page.',
  "sign": 'You have been invited to review and e-sign a form on the <b>SIMPLE</b>ENDING™ website. Please click the button below and you will be taken to the correct page.',
  "collaborate": 'You have been invited to collaborate on a form in the <b>SIMPLE</b>ENDING™ website. Please click the button below and you will be taken to the correct page.',
  "stakeholder": 'You have been invited as a stakeholder on a form on the <b>SIMPLE</b>ENDING™ website. Please click the button below and you will be taken to the correct page.',
  "disagreement": 'This email is to let you know that there has been a disagreement with your spouse. Please <a href="{{clickHereLink}}"><b>CLICK HERE</b></a> to re-evaluate the form, or come to a different conclusion with your spouse.',
  "agreed": 'This email is to let you know that your spouse has agreed to the terms of your form. Please <a href="{{clickHereLink}}"><b>CLICK HERE</b></a> to finish up the process of this form. If you are in need of mediation, please contact Shaw Law to schedule an appointment. <a href="{{shawLawLink}}"><b>(HERE)</b></a>',
  "completed": 'Thank you for choosing <b>SIMPLE</b>ENDING™. Now that your documents have been completed, you simply need to follow the steps outlined in the instruction sheet provided with the documents. Per Utah law, there is a 30-day waiting period from the time you file the initial documents and when the court can enter the Decree of Divorce. If the documents were filed the day they were completed, the earliest the final documents should be filed with the court is {{readyDate}}',
  "signed": 'The form you submitted on {{completionDate}} is now ready to be filed with the court',
  "initial payment": 'Now that you have paid the initial starting fee of ${{cost}}, we will get started on finalizing your divorce.',
  "final payment": 'Thank you for your final payment of ${{cost}}, we will get started on finalizing your divorce.',
  "one time payment": 'Thank you for your one time payment of ${{cost}}, we will get started on finalizing your divorce.'
}