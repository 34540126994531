<template>
  <div class="d-flex align-center my-2">
    <v-select 
      v-model="activeSelection" 
      :items="options" :item-text="itemText" 
      :item-value="itemValue" 
      :label="selectionLabel"
      :clearable="clearable"
      class="a-action-list-type-selector"
      solo/>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs}">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="!activeSelection"
          :title="buttonTitle"
          class="d-inline mx-2"
          fab
          small
          @click="addNewOption"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>{{ buttonTitle }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'MultiOptionAdd',

  props: {
    selectionLabel: {
      type: String,
      default: 'Options To Add',
    },

    buttonTitle: {
      type: String,
      default: 'Add Option'
    },

    options: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
    },
    itemValue: {
      type: String,
    },

    clearable: {
      type: Boolean,
      default: true,
    }

  },

  data() {
    return {
      activeSelection: null,
    }
  },

  methods: {
    addNewOption() {
      this.$emit('add-new-option', this.activeSelection);
    }
  }
}
</script>

<style lang="scss">
  .v-select__slot {
    .v-input__append-inner {
      position:inherit !important;
    }
  }
</style>