<template>
    <v-dialog 
        id="comment-message-dialog"
        v-model="showDialog"
        :persistent="true"
        @click:outside="$emit('close-dialog')"
    >
        <v-card>
            <v-card-title>
                Please wait for your spouse to review              
            </v-card-title>
            <v-card-text>
                <div id="please-wait" class="mb-5">
                    <v-img
                        :src="require(`@/assets/images/wait_green.svg`)"
                        height="75"
                        contain
                    >
                    </v-img>
                    <p>
                        You are now waiting for your spouse to respond to your comments.
                        Until they respond, you can only check the comment history.
                    </p>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-flex class="text-right">
                    <v-btn
                        text
                        title="Close Dialog Window"
                        @click="$emit('close-dialog')"
                    >
                        <v-icon>clear</v-icon>Close
                    </v-btn> 
                </v-flex>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CommentMessage',

    data() {
        return {
            showDialog: false
        }
    },

    props: {
        show: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        show(newVal) {
            this.showDialog = newVal
        }
    }
}
</script>

<style lang="scss">
.comment-message-dialog {
    .v-dialog {
        width: 50% !important;
    }
}

@media (max-width: 600px) {
    .comment-message-dialog {    
        .v-dialog {
            width: 100% !important;
        }
    }
}
</style>