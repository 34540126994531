import SignaturePad from "@/components/SignaturePad";
import TableRowEditForm from "@/components/TableRowEditForm";
import FileUpload from "@/components/FileUpload";
import VuetifyMoney from "@/components/VuetifyMoney";
import HelpIcon from "@/components/HelpIcon/HelpIcon";
import DialogQuestions from "@/components/DialogQuestions";
import expressionatorMixin from "@/mixins/expressionator.mixin";
import itemTableMixin from "@/mixins/itemTable.mixin";
import youtubeMixin from "@/mixins/youtube.mixin";
import pagesMixin from "@/mixins/pages.mixin";
import datePickerMixin from "@/mixins/datePicker.mixin";
import validatorsMixin from "@/mixins/validators.mixin";
import groupedOptionsMixin from "@/mixins/grouped.options.mixin"
import { mapGetters } from "vuex";

export default {
    components: {
        SignaturePad,
        TableRowEditForm,
        HelpIcon,
        FileUpload,
        DialogQuestions,
        VuetifyMoney
    },

    mixins: [
        expressionatorMixin,
        itemTableMixin,
        validatorsMixin,
        pagesMixin,
        youtubeMixin,
        datePickerMixin,
        groupedOptionsMixin
    ],

    props: {
        fill: {
            type: Boolean,
            default: false,
        },

        showTabs: {
            type: Boolean,
            default: true,
        },

        readOnly: {
            type: Boolean,
            default: false,
        },

        formId: {
            type: String,
            default: "",
        },
    },

    created() {
        const dialogs = this.formFields.filter(field => field.type === 'dialog-window' && field.dialog === true)
        dialogs.forEach(field => {
          field.dialog = false
        })
    
        if(!this.canAutoSave) this.setDefaults()
    },

    computed: {
        ...mapGetters(["getAcceptedUploadFileTypes", "getAllowedUploadFileTypes"]),
                
        ...mapGetters('displayLogic', {
            'defaultDisplayLogic': 'formElements',
            'displayTemplate': 'formElementsTemplate'
        }),

        sliderOptions() {
            return (options) => {
              if (options.length === 0) {
                return options;
              } else {
                const formattedOptions = [...options];
      
                formattedOptions.forEach((option, index) => {
                  formattedOptions[index] = this.expressionator(option, this.fields);
                });
                return formattedOptions;
              }
            };
        },

        selectOptions() {
            return (options) => {
              if (options.length === 0) {
                return options;
              } else {
                const formattedOptions = [...options];
      
                formattedOptions.forEach((option, index) => {
                  formattedOptions[index] = {
                    value: option,
                    text: this.expressionator(option, this.fields),
                  };
                });
                return formattedOptions;
              }
            };
        },

        displayStatusDialogWindow() {
            return (uid) => {
              return this.displayTemplate && uid && this.displayTemplate[uid]
                ? this.displayTemplate[uid].display
                : false;
            };
        },

        answerStatus() {
            return (element) => {
              return (
                element.required &&
                this.checkAnswerCalled &&
                !element?.answeredStatus
              );
            };
        },

        embeddedImageCheckSelection() {
            return (field, image, interactionType) => {
              if (image.title && interactionType === "multi-select") {
                return (
                  field.selectionMultiple.findIndex(
                    (elem) => elem.title === image.title
                  ) > -1
                );
              } else if (image.title && interactionType === "single-select") {
                return field.selectionSingle?.title === image.title;
              } else {
                return false;
              }
            };
        },
    },

    methods: {
      updateField(field, value) {
        let update = {};
  
        if (
          ["text-input-short", "text-input-long", "number-input"].indexOf(
            field.type
          ) > -1
        ) {
          update.content = value;
          update.answeredStatus =
            typeof this.rules.required(value) === "boolean";
        } else if (["state-select","single-select", "multi-select"].indexOf(field.type) > -1) {
          update.selection = value;
          update.answeredStatus =
            typeof this.rules.required(value) === "boolean";
        } else if (["date-picker"].indexOf(field.type) > -1) {
          update.answeredStatus =
            typeof this.rules.required(value) === "boolean";
          switch (field.variant) {
            case "single-date":
            if(field.monthPicker !== true) {
              update.dateSingle = value
            } else {
              update.dateSingle = this.monthPickerHandler(field, value)
            }
            break;
            case "multiple-dates":
            case "date-range":
              update.dateMultiple = value;
              break;
          }
        } else if (["embedded-image"].indexOf(field.type) > -1) {
          update.answeredStatus =
            typeof this.rules.required(value) === "boolean";
          switch (field.interactionType) {
            case "single-select":
              if(field.monthPicker !== true) {
                update.dateSingle = value
              } else {
                update.dateSingle = this.monthPickerHandler(field, value)
              }
              break;
            case "multi-select":
              update.selectionMultiple = value;
              break;
          }
        } else if (["file-input"].indexOf(field.type) > -1) {
          update.answeredStatus =
            typeof value !== "undefined" && value !== null;
          update.content = {
            file: value,
            storageRef: field.content.storageRef ?? null,
            deleteFlag: typeof value === "undefined",
          };
        } else if (["dialog-window"].indexOf(field.type) > -1) {
          update.answeredStatus =
            typeof value !== "undefined" && value !== null;
          update.dialog = false;
          update.selection = {
            buttonClicked: value,
          };
        } else if (["signature-pad"].indexOf(field.type) > -1) {
          update.answeredStatus = value.signed;
          update.signature = value;
        }
  
        const changeObj = {
          type: "published",
          field: {
            title: field.title,
            type: field.type,
            uid: field.uid,
          },
          updates: update,
        };
  
        return changeObj
      },

      muteVideo() {
          let videos = document.querySelectorAll("iframe, video");
    
          Array.prototype.forEach.call(videos, function(video) {
            if (video.tagName.toLowerCase() === "video") {
              video.pause();
            } else {
              let src;
              src = video.src;
              video.src = src;
            }
          });
      },

      // Dialog-Window Methods

      async dialogWindowChange(field, payload) {
        await this.$store.dispatch("displayLogic/updateFormElementsDisplayStatus", {
          formElementsToUpdate:  [field.uid],
        });
      },

      async dialogWindowSelectionMade(field, item) {
        this.inputHandler(field, item);
        await this.dialogWindowChange(field, false);
      },        
    }
}