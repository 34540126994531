<template>
  <v-expansion-panel :class="bg">
    <v-expansion-panel-header :class="{ 'blockDrag': blockDrag }">
      <div class="d-flex flex-wrap">
        <div class="d-flex align-center">
          <v-icon class="mr-2">{{ icon }}</v-icon>
        </div>
        <div class="d-block" style="line-height: 16px;">
          <h4 class="text-left align-center">
            <span class="font-italic pr-1">{{ title }}</span>
          </h4>
          <h5
            class="text-left align-center justify-center"
            :class="{ 'text-danger': !isUnique }"
          >
            {{ data.title }}
          </h5>
        </div>
        <div class="ml-auto d-flex align-center">
          <v-btn
            v-if="data.fieldsIndex !== 0 || data.type !== 'page-break'"
            @click.stop="duplicateFormElement"
            class="mx-2"
            x-small
            fab
            outlined
            color="grey"
            title="Duplicate"
          >
            <v-icon>content_copy</v-icon>
          </v-btn>
          <v-btn
            v-if="data.fieldsIndex !== 0 || data.type !== 'page-break'"
            @click.stop="removeFormElement"
            class="mr-2"
            x-small
            fab
            outlined
            color="red"
            title="Delete"
          >
            <v-icon>delete_outline</v-icon>
          </v-btn>
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-select
        v-if="data.fieldsIndex > 0"
        v-model="data.moveToSelection"
        :items="questionList"
        label="Move Element To After"
        item-text="questionListTitle"
        item-value="fieldsIndex"
        solo
        dense
        @input="moveFormElement"
      />
      <v-alert v-show="!isUnique" type="error">
        Please enter a unique title for this form element.
      </v-alert>
      <v-text-field
        v-if="uniqueTitle"
        :label="uniqueTitle"
        v-model="data.title"
        @keyup="parseTitle(data)"
        @blur="checkForUniqTitle(data)"
      />
      <v-text-field v-if="name" :label="name" v-model="data.name" />
      <v-text-field v-if="url" :label="url" v-model="data.videoURL" />
      <v-text-field v-if="label" :label="label" v-model="data.label" />
      <v-text-field v-if="heading" :label="heading" v-model="data.heading" />
      <v-text-field v-if="text" :label="text" v-model="data.text" />
      <v-text-field
        v-if="content && typeof data.content === 'string'"
        :label="content"
        v-model="data.content"
      />
      <v-text-field
        v-if="min"
        v-model="data.lowerBound"
        :label="min"
        type="number"
      />
      <v-text-field
        v-if="max"
        v-model="data.upperBound"
        :label="max"
        type="number"
      />
      <v-text-field
        v-if="
          data.hasOwnProperty('prefix') || ['computed-property'].indexOf(data.type) > -1
        "
        v-model="data.prefix"
        label="Prefix"
      />
      <v-text-field
        v-if="
          data.hasOwnProperty('suffix') || ['computed-property'].indexOf(data.type) > -1
        "
        v-model="data.suffix"
        label="Suffix"
      />
      <v-select
        v-if="data.hasOwnProperty('variant') && data.type === 'single-select'"
        :items="singleSelectVariants"
        v-model="data.variant"
        label="Variant"
      />
      <v-select
        v-if="data.hasOwnProperty('variant') && data.type === 'multi-select'"
        :items="multiSelectVariants"
        v-model="data.variant"
        label="Variant"
      />
      <v-select
        v-if="data.hasOwnProperty('variant') && data.type === 'item-table'"
        :items="itemTableVariants"
        v-model="data.variant"
        label="Variant"
      />
      <v-select
          v-if="data.type === 'date-picker'"
          :items="datePickerFormats"
          item-text="value"
          item-value="key"
          v-model="data.format"
          label="Date Format"
      />
      <v-select
        v-if="data.hasOwnProperty('variant') && data.type === 'date-picker'"
        :items="datePickerVariants"
        v-model="data.variant"
        label="Variant"
      />
      <v-select
        v-if="
          1 === 2 &&
          data.hasOwnProperty('variant') &&
          data.type === 'embedded-image'
        "
        :items="embeddedImageVariants"
        v-model="data.variant"
        label="Variant"
      />

      <template v-if="data.type === 'date-picker'">
        <v-dialog
          v-model="datePickerMinDateDialog"
          :retain-focus="false"
          class="min-date-picker"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="data.minDate"
              label="Earliest Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="data.minDate"
            min="1900-01-01"
            scrollable
          ></v-date-picker>
        </v-dialog>

        <v-dialog
          v-model="datePickerMaxDateDialog"
          :retain-focus="false"
          class="max-date-picker"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="data.maxDate"
              label="Latest Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="data.maxDate"
            min="1900-01-01"
            scrollable
          ></v-date-picker>
        </v-dialog>

        <v-switch
          v-if="data.hasOwnProperty('monthPicker')"
          label="Month Picker"
          v-model="data.monthPicker"
        />
      </template>

      <template v-if="data.type === 'item-table'">
        <v-divider></v-divider>

        <br />

        <v-card class="bg-light">
          <v-card-title>Create New Column</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="data.headerToAdd.text"
              label="Header"
              @blur="itemTableUpdateValueField(data)"
            />

            <v-select
              v-model="data.headerToAdd.align"
              :items="itemTableHeaderAlignOptions"
              label="Header Alignment"
            />
            <v-switch
              v-model="data.headerToAdd.divider"
              label="Header Divider"
            />
            <v-switch v-model="data.headerToAdd.sortable" label="Sortable" />
            <v-switch
              v-if="data.variant === 'read-only'"
              v-model="data.headerToAdd.writeable"
              label="Allow User Input"
            />
            <v-select
              v-model="data.headerToAdd.variant"
              :items="headerVariantOptions"
              label="Entry Type"
            />
            <template v-if="data.headerToAdd.variant === 'select'">
              <v-text-field
                v-model="data.headerToAdd.optionToAdd"
                label="Add Selectable Options"
                @keydown="
                  (e) => {
                    if (e.keyCode === 13) {
                      addHeaderOption(data.headerToAdd);
                    }
                  }
                "
              >
                <template v-slot:append-outer>
                  <v-btn text @click="addHeaderOption(data.headerToAdd)">
                    <v-icon>add_circle</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <v-chip-group column>
                <v-chip
                  v-for="(chip, index) in data.headerToAdd.options"
                  :key="index"
                  close
                  @click:close="removeHeaderOption(data.headerToAdd, index)"
                  >{{ chip }}</v-chip
                >
              </v-chip-group>
            </template>
            <v-btn @click="addHeader(data)" class="my-4">
              <v-icon class="pr-1">add_circle</v-icon>
              Add Column
            </v-btn>

            <br />
            <br />

            <template v-if="data.headers.length > 1">
              <v-divider></v-divider>
              <h4>COLUMNS</h4>
              <v-divider></v-divider>
              <v-chip-group column> 
                <template v-for="(chip, index) in data.headers">
                  <v-chip
                    v-show="chip.value !== 'actions'"
                    :key="index"
                    close
                    @click:close="removeHeader(data, index)"
                    >{{ chip.text }}
                  </v-chip>
                </template>
              </v-chip-group>
              <v-divider></v-divider>
            </template>
          </v-card-text>
        </v-card>

        <br />

        <v-divider></v-divider>

        <v-card v-if="data.variant === 'read-only'">
          <v-card-title>Add Read-Only Rows</v-card-title>
          <v-card-text>
            <template>
              <v-data-table
                :headers="data.headers"
                :items="data.readOnlyRows"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-subheader>{{ data.label }}</v-subheader>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      title="Add new read-only row"
                      dark
                      icon
                      class="mb-2"
                      v-bind="$attrs"
                      @click="itemTableAddNewRow(data)"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, rIndex) in items" :key="rIndex" class>
                      <td
                        v-for="(header, hIndex) in itemTableRows(data.headers)"
                        :key="hIndex"
                        :class="`text-${header.align}`"
                      >
                        {{ item[header.value] }}
                      </td>
                      <td v-if="previewMode === false">
                        <v-icon
                          small
                          class="mr-2"
                          @click="
                            itemTableEditItem(
                              item,
                              rIndex,
                              form.fields,
                              'readOnlyRows'
                            )
                          "
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          @click="
                            itemTableDeleteItem(
                              item,
                              rIndex,
                              form.fields,
                              'readOnlyRows'
                            )
                          "
                        >
                          mdi-delete
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
              <v-dialog v-model="itemTableEditItemDialog" max-width="1200">
                <table-row-edit-form
                  :row-title="itemTableRowTitle"
                  :headers="data.headers"
                  :data="editedItem"
                  :is-admin="true"
                  @cancel="itemTableCloseDialog"
                  @save="
                    itemTableSaveItem(
                      data,
                      data.variant === 'read-only'
                        ? 'readOnlyRows'
                        : 'inputRows'
                    )
                  "
                />
              </v-dialog>
              <v-dialog v-model="itemTableDeleteItemDialog" max-width="500px">
                <v-card>
                  <v-card-title class="headline">
                    Are you sure you want to delete this item?
                  </v-card-title>
                  <v-card-actions style="justify-content: right;">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="itemTableDeleteItemConfirm(
                        data,
                        data.variant === 'read-only'
                          ? 'readOnlyRows'
                          : 'inputRows'
                      )"
                    >
                      OK
                    </v-btn>
                    <v-btn
                      color="primary darken-1"
                      text
                      @click="itemTableCloseDeleteDialog"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-card-text>
        </v-card>
      </template>

      <template v-if="data.type === 'embedded-image'">
        <v-select
          v-if="data.variant === 'static-image'"
          v-model="data.alignment"
          label="Alignment"
          :items="alignmentOptionsHorizontal"
        />

        <v-select
          v-model="data.interactionType"
          label="User Interaction Type"
          :items="embeddedImage.userInteraction"
        />

        <v-file-input
          hide-input
          :id="`embedded-image-${data.fieldsIndex}`"
          class="d-none"
          label="Upload Images(s)"
          prepend-icon="none"
          :accept="getAcceptedUploadFileTypes"
          multiple
          @change="embeddedImageSet"
        />
        <v-btn
          @click="
            embeddedImageClickFileInput(`embedded-image-${data.fieldsIndex}`)
          "
        >
          <span v-if="!data.imageRefs.length">Choose & Upload Images</span>
          <span v-else>Change Images</span>
        </v-btn>

        <v-sheet
          v-for="(image, index) in data.imageRefs"
          :key="index"
          class="my-1 elevation-2"
        >
          {{ image.name }}
        </v-sheet>

        <v-divider class="my-4 grey darken-3" />

        <v-btn color="white" @click="embeddedImageOpenDialog">
          Add Image Context
        </v-btn>

        <v-divider class="my-4 grey darken-3" />

        <v-dialog v-model="embeddedImage.dialog">
          <v-card>
            <v-card-title>
              <span class="headline">Image Context</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="2" />
                  <v-col cols="8">
                    <v-carousel
                      :show-arrows-on-hover="false"
                      hide-delimiters
                      height="100%"
                    >
                      <v-carousel-item
                        v-for="(image, i) in embeddedImage.images"
                        :key="i"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      >
                        <v-card
                          class="fill-height"
                          :dark="true"
                          justify="center"
                        >
                          <v-img height="400" :src="image.url" />
                          <v-card-title>
                            <v-text-field
                              label="Title"
                              v-model="embeddedImage.images[i].title"
                              :rules="[rules.required]"
                            />
                          </v-card-title>
                          <v-card-text>
                            <v-text-field
                              label="Description"
                              v-model="embeddedImage.images[i].description"
                            />
                            <v-divider class="mx-4" />
                          </v-card-text>
                        </v-card>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                  <v-col cols="2" />
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="red darken-1"
                outlined
                @click="embeddedImageCloseDialog('close')"
              >
                Close
              </v-btn>
              <v-spacer />
              <v-btn
                color="green"
                outlined
                @click="embeddedImageCloseDialog('save')"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-if="data.type === 'grouped-options'">
        <v-autocomplete v-model="data.groupingQuestion" :items="groupingQuestions" label="Question For Grouping"
                        item-text="questionListTitle" item-value="uid" />
        <v-select v-if="!!data.groupingQuestion" v-model="data.groupingVariant" :items="['text', 'select']" label="Grouped Options Variant" />

        <template v-if="groupingSelectedQuestion && ['single-select','state-select'].indexOf(groupingSelectedQuestion.type) > -1">
          <v-row v-for="(option, index) in groupingAnswerOptions" :key="index">
            <v-col cols="4" class="d-flex justify-center align-center">
              <v-chip :title="option">{{ option }}</v-chip>
            </v-col>
            <v-col cols="8">
              <v-text-field v-if="data.groupingVariant === 'text'" v-model="data.groupingOptions[index]" />
              <v-select v-if="data.groupingVariant === 'select'" v-model="data.groupingOptions[index]" :items="data.content" clearable />
            </v-col>
          </v-row>
        </template>
      </template>

      <template
        v-if="['single-select', 'multi-select', 'dialog-window'].indexOf(data.type) > -1 && Array.isArray(data.content) || (data.type === 'grouped-options' && data.groupingVariant === 'select')">
        <v-text-field
          v-model="data.optionToAdd"
          label="Add Selectable Options"
          @keydown="
            (e) => {
              if (e.keyCode === 13) {
                addOption(data);
              }
            }
          "
        >
          <template v-slot:append-outer>
            <v-btn text @click="addOption(data)">
              <v-icon>add_circle</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <v-chip-group column>
          <v-chip
            v-for="(chip, index) in data.content"
            :key="index"
            close
            @click:close="removeOption(data, index)"
            >{{ chip }}
          </v-chip>
        </v-chip-group>
        <DialogBuilder v-if="['single-select', 'multi-select', 'dialog-window'].indexOf(data.type) > -1 && Array.isArray(data.content)" :form-id="form.id" :field="data" />
      </template>
      <v-switch
        v-if="['computed-property', 'grouped-options', 'item-table'].indexOf(data.type) !== -1"
        v-model="data.visible"
        label="Option - Visible"
      />
      <v-text-field
        v-if="['text-input-short', 'text-input-long'].indexOf(data.type) > -1"
        v-model="data.default"
        label="Default Value"
        @blur="setDefault(data)"
      />
      <v-text-field
        v-if="data.type === 'number-input'"
        v-model="data.default"
        label="Default Value"
        type="number"
        @blur="setDefault(data)"
      />
      <v-checkbox
        v-if="data.type === 'number-input'"
        v-model="data.useCommas"
        label="Use Commas?"
      />
      <v-select
        v-if="data.type === 'single-select'"
        v-model="data.default"
        :items="['', ...data.content]"
        label="Default Value"
        @blur="setDefault(data)"
      />
      <v-select
        v-if="data.type === 'state-select'"
        v-model="data.default"
        :items="['', ...getStates]"
        label="Default Value"
        @blur="setDefault(data)"
      />
      <v-text-field
        v-if="data.type === 'text-input-short'"
        v-model="data.placeholder"
        label="Placeholder"
      />
      <v-select
        v-if="validators.length > 0"
        :items="validators"
        v-model="data.validator"
        label="Validator"
      />
      <help-icon-switcher
        v-if="['page-break', 'dialog-window'].indexOf(data.type) === -1"
        :value="data.helpData"
        @helpDataChanged="handleHelpData(data, $event)"
      />
      <v-switch
        v-if="
          data.hasOwnProperty('required') &&
          formElementOptions.required.indexOf(data.type) !== -1
        "
        label="Required"
        v-model="data.required"
      />
      <v-switch
        v-if="data.type === 'dialog-window' && data.hasOwnProperty('dialog')"
        label="Dialog Status"
        v-model="data.dialog"
        @change="dialogWindowToggle(data, $event)"
      />
      <v-select
        v-if="data.align"
        :items="['center', 'left', 'right']"
        v-model="data.align"
        label="User | Text Align"
      />
      <v-switch
        v-if="data.spaceAfter"
        label="Option - Add Vertical Space After Question"
        v-model="data.spaceAfter"
      />
      <v-img v-if="data.img" :src="data.img" />
      <template v-if="data.type === 'youtube-video'">
        <v-row>
          <v-col cols="6" />
          <v-col cols="6">
            <v-btn @click="checkYoutubeVideo">
              <v-icon>check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import validatorsMixin from "@/mixins/validators.mixin";
import { mapState, mapGetters, mapActions } from "vuex";
import TableRowEditForm from "./TableRowEditForm";
import itemTableMixin from "@/mixins/itemTable.mixin";
import datePickerMixin from "@/mixins/datePicker.mixin";
import DialogBuilder from "@/components/DialogBuilder";
import HelpIconSwitcher from "@/components/HelpIcon/HelpIconSwitcher";
import pdfMixin from "@/mixins/pdf.mixin";
import pdfToPng from "@/services/pdfToPng";
import { imgDataToFile } from "@/utils/file.util";

export default {
  name: "FormElement",

  components: {
    TableRowEditForm,
    DialogBuilder,
    HelpIconSwitcher,
  },

  mixins: [itemTableMixin, pdfMixin, datePickerMixin, validatorsMixin],

  props: {
    blockDrag: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    questionList: {
      type: Array,
      required: true,
    },
    isUnique: {
      type: Boolean,
      default: true,
    },
    bg: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    uniqueTitle: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      required: true,
    },
    validators: {
      type: Array,
      default: () => []
    },
    formFields: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      singleSelectVariants: ["radio", "drop-down", "slider"],
      multiSelectVariants: ["checkboxes", "drop-down"],
      headerVariantOptions: ["text", "select", "number", "money"],
      embeddedImageVariants: ["static-image", "image-carousel"],
      alignmentOptionsHorizontal: ["right", "left", "center"],
      datePickerMinDateDialog: false,
      datePickerMaxDateDialog: false,
      embeddedImage: {
        dialog: false,
        userInteraction: ["single-select", "multi-select", "none"],
        images: [],
      },
      rules: {
        required: (value) => !!value || "Required",
      },
      parseTitleDebounce: undefined,
    };
  },

  computed: {
    ...mapState({ form: "formBuilderActiveForm" }),

    ...mapGetters({
      formElementOptions: "getFormElementOptions",
      getAcceptedUploadFileTypes: "getAcceptedUploadFileTypes",
      getStates: 'getStates'
    }),

    groupingQuestions() {
      return this.questionList.filter(x => {
        // This determines which types of form elements can be used with grouped-options
        return ['single-select'].indexOf(x.type) > -1;
      });
    },

    groupingSelectedQuestion() {
      if (!this.data.groupingQuestion) {
        return null;
      }
      return this.questionList[this.questionList.findIndex(x => x.uid === this.data.groupingQuestion)];
    },

    groupingAnswerOptions() {
      if (this.data.groupingQuestion) {
        if (this.groupingSelectedQuestion.type === 'single-select') {
          if (['radio', 'drop-down', 'slider'].indexOf(this.groupingSelectedQuestion.variant) > -1) {
            return this.groupingSelectedQuestion.content ?? [];
          }
        }
      } else {
        return [];
      }
    }
  },

  methods: {
    ...mapActions({
      toggleOverlay: "toggleLoadingOverlayStatus",
      uploadEmbeddedImages: "workflow/uploadEmbeddedImages",
      updateField: "updateWorkflowField",
    }),

    ...mapActions("alert", ["setAlert"]),

    handleHelpData(field, helpData) {
      this.updateField({
        field: {
          uid: field.uid,
          title: field.title,
          type: field.type,
        },
        updates: {
          helpData: helpData,
        },
      });
    },

    checkForUniqTitle(data) {
      this.parseTitle(data);
      this.$emit("uniqueCheck");
    },

    parseTitle(data) {
      // Use to capitalize & remove spaces from title
      if (this.parseTitleDebounce) {
        clearTimeout(this.parseTitleDebounce);
      }

      this.parseTitleDebounce = setTimeout(() => {
        let parsedTitle = data.title.replace(/\s/g, "-");
        parsedTitle = parsedTitle.toUpperCase();
        data.title = parsedTitle;
        this.parseTitleDebounce = undefined;
      }, 500);
    },

    moveFormElement(moveSelection) {
      this.$emit("move", moveSelection);
    },

    duplicateFormElement() {
      this.$emit("duplicate");
    },

    removeFormElement() {
      this.$emit("remove");
    },

    addOption(field) {
      field.content.push(field.optionToAdd);
      field.optionToAdd = "";
    },

    addHeaderOption(header) {
      if (header.options === undefined) {
        header.options = [];
      }
      header.options.push(header.optionToAdd);
      header.optionToAdd = "";
    },

    removeOption(field, index) {
      field.content.splice(index, 1);
    },

    removeHeaderOption(header, index) {
      header.optionToAdd = null;
      header.options.splice(index, 1);
      header.options = [...header.options];
      header.optionToAdd = "";
    },

    addHeader(field) {
      let hasError = false;
      let errorMsg = "";
      if (field.headerToAdd.header === "" || field.headerToAdd.align === null) {
        errorMsg = "Please enter a header and alignment for this new column";
        hasError = true;
      } else if (
        field.headerToAdd.variant === "select" &&
        field.headerToAdd.options.length === 0
      ) {
        errorMsg = "Please enter some options for this dropdown";
        hasError = true;
      }

      if (hasError) {
        this.setAlert({
          type: 'error',
          message: errorMsg
        })

        return;
      }

      let newHeader = JSON.parse(JSON.stringify(field.headerToAdd));
      const actionHeader = field.headers.find(h => h.text === 'Actions')
      const newHeaders = [...field.headers.filter(h => h.text !== 'Actions')]
      newHeaders.push(newHeader)
      newHeaders.push(actionHeader)
      field.headers = newHeaders
      field.headerToAdd = {
        variant: "text",
        text: "",
        align: 'center',
        sortable: false,
        divider: true,
        writeable: field.variant !== "read-only",
        value: "",
      };
    },

    removeHeader(field, index) {
      field.headers.splice(index, 1);
    },

    // YouTube-Video Methods

    checkYoutubeVideo() {
      this.$emit("videoCheck");
    },

    // Dialog-Window Methods

    dialogWindowToggle(field, event) {
      field.dialog = event;
      this.$store.dispatch("displayLogic/updateFormElementsDisplayStatus", {
        formElementsToUpdate: [field.uid],
      });
    },

    // Embedded-Image Methods

    embeddedImageOpenDialog() {
      this.embeddedImage.images = [];

      const imageRefsLength = this.data.imageRefs.length;
      for (let i = 0; i < imageRefsLength; i++) {
        this.embeddedImage.images.splice(
          i,
          0,
          Object.assign({}, this.data.imageRefs[i])
        );
      }
      this.embeddedImage.dialog = true;
    },

    embeddedImageCloseDialog(directive) {
      if (directive === "save") {
        const payload = {
          field: {
            type: this.data.type,
            title: this.data.title,
          },
          updates: {
            imageRefs: this.embeddedImage.images,
          },
        };
        this.$store
          .dispatch("updateFormBuilderActiveFormField", payload)
          .then(() => {
            // Clear dialog data and close it
            this.embeddedImage.dialog = false;
            this.embeddedImage.images = [];
          });
      } else if (directive === "close") {
        // Clear dialog data and close it
        this.embeddedImage.dialog = false;
        this.embeddedImage.images = [];
      } else {
        // Clear dialog data and close it
        this.embeddedImage.dialog = false;
        this.embeddedImage.images = [];
      }
    },

    embeddedImageClickFileInput(id) {
      document.getElementById(id).click();
    },

    async embeddedImageSet(images) {
      let notAllowed = false
      images.forEach(img => {
        const ext = '.' + img.name.split('.')[1]
        if(this.getAcceptedUploadFileTypes.indexOf(ext) < 0)
          notAllowed = true
      })

      if(notAllowed) {
        this.setAlert({
          type: 'info',
          message: "Please only upload accepted files"
        })
        
        return
      }

      this.toggleOverlay(true);

      for (let i = 0; i < images.length; i++) {
        let file = images[i];
        if (file.type === "application/pdf") {
          const response = await pdfToPng.convert(file);
          images[i] = imgDataToFile(
            response.data,
            file.name.replace(".pdf", ".png")
          );
        }
      }

      const payload = {
        field: this.data,
        images: images,
      };

      this.uploadEmbeddedImages(payload).then((imageRefs) => {
        this.toggleOverlay(false);
        this.embeddedImageOpenDialog();
      });
    },
  },

  mounted() {
    this.fields = this.form.fields;
  },
};
</script>

<style lang="scss">
.v-chip {
  position: relative;

  .v-chip__content {
    display: inline-block;
    height: 100%;
    max-width: 400px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 5px;
    margin-right: 20px;

    button {
      position: absolute;
      right: 10px;
      z-index: 99;
      top: 7px;
    }
  }
}

  .blockDrag {
    cursor: no-drop !important;
  }
</style>
