/* eslint-disable camelcase */
import axios from 'axios';
import { getProject } from '../gcloud/getProject';
import getSecret from '../get-secret.service'

export default function payPalService(projectUrl = getProject(), httpRequest = axios) {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };

  const mode = projectUrl.indexOf('-simple-ending.') > 0 ? 'live' : 'sandbox';

  return {
    getClientId: async() => {
      const { client_id } = await getSecret('PAY_PAL')
      return client_id
    },
    
    requestPayment: async (amount, level=1, redirect = '/paypal/success', cancel = '/paypal/cancel') => {
      const url = `${projectUrl}/paypalRequest`;
      const body = {
        base_url: location.protocol + "//" + location.host,
        redirect,
        cancel,
        desc: 'Paypal payment from client',
        amount,
        mode,
        level
      };

      const dataStr = JSON.stringify(body);

      return httpRequest({
        url: url,
        method: 'POST',
        headers: config,
        data: dataStr
      })
    },

    success: (payerId, paymentId, amount) => {
      const url = `${projectUrl}/paypalSuccess`;
      const body = {
        mode,
        payerId,
        paymentId,
        amount
      }

      const dataStr = JSON.stringify(body);

      return httpRequest({
        url: url,
        method: 'POST',
        headers: config,
        data: dataStr
      })
    },

    validToken: async (token) => {
      const url = `${projectUrl}/paypalTokenValidator?token=${token}`
      return await httpRequest({
        url,
        method: 'GET',
        headers: config
      })
    },

    log: (userId, formId, token, level, amount, couponCode, transactionId) => {
      const url = `${projectUrl}/logPaypalToken`

      const body = {
        userId,
        formId,
        token,
        level,
        amount,
        couponCode,
        transactionId
      }

      const dataStr = JSON.stringify(body);
      return httpRequest({
        url,
        method: 'POST',
        headers: config,
        data: dataStr
      })
    }
  };
};
