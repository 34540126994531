export default {
  computed: {
    groupOptions() {
      return (field, formFields) => {
        if(!field || !formFields) return
        let returnValue = "";
        try {
          const groupedFormElement = formFields.find(x => x?.uid === field.groupingQuestion);
          let optionIndex;
          if(!groupedFormElement) {
            return returnValue
          } else if (groupedFormElement.type === 'single-select' && groupedFormElement.variant === 'slider') {
            optionIndex = groupedFormElement.selection;
          } else {
            optionIndex = groupedFormElement.content.findIndex(x => x === groupedFormElement.selection);
          }

          returnValue =  field.groupingOptions[optionIndex];
        } catch (e) {
          throw e
        }

        field.selection = returnValue;
        return returnValue;
      };
    },
  }
}