<template>
  <p>
    If you have an questions or concerns, please contact us by clicking <a href="https://simpleending.com/contact-us" target="_blank">here</a>.
  </p>  
</template>

<script>
export default {
  name: 'ContactSupport'
}
</script>