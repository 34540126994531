export const PRICING_CARD_DROPDOWN_OPTIONS = {
  WITH_SPOUSE_GENERAL: 'Collaborative Overview',
  WITH_SPOUSE_LEVEL_ONE: 'Collaborative Paywall One',
  WITH_SPOUSE_LEVEL_TWO: 'Collaborative Paywall Two',
  WITHOUT_SPOUSE_GENERAL: 'Unilateral General',
  WITHOUT_SPOUSE_LEVEL_ONE: 'Unilateral Paywall One',
  WITHOUT_SPOUSE_LEVEL_TWO: 'Unilateral Paywall Two',
  WITH_SPOUSE_GENERAL_PREVIEW: 'Collaborative Overview Preview',
  WITH_SPOUSE_LEVEL_ONE_PREVIEW: 'Collaborative Paywall One Preview',
  WITH_SPOUSE_LEVEL_TWO_PREVIEW: 'Collaborative Paywall Two Preview',
  WITHOUT_SPOUSE_GENERAL_PREVIEW: 'Unilateral General Preview',
  WITHOUT_SPOUSE_LEVEL_ONE_PREVIEW: 'Unilateral Paywall One Preview',
  WITHOUT_SPOUSE_LEVEL_TWO_PREVIEW: 'Unilateral Paywall Two Preview',
}
