const getQuestionConditionMet = async ({dispatch}, block) => {
  if (block.condition === 'always') {
    return true;
  } else if (block.condition === 'if') {
    switch (block.operator) {
      case 'and':

        let ruleCriteria_and = true; // Default to rule being true, set to false if any question criteria isn't met

        for (let i = 0; i < block.questions.length; i++) {
          const question = block.questions[i]

          if(block.tag === '[*NEITHER_ACTIVE_MIL*|  |**]')
            question.tag = block.tag

          let questionCriteria = await dispatch('checkQuestionForMatch', question);

          if (!questionCriteria) {
            ruleCriteria_and = false;
          }
        }

        return ruleCriteria_and;

      case 'or':
        let ruleCriteria_or = false; // Default to rule being false, set to true if any question criteria is met

        for (let i = 0; i < block.questions.length; i++) {
          const question = block.questions[i]
          let questionCriteria = await dispatch('checkQuestionForMatch', question);

          if (questionCriteria) {
            ruleCriteria_or = true;
          }
        }

        return ruleCriteria_or;

      case 'not':
        let ruleCriteria_not = true; // Default to rule being true, set to false if any question criteria is met

        for (let i = 0; i < block.questions.length; i++) {
          const question = block.questions[i]
          let questionCriteria = await dispatch('checkQuestionForMatch', question);

          if (questionCriteria) {
            ruleCriteria_not = false;
          }
        }

        return ruleCriteria_not;

      case 'x-or':
        let num_question_criteria_met = 0; // Holds number of question criteria that has been met

        for (let i = 0; i < block.questions.length; i++) {
          const question = block.questions[i]
          let questionCriteria = await dispatch('checkQuestionForMatch', question);

          if (questionCriteria) {
            num_question_criteria_met++;
          }
        }

        return num_question_criteria_met === 1;
    }
  } else {
    return false;
  }
}

module.exports = getQuestionConditionMet