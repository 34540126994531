const petitionerSteps = [
    '',
    'Fill Out Questionnaire',
    'Share Questionnaire',
    'Review Questionnaire & Comments',
    'Pay For Completed Documents',
    'Review',
    'Spousal Review & E-Signature',
    'Completed Documents'
]

module.exports = petitionerSteps