const defaultState = () => {
    return {
        errorMsg: ''
    }
}

export const teap = {
    namespaced: true,

    state: defaultState(),

    getters: {
        errorMsg: state => state.errorMsg
    },

    mutations: {
        SET_ERROR_MSG(state, msg) {
            state.errorMsg = msg
        }
    },

    actions: {
        setErrorMsg({ commit, state }, msg) {
            if(state.errorMsg.length > 0) {
                commit('SET_ERROR_MSG', '')
            }

            setTimeout(() => {
                commit('SET_ERROR_MSG', msg)
            }, 500)
        }
    }
}