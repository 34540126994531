import Quill from 'quill'
import VuePlainClipboard from 'vue-quill-plain-clipboard'
import {quillEditor} from "vue-quill-editor";
import {QUILL_MODULES_COMMENTS} from "@/constants/optionsConstants";
import {mapActions, mapGetters} from "vuex";
import {EventBus} from "@/plugins/eventBus";
import commentsMixin from "@/mixins/comments.mixin"

export default {
  mixins: [commentsMixin],
  
  components: {
    quillEditor
  },

  props: {
    pageNames: Array,
    disableComments: {
      type: Boolean,
      default: false
    },
    currentTab: Number,
    commentButton: {},
    isOwner: Boolean,
    commentOwnerName: String,
    currentStep: [String, Number],
    showDefault: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      characterLimit: 1000,
      limitExceded: false,
      loading: false,
      panel: 0,
      drawer: false,
      snackbar: false,
      msgText: '',
      msgColor: 'success',
      comments: [],
      tab: null,
      editorOption: {
        modules: QUILL_MODULES_COMMENTS,
        placeholder: 'Please enter comments or questions in this textbox in order to communicate with your spouse'
      },
    }
  },

  mounted() {
    Quill.register('modules/clipboard', VuePlainClipboard, true)

    this.$root.$on('finalSaveAction', () => {
      this.saveProgress()
    })
  },

  watch: {
    limitExceded(val) {
      this.$emit('limit-exceeded', val);
    },
    tab(val) {
      let formated = val.split('tab-');
      this.$emit('change-tab', formated[1] -1);
    },
    currentTab(val) {
      this.markAsRead()
      this.tab = `tab-${val + 1}`;
    },
    comments: {
      handler(val) {
        if(val.length === 0) {
          this.setupComments();
          EventBus.$emit('set-comments', this.comments);
        } else {
          EventBus.$emit('set-comments', val);
        }
        this.limitExceded = false;
        val.forEach(item => {
          if(this.charachersUsed(item.comment) > this.characterLimit) {
            this.limitExceded = true;
          }
        });
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("workflowPublished", {
      formData: "getFormData",
    }),

    ...mapGetters("comments", {
      results: "spouseComments",
      unreadCommentsByPage: "unreadCommentsByPage"
    })
  },

  async beforeMount() {
    EventBus.$once('save-comments-progress', () => { this.saveProgress() });
    if(this.showDefault) this.drawer = true;
    await this.getComments();
    this.setupComments();
  },

  methods: {
    ...mapActions('workflowPublished', {
      replyComments: 'saveSpouseReply',
    }),

    ...mapActions('comments', {
      saveComments: 'saveSpouseComments',
      updateSpouseComments: 'updateSpouseComments',
      getComments: 'getSpouseComments'
    }),

    updateComments() {
      this.limitExceded = '';
      this.limitExceded = false;
      this.comments.forEach(item => {
        if(this.charachersUsed(item.comment) > this.characterLimit) {
          this.limitExceded = true;
        }
      });
    },

    onEditorFocus(e) {
      addEventListener("paste", (event) => {
        e.insertText(0, ' ', true);
      });
    },

    charachersUsed(string) {
      if(string) return string.replace(/<[^>]*>?/gm, '').length;
      return 0;
    },

    setupComments() {
      for(let i = 0; i < this.pageNames.length; i++) {
        this.comments[i] = {
          page: this.pageNames[i],
          comment: this.results?.[0]?.comments[i]?.comment || '',
          hasRead: true,
        };
      }
    },

    async scrollToFirstUnread(index) {
      if(this.showUnreadCommentWarning(index)) {
        setTimeout(() => {
          document.querySelector('.comments-frame .v-navigation-drawer__content').scrollTo({ top: 350 })
        }, 1000)
      }
    },

    async saveProgress() {
      if(this.comments[this.currentTab]?.comment.length === 0) return
      if(document.querySelector('#please-wait')) return

      this.loading = true;
      
      this.comments.forEach(c => {
        if(c.comment.length > 0) {
          c.hasRead = false
        }
      })
      
      const response = await this.saveComments({
        comments: this.comments,
        commentId: this.results.length > 0 ? this.results[0].commentId : 'c' + new Date().valueOf(),
        commentOwner: this.isOwner ? 'petitioner' : 'respondent',
        commentOwnerName: this.isOwner ? this.formData.owner.name : this.formData.sharingProps[0].user.name,
      });
      
      this.snackbar = true;
      if(response === 'success') {
        this.msgColor = 'success';
        this.msgText = 'Progress saved!';
      } else if(response === 'error') {
        this.msgColor = 'error';
        this.msgText = 'Something went wrong!';
      }
      this.loading = false;
      this.getComments();
    },
  }
}