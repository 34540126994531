import { render, staticRenderFns } from "./Practice.vue?vue&type=template&id=70fd419a"
import script from "./Practice.vue?vue&type=script&lang=js"
export * from "./Practice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports