<template>
  <v-app :class="{'app-loading': loadingOverlayStatus}">
    <v-navigation-drawer
        v-model="drawer"
        app
        absolute
        temporary
        right
        class="nav-drawer-fixed"
    >
      <v-list>
        <v-list-item @click="$router.push('/admin')">
          <v-list-item-icon>
            <v-icon>mdi-lock-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="isAuthenticated && isAdmin">
            <v-list-item-title class="text-left">
              Admin Functions
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-for="item in navItems"
            :key="item.title"
            link
            @click="$router.push({ name: item.name }).catch(() => {})"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-left">{{
                item.title
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="signOutUser">
          <v-list-item-icon>
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">
              Sign Out
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app fixed class="z-index-5">
      <v-toolbar-title
          @click="$router.push({ name: 'home' }).catch(() => {})"
          class="d-flex align-items-center"
      >
        <v-img
          class="d-sm-inline-block d-md-none cursor-pointer"
          max-height="40"
          max-width="120"
          contain
          src="./assets/simple_ending_logo_icon_horizontal.svg"
        >
        </v-img>
        <v-img
          class="d-md-inline-block d-none cursor-pointer"
          max-width="200"
          width="200"
          contain
          src="./assets/simple_ending_logo_icon_horizontal.svg"
        >
        </v-img>
      </v-toolbar-title>
      <div class="flex-grow-1">
        <div class="page-title">{{ title }}</div>
      </div>
      <v-toolbar-items class="header-toolbars">
        <v-btn
            v-if="isAuthenticated && !ignoreAuth"
            class="header-back-btn"
            text
            @click="backClick"
            title="Go Back"
        >
          <v-icon color="accent">mdi-arrow-left</v-icon>
        </v-btn>

        <v-btn v-if="isAuthenticated && !ignoreAuth" text @click="signOutUser">
          <span class="mr-1 d-sm-inline-block">Sign Out</span>
        </v-btn>

        <v-btn v-else text @click="navigateSignInUp">
          <span> {{ currentRouteName == 'sign-in' ? 'Create Account' : 'Sign In' }} </span>
        </v-btn>

      </v-toolbar-items>
      <v-app-bar-nav-icon v-if="showHamburger" @click.stop="drawer = !drawer"/>
      <v-btn class="header-profile-name" v-if="isAuthenticated && !ignoreAuth" text @click="$router.push({ name: 'user-profile' })">
        <div>{{ user.full_name || 'User Profile' }}</div>
      </v-btn>
    </v-app-bar>

    <v-main class="main-app">
      <Alert />
      <Confirmation v-if="showConfirmation" />
      <router-view/>
    </v-main>

    <v-footer
        dark
        class="main-footer d-flex justify-space-between align-center white--text px-10 py-5"
        color="#156573"
        elevation="6"
    >
      <div>&copy;{{ new Date().getFullYear() }} - <strong>SimpleEnding&reg;</strong></div>
      <div>
        <a class="footer-link me-3" href="https://knottilnot.com/" target="_blank">
          <img src="@/assets/images/knot_til_not.png" alt="knot til not" style="width: 20px; margin-bottom: -8px; margin-right: 5px"/>
          Need Legal Advice?
        </a>
        <a class="footer-link me-3" href="https://simpleending.com/contact-us" target="_blank">
          Contact Us
        </a>
        <a class="footer-link me-3" href="https://simpleending.com/terms-of-service" target="_blank">
          Terms of Service
        </a>
        <a class="footer-link" href="https://simpleending.com/privacy-policy" target="_blank">
          Privacy Policy
        </a>
      </div>

    </v-footer>

    <LoadingOverlay
      id="loading-overlay"
      :show-overlay="loadingOverlayStatus"
      :text="overlayMessage"
    />

    <v-dialog
        v-model="idleCounterDialog"
        persistent
        width="500"
    >
      <v-card>
        <v-toolbar
            color="primary"
            dark
        >
          Session Activity
        </v-toolbar>

        <v-card-text class="pt-5">
          Your session will expire in <b>60 seconds</b>. To stay logged in, please click the 'Continue' button below. Otherwise, you will be automatically logged out
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
              color="primary"
              text
              @click="logoutAction"
          >
            Logout
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="continueWork"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import LoadingOverlay from "@/components/LoadingOverlay";
import inactiveTimeout from "./services/inactive-timeout.service";
import Alert from "./components/Alerts/Alert";
import Confirmation from "./components/Alerts/Confirmation";
import { install, gtag } from 'ga-gtag';
import axios from 'axios'

export default {
  name: "App",

  data() {
    return {
      drawer: false,
      mainForm: [],
      idleCounter: 0,
      idleCounterDialog: false,
      timerCount: 60,
      counterReset: null,
      idleTime: 0
    };
  },

  components: {
    LoadingOverlay,
    Alert,
    Confirmation
  },

  computed: {
    ...mapState(["navItems", "navItemsAdmin", "loadingOverlayStatus"]),

    ...mapGetters("profile", {
      user: "userInfo",
      isAdmin: "isAdmin",
    }),

    ...mapGetters("confirmation", {
      "showConfirmation": "show"
    }),

    ...mapGetters(["getFirebaseConfig", "title", "overlayMessage"]),

    isAuthenticated() {
      return this?.getFirebaseConfig?.auth?.currentUser?.emailVerified || false
    },

    showHamburger() {
      return this.isAuthenticated && this.isAdmin && !this.ignoreAuth
    },

    currentRouteName() {
      return this.$route.name;
    },

    ignoreAuth() {
      return ['sign-in', 'verify-user', 'create-user'].includes(this.$route.name)
    },

    time: function () {
      return this.date.format('mm:ss');
    }
  },

  async created() {
    this.installAnalyics()
  },

  async mounted() {
    this.setBaseUrl(window.location.origin);
    const timeoutData = await inactiveTimeout(this.getFirebaseConfig.db)
    this.idleTime = timeoutData.timeout * 60000
    addEventListener("mousemove", () => {
      window.clearTimeout(this.idleCounter)
      this.idleCounter = window.setTimeout(() => {
        this.idleCounter = 0
        if (this.user.first_name) {
          this.idleCounterDialog = true
          this.counterReset = setInterval(() => {
            if (this.timerCount > 0) {
              this.timerCount--
            } else if (this.timerCount === 0) {
              this.idleCounterDialog = false
              this.userSignOut({router: this.$router});
            }
          }, 1000);
        }
      }, this.idleTime)
    })
  },

  methods: {
    ...mapActions(["setBaseUrl", "toggleLoadingOverlayStatus"]),

    ...mapActions("profile", ["establishUserAuth", "userSignOut"]),

    async installAnalyics() {
      const { VUE_APP_REGION, VUE_APP_PROJECT } = process.env 
      const secretBaseUrl = `https://${VUE_APP_REGION}-${VUE_APP_PROJECT}.cloudfunctions.net/getSecret`
      const analyticsData = (await axios.get(`${secretBaseUrl}?key=google-analytics-id`)).data
      install(analyticsData.gaID, { send_page_view: false })
      gtag('config', analyticsData.awID)
      this.$store.dispatch('analytics/init', analyticsData)
    },
    backClick() {
      history.back();
    },
    logoutAction() {
      this.userSignOut({router: this.$router});
      window.clearTimeout(this.idleCounter)
      this.idleCounterDialog = false
      this.idleCounter = 0
    },
    continueWork() {
      window.clearTimeout(this.idleCounter)
      window.clearInterval(this.counterReset)
      this.idleCounterDialog = false
      this.idleCounter = 0
      this.timerCount = 60
    },

    signOutUser() {
      this.toggleLoadingOverlayStatus(true);
      this.userSignOut({ redirect: false });
      this.toggleLoadingOverlayStatus(false);
    },

    navigateSignInUp() {
      const name = this.currentRouteName === 'sign-in' ? 'create-user' : 'sign-in';
      this.$router.push({name}).catch(() => {
      });
    }
  },
};
</script>

<style lang="scss">
.app-loading {
  .v-application--wrap {
    pointer-events: none;
  }
}

.main-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.footer-link {
  color: $white !important;
  text-decoration: none;
  font-size: 13px;

  &:hover {
    text-decoration: underline;
  }
}

@media(max-width: 700px) {
  .page-title, .header-profile-name {
    display: none;
  }
}
</style>
