import { mapGetters, mapActions } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import { setCookie, eraseCookie } from "@/utils/cookie.util";
import ProfileForm from "@/components/ProfileForm.vue";
import rulesMixin from "@/mixins/rules.mixin";
import userExists from "@/services/user-exists.service"
import { storeWorkflowId, getWorkflowId } from "@/utils/workflow.localstorage.util"
import dayjs from 'dayjs'
import analyticsMixin from '@/mixins/analytics.mixin';
import addMailChimpMember from '@/services/add-mailchimp-member.service'

export default {
  components: {
    VueRecaptcha,
    ProfileForm,
  },

  mixins: [rulesMixin,analyticsMixin],

  data() {
    return {
      createProfileValidity: false,
      findingPetitioner: false,
      petitioner_found: true,
      show1: false,
      acceptTermsOfService: false,
      verified: false,
      fullName: "",
      userFormValidity: false,
      loading: false,
      userProfile: {
        is_petitioner: true,
        respondent_data: {
          petitioner_email: ''
        },
        petitioner_data: {
          respondent_email: '',
          respondent_first_name: '',
          respondent_last_name: '',
        },
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        full_name: "",
        address_street_1: "",
        address_street_2: "",
        address_city: "",
        address_state: "",
        address_zip_code: "",
        phone_number: "",
        permission_to_contact: null,
        role: "user-basic",
      },
    };
  },

  watch: {
    userProfile: {
      handler(profile) {
        this.userProfile.email = profile?.email?.toLowerCase() || '';
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("profile", { user: "userInfo" }),

    ...mapGetters(["getStates", "getAcceptedUploadFileTypes"]),

    ...mapGetters("alert", ["showAlert"]),

    isFormValid() {
      return this.userFormValidity && this.createProfileValidity
    },

    disableSubmitButton() {
      return !this.verified || !this.isFormValid || !this.acceptTermsOfService || this.spouseInfoFinished || this.showAlert
    },

    spouseInfoFinished() {
      if(this.userProfile.is_petitioner) {
        const pd = this.userProfile.petitioner_data
        return pd.respondent_email === '' || pd.respondent_first_name === '' || pd.respondent_last_name === ''
      } else {
        return this.petitioner_found === false || this.userProfile.respondent_data.petitioner_email === ''
      }
    },

    getFullName() {
      return this.userProfile.first_name + " " + this.userProfile.last_name;
    },
  },

  async created() {
    if(this.$route?.query?.email && await userExists(decodeURIComponent(this.$route.query.email))) {
      void this.$router.push({ path: '/sign-in', query: { email: this.$route.query.email }})
    }
  },

  mounted() {
    if(this.$route.query?.wfid) {
      storeWorkflowId('wfid', this.$route.query.wfid)
    }

    setTimeout(() => {
      for (const property in this.user) {
        if (this.userProfile?.[property]) {
          this.userProfile[property] = this.user[property];
        }
      }

      if(this.$route.query?.petitioner) {
        this.petitioner_found = true;
        this.userProfile.is_petitioner = false;
        this.userProfile.respondent_data.petitioner_email = this.$route.query.petitioner
        this.userProfile.email = this.$route.query.respondent
      }

      if(Object.keys(this.$route.query).filter(key => key.indexOf('data_') === 0).length) {
        this.userProfile.email = this.$route.query.data_2;
        this.userProfile.first_name =  this.$route.query.data_3;
        this.userProfile.last_name =  this.$route.query.data_4;
      }
      if(this.$route.query?.email) {
        this.userProfile.email = this.$route.query.email
      }
      if(this.$route.query?.name) {
        this.userProfile.first_name = this.$route.query.name
      }
    }, 2000)
  },

  methods: {
    ...mapActions(["toggleLoadingOverlayStatus", "getFirebaseConfig"]),

    ...mapActions("profile", ["userCreateNew", "userSignOut"]),

    ...mapActions("alert", ["setAlert"]),

    emailMatch(value) {
      if(this.userProfile.is_petitioner === true && value === this.userProfile.petitioner_data.respondent_email) {
        return "Sorry, your email cannot match your spouse's"
      } else if(value === this.userProfile.respondent_data.petitioner_email) {
        return "Sorry, your email cannot match your spouse's"
      }
      return true
    },

    resetRespondentData() {
      this.userProfile.petitioner_data.respondent_email = ''
      this.userProfile.petitioner_data.respondent_first_name = ''
      this.userProfile.petitioner_data.respondent_last_name = ''
    },

    isPetitionerChange(val) {
      this.findingPetitioner = false
      this.userProfile.respondent_data.petitioner_email = ''
      this.resetRespondentData()
    },

    respondentEmailCheck(email) {
      this.resetAlertMessage()
      if(!email) return
      this.findingPetitioner = true
      this.petitioner_found = false
      userExists(email.toLowerCase()).then(status => {
        this.petitioner_found = status
        if(status === true) {
          this.setAlert({
            type: 'info',
            message: 'Looks like your spouse has already started the process.'
          })
          this.userProfile.is_petitioner = false
          this.findingPetitioner = false
          this.userProfile.email = this.userProfile.email.toLowerCase()
          this.userProfile.respondent_data.petitioner_email = email.toLowerCase()
          this.resetRespondentData()
        }
        this.findingPetitioner = false
      })
    },

    petitionerEmailCheck(email) {
      this.resetAlertMessage()
      if(!email) return
      this.userProfile.respondent_data.petitioner_email = email.toLowerCase()
      this.findingPetitioner = true
      this.petitioner_found = false
      userExists(email.toLowerCase()).then(status => {
        this.petitioner_found = status
        if(status === false) {
          this.setAlert({
            type: 'warning',
            message: "Sorry, could not find your spouse's email in our records. Please try again."
          })
        }
        this.findingPetitioner = false
      })
    },

    resetAlertMessage() {
      this.setAlert({
        show: false,
        type: '',
        message: ''
      })
    },

    openModal() {
      this.modal = true;
    },

    toggleModal(value) {
      this.modal = value;
    },

    accept(value, createCookie = setCookie, removeCookie = eraseCookie) {
      if (value) {
        return createCookie("terms-of-service", value, 7);
      }

      return removeCookie("terms-of-service");
    },

    async createProfile() {
      this.loading = true
      await this.toggleLoadingOverlayStatus(true);
      this.userProfile.full_name = this.userProfile.first_name + " " + this.userProfile.last_name;
      this.userProfile.email = this.userProfile.email.toLowerCase()
      if(this.userProfile.is_petitioner === false && getWorkflowId('wfid')) {
        this.userProfile.respondent_data['wfid'] = getWorkflowId('wfid')
      }

      this.userProfile['created_at'] = dayjs().toString()
      this.userProfile['last_sign_in'] = dayjs().toString()
      await this.userCreateNew({ userData: this.userProfile })
      localStorage.removeItem('wfid')
      if(this.userProfile.permission_to_contact) {
        await addMailChimpMember(this.userProfile.email)
      }
      await Promise.all([
        this.toggleLoadingOverlayStatus(false),
        this.userSignOut({ router: this.$router, redirect: true, type: 'success', msg: 'Please check your email and verify your account before you can login.' })
      ])
      return
    },

    clearForm() {
      this.userProfile = {
        is_petitioner: true,
        respondent_data: {
          petitioner_email: ''
        },
        petitioner_data: {
          respondent_email: '',
          respondent_first_name: '',
          respondent_last_name: '',
        },
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        full_name: "",
        gender: "",
        date_of_birth: "",
        address_street_1: "",
        address_street_2: "",
        address_city: "",
        address_state: "",
        address_zip_code: "",
        phone_number: "",
        permission_to_contact: null,
        role: "user-basic",
      };
    },
  },
}