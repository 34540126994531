import TeapAlert from "@/components/Alerts/TeapAlert";
import WorkflowGuide from "@/components/WorkflowGuide";
import {mapActions, mapGetters} from "vuex";
import SignaturePad from "@/components/SignaturePad";
import {EventBus} from "@/plugins/eventBus";
import dayjs from 'dayjs'
import ShareFormDialog from "@/components/ShareFormDialog";
import FileRenderer from "@/components/FileRenderer";
import titleMixin from "@/mixins/pageTitle.mixin";
import signaturePadMixin from "@/mixins/signature.pad.mixin";
import displayLogicMixin from "@/mixins/displayLogic.mixin";
import partnerMixin from '@/mixins/partner.mixin';
import GenerateDoc from "@/components/GenerateDoc";
import pdfGenerator from '@/services/pdfGenerator'
import {download_file} from "@/utils/downloadFile.util";
import sendEmailUtil from "@/utils/send-email.util"
import { getDateFormat } from "@/utils/getDateFormat.util"
import analyticsMixin from "@/mixins/analytics.mixin";

export default {
  components: {
    ShareFormDialog,
    SignaturePad,
    WorkflowGuide,
    FileRenderer,
    GenerateDoc,
    TeapAlert
  },

  mixins: [
    analyticsMixin,
    partnerMixin,
    titleMixin,
    displayLogicMixin,
    signaturePadMixin
  ],

  data() {
    return {
      disableComments: false,
      updating: false,
      agreeLoading: false,
      reviewLoading: false,
      dialog: false,
      characterLimit: false,
      checkbox: false,
      submitResponseDialog: null,
      spousalEmailChecker: null,
      spousalEmailCount: 0,
      btnLoading: false,
      answers: {},
      comments: [],
      loading: false,
      step: 1,
      e6: 1,
      form: null,
      spouseEsign: {
        spouse: true,
        signature: {
          signed: false
        }
      },
      warningIssues: {},
      sharing: {
        isNew: true,
        access: true,
        reviewType: "",
        user: {
          confirmation: {},
          email: "",
          name: "",
          firstAccessed: null,
          lastAccessed: null,
        },
      },
      partner: {
        firstName: '',
        lastName: '',
        email: ''
      },
      paymentLevel: 1,
      disableButtonToProceed: false,
    }
  },

  watch: {
    formData: {
      handler: async function (newVal, oldVal) {
        if (this.updating || !newVal) return

        if (newVal?.docs && newVal?.fields) {
          this.updating = true
          await this.buildDisplayLogic()
          this.update = false
        }

        this.setTitle('Shared Survey - ' + newVal.owner.name)
        this.step = newVal?.respondentStep || 1
        if (this.step === 2) {
          const docIndex = newVal.docs.findIndex(d => d.name.toLowerCase().indexOf('stipulation') >= 0)
          this.setActiveDocIndex(newVal.docs[docIndex].uid).then(() => {
            this.generateDocument(false).then(() => {
              this.toggleLoadingOverlayStatus(true)
              this.setOverlayMessage('Loading. Please wait.')
              this.generatePdfFile({processedFile: true, docIndex}).then(() => {
                this.toggleLoadingOverlayStatus(false)
                this.setOverlayMessage()
              }).catch(error => {
                this.setAlert({ type: 'error', message: error.message })
              })
            })
          })
        } else if (this.step === 3 && !this.formData.finalPDF) {
          await this.generateDoc()
        } else {
          await this.toggleLoadingOverlayStatus(false)
        }

        this.setPartnerContactInfo(newVal)
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters(["baseUrl", "getFirebaseConfig"]),

    ...mapGetters("profile", [
      "userInfo",
      "isAdmin",
      "formHasPaymentForUser",
      "myStates",
    ]),

    ...mapGetters("workflowPublished", [
      "getActiveDocIndex",
      "isFormSaved",
      "sharingData",
      "isOwner",
      "requiredFields",
      "petitionerStep"
    ]),

    ...mapGetters('displayLogic', ['visiblyRequired']),

    ...mapGetters("workflowPublished", {
      formData: "getFormData",
      processedFile: "getProcessedFile",
      forms: "publishedWorkflows",
    }),    

    petitionerHasSigned() {
      return this.formData?.esign?.signature?.signed
    },

    visibleRequiredFields() {
      return this.visiblyRequired(this.requiredFields).filter(e => {
        return e.answeredStatus === false && e.required === true
      })
    },

    commentsClasses() {
      return !sharedUserProps?.commentsDisabled ?
          'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default green' :
          'disable-events'
    },

    displayBtn() {
      let display = true
      if (this.step === 4) {
        display = Object.keys(warningIssues).length === 0 && !sharedUserProps.commentsDisabled
      }

      return display ?
          'v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default green' :
          'disable-events'
    },

    formLoaded() {
      return !!this.formData;
    },

    reviewType() {
      if (!this.sharedUserProps) return null;
      return this.sharedUserProps.reviewType || null;
    },

    sharedUserProps() {
      if (this.formData && Array.isArray(this.formData.sharingProps)) {
        return this.formData.sharingProps[0]
      } else {
        return {};
      }
    },

    canSendSpouseEmail() {
      const dateChecker = dayjs().diff(dayjs(this.spousalEmailChecker), 'day')
      return (this.spousalEmailChecker && dateChecker <= 1) && !this.isAdmin
    },

    disableBtn() {
      if (this.step > 7) {
        return true
      }
    },

    formSelection() {
      return this.form != null;
    },

    buttonText() {
      return this.step === 3 ? 'Download' : 'Proceed'
    },

    userHasPaid() {
      if (!this?.form?.id) return false
      const stateFound = this.isMyState(this?.form?.state)
      const paymentFound = this.formHasPaymentForUser(this?.form?.id, this.paymentLevel)
      return (stateFound || paymentFound) || false;
    },

    formId() {
      return this.formData?.id || "Loading"
    },

    userHasFinalPayment() {
      if (!this?.form?.id) return false
      const paymentFound = this.formHasPaymentForUser(this?.form?.id, this.paymentLevel)
      return paymentFound || false;
    }
  },

  async beforeMount() {
    await this.establishUserAuth()
    if(!this.isAdmin && this.userInfo.is_petitioner === true) {
      void this.$router.push(`/legal-form-guide/divorce-forms/${this.$route.params.id}`)
    }
    EventBus.$on('set-comments', (payload) => {
      this.comments = payload;
    });
  },

  mounted() {
    const importantDialog = localStorage.getItem("importantDialog")
    if(!importantDialog) {
      this.dialog = true
    }
    localStorage.setItem("importantDialog", "true");
  },

  async created() {
    const workflowsUserId = this.$route.params.id;
    await Promise.all([
      this.toggleLoadingOverlayStatus(true),
      this.loadSharedForm({workflowsUserId})
    ])
    
    this.disableButtonToProceed = this.sharingData[0]?.commentsDisabled ? this.sharingData[0].commentsDisabled : false
  },

  methods: {
    ...mapActions("alert", ["setAlert"]),
    
    ...mapActions(["setForms", "toggleLoadingOverlayStatus", "setOverlayMessage"]),

    ...mapActions("workflowPublished", [
      "loadSharedForm",
      "saveForm",
      "saveStep",
      "saveRespondentStep",
      "saveSignature",
      "shareForm",
      "deleteShare",
      "generateDocument",
      "generatePdfFile",
      "setFinalPDF",
      "setActiveDocIndex"
    ]),

    ...mapActions("workflowPublished", {
      sendBack: "sendBackForReview",
      agreedToTerms: "agreedToTerms",
      agreedToTermsWithSpouse: "spouseAgreed",
      saveRawForm: "saveRawForm"
    }),

    ...mapActions("comments", {
      saveComments: "saveSpouseComments",
      disableEditingComments: "disableEditingComments",
    }),

    ...mapActions("confirmation", ["confirm"]),

    ...mapActions("profile", [
      "getMyStates",
      "establishUserAuth",
      "removeFormById",
    ]),

    async restart() {
      this.confirm({
        title: 'Are you sure you want to go back to the questionnaire?',
        accepted: async () => {
          await this.toggleLoadingOverlayStatus(true)
          this.step = 1
          this.formData.respondentStep = 1
          this.formData.step = 3
          this.formData.sharingProps[0].agreedWithSpouse = false
          this.formData.esign = null

          const toUpdate = {
            id: this.formData.id,
            respondentStep: 1,
            step: 3,
            sharingProps: this.formData.sharingProps,
            esign: null
          }
          await this.saveRawForm(toUpdate)
          await sendEmailUtil(this.baseUrl, this.formData.owner, 'disagreement', 'SIMPLEENDING™ shared form status update')
          await this.toggleLoadingOverlayStatus(false)          
        }
      })
    },

    scrollToBottom() {
      const rc = document.querySelector('.right-content')
      rc.scrollTop = rc.scrollHeight
    },

    disableProceedButton(warning) {
      this.warningIssues = warning
    },

    stepClick(currentStep) {
      if (this.isAdmin) {
        this.toggleLoadingOverlayStatus(true)
        this.step = currentStep;
        if (this.step === 3 && !this.formData.finalPDF) {
          this.generateDoc()
        }
        this.toggleLoadingOverlayStatus(false)
      }
    },

    async sendSpousalReviewEmail() {
      if (this.canSendSpouseEmail) {
        this.btnLoading = false
        return
      }
      const {firstName, lastName, email} = this.partner

      const user = {
        email,
        name: `${firstName} ${lastName}`
      }

      await sendEmailUtil(this.baseUrl, user, 'sign', 'SIMPLEENDING™ form is ready for review and e-signature')
      this.spousalEmailCount += 1
      if (this.spousalEmailCount === 3) {
        localStorage.setItem('spouse-email-stamp', dayjs())
        this.spousalEmailChecker = dayjs()
      }
      this.btnLoading = false
      this.setAlert({ type: 'success', message: 'Successfully sent email for review and e-signature' })
    },

    async generateDoc() {
      this.btnLoading = true
      await this.toggleLoadingOverlayStatus(true)
      this.setOverlayMessage(
        "Building final document, Please wait."
      );
      await this.setFinalPDF({docId: this.formId, finalPDF: null})
      const {first_name} = this.userInfo
      const {name} = this.formData.owner
      const fileName = encodeURIComponent(`SIMPLEENDING-${first_name}-and-${name}-${getDateFormat(dayjs(),'MMDDYYYY')}`)
      const finalPDF = await pdfGenerator().run(this.formId, fileName)
      await this.setFinalPDF({docId: this.formId, finalPDF})
      this.trackEvent('event', 'workflow_completed')
      this.btnLoading = false
      await this.toggleLoadingOverlayStatus(false)
      this.setOverlayMessage()
      return
    },

    async buttonClick() {
      this.$root.$emit('finalSaveAction')
      if (this.step === 1) {
        this.submitResponseDialog = true;
        return
      }

      if (this.step > 4) return;

      switch (this.step) {
        case 1:
          this.step = 2;
          break;
        case 2:
          this.confirm({
            title: 'You are about to sign a legally binding agreement. Please confirm.',
            accepted: async () => {
              this.btnLoading = true;
              await this.toggleLoadingOverlayStatus(true)
              await this.getFirebaseConfig.db.runTransaction(async transaction => {
                await this.saveSignature({ transaction, docId: this.formData.id, esign: this.spouseEsign });
                this.isSigned = true
                await this.saveStep({ transaction, docId: this.formData.id, step: 7 });
                this.trackEvent('event', 'workflow_completed')
                await this.saveRawForm({ id: this.formData.id, notifyPetitionerThatRespondentSigned: Date.now() })
                await Promise.all([
                  sendEmailUtil(this.baseUrl, this.formData.owner, 'completed', 'SIMPLEENDING™ form entry reminder'),
                  sendEmailUtil(this.baseUrl, this.formData.sharingProps[0].user, 'completed', 'SIMPLEENDING™ form entry reminder')
                ])
                await this.saveRespondentStep({ transaction, docId: this.formData.id, respondentStep: 3 });
              })
              this.step = 3;
              this.btnLoading = false;
              await this.toggleLoadingOverlayStatus(false)
              window.location.reload();
            }
          })
          break;
        case 3:
          const fileName = decodeURIComponent(this.formData.finalPDF).split('/').pop()
          await download_file(this.formData.finalPDF, fileName)
          break;
      }

      setTimeout(() => {
        this.toggleLoadingOverlayStatus(false)
      }, 1000)
    },

    async submitResponse(type) {
      if (type === 'agree') {
        this.agreeLoading = true
        const response = await this.agreedToTermsWithSpouse(this.formData.id);
        if (response === 'success') {
          this.setAlert({
            type: 'success',
            message: 'You have agreed to all the terms!'
          })
        }
        await this.getFirebaseConfig.db.runTransaction(async transaction => {
          await this.saveStep({ transaction, docId: this.$route.params.id, step: 4 });
          await this.saveRespondentStep({ transaction, docId: this.$route.params.id, respondentStep: 2 });
        })
        this.step = 2;
      }

      if (type === 'send-back') {
        //EventBus.$emit('save-comments-progress');
        this.reviewLoading = true;
        this.disableButtonToProceed = true
        await this.sendBack({
          numberOfPages: this.comments.length,
          commentOwner: 'petitioner',
          commentOwnerName: this.formData.owner.name
        });
        const response = await this.disableEditingComments(this.$route.params.id)
        if (response === 'success') {
          this.setAlert({
            type: 'success',
            message: 'You are now waiting for your spouse to respond to your comments. Until they respond, you can only check the comment history.'
          })
        }
        this.reviewLoading = false;
        await this.saveRespondentStep({docId: this.$route.params.id, respondentStep: 1});
      }

      this.agreeLoading = false
      this.disableComments = true
      this.submitResponseDialog = false;
    },

    async loadFormData(form) {
      if (!form) return;
      if (!this.userHasPaid) return;
      this.loadForm(form);
    },

    isMyState(state) {
      return this.myStates.findIndex(doc => doc.state === state) >= 0
    },
  }  
}