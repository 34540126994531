const { default: jsPDF } = require("jspdf");

const formatMap = [
  {
    key: '<strong>',
    value: 'bold'
  },
  {
    key: '<em>',
    value: 'italic'
  }
]

const handleFormatting = (
  pdfDoc,
  { horizontalOffset, verticalOffset, linesCurrentPage, linesWidth, lineHeight }
) => {
  if(linesCurrentPage) {
    let startX = horizontalOffset
    let startY = verticalOffset
    let align = 'left'
    const widthOfSpace = pdfDoc.getTextWidth(" ")
    linesCurrentPage.forEach((line) => {
      line = line.trim()
      const centerChecker = '<p class=\"ql-align-center\">'
      if(line.indexOf(centerChecker) >= 0) {
        align = 'center'
        line = line.replace(centerChecker, '').trim()
      } else if(line.indexOf('<p>') >= 0) {
        align = 'left'
      }
      const lineSpacing = 1
      const lineWidth = pdfDoc.getTextWidth(line) + lineSpacing
      const leftSpacing = (8.5 - horizontalOffset) - lineWidth
      let newLine = true
      line.split(" ").forEach((text, s) => {
        const spaceSize = Math.ceil(leftSpacing / widthOfSpace)
        const spacing = (newLine === true && align === 'center' && spaceSize > 0) ? spaceSize : 0
        const spaces = " ".repeat(spacing / 2)
        const tag = formatMap.find(f => text.indexOf(f.key) >= 0)
        if(tag?.value) pdfDoc.setFont(undefined, tag.value)
        const finalText = spaces + text.replace(/<(.|\n)*?>/g, '') + " "
        pdfDoc.text(finalText, startX, startY);
        startX = startX + (pdfDoc.getStringUnitWidth(finalText) / 6)
        if(text.indexOf('</') >= 0) {
          pdfDoc.setFont(undefined, 'normal')
        }
        newLine = false
      })
      startX = horizontalOffset
      startY += lineHeight
    })
  } else {
    pdfDoc.text(horizontalOffset, verticalOffset, linesCurrentPage, { maxWidth: linesWidth });
  }
};

module.exports = handleFormatting