<template>
  <v-container>
    <v-img src="@/assets/images/login-header.svg" contain max-height="255" />
    <v-row class="text-center mt-10 justify-center">      
      <v-col xl="6" lg="6" md="10" cols="12">
        <div class="mb-5">
          <h2 class="signin-title text-center">Welcome</h2>
          <p class="signin-subtitle">Please sign into your account</p>
        </div>
        <div ref="form" class="sign-in-form">
          <v-form v-model="formValid" @submit.prevent="logMeIn" onSubmit="return false;">
            <label>Email</label>
            <v-text-field
              placeholder="username@example.com"
              v-model="email"
              :rules="[rules.required,rules.email]"
              outlined
              required
              autofocus
              class="emailText"
              @blur="email = email.toLowerCase()"
            />

            <div>
              <label>Password</label>
              <v-text-field
                v-model="password"
                :rules="[rules.required]"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :type="showPassword ? 'text' : 'password'"
                :hide-details="true"
                required
                outlined
                @click:append="showPassword = !showPassword"
              />
              <v-btn
                text
                to="/forgot-password"
                color="primary"
                x-small
                plain
              >
                Forgot password?
              </v-btn>
            </div>

            <v-btn
              type="submit"
              :disabled="!formValid"
              class="px-10 mt-10"
              color="primary"
              x-large
            >
              Sign In
            </v-btn>
          </v-form>
        </div>
      </v-col>
      <v-col cols="12">
        <h6 class="text-center">
          Haven't created an account yet? <br />
          <v-btn
            text
            color="primary"
            x-small
            plain
            class="px-0"
            @click.prevent="$router.push({ name: 'create-user' }).catch(() => {})"
          >
            Click here
          </v-btn>
          to do it now.
        </h6>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import rulesMixin from '@/mixins/rules.mixin'
import { storeWorkflowId } from '@/utils/workflow.localstorage.util'
import analyticsMixin from "../mixins/analytics.mixin";

export default {
  name: "SignIn",

  mixins: [rulesMixin,analyticsMixin],

  data: () => ({
    formValid: false,
    email: '',
    password: "",
    showPassword: false,
  }),

  computed: {
    ...mapGetters("profile", ["userInfo","sharedFormId"])
  },

  methods: {
    ...mapActions(["toggleLoadingOverlayStatus"]),

    ...mapActions("profile", ["userSignIn","isSharedUser"]),

    ...mapActions("alert", ["setAlert"]),

    reset() {
      this.email = ""
      this.password = ""
    },

    async logMeIn() {
      const userAuth = { email: this.email, password: this.password };
      await this.toggleLoadingOverlayStatus(true);
      const { type, message } = await this.userSignIn({ userAuth })
      if(type.toLowerCase() === 'success') {
        localStorage.removeItem('wfid')

        if(this.userInfo.role === 'admin-global') {
          void this.$router.push('/admin')
        } else if(this.userInfo?.is_petitioner === false) {
          if(this.userInfo?.respondent_data?.wfid) {
            void this.$router.push(`/respondent-guide/${this.userInfo.respondent_data.wfid}`)
          } else {
            await this.isSharedUser()
            if(this.sharedFormId) {
              void this.$router.push(`/respondent-guide/${this.sharedFormId}`)
            } else {
              void this.$router.push('/wait')
            }
          }
        } else if(this.userInfo?.forms?.length > 0) {
          void this.$router.push(`/legal-form-guide/divorce-forms/${this.userInfo.forms[0].workflowsUserId}`)
        } else if(this.userInfo?.is_petitioner === true) {
          void this.$router.push('/legal-form-guide')
        } else if(type === 'success') {
          void this.$router.push({ path: '/' })
        }
      } else {
        this.setAlert({ message, type })
      }
      await this.toggleLoadingOverlayStatus(false);
      return false
    },
  },

  mounted() {
    if(this.$route.query?.wfid) {
      storeWorkflowId('wfid', this.$route.query.wfid)
    }

    if(this.$route.query?.email) {
      this.email = this.$route.query.email
    }

    const { type, msg } = this.$route.query
    if(type) {
      this.setAlert({ message: msg, type })
    }
  }
}
</script>

<style lang="scss">
.emailText {
  input {
    text-transform: lowercase !important;
  }
}

.sign-in-form {
  label {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    display: block;
    text-align: left;
    opacity: .8;
  }
}
</style>
