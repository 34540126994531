<template>
  <v-container fluid class="full-height p-survey-detail">
    <v-row>
      <v-col cols-12 md="8" offset-md="2">
        <div v-if="formLoaded">

          <h3 class="font-weight-400 mt-4 mb-3 text-left">Shared Survey - <b>{{ formName }}</b></h3>

          <WorkflowGuide
              @add-comments="answers = $event"
              :form-fields="formData.fields"
              :is-owner="isOwner"
              :shared-user-props="sharedUserProps"
              :userComments="this.comments"
          />

          <div class="d-flex justify-space-between mt-5">
            <v-btn
                :disabled="sharedUserProps && !sharedUserProps.access"
                outlined
                color="red"
                @click="submitResponseDialog = true"
                class="mr-2"
            >
              Sand Back for Review
            </v-btn>
            <v-btn outlined color="blue" @click="$router.push({ name: 'shared-forms' }).catch(() => {})">Go Back</v-btn>
            <v-btn
                :disabled="sharedUserProps && !sharedUserProps.access"
                outlined
                color="green"
                @click="submitAgreeDialog = true"
                class="ml-auto"
            >Agree</v-btn>
          </div>

        </div>
      </v-col>
    </v-row>

    <!-- Dialog that pops up when user wants to submit response (by clicking the Submit Response button) -->
    <v-dialog v-model="submitResponseDialog" max-width="500">
      <v-card class="pa-3">
        <v-card-title class="headline">
          <v-icon color="error" class="mr-2">error</v-icon>
          Disagree with your spouse?
        </v-card-title>

        <v-card-text>
          Send the document back for review? <br />
          <strong>This action will be permanent</strong>.
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="submitResponseDialog = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn @click="submitResponse('send-back-for-review')" class="primary" text>
            Yes, Send Back
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="submitAgreeDialog" max-width="500">
      <v-card class="pa-3">
        <v-card-title class="headline">
          <v-icon color="error" class="mr-2">error</v-icon>
          Agree with your spouse?
        </v-card-title>

        <v-card-text>
          Are you sure you want to procees? <br />
          <strong>This action will be permanent</strong>.
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="submitAgreeDialog = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn @click="submitResponse('agreed')" class="primary" text>
            Yes, Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import WorkflowGuide from "../components/WorkflowGuide";
import { EventBus } from "@/plugins/eventBus";

export default {
  name: "SurveyDetail",
  components: {
    WorkflowGuide,
  },
  data() {
    return {
      submitResponseDialog: null,
      submitAgreeDialog: null,
      answers: {},
      comments: [],
      mini: false,
      sheet: false,
      tiles: [
        { img: 'keep.png', title: 'Keep' },
        { img: 'inbox.png', title: 'Inbox' },
        { img: 'hangouts.png', title: 'Hangouts' },
        { img: 'messenger.png', title: 'Messenger' },
        { img: 'google.png', title: 'Google+' },
      ],
    }
  },

  computed: {
    ...mapState("workflowPublished", {
      formData: "formData",
    }),

    ...mapGetters("profile", ["userInfo"]),

    ...mapGetters("workflowPublished", {
      isOwner: "isOwner",
    }),

    formName() {
      return this.formData?.name || this.formData?.state
    },

    formLoaded() {
      return !!this.formData;
    },

    reviewType(){
      if(!this.sharedUserProps) return null;
      return this.sharedUserProps.reviewType || null;
    },

    sharedUserProps() {
      if(this.formData && Array.isArray(this.formData.sharingProps)) {
        return this.formData.sharingProps.find(item => item.user.email === this.userInfo.email);
      } else {
        return {};
      }
    }
  },

  methods: {
    ...mapActions(["toggleLoadingOverlayStatus"]),

    ...mapActions("workflowPublished", {
      loadSharedForm: "loadSharedForm",      
      sendBack: "sendBackForReview",
    }),

    ...mapActions("comments", {
      saveComments: "saveSpouseComments",
    }),

    async submitResponse(type) {
      if(type === 'send-back-for-review') {
        await this.sendBack(this.comments.length);
      }

      // await this.saveComments(this.answers);
      this.submitResponseDialog = false;
      this.submitAgreeDialog = false;
    }
  },

  beforeMount() {
    EventBus.$on('set-comments', (payload) => {
      this.comments = payload;
    });
  },

  created() {
    const workflowsUserId = this.$route.params.id;
    this.toggleLoadingOverlayStatus(true);
    this.loadSharedForm({ workflowsUserId }).then(() => {
      this.toggleLoadingOverlayStatus(false);
      if(!this.formData.sharingUser.includes(this.userInfo.email)) this.$router.push('/shared-forms').catch(() => {});
      if(!this.formData.sharingUser.includes(this.userInfo.email)) this.$router.push('/shared-forms').catch(() => {});
    });

  },
};
</script>
