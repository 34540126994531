<template>
  <v-container fluid class="full-height">
    <v-row class="full-height">
      <v-col cols="0" sm="1" />
      <v-col cols="12" sm="10">
        <v-card>
          <v-card-title
            class="background-gradient-light-blue-to-green justify-center"
          >
            <span class="white--text">Shared Forms</span>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Description</th>
                    <th class="text-left">From</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="survey in sharedSurvey"
                    :key="survey.id"
                    link
                    style="cursor:pointer;"
                    @click="(event) => { event.shiftKey ? $router.push(`/survey-detail/${survey.id}`).catch(() => {}) : $router.push(`/respondent-guide/${survey.id}`).catch(() => {}) }"
                  >
                    <td class="text-left">{{ survey.name }}</td>
                    <td class="text-left">{{ survey.description }}</td>
                    <td class="text-left">{{ survey.owner.email }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div v-if="sharedSurvey.length === 0" class="ma-4 text-center">
              No data to display
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="0" sm="1" />
    </v-row>
  </v-container>
</template>

<script>
import WorkflowGuide from "../components/WorkflowGuide";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SharedForm",

  components: { WorkflowGuide },

  data() {
    return {
      sharedSurvey: [],
    };
  },

  computed: {
    ...mapGetters({
      getFirebaseConfig: "getFirebaseConfig",
    }),

    ...mapGetters("profile", ["userEmail"]),
  },

  methods: {
    ...mapActions(["toggleLoadingOverlayStatus"]),

    async loadSharedForms(userEmail) {
      await this.toggleLoadingOverlayStatus(true);
      const res = await this.getFirebaseConfig.db
        .collection("workflows-user")
        .where("sharingUser", "array-contains", userEmail)
        .get()
      res.forEach((doc) => {
        const survey = doc.data();
        this.sharedSurvey.push(survey);
      });
      this.toggleLoadingOverlayStatus(false);
    },
  },

  watch: {
    userEmail: function (userEmail) {
      this.toggleLoadingOverlayStatus(true);
      this.loadSharedForms(userEmail).then(() => {
        this.toggleLoadingOverlayStatus(false);
      })
    },
  },

  mounted() {
    if (this.userEmail !== "") {
      this.toggleLoadingOverlayStatus(true);
      this.loadSharedForms(this.userEmail).then(() => {
        this.toggleLoadingOverlayStatus(false);
      })
    }
  },
};
</script>
