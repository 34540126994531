const { downloadFileContent } = require('@/services/downloadFileContent.service')

/* Helper function */
const download_file = async (fileUrl, fileName, fileContent = downloadFileContent) => {
  const dataUrl = await fileContent(fileUrl)
  var link = document.createElement('a');
  link.href = dataUrl;
  link.setAttribute('download', fileName);
  link.style.display = "none"
  link.dispatchEvent(new MouseEvent('click'));
  return
}

module.exports = { download_file }