import { mapGetters, mapActions } from "vuex"
import analyticsMixin from '../analytics.mixin'
import CostInfo from "@/components/CostInfo.vue"

export default {
  components: {
    CostInfo
  },

  mixins: [analyticsMixin],

  data() {
    return {
      formData: [],
      currentStep: 1,
      jurisdiction: '',
      childrenOptions: '',
      currentCounty: '',
      currentState: '',
      collaborate: true,
      isEditable: false,
    };
  },

  watch: {
    currentStep(newVal) {
      if(newVal < 3) {
        this.currentCounty = ''
      }

      if(newVal === 1) {
        this.currentState = ''
        this.childrenOptions = ''
      }
    }
  },

  computed: {
    ...mapGetters("profile", ["userInfo", "myStates", "getPayments"]),

    ...mapGetters("prices", ["getPriceByLevel"]),

    ...mapGetters("workflowPublished", {
      forms: "publishedWorkflows",
    }),

    unilateral() {
      return this.getPriceByLevel('unilateral','WithoutSpouse')
    },

    collaborative() {
      return this.getPriceByLevel('collaborative','WithSpouse')
    },

    formType() {
      return this.collaborate ? 'collaborative' : 'unilateral'
    },

    countiesByStateAndKids() {
      const counties = []
      this.countiesByState
        .filter(fd => fd?.childrenOptions && fd.childrenOptions === this.childrenOptions)
        .forEach(fd => {
          fd.counties.forEach(c => {
            counties.push(c)
          })
        })
      return counties.sort().flat()
    },

    needsChildren() {
      return this.formData.find(fd => fd.state === this.currentState && fd.childrenOptions !== 'both') || false
    },

    hasCounties() {
      return this.countiesByState.length > 0
    },

    countiesByState() {
      return this.formData.filter(fd => fd.state === this.currentState && fd?.counties?.length > 0) || []
    },

    getStates() {
      const states = this.myStates.map(r => ({ ...r, notPublished: true }))

      const rval = [...this.forms, ...states]

      return rval.filter(r => r.state).map(s => s.state).sort()
    },
  },

  beforeMount() {
    this.setPublishedWorkflows()
  },

  beforeDestroy() {
    this.$store.dispatch("workflowPublished/resetWorkflowPublished");
  },

  async created() {
    await this.establishUserAuth()
    if(this.userInfo.forms.length === 1) {
      void this.$router.push(`/legal-form-guide/divorce-forms/${this.userInfo.forms[0].workflowsUserId}`).catch(() => {});
    }
  },

  async mounted() {
    if(this.getPayments && Object.keys(this.getPayments).length > 0) {
      await this.toggleLoadingOverlayStatus(true)
      this.setOverlayMessage('Please wait while we build your questionnaire.')
      const parentId = Object.keys(this.getPayments)[0]
      await this.loadForm({ parentId })
      const formId = await this.saveForm(false)
      void this.$router.push(`/legal-form-guide/divorce-forms/${formId}`)
      await this.toggleLoadingOverlayStatus(true)
    }
    await this.getMyStates(this.userInfo.email)
    await this.setPrices()
    this.setOverlayMessage()
  },

  methods: {
    ...mapActions(["toggleLoadingOverlayStatus", "setOverlayMessage"]),

    ...mapActions("workflowPublished", ["setPublishedWorkflows", "getRawFormDataByState", "loadForm", "saveForm"]),

    ...mapActions("profile", ["getMyStates", "establishUserAuth"]),

    ...mapActions("alert", ["setAlert"]),

    ...mapActions("prices", ["setPrices"]),

    countySelected() {
      const increment = this.collaborate ? 1 : 2
      this.handleFindingForm(increment)
    },

    changeStep(evt) {
      this.currentStep = parseInt(evt)
      this.pauseVideo()
    },

    handleChildrenOptions() {
      const increment = this.hasCounties ? 1 : (this.collaborate ? 2 : 3)
      this.handleFindingForm(increment)
    },

    collaborateClick(collaborate) {
      this.collaborate = collaborate
      const increment = collaborate ? 5 : 6
      this.currentStep = this.needsChildren ? (this.currentStep + 1) : increment
    },

    handleFindingForm(increment = 1) {
      this.myForm = this.findMyForm()
      if(this.myForm) {
        this.jurisdiction = this.myForm?.jurisdiction
        this.currentStep += increment
      } else {
        this.setAlert({ type: 'error', message: `Sorry, could not find a ${this.currentState} form ${this.childrenOptions} children for ${this.currentCounty || 'any'} county`})
      }
    },

    pauseVideo() {
      if(this.$refs?.tutorialVideo?.player)
        this.$refs.tutorialVideo.player.pauseVideo()
    },

    findMyForm() {
      if(this.currentCounty?.length > 0 && this.childrenOptions?.length > 0) {
        return this.formData.find(f => f.state === this.currentState && f.counties.indexOf(this.currentCounty) > -1 && f.childrenOptions === this.childrenOptions)
      } else if(this.childrenOptions?.length > 0) {
        return this.formData.find(f => f.state === this.currentState && f.childrenOptions === this.childrenOptions)
      } else {
        return this.formData.find(f => f.state === this.currentState)
      }
    },

    async redirectToState() {
      this.$store.state.isCollaborate = this.collaborate
      const relatedForm = this.findMyForm()
      localStorage.setItem('collaborate', this.collaborate)
      void this.$router.push(`/pay-for-form/${relatedForm.id}/${this.userInfo.email}/${this.formType}/1`).catch(() => {});
      return
    },

    nextClick() {
      this.currentStep += 1
      this.pauseVideo()
    },

    async stateSelected(state) {
      this.formData = await this.getRawFormDataByState({ formName: 'workflows-published', state })
      if(this.formData.length === 1) {
        this.jurisdiction = this.formData[0].jurisdiction
        if(this.countiesByState.length === 0 && !this.jurisdiction) {
          this.redirectToState()
        } else {
          this.isEditable = false
        }
      } else {
        this.isEditable = true
      }

      this.currentStep += 1
    },
  },
};