import { mapGetters, mapActions } from 'vuex'

export default {
    async beforeMount() {
        await this.establishUserAuth()
        if(!this.isAdmin) {
            void this.$router.push('/').catch(() => {})
        }
    },

    methods: {
        ...mapActions('profile', ['establishUserAuth']),
    },

    computed: {
        ...mapGetters("profile", ["isAdmin"]),
    }

}