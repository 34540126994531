<template>
  <div>
    <v-text-field
      label="Text"
      @input="onChange('text', $event)"
      :value="helpData.text"
    />
    <v-text-field
      label="Youtube"
      @change="onChange('youtubeLink', $event)"
      :value="helpData.youtubeLink"
      :rules="youtubeRule"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },

  data() {
    return {
      helpData: {
        text: this.value ? this.value.text : "",
        youtubeLink: this.value ? this.value.youtubeLink : "",
      },

      youtubeRule: [
        (value) => {
          return this.checkYoutubeLink(value) || "Invalid YouTube format.";
        },
      ],
    };
  },

  activated() {
    this.$emit("helpDataChanged", this.helpData);
  },

  methods: {
    checkYoutubeLink(link) {
      const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      return pattern.test(link);
    },

    onChange(key, value) {
      if (key === "youtubeLink") {
        if (value.indexOf("watch?v=") >= 0) {
          value = value.replace("watch?v=", "embed/");
        }

        if (!this.checkYoutubeLink(value)) {
          value = "";
        }

        this.helpData[key] = value;
      } else {
        this.helpData[key] = value;
      }
      this.$emit("helpDataChanged", this.helpData);
    },
  },
};
</script>
