<template>
  <v-container fluid class="full-height">
    <v-row v-if="isAdmin" no-gutters>
      <v-col cols="0" sm="1" />
      <v-col cols="12" md="10">
        <v-row justify="center">
          <v-col cols="12">
            <v-card ref="form" elevation="4">
              <v-card-title class="bg-primary white--text">
                Manage Workflows
                <FormBuilder justify="center" />
              </v-card-title>

              <v-divider />

              <v-card-actions class="flex align-center">
                <v-row no-gutters>
                  <v-col cols="12">
                    <FilterKeyword
                      :label="'Search Workflows'"
                      :array-to-filter="formsWithOriginalIndex"
                      :properties-filterable="['name', 'description', 'childrenOptions', 'state', 'status']"
                      @filtered="updateFilteredForms"
                    />
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center">
          <template v-for="(form, index) in filteredForms">
            <v-col v-if="form?.name" :key="index" cols="12" md="10" xl="6">
              <v-card raised :id="form.id" class="my-3">
                <v-card-title class="bg-light font-weight-bold">
                  {{ form.name }}:
                  {{ form.state }} - 
                  {{ form.childrenOptions }} children
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row v-if="hasCounties(form)">
                    <v-col cols="12">
                      <p 
                        :id="`form-counties-${form.id}`"
                        class="py-1"
                        style="width:100%;overflow:hidden;height:30px;position:relative;"
                      >
                        <b>Counties: </b>
                        {{ form.counties.join(', ') }}
                        <v-btn 
                          x-small 
                          rounded 
                          style="position:absolute;right:5px;bottom:5px;"
                          @click="showCounties(form.id)"
                        >...</v-btn>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="d-flex justify-start align-center" style="margin-top:20px">
                      Version: {{ form.version }}
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end align-center" style="margin-top:20px">
                      Last Modified On: {{ getLastModifiedDate(form.modifiedDate || Date.now()) }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                    >
                      <p class="py-1" style="width:100%;">{{ form.description }}</p>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <div class="status" v-if="form.status">
                    {{ form.status }} for {{ form.state }} on {{ getDate(form.statusDateStamp) }}
                  </div>
                  <v-spacer />
                  <v-menu offset-y :key="form.id">
                    <template v-slot:activator="{ on: on_click, attrs: attrs_menu }">
                      <v-btn
                        icon
                        color="primary"
                        v-bind="attrs_menu"
                        v-on="on_click"
                      >
                        <v-icon color="primary">
                          mdi-dots-horizontal
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-btn
                          :disabled="isPending(form.status)"
                          title="Edit"
                          text
                          @click="editForm(form.originalIndex)"
                        >
                          <v-icon>edit</v-icon>
                          Edit Workflow
                        </v-btn>
                      </v-list-item>
                      <v-list-item v-if="(isOwner(form.owner) || isAdmin)">
                        <v-btn
                          :disabled="disableBtn(form.owner, form.status)"
                          :title="publishBtnTitle(form.status)"
                          id="publish-btn"
                          text
                          @click="showPublishDialog(form.originalIndex)"
                        >
                          <v-icon>publish</v-icon>
                          {{ publishBtnTitle(form.status) }}
                        </v-btn>
                      </v-list-item>
                      <v-list-item v-if="(isOwner(form.owner) || isAdmin) && isApproved(form.status)">
                        <v-btn
                          title="Unpublish"
                          id="publish-btn"
                          text
                          @click="removePublishedWorkflow(form)"
                        >
                          <v-icon>mdi-publish-off</v-icon>
                          Unpublish
                        </v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-btn
                          title="Move to stage"
                          text
                          @click="migrateForm(form.id)"
                        >
                          <v-icon>mdi-file-move</v-icon>
                          Move to stage
                        </v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-btn
                          title="Duplicate"
                          text
                          @click="copyForm(form.id)"
                        >
                          <v-icon>content_copy</v-icon>
                          Clone Workflow
                        </v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-btn
                          :disabled="disableBtn(form.owner, form.status)"
                          title="Delete"
                          text
                          @click="deleteFormPopup(form.originalIndex)"
                        >
                          <v-icon>delete</v-icon>
                          Delete Workflow
                        </v-btn>
                      </v-list-item>
                      <v-list-item v-if="isOwner(form.owner)">
                        <v-btn
                          :disabled="disableBtn(form.owner, form.status)"
                          title="Share"
                          text
                          @click="shareFormPopup(form.originalIndex)"
                        >
                          <v-icon>person_add</v-icon>
                          Share Workflow With...
                        </v-btn>
                      </v-list-item>
                      <v-list-item v-else>
                        <v-btn
                          title="Remove me from this workflow"
                          text
                          @click="removeMeFromCollaboratorList(form)"
                        >
                          <v-icon>person_remove</v-icon>
                          Remove Me...
                        </v-btn>
                      </v-list-item>                      
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
      <v-col cols="0" sm="1" />
    </v-row>

    <publish-form
      v-if="shareFormMetadata.publishDialog"
      :share-form-metadata="shareFormMetadata"
      @published="setForms"
    />

    <collaborators-dialog
      v-if="shareFormMetadata.collaboratorDialog"
      :share-form-metadata="shareFormMetadata"
    />

    <!-- Copy workflow dialog -->
    <v-dialog v-model="copyFormMetadata.dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Keep Collaborators?</v-card-title>
        <v-card-text
          >Do you want to keep the same collaborators &
          stakeholders?</v-card-text
        >
        <v-card-actions style="justify-content: right;">
          <v-btn
            color="primary"
            @click="handleCollaboratorsChoice('keep')"
            >Keep
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="handleCollaboratorsChoice('remove')"
            >No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete workflow dialog -->
    <v-dialog v-model="deleteFormMetadata.dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Permanent Deletion</v-card-title>

        <v-card-text v-if="deleteFormMetadata.dialog">
          Are you sure you want to permanently delete the workflow named
          <span class="font-weight-bold">{{ forms[deleteFormMetadata.index].name }}</span>?
        </v-card-text>

        <v-card-actions style="justify-content: right;">
          <v-btn
            color="primary"
            @click="deleteForm()"
          >
            Delete
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="deleteFormMetadata.dialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import manageWorkflowsMixin from '@/mixins/views/manageWorkflows.mixin';

export default {
  name: "ManageWorkflows",

  mixins: [manageWorkflowsMixin]
};
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 75px;  
}

.v-card__text {
  padding: 0 20px;

  #collaborators,
  #stakeholders {
    .v-list-item {
      padding: 0px;
      border: 1px solid white;
    }

    .full-header {
      width: 100%;
    }

    .header1 {
      text-align: left;
    }

    .header2 {
      text-align: right;
    }

    .v-subheader {
      padding: 10px 0;
      flex: 1 1 auto;
      height: fit-content;
      display: inline;
    }

    .v-list-item__title {
      text-align: left;
      flex: 2 2 auto;
    }

    .v-list-item__subtitle {
      text-align: right;
      flex: 1 1 auto;

      .stakeholder {
        display: inline;
        vertical-align: middle;

        input[type="checkbox"] {
          height: 15px;
          width: 15px;
        }
      }

      button {
        margin-left: 10px;
        width: 20px;
        height: 20px;

        .v-icon {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

    .v-list-item__content {
      padding: 0px;
    }
  }
}

.heading {
  text-align: left;
  padding: 0 3rem;
}

.v-card__text {
  padding-bottom: 0;
}

.v-card__actions {
  .status {
    margin-left: 10px;
  }
}

.transform-scale-small {
  transform: scale(0.7);
}

@media screen and (min-width: 600px) {
  .border-left-black-1px {
    border-left: rgba(0, 0, 0, 0.87) solid 1px;
  }
}

@media screen and (max-width: 599px) {
  .border-left-black-1px {
    border-top: rgba(0, 0, 0, 0.87) solid 1px;
    border-bottom: rgba(0, 0, 0, 0.87) solid 1px;
  }
}
</style>
