export const childCalculator = {
  namespaced: true,
  
  mutations: {},
  actions: {
    async getCountries({ rootState }) {
      const docRef = rootState.firebaseConfig.db.collection('child-support-calc');
      const states = (await docRef.get()).docs.map(d => d.id)
      return states;
    },

    async addNewStateToCC({ commit, rootState }, state) {
      const docRef = rootState.firebaseConfig.db.collection('child-support-calc');
      try {
        await docRef.doc(state).set({website:'', description:''}, { merge: true })
        return 'success';
      } catch (e) {
        return 'error';
      }
    },

    async removeStateFromCC({ commit, rootState }, state) {
      const ref = rootState.firebaseConfig.db.collection('child-support-calc');
      try {
        ref.doc(state).delete();
        return 'success';
      } catch (e) {
        return 'error';
      }
    },

    async getCCLogic({ commit, rootState }, state) {
      return (await rootState.firebaseConfig.db.collection('child-support-calc').doc(state).get())?.data() || null
    },

    async saveCCLogic({ commit, rootState }, payload) {
      const docRef = rootState.firebaseConfig.db.collection('child-support-calc');
      const template = {
        description: payload.description,
        website: payload.website,
        hyperlink: payload.hyperlink,
      };
      try {
        await docRef.doc(payload.state).set(template, { merge: true })
        return 'success';
      } catch (e) {
        return 'error'
      }
    },
  },
}
