<template>
  <div class="generate-doc">
    <div v-if="!finalPdf">
        <p style='margin-top: 20px;'>
          Click Generate button to download final PDF document
        </p>
    </div>
    <iframe v-else :src="finalPdf" width="100%" height="650"></iframe>
  </div>
</template>

<script>
export default {
  name: "GenerateDocument",

  props: {
    finalPdf: {
      type: String,
      default: null
    }
  },
}
</script>

<style>
.generate-doc {
  height: 100%;
}
</style>
