export default Object.freeze({
  actionTypes: [
    { text: "X", value: "add-x" },
    { text: "Check Mark", value: "add-check-mark" },
    { text: "Static Text", value: "text-static" },
    { text: "Input From Question", value: "text-from-question" },
    { text: "Text Editor", value: "text-editor" },
    { text: "Custom Image", value: "custom-image" },
    { text: 'Petitioner Signature Date', value: 'pet-signature-date'},
    { text: 'Respondent Signature Date', value: 'resp-signature-date'},
    { text: 'Petitioner Signature Image', value: 'pet-signature-img'},
    { text: 'Respondent Signature Image', value: 'resp-signature-img'},    
  ],
  dynamicBlockTypes: [
    { text: 'Question Input', value: 'question-input' },
    { text: 'Image Input', value: 'image-input' },
    { text: 'Conditional Display', value: 'static-block' },
    { text: 'Petitioner Signature Date', value: 'pet-signature-date'},
    { text: 'Respondent Signature Date', value: 'resp-signature-date'},
    { text: 'Petitioner Signature Image', value: 'pet-signature-img'},
    { text: 'Respondent Signature Image', value: 'resp-signature-img'},
    { text: 'Log Data', value: 'log-data'},  
  ],
});
