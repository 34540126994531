<template>
  <v-container fluid class="full-height">
    <v-row class="full-height">
      <v-col cols="0" sm="1" />
      <v-col cols="12" sm="10">
        <v-card v-if="forms" height="100%" elevation="4">
          <v-card-title
            class="background-gradient-light-blue-to-green justify-center"
          >
            <span class="white--text">Workflow Form Previews</span>
          </v-card-title>
          <v-card-subtitle class="mt-2">
            <v-row>
              <v-col cols="0" sm="1" md="3" />
              <v-col cols="12" sm="10" md="6">
                <v-select
                  :items="forms"
                  v-model="form"
                  item-text="name"
                  return-object
                  label="Select the form you would like to preview"
                />
              </v-col>
              <v-col cols="0" sm="1" md="3" />
            </v-row>
          </v-card-subtitle>
          <v-divider />
          <v-card-text>
            <QuestionForm
              :form-fields="formFields"
              :form-id="form.id"
              :preview-mode="false"
              :current-survey-tab="currentSurveyTab"
              @update-current-survey-tab="updateCurrentSurveyTab"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="0" sm="1" />
    </v-row>
  </v-container>
</template>

<script>
import QuestionForm from "../components/QuestionForm";
import { mapState, mapGetters, mapActions } from "vuex";
import adminCheckerMixin from '@/mixins/adminChecker.mixin'

export default {
  name: "WorkflowFormPreview",

  mixins: [adminCheckerMixin],

  components: { QuestionForm },

  data() {
    return {
      form: [],
      formID: "divorce-form-test-1",
      currentSurveyTab: 0,
    };
  },
  computed: {
    ...mapState(["forms"]),

    ...mapGetters(["getFirebaseConfig"]),

    formFields() {
      if (this.form) {
        return this.form.fields;
      } else {
        return [];
      }
    },
  },

  created() {
    this.setForms();
  },

  methods: {
    ...mapActions(["setForms"]),

    updateCurrentSurveyTab(index) {
      this.currentSurveyTab = index;
    },
  },
};
</script>
