import axios from 'axios';
import { getProject } from './gcloud/getProject';

export default async function updateSubCollection(body, projectUrl = getProject(), httpRequest = axios) {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };

  const response = await httpRequest({
    url: `${projectUrl}/saveSubCollection`,
    method: 'POST',
    headers: config,
    data: JSON.stringify(body)
  })

  return response
}
