<template>
  <div class="file-upload-container">
    <v-card tile v-if="field.content.url" class="file-card my-2">
      <v-card-title class="header">
        <v-icon class="pl-1">image</v-icon>
        {{ field.label || "FILE INPUT" }}
        <v-spacer />
        <v-btn
          v-if="!readOnly"
          title="Delete uploaded file"
          icon
          @click="deleteFileInput"
        >
          <v-icon>clear</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <a v-if="field.content.url" target="_blank" :href="field.content.url">
          {{ field.content.name.toUpperCase() }}
        </a>
      </v-card-text>
    </v-card>
    <v-file-input
      v-else
      :value="field.content.file"
      :label="label"
      :disabled="readOnly"
      :rules="rulesStatus"
      :error="answerStatus"
      outlined      
      prepend-icon="cloud_upload"
      append-icon=""
      :accept="accept"
      @change="fileInputUpload($event)"
      @click:clear="fileInputClear()"
    />
    <br v-if="field.spaceAfter" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { urlExists } from "@/utils/file.util";

export default {
  name: "FileUpload",

  props: {
    accept: {
      type: String,
      default: "",
      required: false,
    },

    label: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      default: null,
    },

    formId: {
      type: String,
      default: "",
    },

    isPublished: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    rulesStatus: {
      type: Array,
      default: () => [],
    },

    answerStatus: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(["getFormById"]),

    ...mapGetters("workflowPublished", ["getFormData"]),
  },

  methods: {
    // File-Input Methods
    ...mapActions(["setFileInput", "readFileInput"]),

    ...mapActions("workflowPublished", ["updateFormDataField"]),

    ...mapActions("alert", ["setAlert"]),

    ...mapActions("confirmation", ["confirm"]),

    fileInputUpload(file) {
      if(file === null) return
      const ext = '.' + file.name.split('.')[1].toLowerCase()
      if(this.accept.indexOf(ext) < 0) {
        this.fileInputClear()
        this.setAlert({ type: "info", message: "Please only upload valid file formats" })
        return
      }

      this.field.content = {
        file,
        deleteFlag: false,
        storageRef: null,
      };

      this.updateFileInput(this.field);
    },

    deleteFileInput() {
      this.confirm({
        type: "Are you sure you want to delete this uploaded file?",
        accepted: () => this.fileInputDefault()
      })
    },

    fileInputDefault() {
      this.field.content = {
        file: {},
        name: "",
        deleteFlag: true,
        storageRef: null,
        url: null,
      };
    },

    fileInputClear() {
      this.fileInputDefault();
      this.updateFileInput(this.field);
    },

    updateFileInput(field) {
      if (this.isPublished) {        
        const { title, type, uid, content } = field;
        this.updateFormDataField({
          type: "published",
          field: { title, type, uid },
          updates: { content },
        });
      } else {
        const { originalIndex } = this.getFormById(this.formId);
        this.setFileInput({ field, formIndex: originalIndex });
      }
    },

    async fileCheck(findUrl = urlExists) {
      if (this.field.content.storageRef && !this.readOnly) {
        this.readFileInput({ field: this.field, formId: this.formId });
        const urlFound = await findUrl(this.field.content.url);
        if (!urlFound) {
          this.fileInputClear();
        }
      }
    },
  },

  mounted() {
    this.fileCheck();
  },
};
</script>

<style lang="scss">
.file-upload-container {
  position: relative;

  .file-card {
    .header {
      padding: 10px;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.375rem;
      letter-spacing: 0.0071428571em;
    }
  }

  .v-input__control {
    .v-input__append-inner {
      margin-left: 0px;
      position: absolute !important;
      top: 0px;
      right: 4px
    }
  }
}
</style>