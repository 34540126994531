import questionsMixin from "@/mixins/questions.mixin"

export default {
  mixins: [questionsMixin],

  props: {
    form: {
      type: Object,
      required: true,
    },

    docIndex: {
      type: Number,
      default: 0,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ruleSearch: "",
      deleteRuleMetadata: {
        dialog: false,
        index: null,
      },
      changeNameFlag: false,
      logicalOperators: ["and", "or", "not", "x-or"],
      logicalOperatorSelection: "",
      types: [
        { text: "Action Logic", disabled: false },
        {
          text: "Sharing Logic",
          disabled: false,
        },
      ],
      typeSelection: "",
      actionItems: [
        "Add X",
        "Add check mark",
        "Add static text",
        "Add input from question as text",
        "Text Editor",
        "Add signature pad",
      ],
      actionSelection: null,
      conditionals: ["if", "always", "else"],
      conditionalSelection: "",
      answers: [],
      answerSelection: null,
      rulesLegendHeight: 0,
    };
  },

  computed: {
    currentRule: {
      get() {
        return this.$store.state.workflow.workflowBuilderCurrentRule;
      },
      set(value) {
        this.$store.dispatch("workflow/setWorkflowBuilderCurrentRule", value);
      },
    },

    parsedQuestionList() {
      const list = this.form.fields.slice();
      let pageNumber = 0;

      for (let i = 0; i < list.length; i++) {
        let question = "";

        if (list[i].type === "page-break") {
          pageNumber++;
          question = "Page " + pageNumber + " | Title: " + list[i].title;
        } else {
          question = "Type: " + list[i].type + " | Title: " + list[i].title;
        }

        list[i].questionListTitle = question;
      }

      list.push({
            uid: 'participating',
            title: 'participating',
            type: 'participating',
            questionListTitle: 'Participating'
        })

      return list;
    },

    orderList() {
      const list = Object.keys(this.form.docs[this.docIndex].rules);
      const newList = [];

      for (let i = 1; i <= list.length; i++) {
        newList.push(i);
      }

      return newList;
    },

    isReadOnly() {
      return this.readOnly;
    },

    rules() {
      const ruleValues =
        Object.values(this.form?.docs[this.docIndex]?.rules) || [];
      const currentRules = ruleValues.map((rule, index) => {
        return {
          ...rule,
          name: rule.name.length > 0 ? rule.name : "Rule " + (index + 1),
        };
      });

      if (
        currentRules.length > 0 &&
        this.ruleSearch &&
        this.ruleSearch.length >= 3
      ) {
        return currentRules.filter(
          (rule) =>
            rule.name.toLowerCase().indexOf(this.ruleSearch.toLowerCase()) >= 0
        );
      }
      return currentRules;
    },

    showSearch() {
      const ruleValues =
        Object.values(this.form?.docs[this.docIndex]?.rules) || [];
      return ruleValues.length > 10;
    },
  },

  mounted() {
    this.adjustCanvasHeight();
  },

  methods: {
    adjustCanvasHeight() {
      const toolbarHeight = !!document.querySelector(".toolbar-title")
        ? document.querySelector(".toolbar-title").clientHeight * 2
        : 0;
      const iconHeaderTabHeight = !!document.querySelector(".icon-header-tabs")
        ? document.querySelector(".icon-header-tabs").clientHeight * 2
        : 0;
      const screenHeight = window.innerHeight;

      this.rulesLegendHeight =
        screenHeight - (toolbarHeight + iconHeaderTabHeight);
    },

    getSelectedQuestionAnswers(uid, index, ruleIndex) {
      const rule = this.form.docs[this.docIndex].rules[ruleIndex];

      for (let i = 0; i < this.parsedQuestionList.length; i++) {
        if (this.parsedQuestionList[i].uid === uid) {
          const currentQuestion = this.parsedQuestionList[i];

          const currentAnswerOptions = this.getSelectedQuestionAnswer(currentQuestion);

          rule.questions[index].title = currentQuestion.title;
          rule.questions[index].uid = currentQuestion.uid;
          rule.questions[index].type = currentQuestion.type;
          rule.questions[index].options = currentAnswerOptions;
          this.$forceUpdate();
        }
      }
    },

    deleteRule(index) {
      if (index !== null) {
        const ObjectKeys = Object.keys(this.form.docs[this.docIndex].rules);
        const RulesCopy = JSON.parse(
          JSON.stringify(this.form.docs[this.docIndex].rules)
        );

        for (let i = 0; i < ObjectKeys.length; i++) {
          const currentKey = parseInt(ObjectKeys[i]);

          // Shift rules after one to be deleted down by one
          if (
            currentKey >= parseInt(index) &&
            currentKey < ObjectKeys.length - 1
          ) {
            this.form.docs[this.docIndex].rules[currentKey] =
              RulesCopy[currentKey + 1];
          }

          // Ensure that order is set properly
          this.form.docs[this.docIndex].rules[currentKey].order =
            currentKey + 1;
        }

        // Delete rule at end now that rules have been shifted down one
        this.$delete(
          this.form.docs[this.docIndex].rules,
          parseInt(ObjectKeys.length) - 1
        );
      }

      this.deleteRuleMetadata.dialog = false;
      this.deleteRuleMetadata.index = null;
    },

    addRule() {
      this.$store.dispatch("addRule", this.docIndex);
    },

    toggleDeleteRuleDialog(index) {
      this.deleteRuleMetadata.index = index;
      this.deleteRuleMetadata.dialog = true;
    },

    changeName() {
      this.changeNameFlag = !this.changeNameFlag;
    },

    setOrder(positionA) {
      const ruleA = this.form.docs[this.docIndex].rules[positionA];
      const positionB = parseInt(ruleA.order) - 1;
      const ruleB = this.form.docs[this.docIndex].rules[positionB];

      this.form.docs[this.docIndex].rules[positionA] = ruleB;
      this.form.docs[this.docIndex].rules[positionB] = ruleA;

      this.form.docs[this.docIndex].rules[positionA].order =
        parseInt(positionA) + 1;
      this.form.docs[this.docIndex].rules[positionB].order =
        parseInt(positionB) + 1;

      setTimeout(() => {
        this.currentRule = positionB;
      }, 500);
    },

    addQuestion(index) {
      this.form.docs[this.docIndex].rules[index].questions.push({
        uid: null,
        title: null,
        type: null,
        selection: {},
        options: [],
      });
    },

    deleteQuestion(index1, index2) {
      this.form.docs[this.docIndex].rules[index1].questions.splice(index2, 1);
    },

    onTypeChange() {
      const sharingLogicOpt = this.types.find(
        (type) => type.text === "Sharing Logic"
      );

      const hasSharingLogic = Object.values(
        this.form.docs[this.docIndex].rules
      ).find((rule) => rule.type === "Sharing Logic");

      if (hasSharingLogic) {
        sharingLogicOpt.disabled = true;
        hasSharingLogic.readOnlyShareLimit ||= 1;
        hasSharingLogic.commentShareLimit ||= 1;
        hasSharingLogic.editShareLimit ||= 0;
      } else {
        sharingLogicOpt.disabled = false;
      }
    },
  },  
}