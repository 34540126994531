<template>
  <v-dialog
    v-model="dialog.visible"
    scrollable
    :retain-focus="false"
    max-width="30%"
  >
    <v-card>
      <v-card-title>Reorder Documents</v-card-title>
      <v-divider />
      <v-card-text
        style="padding-top: 10px;"
      >
        <draggable
          :list="sortableDocs"
        >
          <template v-for="doc in sortableDocs">
            <v-list-item :key="doc.order">
              <v-list-item-content
                style="padding: 10px; border: 1px solid #ccc; border-radius:5px; cursor: pointer;"
              >
                <v-list-item-title>
                  {{ doc.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </draggable>
      </v-card-text> 
      <v-card-actions>
          <v-btn 
            color="primary"
            :loading="saving"
            @click="saveReorder"
          >
            Save
          </v-btn>

          <v-btn @click="dialog.visible = false">
            Close
          </v-btn>
      </v-card-actions>     
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "ReorderDocumentsDialog",
  
  components: {
    draggable
  },

  props: {
    formId: {
      type: String,
      required: true
    },

    dialog: {
      type: Object,
      default: () => ({
        visible: false
      })
    },

    docs: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      saving: false,
      sortableDocs: []
    }
  },

  mounted() {
    this.sortableDocs = this.docs
  },

  methods: {
    async saveReorder() {
      this.saving = true;
      this.sortableDocs.forEach((d, i) => {
        d.order = i
      })
      setTimeout(() => {
        this.saving = false;
        this.dialog.visible = false
      }, 1500)
      this.$emit('reorder', this.sortableDocs)
    }
  }
}
</script>