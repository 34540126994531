<template>
  <v-card tile height="fit-content" elevation="4" class="mx-auto" style="border:1px solid lightgray;">
    <v-card-title class="bg-primary white--text" style="display:flex;justify-content:space-between;">
      Block Checker
      <v-btn 
        fab
        small
        @click="runCheck"
      >
        <v-icon>mdi-run</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text style="margin-top: 10px;">
      <v-list-item 
        v-for="block in lostBlocks" 
        :key="block"
        style="border-bottom:1px solid lightgray"
      >
        <v-list-item-content>
          <v-list-item-title style="text-align:left;">
            *{{ block }}*
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon title="Copy Block Text" @click="copyText(block)">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card-text>    
  </v-card>
</template>

<script>
import blockCheckerMixin from '@/mixins/components/blockChecker.mixin.js'

export default {
  name: 'BlockChecker',

  mixins: [blockCheckerMixin]
}
</script>
