import { mapGetters, mapActions } from 'vuex'

export default {
  watch: {
    sharedFormId: (newVal) => {
      if(newVal) {
        this.$router.push(`/respondent-guide/${newVal}`)
      } else {
        this.$router.push('/wait')
      }
    }
  },

  computed: {
    ...mapGetters("profile", ["sharedFormId", "userInfo"])
  },

  methods: {
    ...mapActions("profile", ["isSharedUser", "establishUserAuth"]),
  }
}