<template>
  <v-form v-model="userFormValid" ref="form">
    <v-card id="table-row-edit-form">
        <v-card-title>
          <span class="headline">{{ rowTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
              <v-row v-for="(header, index) in headers" :key="index">
                <v-col
                  v-if="header.text !== 'Actions'"
                  :id="`header-${index}`"
                  cols="12"
                >
                  <v-select
                    v-if="header.variant === 'select'"
                    outlined
                    v-model="data[header.value]"
                    :id="`select-variant-${index}`"
                    :key="index"
                    :rules="rulesStatus({ required: true })"
                    :items="headerOptions(header.options)"
                    :readonly="header.writeable === false"
                    :label="header.text"
                  />
                  <VuetifyMoney
                    v-else-if="header.variant === 'money'"
                    :value="data[header.value]"
                    :id="`money-variant-${index}`"
                    :rules="rulesStatus({ required: true, validator: 'number-input', type: 'number-input', upperBound: 100000, lowerBound: 1 })"
                    :disabled="canEditColumn(header) === false"
                    :label="header.text"
                    :clearable="true"
                    :options="{
                      locale: 'en-US',
                      precision: 0,
                      prefix:'$',
                    }"
                    @blur="data[header.value] = $event"
                  />
                  <v-text-field
                    v-else
                    v-model="data[header.value]"
                    outlined
                    :type="header.variant"
                    :id="`other-variant-${index}`"
                    :rules="rulesStatus({ required: true })"
                    :disabled="canEditColumn(header) === false"
                    :label="header.text"
                    clearable
                  />
                </v-col>

              </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="save-btn"
              color="primary"
              :disabled="!userFormValid"
              :loading="loading"
              @click="saveClick">
                Save
            </v-btn>
            <v-btn
              id="cancel-btn"
              color="primary darken-1"
              text
              @click="$emit('cancel')"
            >
              Cancel
            </v-btn>
        </v-card-actions>
    </v-card>  
  </v-form>
</template>

<script>
import expressionatorMixin from '@/mixins/expressionator.mixin';
import VuetifyMoney from "@/components/VuetifyMoney";
import validatorsMixin from '@/mixins/validators.mixin'

export default {
    name: "TableRowEditForm",

    data() {
      return {
        loading: false,
        userFormValid: false
      }
    },

    components: {
      VuetifyMoney
    },

    mixins: [expressionatorMixin, validatorsMixin],

    computed: {
        headerOptions() {
            return (options) => {
                if(this.fields.length === 0) {
                  return options
                } else {
                  const formattedOptions = [...options]

                  formattedOptions.forEach((option, index) => {
                    formattedOptions[index] = {
                      value: option,
                      text: this.expressionator(option, this.fields)
                    }
                  })
                  return formattedOptions
                }
            }
        },

        canEditColumn() {
          return (header) => {
            let canEdit = true
            if(!this.isAdmin && this.readOnly) {
              canEdit = header?.writeable ? header.writeable : true;
            }
            return canEdit
          }
        }
    },

    props: {
      rowTitle: {
        type: String,
        required: true
      },

      headers: {
        type: Array,
        required: true
      },

      data: {
        type: Object,
        default: undefined
      },

      fields: {
          type: Array,
          default: () => []
      },

      isAdmin: {
        type: Boolean,
        default: false
      },

      readOnly: {
        type: Boolean,
        default: false
      },    
    },

    mounted() {
      this.$refs.form.resetValidation()
    },

    methods: {
      async saveClick() {
        this.loading = true
        await this.$nextTick()
        this.$emit('save')
        this.loading = false
      }
    }
}
</script>