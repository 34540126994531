<template>
 <v-container>
    <v-img src="@/assets/images/login-header.svg" contain max-height="255" />
    <v-row class="text-center mt-10 justify-center">      
      <v-col xl="6" lg="6" md="10" cols="12">
        <div class="mb-5">
          <h2 class="signin-title text-center">Forgot Password?</h2>
          <p class="signin-subtitle">Please enter your email address</p>
        </div>
        <div ref="form" class="sign-in-form">
          <v-form v-model="formValid" @submit.prevent="sendResetPwdRequest(email)" onSubmit="return false;">
            <label>Email</label>
            <v-text-field
              placeholder="username@example.com"
              v-model="email"
              :rules="[rules.required,rules.email]"
              outlined
              required
              autofocus
              class="emailText"
              @blur="email = email.toLowerCase()"
            />
            <v-btn
              type="submit"
              :disabled="!formValid"
              class="px-10 mt-10"
              color="primary"
              x-large
            >
              Reset Password
            </v-btn>
          </v-form>
        </div>
      </v-col>
      <v-col cols="12">
        <h6 class="text-center">
          Haven't created an account yet? <br />
          <v-btn
            text
            color="primary"
            x-small
            plain
            class="px-0"
            @click.prevent="$router.push({ name: 'create-user' }).catch(() => {})"
          >
            Click here
          </v-btn>
          to do it now.
        </h6>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import resetPasswordMixin from '@/mixins/resetPassword.mixin'
import rulesMixin from '@/mixins/rules.mixin'

export default {
  name: 'ForgotPassword',

  mixins: [resetPasswordMixin, rulesMixin],

  data() {
    return {
      formValid: false,
      email: ''
    }
  },
}
</script>
