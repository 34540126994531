const { nanoid } = require("nanoid")
const { firebaseStorageDeleteDirectory } = "@/services/workflow"
import $logger from '@/utils/logger.util'

export const handleFileInputs = async ({ state, form, formId, isAdmin = true }) => {
  const uploads = form.fields.filter(f => f?.type && f.type === 'file-input');
  for (let f = 0; f < uploads.length; f++) {
    const fileInputField = uploads[f];
    if (fileInputField.content?.file?.name) {
      form.fields[fileInputField.fieldsIndex] = await handleFileInput({ state, formId, formState: form.state, inputField: fileInputField, isAdmin });
    } else if (fileInputField.content && fileInputField.content.name != null) {
      form.fields[fileInputField.fieldsIndex].content = { ...fileInputField.content };
    } else {
      form.fields[fileInputField.fieldsIndex].content = {};
    }
  }
}

export const handleFileInput = async ({ state, formId, formState, inputField, isAdmin = true }) => {
  const storageRef = state.firebaseConfig.storage.ref();
  // Iterate over files and push them into storage request at appropriate storage location
  if (inputField.uid === undefined) inputField.uid = nanoid(12);
  const adminPath = `workflows-in-edit/${formId}/form-elements/file-input/${inputField.uid}`;
  const userPath = `workflows-user/${formState}/${formId}/file-input/${inputField.uid}`;
  const storagePath = isAdmin ? adminPath : userPath;

  if (inputField.content.deleteFlag) {
    await firebaseStorageDeleteDirectory(storageRef.child(inputField.storageRef));
  } else {
    const filePath = `${storagePath}/${inputField.content.file.name}`;
    await firebaseStorageDeleteDirectory(storageRef.child(filePath));
    const snapshot = await storageRef.child(filePath).put(inputField.content.file);

    const downloadURL = await snapshot.ref.getDownloadURL();
    inputField.content.url = downloadURL;
    inputField.content.storageRef = snapshot.metadata.fullPath;
    inputField.content.name = snapshot.metadata.name;
    inputField.content.file = {};
    return inputField;
  }
}