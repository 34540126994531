<template>
  <div>
    <v-row>
      <v-col cols="12">

        <div class="py-2 background-light-grey">
          <span class="my-2 black--text font-size-large font-weight-600">Expression Configuration</span>
        </div>

        <v-divider/>

        <v-row class="py-2">
          <v-col
              cols="12"
              md="6"
          >
            <v-select
                :items="conditionals"
                v-model="rule.condition"
                :readonly="isReadOnly"
                label="Conditional"
                class="solo-height-fix"
                solo
            />
          </v-col>
          <v-col
              v-if="rule.condition !== 'always'"
              cols="12"
              md="6"
          >
            <v-select
                :items="logicalOperators"
                v-model="rule.logical_operator"
                :readonly="isReadOnly"
                label="Logical Operator"
                class="solo-height-fix"
                solo
            />
          </v-col>
        </v-row>

        <v-divider/>

        <template v-if="rule.condition !== 'always'">
          <v-btn
              v-if="isReadOnly === false"
              class="my-4"
              outlined
              @click="addQuestion"
          >
            <v-icon>add</v-icon>
            <span>Add Question</span>
          </v-btn>

          <v-divider/>

          <template v-for="(question, index) in rule.questions">
            <v-row :key="index">
              <v-col cols="6" class="d-flex justify-center align-center">
                <!-- Sets answer options when changed -->
                <v-autocomplete
                    v-model="question.uid"
                    :readonly="isReadOnly"
                    :label="'Question ' + (index + 1)"
                    :items="parsedQuestionList"
                    class="py-2 text-left solo-height-fix"
                    item-text="questionListTitle"
                    item-value="uid"
                    auto-select-first
                    solo
                    @change="getSelectedQuestionAnswers(question.uid, index)"
                />
              </v-col>
              <v-col
                  v-if="showAnswerDropdown(question)"
                  cols="5"
              >
                <v-select
                    v-model="question.selection['answer']"
                    :readonly="isReadOnly"
                    :label="'Answer'"
                    :items="question.options"
                    solo
                    class="py-2 text-left solo-height-fix"
                />
              </v-col>
              <template
                  v-if="question.type === 'number-input'"
              >
                <v-col cols="3">
                  <v-select
                      v-model="question.selection.comparison"
                      :readonly="isReadOnly"
                      :label="'Comparison'"
                      :items="question.options"
                      solo
                      class="py-2 text-left solo-height-fix"
                  />
                </v-col>
                <template
                    v-if="question.selection.comparison === 'greater-than'"
                >
                  <v-col cols="2">
                    <v-text-field
                        v-model="question.selection.lowerLimit"
                        :readonly="isReadOnly"
                        type="number"
                        label="Lower Limit"
                        solo
                        class="py-2 text-left solo-height-fix"
                    />
                  </v-col>
                </template>
                <template v-if="question.selection.comparison === 'equal-to'">
                  <v-col cols="2">
                    <v-text-field
                        v-model="question.selection.equalTo"
                        :readonly="isReadOnly"
                        type="number"
                        label="Equal To"
                        solo
                        class="py-2 text-left solo-height-fix"
                    />
                  </v-col>
                </template>
                <template v-if="question.selection.comparison === 'less-than'">
                  <v-col cols="2">
                    <v-text-field
                        v-model="question.selection.upperLimit"
                        :readonly="isReadOnly"
                        type="number"
                        label="Upper Limit"
                        solo
                        class="py-2 text-left solo-height-fix"
                    />
                  </v-col>
                </template>
                <template v-if="question.selection.comparison === 'between'">
                  <v-col cols="1">
                    <v-text-field
                        v-model="question.selection.lowerLimit"
                        :readonly="isReadOnly"
                        type="number"
                        label="Lower Limit"
                        solo
                        class="py-2 text-left solo-height-fix"
                    />
                  </v-col>
                  <v-col cols="1">
                    <v-text-field
                        v-model="question.selection.upperLimit"
                        :readonly="isReadOnly"
                        type="number"
                        label="Upper Limit"
                        solo
                        class="py-2 text-left solo-height-fix"
                    />
                  </v-col>
                </template>
              </template>
              <template
                  v-if="question.type === 'multi-select' || (question.type === 'embedded-image' && question.interactionType === 'multi-select')"
              >
                <v-col cols="4">
                  <v-select
                      v-model="question.selection.answer"
                      :readonly="isReadOnly"
                      :label="'Answer'"
                      :items="question.options"
                      multiple
                      solo
                      class="pt-4 solo-height-fix"
                  />
                </v-col>
                <v-col cols="1">
                  <v-switch
                      v-model="question.selection.orLogic"
                      :readonly="isReadOnly"
                      :label="question.selection.orLogic ? 'Any' : 'All' "
                  />
                </v-col>
              </template>
              <v-col
                  cols="1"
                  class="d-flex align-center justify-center"
              >
                <v-btn
                    icon
                    v-if="isReadOnly === false"
                    class="mt-3"
                    @click="deleteQuestion(index)"
                >
                  <v-icon>cancel</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div v-if="rule && rule.logical_operator && index !== rule.questions.length - 1" class="d-flex justify-center align-center mt-4 mb-0"><span class="pa-1 border-solid-1">{{ rule.logical_operator }}</span></div>
          </template>

        </template>

      </v-col>
      <v-col
          cols="0"
          md="1"
          xl="2"
      />
    </v-row>
  </div>
</template>

<script>
import questionsMixin from "@/mixins/questions.mixin"

export default {
  name: 'DisplayLogicExpression',

  mixins: [questionsMixin],
  
  props: {
    rule: {
      type: Object,
      required: true,
      default: () => {
      },
    },

    parsedQuestionList: {
      type: Array,
      default: () => [],
    },

    isReadOnly: {
      type: Boolean,
      default: false,
    }
  },

  watch: {
    rule: {
      handler: function (newVal) {
        this.$emit('update-active-node', newVal)
      },
      deep: true
    }
  },

  data() {
    return {
      logicalOperators: ['and', 'or', 'x-or'],
      logicalOperatorSelection: '',
      conditionals: ['if', 'else', 'always'],
      conditionalSelection: '',
      answers: [],
      answerSelection: null,
    }
  },

  methods: {
    addQuestion() {
      const newQuestion = {
        uid: '',
        title: '',
        type: '',
        selection: {},
        options: []
      };

      if (!this.rule?.questions) {
        this.rule['questions'] = [];
      }
      const updatedQuestions = [...this.rule.questions, newQuestion];

      const payload = {
        uid: this.rule.uid,
        updates: {
          questions: updatedQuestions,
        }
      }

      this.$set(this.rule, 'questions', updatedQuestions);
      this.$forceUpdate();
    },

    deleteQuestion(index) {
      this.rule.questions.splice(index, 1);
    },

    getSelectedQuestionAnswers(uid, index) {
      for (let i = 0; i < this.parsedQuestionList.length; i++) {
        if (this.parsedQuestionList[i].uid === uid) {
          const currentQuestion = this.parsedQuestionList[i];

          let currentAnswerOptions = this.getSelectedQuestionAnswer(currentQuestion);

          this.rule.questions[index].uid =
              currentQuestion.uid;
          this.rule.questions[index].type =
              currentQuestion.type;
          this.rule.questions[index].options = currentAnswerOptions;
          this.$forceUpdate();
        }
      }
    },
  },
}
</script>