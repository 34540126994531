import axios from 'axios';
import { getProject } from './gcloud/getProject';

export const removeSubCollection = (body, projectUrl = getProject(), httpRequest = axios) => {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };

  return httpRequest({
    url: `${projectUrl}/deleteSubCollection`,
    method: 'POST',
    headers: config,
    data: JSON.stringify(body)
  })  
}

export const removeAllFormSubCollections = ({ id, parent }, projectUrl = getProject(), httpRequest = axios) => {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };

  return httpRequest({
    url: `${projectUrl}/deleteAllSubCollections`,
    method: 'POST',
    headers: config,
    data: JSON.stringify({ id, parent })
  })    
}