const { addActionsToArray } = require("@/services/workflow/index");

const getActionsFromRules = async ({dispatch}, rules) => {
    let ACTIONS = [];

    // Iterate over all rules to check for met criteria
    for (let r = 0; r < rules.length; r++) {
        let currentRule = rules[r];

        // Filter out rules that don't have actions or aren't of type 'Action Logic'
        if (currentRule.type === 'Action Logic' && currentRule.actions && currentRule.actions.length > 0) {
            if (currentRule.condition === 'always') {
                addActionsToArray(currentRule.actions, ACTIONS);
            } else if (currentRule.condition === 'if') {
                switch (currentRule.operator) {

                case 'and':
                    let ruleCriteria_and = true; // Default to rule being true, set to false if any question criteria isn't met

                    for (let i = 0; i < currentRule.questions.length; i++) {

                    let questionCriteria = await dispatch('checkQuestionForMatch', currentRule.questions[i]);


                    if (!questionCriteria) {
                        ruleCriteria_and = false;
                    }
                    }

                    if (ruleCriteria_and) {
                        addActionsToArray(currentRule.actions, ACTIONS);
                    }
                    break;

                case 'or':
                    let ruleCriteria_or = false; // Default to rule being false, set to true if any question criteria is met
                    for (let i = 0; i < currentRule.questions.length; i++) {
                    let questionCriteria = await dispatch('checkQuestionForMatch', currentRule.questions[i]);

                    if (questionCriteria) {
                        ruleCriteria_or = true;
                    }
                    }

                    if (ruleCriteria_or) {
                        addActionsToArray(currentRule.actions, ACTIONS);
                    }
                    break;

                case 'not':
                    let ruleCriteria_not = true; // Default to rule being true, set to false if any question criteria is met

                    for (let i = 0; i < currentRule.questions.length; i++) {

                    let questionCriteria = await dispatch('checkQuestionForMatch', currentRule.questions[i]);

                    if (questionCriteria) {
                        ruleCriteria_not = false;
                    }
                    }

                    if (ruleCriteria_not) {
                        addActionsToArray(currentRule.actions, ACTIONS);
                    }
                    break;

                case 'x-or':
                    let num_question_criteria_met = 0; // Holds number of question criteria that has been met

                    for (let i = 0; i < currentRule.questions.length; i++) {
                    let questionCriteria = await dispatch('checkQuestionForMatch', currentRule.questions[i]);

                    if (questionCriteria) {
                        num_question_criteria_met++;
                    }
                    }

                    if (num_question_criteria_met === 1) {
                        addActionsToArray(currentRule.actions, ACTIONS);
                    }
                    break;
                }
            }
        }
    }

    return ACTIONS;
}

module.exports = getActionsFromRules