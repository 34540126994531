import {mapGetters, mapActions} from 'vuex';
import workflowBuilderConstants from "@/constants/workflowBuilderConstants";
import {nanoid} from "nanoid";
import MultiOptionAdd from "@/components/Common/MultiOptionAdd";

export default {
  components: {MultiOptionAdd},
  props: {
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    currentPageNumber: {
      type: Number,
      required: false,
      default: 1,
    },

    currentPageIsBlank: {
      type: Boolean,
      required: false,
      default: false,
    },

    defaultHeight: {
      type: String | Number,
      default: '100%'
    }
  },

  data() {
    return {
      searchActions: "",
      activeActionType: null,
      selectionActionIndex: 0,
    };
  },

  computed: {
    ...mapGetters({
      currentDoc: 'getWorkflowBuilderCurrentDoc',
      currentRule: 'workflow/getWorkflowBuilderCurrentRule',
      currentAction: 'workflow/getWorkflowBuilderCurrentAction',
    }),

    actions() {
      if (this.currentDoc) {
        const _self = this;
        setTimeout(() => {
          _self.activateAction(_self.selectionActionIndex);
        }, 1000);
        const currentActions = this.currentDoc.rules[this.currentRule].actions;
        if(currentActions.length > 0 && (this.searchActions && this.searchActions.length >= 3)) {
          return currentActions.filter(a => (a.title && a.type) && (a.title.toLowerCase().indexOf(this.searchActions.toLowerCase()) >= 0 || a.type.toLowerCase().indexOf(this.searchActions.toLowerCase()) >= 0))
        }
        return currentActions
      } else {
        return [];
      }
    },

    showSearch() {
      return this.currentDoc?.rules[this.currentRule]?.actions?.length > 10 || false
    }
  },

  beforeCreate() {
    // Initializing
    this.ACTION_TYPES = workflowBuilderConstants.actionTypes;
  },

  mounted() {
    this.selectionActionIndex = this.actions.length === 0 ? -1 : 0;
    this.activateAction(this.selectionActionIndex);
  },

  methods: {
    ...mapActions({
      addAction: 'addAction',
      deleteAction: 'workflow/deleteAction',
      updateDocumentRender: 'updateDocumentRender',
      toggleLoadingOverlay: 'toggleLoadingOverlayStatus',
    }),

    ...mapActions("confirmation", ["confirm"]),

    activateAction(index) {
      if(index < 0 || !this?.actions?.[index]?.location?.page) return
      this.$emit('page-change', this.actions[index].location.page)
      this.$store.dispatch("workflow/setWorkflowBuilderCurrentAction", index);
    },

    addNewAction(type) {
      // If no action type is selected, do nothing
      if (!type) {
        return;
      }

      let newAction;
      switch (type) {
        case "add-x":
          newAction = {
            uid: nanoid(12),
            title: 'New Add X',
            type,
            location: {
              page: this.currentPageNumber,
              x: 1,
              y: 1,
            },
            size: 'md'
          };
          break;
        case 'add-check-mark':
          newAction = {
            uid: nanoid(12),
            title: 'New Check Mark',
            type,
            location: {
              page: this.currentPageNumber,
              x: 1,
              y: 1,
            },
            size: 'md'
          };
          break;
        case 'text-from-question':
          newAction = {
            uid: nanoid(12),
            title: 'New Input From Question',
            type,
            location: {
              page: this.currentPageNumber,
              startX: 0.4 * 100,
              startY: 0.4 * 100,
              width: 0.2 * 100,
              height: 0.2 * 100,
            },
            fontSize: '12',
            fontFamily: 'Times New Roman',
            textDecoration: null,
            textColor: 'black',
            question: null,
          };
          break;
        case 'text-static':
          newAction = {
            uid: nanoid(12),
            title: 'New Static Text',
            type,
            location: {
              page: this.currentPageNumber,
              startX: 0.4 * 100,
              startY: 0.4 * 100,
              width: 0.2 * 100,
              height: 0.2 * 100,
            },
            fontSize: '12',
            fontFamily: 'Times New Roman',
            textDecoration: null,
            textColor: 'black',
            staticText: 'Example static text. Enter text in \'Current Action\' panel to change this text.',
          };
          break;
        case 'text-editor':
          newAction = {
            uid: nanoid(12),
            title: 'New Text Editor',
            type,
            location: {
              page: this.currentPageNumber,
              firstPageMargins: {
                top: 1.0,
                bottom: 1.0,
                right: 1.0,
                left: 1.0,
              },
              laterPageMargins: {
                top: 1.0,
                bottom: 1.0,
                right: 1.0,
                left: 1.0,
              },
            },
            pageIsBlank: this.currentPageIsBlank,
            savedData: null,
            blocks: [],
            autoNumberingLevels: [],
            fontSize: 12,
            fontFamily: 'Times New Roman',
          };
          break;
        case 'custom-image':
          newAction = {
            uid: nanoid(12),
            title: 'New Custom Image',
            type,
            variant: 'image-from-survey',
            location: {
              page: this.currentPageNumber,
              startX: 0.4 * 100,
              startY: 0.4 * 100,
              width: 0.2 * 100,
              height: 0.2 * 100,
            },
            image: undefined,
          };
          break;
        case 'pet-signature-img':
          newAction = {
            uid: nanoid(12),
            title: 'New Petitioner Signature Image',
            type,
            location: {
              page: this.currentPageNumber,
              startX: 0.4 * 100,
              startY: 0.4 * 100,
              width: 40,
              height: 20,
            },
            image: undefined,
          };
          break;
        case 'resp-signature-img':
          newAction = {
            uid: nanoid(12),
            title: 'New Respondent Signature Image',
            type,
            location: {
              page: this.currentPageNumber,
              startX: 0.4 * 100,
              startY: 0.4 * 100,
              width: 40,
              height: 0.2 * 100,
            },
            image: undefined,
          };        
          break;
        case 'pet-signature-date':
          newAction = {
            uid: nanoid(12),
            title: 'New Petitioner Signature Date',
            type,
            location: {
              page: this.currentPageNumber,
              startX: 0.4 * 100,
              startY: 0.4 * 100,
              width: 20,
              height: 0.2 * 100,
            },
            fontSize: '12',
            fontFamily: 'Times New Roman',
            textDecoration: null,
            textColor: 'black',
            staticText: 'MM/DD/YYYY',
          };
          break;
        case 'resp-signature-date':
          newAction = {
            uid: nanoid(12),
            title: 'New Respondent Signature Date',
            type,
            location: {
              page: this.currentPageNumber,
              startX: 0.4 * 100,
              startY: 0.4 * 100,
              width: 20,
              height: 0.2 * 100,
            },
            fontSize: '12',
            fontFamily: 'Times New Roman',
            textDecoration: null,
            textColor: 'black',
            staticText: 'MM/DD/YYYY',
          };
          break;           
      }

      this.selectionActionIndex = this.actions.length;
      this.toggleLoadingOverlay(true)
      this.addAction({
        ruleIndex: this.currentRule,
        action: newAction,
      }).then(() => {        
        this.updateDocumentRender();
        this.toggleLoadingOverlay(false)
        this.activateAction(this.selectionActionIndex);
      });
    },

    deleteOldAction(index, ask = true) {
      if (ask) {
        this.confirm({
          title: 'Are you sure you want to delete this action?',
          accepted: () => removeAction()
        })
      } else {
        removeAction()
      }

      const removeAction = () => {
        this.toggleLoadingOverlay(true)
        this.deleteAction(index).then(() => {        
          this.updateDocumentRender();
          if(index > 0)
            this.selectionActionIndex = index - 1;
          this.toggleLoadingOverlay(false)
        });
      }
    },
  },
}