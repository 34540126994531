const clearFormFields = (fields) => {
  for (let i = 0; i < fields.length; i++) {
    const currentField = fields[i];

    // Clear answered status
    currentField.answeredStatus = false;

    // Clear selections
    switch (currentField.type) {
      case "multi-select":
        currentField.selection = [];
        break;
      case "single-select":
      case "state-select":
        currentField.selection = currentField?.default || '';
        currentField.answeredStatus = !!currentField?.default
        break;
      case "embedded-image":
        if (currentField.interactionType === "multi-select") {
          currentField.selection = [];
        } else {
          currentField.selection = "";
        }
        break;
      case "number-input":
      case "text-input-short":
      case "text-input-long":
        currentField.content = currentField?.default || '';
        currentField.answeredStatus = !!currentField?.default
        break;
      case "date-picker":
        currentField.dateSingle = "";
        currentField.dateValue = "";
        currentField.dateMultiple = "";
        break;
      case "item-table":
        currentField.inputRows = [];
        break;
      case "signature-pad":
        currentField.signature = {};
        break;
      case "file-input":
        currentField.content = {};
        break;
    }
  }

  return fields;
}

module.exports = clearFormFields