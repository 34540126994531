import sendEmailUtil from "@/utils/send-email.util";
import $logger from "@/utils/logger.util";

const defaultState = () => {
  return {
    user: {
      is_petitioner: true,
      respondent_data: {
        petitioner_email: "",
      },
      petitioner_data: {
        respondent_email: "",
        respondent_first_name: "",
        respondent_last_name: "",
      },
      email: "",
      forms: [],
      formAdmin: false,
      gender: "",
      date_of_birth: "",
      first_name: "",
      last_name: "",
      full_name: "",
      address_street_1: "",
      address_street_2: "",
      address_city: "",
      address_state: "",
      address_zip_code: "",
      phone_number: "",
      permission_to_contact: null,
      role: "user-basic",
    },

    sharedFormId: null,

    myStates: [],

    payments: [],
  };
};

export const profile = {
  namespaced: true,

  state: defaultState(),

  getters: {
    sharedFormId: (state) => state.sharedFormId,

    myStates: (state) => state.myStates,

    userInfo: (state) => state.user,

    userEmail: (state) => state.user.email,

    workflowIdByParent: (s) => (parentId) =>
      s.user.forms.find((f) => f.parentId === parentId),

    parentByWorkflowId: (s) => (formId) =>
      s.user.forms.find((f) => f.workflowsUserId === formId).parentId,

    isAdmin: (state) =>
      ["admin-global", "admin-form"].indexOf(state.user.role) > -1,

    getPayments: (state) => state.payments,

    alreadyUsedToken:
      (state) =>
      (token, level = 1) =>
        Object.values(state.payments).findIndex(
          (p) => p[`level_${level}`] === token
        ) >= 0,

    getPaymentsByFormId: (state) => (formId) => state.payments[formId],

    formHasPaymentForUser:
      (state) =>
      (formId, levelId = 1) =>
        state.payments?.[formId] &&
        state.payments[formId]?.["level_" + levelId],
  },

  mutations: {
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },

    SET_SHARED_FORM_ID(state, payload) {
      state.sharedFormId = payload;
    },

    SET_UPDATE_USER(state, user) {
      for (const property in user) {
        state.user[property] = user[property];
      }
    },

    SET_MY_STATES(state, payload) {
      state.myStates = payload;
    },

    USER_SIGN_OUT(state) {
      state.user = {
        email: "",
        forms: [],
        formAdmin: false,
        first_name: "",
        last_name: "",
        full_name: "",
        address_street_1: "",
        address_street_2: "",
        address_city: "",
        address_state: "",
        address_zip_code: "",
        phone_number: "",
        permission_to_contact: null,
        role: "basic-user",
      };
    },

    SET_PAYMENTS(state, payload) {
      state.payments = payload;
    },
  },

  actions: {
    resetState({ commit }) {
      commit("RESET_STATE");
    },

    async isSharedUser({ rootState, commit, getters }) {
      const fb = rootState.firebaseConfig;
      const status = await fb.db
        .collection("users")
        .doc(getters.userInfo.respondent_data.petitioner_email)
        .get();
      if (status.exists) {
        const petitioner = status.data();
        if (petitioner?.forms?.length > 0) {
          const wfid = petitioner.forms[0].workflowsUserId;
          commit("SET_SHARED_FORM_ID", wfid);
        } else {
          location.href = "/wait";
        }
      } else {
        location.href = "/wait";
      }
      return;
    },

    async removeFormById({ dispatch, getters }, formId) {
      const userData = getters.userInfo;
      if (!userData.forms || userData.forms.length === 0) return;
      userData.forms = userData.forms.filter(
        (f) => f.workflowsUserId !== formId
      );
      await dispatch("userUpdate", userData);
      return;
    },

    async removeAllForms({ dispatch, getters }) {
      const userData = getters.userInfo;
      userData.forms = [];
      await dispatch("userUpdate", userData);
      return;
    },

    async removeAllPayments({ commit, rootState, getters }) {
      const userData = getters.userInfo;
      const paymentRef = rootState.firebaseConfig.db
        .collection("users")
        .doc(userData.email)
        .collection("payments");
      const paymentIds = (await paymentRef.get()).docs.map((p) => p.id);
      for (let p = 0; p < paymentIds.length; p++) {
        await paymentRef.doc(paymentIds[p]).delete();
      }
      commit("SET_PAYMENTS", {});
      return;
    },

    async userSignIn({ rootState, commit, dispatch, getters }, { userAuth }) {
      try {
        const user =
          await rootState.firebaseConfig.auth.signInWithEmailAndPassword(
            userAuth.email,
            userAuth.password
          );
        if (!user.user.emailVerified) {
          const message =
            "Sorry, you have not verified your email. Please check your email.";
          const warningObj = {
            redirect: false,
            type: "warning",
            message,
          };

          rootState.firebaseConfig.auth.currentUser.sendEmailVerification({
            url: `${rootState.baseUrl}/sign-in`,
          });
          dispatch("userSignOut", warningObj);
          return warningObj;
        } else {
          let userEmail = user.user.email;
          const auth = await rootState.firebaseConfig.auth.currentUser.metadata;
          try {
            const userRecord = await rootState.firebaseConfig.db
              .collection("users")
              .doc(userEmail)
              .get();
            if (userRecord.exists) {
              let userData = userRecord.data();
              let localUserData = Object.assign(
                {},
                { ...userData, uid: user.user.uid }
              );

              await rootState.firebaseConfig.db
                .collection("users")
                .doc(userEmail)
                .set(
                  {
                    last_sign_in: new Date(auth.lastSignInTime).toUTCString(),
                    created_at: new Date(auth.creationTime).toUTCString(),
                  },
                  { merge: true }
                );
              commit("SET_UPDATE_USER", localUserData);
              return { type: "success" };
            } else {
              return {
                type: "warning",
                message:
                  "The profile associated with the email address '" +
                  userEmail +
                  "' cannot be found. Please try to sign in again, or if this error persists, contact customer support.",
              };
            }
          } catch (error) {
            return {
              type: "error",
              message: "We experienced the following error: \n" + error,
            };
          }
        }
      } catch (error) {
        let errorCode = error.code;
        let errorMessage = error.message;

        if (errorCode === "auth/wrong-password") {
          return {
            type: "error",
            message: "Wrong password.",
          };
        } else if (errorCode === "auth/user-not-found") {
          return {
            type: "error",
            message:
              "Sorry, could not find this email address in our system. Please try again",
          };
        } else {
          return {
            type: "error",
            message: errorMessage,
          };
        }
      }
    },

    async getMyStates({ rootState, commit }, email) {
      const records = await rootState.firebaseConfig.db
        .collection("workflows-user")
        .where("owner.email", "==", email)
        .get();
      if (!records.empty) {
        const states = [];
        records.forEach((r) => {
          const doc = r.data();
          if (states.findIndex((s) => s.state === doc.state) < 0) {
            states.push(doc);
          }
        });

        commit("SET_MY_STATES", states);
        return;
      } else {
        return "empty";
      }
    },

    async userCreateNew(
      { rootState, dispatch },
      { userData }
    ) {
      try {
        const user =
          await rootState.firebaseConfig.auth.createUserWithEmailAndPassword(
            userData.email,
            userData.password
          );
        let userRef = user.user;
        let localUserData = {};

        localUserData.role = "user-basic";

        for (const property in userData) {
          if (property !== "password") {
            localUserData[property] = userData[property];
          }
        }

        await userRef.sendEmailVerification({
          url: `${rootState.baseUrl}/sign-in`,
        });
        if (localUserData.is_petitioner === true) {
          const pd = localUserData.petitioner_data;
          const spouse = {
            name: pd.respondent_first_name + " " + pd.respondent_last_name,
            email: pd.respondent_email,
          };
          const link = `create-user?petitioner=${localUserData.email}&respondent=${pd.respondent_email}`;
        }
        await dispatch("createProfile", { localUserData, userRef });
      } catch (error) {
        var errorCode = error.code;
        var errorMessage = error.message;

        // [START_EXCLUDE]
        if (errorCode === "auth/weak-password") {
          await dispatch(
            "alert/setAlert",
            {
              type: "error",
              message: "The password is too weak.",
            },
            { root: true }
          );
        } else {
          await dispatch(
            "alert/setAlert",
            {
              type: "error",
              message: errorMessage,
            },
            { root: true }
          );
        }
        // [END_EXCLUDE]
      } finally {
        return;
      }
    },

    async createProfile({ rootState, commit }, { localUserData, userRef }) {
      await rootState.firebaseConfig.db
        .collection("users")
        .doc(userRef.email)
        .set(localUserData, { merge: true })
      commit("SET_UPDATE_USER", localUserData)
    },

    userUpdate({ rootState, commit, dispatch }, userData) {
      return new Promise((resolve, reject) => {
        let userRef = rootState.firebaseConfig.auth.currentUser;
        let userUID = userRef.uid;
        let localUserData = {};

        for (const property in userData) {
          localUserData[property] = userData[property];
          localUserData["email"] = userRef.email;
        }

        rootState.firebaseConfig.db
          .collection("users")
          .doc(userRef.email)
          .set(localUserData, { merge: true })
          .then(() => {
            commit("SET_UPDATE_USER", localUserData);
            resolve();
            dispatch("alert/setAlert", {
              type: "error",
              message: "Your profile is updated",
            });
          })
          .catch((error) => {
            reject("Error in merging user data");
          });
      });
    },

    async userSignOut(
      { rootState, commit, dispatch },
      { msg = "", type = "", redirect = true }
    ) {
        await Promise.all([
          dispatch("resetState"),
          dispatch("resetState", { root: true }),
          dispatch("publishGuide/resetState", null, { root: true }),
          dispatch("workflow/resetState", null, { root: true }),
          dispatch("workflowPublished/resetState", null, { root: true }),
          dispatch("comments/setComments", [], { root: true }),
          dispatch("coupons/resetState", null, { root: true }),
          dispatch("statisticsLogic/resetState", null, { root: true }),
          dispatch("users/resetState", null, { root: true }),
          dispatch("counties/resetState", null, { root: true })
        ])
        await rootState.firebaseConfig.auth.signOut();
        commit("USER_SIGN_OUT");
        if (redirect === true) {
          setTimeout(() => {
            location.href = `/sign-in?type=${type}&msg=${msg}`;
          }, 1000)
        }
      return;
    },

    async setPayments({ commit, rootState }, userEmail) {
      const paymentData = await rootState.firebaseConfig.db
        .collection("users")
        .doc(userEmail)
        .collection("payments")
        .get();
      const payload = {};
      paymentData.forEach((p) => {
        payload[p.id] = p.data();
      });
      commit("SET_PAYMENTS", payload);
      return;
    },

    establishUserAuth({ commit, dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        rootState.firebaseConfig.auth.onAuthStateChanged((user) => {
          if (user && user.emailVerified) {
            let userEmail = user.email;
            let uid = user.uid;
            const userRef = rootState.firebaseConfig.db
              .collection("users")
              .doc(userEmail);
            dispatch("setPayments", userEmail);
            userRef
              .get()
              .then((userRecord) => {
                if (userRecord.exists) {
                  let userData = userRecord.data();
                  let localUserData = Object.assign({}, { ...userData, uid });

                  commit("SET_UPDATE_USER", localUserData);
                  resolve("user exists");
                } else {
                  reject(
                    "The profile associated with the email address '" +
                      userEmail +
                      "' cannot be found. Please try to sign in again, or if this error persists, contact customer support."
                  );
                }
              })
              .catch((error) => {
                reject("We experienced the following error: \n" + error);
              });
          } else {
            reject("user email has not been verified");
          }
        });
      });
    },
  },
};
