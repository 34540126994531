import Firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import axios from 'axios';
import { getProject } from '@/services/gcloud/getProject';
import store from '@/stores'

export const getFirebaseConfig = async () => {
  const resp = await axios({
    method: 'GET',
    url: `${getProject()}/getSecret?key=FIRE_BASE_CONFIG`,
  });

  const firebaseConfig = resp.data;

  const app = Firebase.initializeApp(firebaseConfig);

  const db = app.firestore();
  // if (location.hostname === "localhost") {
  //   db.useEmulator("localhost", 9299);
  // }
  const auth = app.auth();
  // auth.useEmulator('http://localhost:9099');
  const storage = app.storage();
  // storage.useEmulator("localhost", 9199);
  const functions = app.functions();
  // functions.useEmulator('localhost', 5001);

  const fbConfig = {
    db: db,
    auth: auth,
    storage: storage,
    functions: functions,
  };

  window.$collectionForms = fbConfig.db.collection("forms")
  store.dispatch("setFirebaseConfig", fbConfig)
  return "Successfully connected to Firebase"
}