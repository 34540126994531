const sizeOf = require('image-size')
const getImageHeightWidth = (image, dividedBy = 1) => {
    const base64 = image.split(',').pop()
    const dataUrl = Buffer.from(base64,'base64')
    const dim = sizeOf(dataUrl)
    const width = dim.width / dividedBy
    const height = dim.height / dividedBy
    return {
        height,
        width,
        ratio: width/height
    }
}

module.exports = {
    getImageHeightWidth
}