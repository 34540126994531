const axios = require('axios')

const imgDataToFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

const urlExists = async (url, httpRequest = axios) => {
  try {
    const resp = await httpRequest.get(url)
    return resp.status === 200
  } catch {
    return false
  }
}

module.exports = {
  imgDataToFile,
  urlExists
}