<template>
  <v-form id="profile-form" v-model="userFormValidity">
    <v-row class="justify-center">
      <v-col cols="12" sm="6">
        <v-text-field
          v-model.trim="userProfile.first_name"
          :rules="[rules.required]"
          label="Legal First Name"
          outlined
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model.trim="userProfile.last_name"
          :rules="[rules.required]"
          label="Legal Last Name"
          outlined
        />
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" sm="4">
        <v-text-field
          v-mask="'##/##/####'"
          placeholder="MM/DD/YYYY"
          v-model.trim="userProfile.date_of_birth"
          autocomplete="chrome-off"
          label="Date of Birth"
          :rules="[rules.date, rules.dateLength, rules.adult, rules.required]"
          outlined
          clearable
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          v-model="userProfile.gender"
          :items="['Male', 'Female']"
          label="Gender"
          hint="This field is optional"
          :persistent-hint="$route.path === '/create-user'"
          clearable
          outlined
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-mask="'###-###-####'"
          placeholder="###-###-####"
          v-model="userProfile.phone_number"
          :rules="[rules.lengthPhoneNumber, rules.phone, rules.required]"
          label="Phone Number"
          type="tel"
          outlined
        />
      </v-col>
    </v-row>
    <v-row class="justify-center">      
      <v-col cols="12" sm="4">
        <b>Address</b>
      </v-col>
    </v-row>    
    <v-row class="justify-center">
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="userProfile.address_street_1"
          :rules="[rules.required]"
          label="Address Line 1"
          outlined
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model.trim="userProfile.address_street_2"
          label="Address Line 2"
          hint="This field is optional"
          persistent-hint
          outlined
        />
      </v-col>
    </v-row>
    <v-row class="justify-center">      
      <v-col cols="12" sm="4">
        <v-text-field
          v-model.trim="userProfile.address_city"
          :rules="[rules.required]"
          label="City"
          outlined
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          v-model="userProfile.address_state"
          :items="getStates"
          :rules="[rules.required]"
          label="State"
          outlined
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="userProfile.address_zip_code"
          :rules="[rules.lengthZipCode, rules.numeric, rules.required]"
          label="Zip Code"
          outlined
        />
      </v-col>
    </v-row>
    <slot name="acceptance" />
    <slot name="buttons" />
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProfileForm",

  watch: {
    userFormValidity(newVal) {
      this.$emit("formValid", newVal)
    }
  },

  props: {
    rules: {
      type: Object,
      default: () => {}
    },

    userProfile: {
      type: Object,
      default: () => ({
        gender: "",
        date_of_birth: "",
        first_name: "",
        last_name: "",
        full_name: "",
        address_street_1: "",
        address_street_2: "",
        address_city: "",
        address_state: "",
        address_zip_code: "",
        phone_number: "",
        permission_to_contact: null,
      })
    }
  },

  data() {
    return {
      dateModal: false,
      show1: false,
      userFormValidity: false,
    }
  },

  computed: {
    ...mapGetters(["getStates"])
  }
}
</script>
