import parsedQuestionListMixin from "./parsed-question-list.mixin";

export default {
  mixins: [parsedQuestionListMixin],

  data() {
    return {
      textQuestions: [
        "single-select",
        "state-select",
        "multi-select",
        "number-input",
        "text-input-short",
        "text-input-long",
        "date-picker",
        "embedded-image",
        "item-table",
        "computed-property",
        "grouped-options",
      ],
    };
  },

  computed: {
    parsedTextQuestionsList() {
      return this.form.fields.filter(
        (element) => this.textQuestions.indexOf(element.type) > -1
      );
    },

    parsedImageFormElementsList() {
      let list = this.form.fields.filter((element) => {
        return (
          ["embedded-image", "file-input", "signature-pad"].indexOf(
            element.type
          ) > -1
        );
      });
      return list.map((element) => {
        return {
          questionListTitle: element.questionListTitle,
          title: element.title,
          uid: element.uid,
        };
      });
    },
  },
};
