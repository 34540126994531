import deleteUserService from "../services/deleteUser.service";
import {
  removeSubCollection,
  removeAllFormSubCollections,
} from "../services/removeSubCollection.service";
import getUsersService from "../services/getUsers.service";
import { getDateFormat } from "@/utils/getDateFormat.util";
import petitionerSteps from "../constants/petitionerSteps";
import dateSort from "../utils/dateSort.util";
import $logger from '@/utils/logger.util'
import getDbProfilesService from '@/services/getDbProfiles.service'

const defaultState = () => ({
  users: [],
  dbProfiles: [],
  usersForCommentHistory: [],
})

export const users = {
  namespaced: true,

  state: defaultState,

  getters: {
    userProfiles: (state) => {
      return state.users.filter(u => u.role === 'user-basic').map((u) => {
        u['verified'] = state.dbProfiles.findIndex(p => p.email === u.email && p.emailVerified) > -1 ? 'Yes' : 'No'
        u.is_petitioner = u?.is_petitioner ? "Yes" : "No";
        u.created_at = getDateFormat(u.created_at, "MM/DD/YY");
        u.last_sign_in = getDateFormat(u.last_sign_in, "MM/DD/YY");
        return u;
      });
    },
    users: (state) => state.users,
    dbProfiles: (state) => state.dbProfiles,
    usersForCommentHistory: (state) =>
      state.usersForCommentHistory.sort((a, b) =>
        dateSort(b.modifiedDate, a.modifiedDate)
      ),
  },
  
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async getDbProfiles({ commit }) {
      const profileData = await getDbProfilesService()
      commit('SET_DB_PROFILES', profileData)
    },

    async getUsers({ commit }) {
      const resp = await getUsersService();
      const users = resp.data.filter(u => u?.email);
      users.forEach((u) => {
        if(u?.full_name) {
          const fullName = u.full_name.trim().split(" ");
          u.full_name = fullName.length === 1 ? fullName : fullName[1] + ", " + fullName[0];
        } else {
          $logger.info(`Cannot find fullname for: ${u.id}`)
        }
      });
      commit("SET_USERS", users);
      return;
    },

    async getUsersForCommentHistory({ commit, rootState }) {
      const items = (
        await rootState.firebaseConfig.db.collection("workflows-user").get()
      ).docs.map((d) => ({ ...d.data() }));
      let users = [];
      for(let u = 0; u < items.length; u++) {
        const user = items[u]
        if(!user?.owner?.name) continue
        const ownerName = user.owner.name
          .replace(/\s+/g, " ")
          .trim()
          .split(" ");
        user.owner.name =
          ownerName.length === 1
            ? ownerName
            : ownerName[ownerName.length - 1] + ", " + ownerName[0];
        if (user.sharingProps?.length) {
          const respondentName = user.sharingProps[0].user.name
            .replace(/\s+/g, " ")
            .trim()
            .split(" ");
          user.sharingProps[0].user.name =
            respondentName.length === 1
              ? respondentName
              : respondentName[respondentName.length - 1] +
                ", " +
                respondentName[0];
        } else {
          const respText =
            user.hasOwnProperty("collaborate") && !user.collaborate
              ? "Not Applicable"
              : "Not Registered";
          user.sharingProps.push({
            user: {
              name: respText,
              email: "",
            },
          });
        }
        const step = user?.step || 1;
        user["stepDesc"] = `${step}. ${petitionerSteps[step]}`;
        let collaborateText = "Yes";
        if (user.hasOwnProperty("collaborate")) {
          collaborateText = user.collaborate ? "Yes" : "No";
        }
        user["collaborate"] = collaborateText;
        users.push(user);
      }
      commit("SET_USERS_FOR_COMMENT_HISTORY", users);
      return;
    },

    async deleteUser({ state, commit, rootState }, email) {
      if(!email) {
        $logger.warn('No email passed')
      }
      const parent = "workflows-user";
      const userRef = await rootState.firebaseConfig.db
        .collection(parent)
        .where("owner.email", "==", email)
        .get()
      await removeSubCollection({
        parent: "users",
        id: email,
        sub: "payments",
      })

      if(userRef?.docs?.length > 0) {
        const subCollectionsToDelete = userRef.docs.map(u => removeAllFormSubCollections({ parent, id: u.id }))
        await Promise.all([...subCollectionsToDelete]).catch(error => this.$logger.warn('Problem deleting user subcollections', error))
      }
      
      try {
        const resp = await deleteUserService(email)
        if(resp.status === 204) {
          $logger.info(resp.data)
        }
      } catch(error) {
        if(error.response.status !== 404) {
          throw error
        }
      }
      const index = state.users.findIndex(
        (u) => u.email?.toLowerCase() === email?.toLowerCase()
      );
      if (index >= 0) {
        commit("REMOVE_USER", index);
      }
      return;
    },

    async resetUserWorkflow({ rootState }, email) {
      const db = rootState.firebaseConfig.db;
      const batch = db.batch();
      const wfUserCollection = db.collection("workflows-user");
      const userRef = await wfUserCollection
        .where("owner.email", "==", email)
        .get();
      userRef.forEach((u) => {
        batch.set(
          wfUserCollection.doc(u.id),
          { step: 1, respondentStep: 1, sharingProps: [] },
          { merge: true }
        );
        //wfUserCollection.doc(u.id).set({ step: 1, respondentStep: 1, sharingProps: [] }, { merge: true })
      });
      await batch.commit();
      return;
    },

    async removeForms({ rootState }, email) {
      const userRef = rootState.firebaseConfig.db
        .collection("users")
        .doc(email);
      const userExists = (await userRef.get()).exists;
      if (userExists) await userRef.set({ forms: [] }, { merge: true });

      const paymentIds = (await userRef.collection("payments").get()).docs.map(
        (p) => p.id
      );
      for (let p = 0; p < paymentIds.length; p++) {
        await userRef.collection("payments").doc(paymentIds[p]).delete();
      }
      return;
    },
  },

  mutations: {
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
        
    SET_DB_PROFILES(state, payload) {
      state.dbProfiles = payload
    },

    SET_USERS(state, payload) {
      state.users = payload;
    },

    SET_USERS_FOR_COMMENT_HISTORY(state, payload) {
      state.usersForCommentHistory = payload;
    },

    REMOVE_USER(state, index) {
      state.users.splice(index, 1);
    },
  },
};
