const dayjs = require('dayjs')
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
const _dayjs = dayjs
const getDateFormat = (date = dayjs(), format = 'MM/DD/YYYY') => {
    let formatToUse = format
    if(typeof date === 'string') {
      date = date.replace(/nd|st|rd|th/g,'')
    }
    switch (format) {
        case 'MMMM Do, YYYY':
        case 'Month Day, Year':
          formatToUse = 'MMMM Do, YYYY';
          break;
        case 'this **th day of Month 20**':
          formatToUse = '[this] Do [day of] MMMM YYYY'
          break;
    }
    return _dayjs(date).format(formatToUse);
}

module.exports = { getDateFormat }