<template>
    <v-dialog
      v-model="shareSurveyDialog.visible"
      :retain-focus="false"
      scrollable
      max-width="40%">
      <v-card>
        <v-card-title>Share Survey</v-card-title>
        <v-divider />
        <v-card-subtitle>
          <v-form ref="form">
            <v-row class="my-5 text-left">
              <v-col cols="6">
                <p>Spouse name and lastname</p>
                <p v-if="!partner.firstName" class="font-weight-600">
                  <span class="text-danger">First name is missing</span> {{partner.lastName}}
                </p>
                <p v-else-if="!partner.lastName" class="font-weight-600">
                  {{partner.firstName}} <span class="text-danger">Last name is missing</span>
                </p>
                <p v-else class="font-weight-600">
                  {{partner.firstName + ' ' + partner.lastName}}
                </p>
              </v-col>

              <v-col cols="6">
                <p>Spouse email</p>
                <p v-if="!partner.email" class="font-weight-600 text-danger">
                  Email is missing
                </p>
                <p v-else class="font-weight-600">{{partner.email}}</p>
              </v-col>

            </v-row>
          </v-form>
          <v-row>
            <h5 style="text-align: left; width: 100%">Note: </h5>
            <p>
              Spouse will be able to view and add comments to the document.
            </p>
          </v-row>
        </v-card-subtitle>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="shareSurveyDialog.visible = false">
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              v-if="partner.email"
              text @click="processShareForm({ name: `${partner.firstName} ${partner.lastName}`, email: partner.email })">
            Share
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "ShareFormDialog",

  props: {
    shareSurveyDialog: {
      type: Object,
      default: () => ({
        visible: false
      })
    },

    partner: {
      type: Object,
      default: () => ({
        firstName: '',
        lastName: ''
      })
    },
  },

  data() {
    return {
      sharing: {
        isNew: true,
        access: true,
        reviewType: "",
        agreedWithSpouse: false,
        user: {
          confirmation: {},
          email: "",
          name: "",
          firstAccessed: null,
          lastAccessed: null,
        },
      },
    }
  },

  computed: {
    ...mapGetters("workflowPublished", ["sharingData"]),
  },

  methods: {
    ...mapActions(["toggleLoadingOverlayStatus"]),

    ...mapActions("workflowPublished", [
      "addShareData",
      "shareForm",
      "deleteShare",
    ]),

    processShareData() {
      this.addShareData(this.sharing);
      this.sharing = {
        isNew: true,
        access: true,
        reviewType: "",
        agreedWithSpouse: false,
        user: {
          confirmation: {},
          email: '',
          name: '',
          firstAccessed: null,
          lastAccessed: null,
        },
      };
    },

    processShareForm({ name, email }) {
      this.sharing = {
        isNew: true,
        access: true,
        reviewType: "",
        agreedWithSpouse: false,
        user: {
          confirmation: {},
          email,
          name,
          firstAccessed: null,
          lastAccessed: null,
        },
      };
      this.processShareData()
      const newInviteeAdded = this.sharingData.find(
        (inviteeUser) => inviteeUser.isNew
      );

      if (newInviteeAdded) {
        this.toggleLoadingOverlayStatus(true)
        this.shareForm().then(() => {
          this.toggleLoadingOverlayStatus(false)
          this.$emit("status", "success")
        });
      } else {
        this.$emit("status", "error")
      }
    },
  }
}
</script>
