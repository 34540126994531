export const analytics = {
  namespaced: true,

  state: () => ({
    gaID: '',
    awID: '',
    paywallIds: []
  }),

  getters: {
    awID: state => state.awID,
    paywallIds: state => state.paywallIds
  },

  mutations: {
    SET_AW_ID(state, payload) {
      state.awID = payload
    },

    SET_GA_ID(state, payload) {
      state.gaID = payload
    },

    SET_PAYWALL_IDS(state, payload) {
      state.paywallIds = payload
    }
  },

  actions: {
    init({ commit }, { gaID, awID, paywallIds }) {
      commit('SET_GA_ID', gaID)
      commit('SET_AW_ID', awID)
      commit('SET_PAYWALL_IDS', paywallIds)
    }
  }
}