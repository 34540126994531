<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <v-card tile class="mb-6">
          <v-card-title class="bg-primary white--text">
            Documents
          </v-card-title>
          <v-card-text>
            <v-row class="mt-2">
              <v-col
                  cols="11"
                  class="d-flex justify-center align-center"
              >
                <v-row v-if="!editDocument">
                  <v-col
                      cols="9"
                      class="d-flex justify-center align-center"
                  >
                    <v-select
                      v-model="currentDocIndex"
                      :items="getActiveFormDocs"
                      id="doc-selector"
                      label="Selected Document"
                      item-text="title"
                      item-value="id"
                      solo
                      class="solo-height-fix"
                      @change="documentChange"
                    />
                  </v-col>
                  <v-col
                    v-if="getActiveFormDocs.length > 1"
                    cols="3"
                    class="d-flex justify-space-between align-center"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          fab
                          small
                          @click="editDocument = true"
                        >
                          <v-icon small dark>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Document Name</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                            fab
                            small
                            @click="documentConfirmationDialog = true"
                        >
                          <v-icon small dark color="white">
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Current Document</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="green"
                            fab
                            small
                            @click="showReorderDocsDialog.visible = true"
                        >
                          <v-icon small dark color="white">
                            mdi-order-alphabetical-ascending
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Reorder Documents</span>
                    </v-tooltip>

                  </v-col>
                </v-row>

                <v-row v-else-if="editDocument && !createDocument">
                  <v-col
                      cols="12"
                      md="9"
                      class="d-flex justify-center align-center"
                  >
                    <v-text-field
                      :autofocus="true"
                      v-model="documentOptions.name"
                      label="Enter Document Name"
                      outlined
                      class="solo-height-fix"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      md="2"
                      class="d-flex justify-center align-center"
                  >
                    <v-btn
                        class="d-inline pa-2"
                        fab
                        x-small
                        @click="editCurrentDocument"
                        title="Edit Document"
                    >
                      <v-icon small dark>mdi-floppy</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <template v-if="!createDocument && !editDocument">
                <v-col cols="1" class="d-flex align-items-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs}">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          title="Add New Document"
                          fab
                          small
                          @click="createDocument = true"
                      >
                        <v-icon> mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Add New Document</span>
                  </v-tooltip>

                </v-col>
              </template>

              <template v-if="createDocument && !editDocument">
                <v-col cols="12">
                  <div class="elevation-2 pa-4 grey lighten-3">
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          order-sm="2"
                          class="d-flex justify-end align-start"
                      >
                        <v-btn icon @click="createDocument = false">
                          <v-icon>clear</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="6" order-sm="1">
                        <v-select
                            v-model="documentOptions.selectedType"
                            :items="documentOptions.types"
                            label="New Document Type"
                            solo
                            class="solo-height-fix"
                        />
                      </v-col>
                      <v-col cols="10" order="3">
                        <v-text-field
                          v-model="documentOptions.name"
                          label="Enter Document Name"
                          :autofocus="true"
                        />
                      </v-col>
                      <v-col
                          cols="2"
                          order="4"
                          class="d-flex justify-end align-items-center"
                      >
                        <v-btn
                            class="d-inline pa-2"
                            fab
                            small
                            @click="addNewDocument"
                            title="Save"
                        >
                          <v-icon small dark>mdi-floppy</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </template>
            </v-row>

            <v-row>
              <v-col v-if="hasExistingPages" cols="12">
                <PageCards
                  :pages="getDocTemplate.existingPages"
                  title="Existing Pages"
                  background-color="grey lighten-3"
                  @remove="removeExistingPage($event)"
                  @update="updateExistingPages($event)"
                  @move="activateDocPageMoveDialog($event, 'existing')"
                />
              </v-col>
              <v-col cols="12" v-if="hasCurrentSessionPages">
                <PageCards
                  :name-editable="true"
                  :pages="getDocTemplate.currentSessionPages"
                  title="Pages Added This Session"
                  @remove="removeCurrentSessionPage($event)"
                  @update="updateCurrentSessionPages($event)"
                  @move="activateDocPageMoveDialog($event, 'current-session')"
                  @change-name="activatePageNameDialog($event, 'current-session-pages')"
                />
              </v-col>
              <v-col cols="12">
                <v-divider/>
              </v-col>
              <v-col cols="0" md="1"/>
              <v-col cols="12" md="4">
                <v-btn @click="choosePageUpload"
                >Add New Page As Image
                </v-btn
                >
                <v-file-input
                  id="add-next-page"
                  label="Add Next Page"
                  outlined
                  :accept="getAcceptedUploadFileTypes"
                  prepend-icon=""
                  hide-input
                  @change="addNewPage($event)"
                />
              </v-col>
              <v-col cols="0" md="2"/>
              <v-col cols="12" md="4" style="display:none;">
                <v-btn @click="addNewPage(undefined)"
                >Add New Blank Page
                </v-btn
                >
              </v-col>
              <v-col cols="0" md="1"/>
            </v-row>
          </v-card-text>
        </v-card>

        <DocDisplayLogic 
          v-if="showDocDisplayLogic"
          :display-logic="form.docs[currentDocIndex].displayLogic"
          :form-fields="form.fields"
          @update="form.docs[currentDocIndex].displayLogic = $event"
        />        
      </v-col>
      <v-col cols="12" md="4">
        <FileRenderer :pdf-file="processedFile">
          <template #card-title>Document Preview</template>
        </FileRenderer>
      </v-col>
    </v-row>

    <!-- Dialog to change the page number -->
    <v-dialog
      v-model="pdfTemplatePageNumber.dialog"
      :retain-focus="false"
      max-width="300"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="0" md="2"/>
            <v-col cols="12" md="8">
              <h3>Select where to move this page to.</h3>
              <br/>
              <v-select
                v-model="pdfTemplatePageNumber.pageSelection"
                :items="pdfTemplatePagesItems"
                item-text="moveToTitle"
                item-value="page"
                label="Move Page To"
              />
            </v-col>
            <v-col cols="0" md="2"/>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            :disabled="pdfTemplatePageNumber.pageSelection === null"
            color="green lighten-1"
            text
            @click="acceptDocPageMovement"
          >
            Move
          </v-btn>

          <v-btn color="red lighten-1" text @click="declineDocPageMovement">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog to change the name of pages added this session -->
    <v-dialog
      v-model="pdfTemplatePageName.dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="dissmisModal"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="0" md="2"/>
            <v-col cols="12" md="8">
              <h3>
                Name must be unique within the scope of all template images.
              </h3>
              <br/>
              <h4
                v-if="!pdfTemplatePageName.updatedNameIsUnique"
                class="red--text"
              >
                The name you attempted to accept is already in use.
              </h4>
              <v-text-field
                v-model="pdfTemplatePageName.updatedName"
                :prepend-icon="
                  pdfTemplatePageName.updatedNameIsUnique
                    ? 'check_circle_outline'
                    : 'close'
                "
                label="PDF Template Image Name"
                @keyup="checkPdfTemplateName"
              />
            </v-col>
            <v-col cols="0" md="2"/>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            :disabled="!pdfTemplatePageName.updatedNameIsUnique"
            color="green lighten-1"
            text
            @click="acceptPdfTemplateName"
          >
            Accept Name
          </v-btn>

          <v-btn color="red lighten-1" text @click="declinePdfTemplateName">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="documentConfirmationDialog" :retain-focus="false" max-width="500">
      <v-card class="pa-3">
        <v-card-title class="headline">
          <v-icon color="error" class="mr-2">error</v-icon>
          Delete {{ getActiveFormDocs[currentDocIndex].title }} ?
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete this document? <br />
          Changes will be permanent <strong>only after you save Workflow</strong>.
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="documentConfirmationDialog = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn class="error" text @click="deleteCurrentDocument">
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <reorder-documents-dialog 
      v-if="showReorderDocsDialog.visible"
      :form-id="form.id"
      :docs="[...form.docs]"
      :dialog="showReorderDocsDialog"
      @reorder="(form.docs = $event)" />
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {nanoid} from "nanoid";
import ReorderDocumentsDialog from '@/components/ReorderDocumentsDialog';
import PageCards from "@/components/WorkflowBuilder/Documents/PageCards";
import FileRenderer from "@/components/FileRenderer";
import questionsMixin from "@/mixins/questions.mixin"
import DocDisplayLogic from './DocDisplayLogic.vue';
import pdfToPng from "@/services/pdfToPng";
import pdfMixin from "@/mixins/pdf.mixin";

export default {
  name: 'DocumentsConfiguration',

  mixins: [pdfMixin,questionsMixin],

  components: {
    PageCards,
    FileRenderer,
    ReorderDocumentsDialog,
    DocDisplayLogic
  },

  props: {
    form: {
      type: Object,
      default: () => {},
    },
    docCombinedPages: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      questionSelection: {},
      showReorderDocsDialog: {
        visible: false
      },
      editDocument: false,
      createDocument: false,
      loadingNewPage: false,
      documentConfirmationDialog: false,

      documentOptions: {
        name: "",
        selectedType: "PDF",
        types: ["PDF"]
      },

      pdfTemplatePageName: {
        dialog: false,
        updatedName: "",
        updatedNameIsUnique: false,
        type: "",
        index: null
      },

      pdfTemplatePageNumber: {
        dialog: false,
        pageSelection: null,
        currentPage: null,
        type: "",
        index: null
      },
    }
  },

  computed: {
    ...mapGetters(['getAcceptedUploadFileTypes']),

    showDocDisplayLogic() {
      return this.form && this.currentDocIndex > -1 && this.form?.fields?.length > 0
    },

    currentDocIndex: {
      get() {
        return this.$store.state.formBuilderDocIndex;
      },
      set(value) {
        this.$store.dispatch("setFormBuilderDocIndex", value);
      }
    },   

    getActiveFormDocs() {
      const docs = this.form?.docs || [];
      const formattedDocs = [];

      // Close edit/create document panel
      this.editDocument = false;
      this.createDocument = false;

      docs.sort((a,b) => a.order - b.order).map((doc, i) => {
        formattedDocs.push({
          id: i,
          title: doc.name ?? `Document ${doc.uid}`
        });
      });

      return formattedDocs;
    },

    hasExistingPages() {
      if (this.form?.docs?.length > parseInt(this.currentDocIndex)) {
        return this.form.docs[this.currentDocIndex]?.existingPages?.length > 0
      } else {
        return false;
      }
    },

    hasCurrentSessionPages() {
      if (this.loadingNewPage === false && this.form?.docs?.length > parseInt(this.currentDocIndex)) {
        return this.form.docs[this.currentDocIndex]?.currentSessionPages?.length > 0
      } else {
        return false;
      }
    },

    getDocTemplate() {
      return this.form?.docs && this.currentDocIndex >= 0
        ? this.form.docs[this.currentDocIndex]
        : {
          dbRefs: [],
          existingPages: [],
          currentSessionPages: [],
          combinedPages: [],
          pagesToDelete: []
        };
    },

    pdfTemplatePagesItems() {
      let currentPages = this.docCombinedPages.slice();

      currentPages.map(cp => {
        cp.moveToTitle = `Page ${cp.page} | ${cp.name}`;
      });

      currentPages.unshift({moveToTitle: "START", page: 0});

      currentPages.sort((a,b) => a.page - b.page);

      return currentPages;
    },
  },

  mounted() {
    this.setDisplayLogic()
  },

  methods: {
    ...mapActions({
      toggleLoadingOverlayStatus: 'toggleLoadingOverlayStatus'
    }),

    ...mapActions("workflow", ["setWorkflowBuilderCurrentRule", "setWorkflowBuilderCurrentAction"]),

    setDisplayLogic() {
      if(this.form.docs[this.currentDocIndex].hasOwnProperty('displayLogic') === false) {
        this.form.docs[this.currentDocIndex]['displayLogic'] = {
          questions: [],
          conditional: '',
          operator: ''
        }
      }
    },

    // TODO: this is very hacky and we need a way to handle when there are no rules or acitons selected
    dissmisModal() {
      this.declinePdfTemplateName();
    },

    documentChange() {
      this.setDisplayLogic()
      this.setWorkflowBuilderCurrentRule(0)
      this.setWorkflowBuilderCurrentAction(0)
    },

    /*
      DOCUMENTS & PAGES
    */

    async addNewDocument() {
      const docId = nanoid(12);
      let docName = `Document ${docId}`;
      if (this.documentOptions.name.length > 0)
        docName = this.documentOptions.name;

      await this.$store
        .dispatch("addDocument", {
          uid: docId,
          type: "pdf", // When we support spreadsheets, that will be an option here
          name: docName,
          combinedPages: [],
          existingPages: [],
          currentSessionPages: [],
          pagesToDelete: [],
          rules: [this.createBlankRule()]
        })
        // Set currentDocIndex to the newly added document
        this.currentDocIndex = this.form.docs.length - 1;
        this.documentOptions.name = "";
        this.createDocument = false;
    },  

    createBlankRule() {
      return {
        uid: nanoid(12),
        name: "",
        type: "",
        displayLogicQuestions: [],
        order: 1,
        condition: null,
        operator: null,
        questions: [
          {
            uid: null,
            title: null,
            type: "",
            selection: {},
            options: []
          }
        ],
        actions: []
      };
    },

    async editCurrentDocument() {
      if (this.documentOptions.name.length === 0) {
        // Name is not updated if user doesn't provide new name
        this.editDocument = false;
      } else {
        // Name is updated
        await this.$store.dispatch("editDocument", {
          name: this.documentOptions.name
        })
        this.documentOptions.name = "";
        this.editDocument = false;
      }
    },

    deleteCurrentDocument() {
      if (this.form.docs.length > 1) {
        this.$store.dispatch("deleteDocument", {docId: this.getDocTemplate.uid});
        this.currentDocIndex = 0;
        this.documentConfirmationDialog = false;
      }
    },

    activateDocPageMoveDialog(index, type) {
      this.$store
          .dispatch("sortDocumentPages", {
            docId: this.getDocTemplate.uid
          })
          .then(() => {
            this.pdfTemplatePageNumber.type = type;
            this.pdfTemplatePageNumber.index = index;
            this.pdfTemplatePageNumber.currentPage =
                type === "existing"
                    ? this.getDocTemplate.existingPages[index].page
                    : this.getDocTemplate.currentSessionPages[index].page;
            this.pdfTemplatePageNumber.pageSelection = null;
            this.pdfTemplatePageNumber.dialog = true;
          });
    },

    activatePageNameDialog(index, type) {
      this.pdfTemplatePageName.type = type;
      this.pdfTemplatePageName.index = index;

      if (type === "existing-pages") {
        this.pdfTemplatePageName.updatedName = this.getDocTemplate.existingPages[
            index
            ].name;
        this.pdfTemplatePageName.updatedNameIsUnique = this.getDocTemplate.existingPages[
            index
            ].isNameUnique;
      } else if (type === "current-session-pages") {
        this.pdfTemplatePageName.updatedName = this.getDocTemplate.currentSessionPages[
            index
            ].name;
        this.pdfTemplatePageName.updatedNameIsUnique = this.getDocTemplate.currentSessionPages[
            index
            ].isNameUnique;
      }

      this.pdfTemplatePageName.updatedName = this.pdfTemplatePageName.updatedName.toUpperCase();
      this.pdfTemplatePageName.updatedNameIsUnique = this.isPageNameUnique(
          this.pdfTemplatePageName.updatedName
      );

      this.pdfTemplatePageName.dialog = true;
    },

    choosePageUpload() {
      // This function just clicks the hidden file-input component
      document.getElementById("add-next-page").click();
    },

    async addNewPage(element = undefined) {
      this.loadingNewPage = true;
      if (element !== undefined) {
        // Handle page with template image
        this.toggleLoadingOverlayStatus(true);
        let reader = new FileReader();
        reader.onloadend = async () => {
          let fileResult = reader.result;
          if (element.type === "application/pdf") {
            const pdfImage = await pdfToPng.convert(element);
            fileResult = pdfImage.data;
          }

          if(Array.isArray(fileResult)) {
            const toConfigure = []
            for(let f = 0; f < fileResult.length; f++) {
              const data = fileResult[f]
              const fileData = element.name.split('.')
              const name = fileData[0] + (f + 1) + "." + fileData[1]
              toConfigure.push(this.configureNewPage({ data,name }))
            }
            await Promise.all(toConfigure)
          } else {
            await this.configureNewPage({
              data: fileResult,
              name: element.name
            });            
          }

          this.toggleLoadingOverlayStatus(false);
          this.loadingNewPage = false;
        };
        reader.readAsDataURL(element);
      } else {
        // Handle blank page
        await this.configureNewPage({data: undefined, name: "NEW BLANK PAGE"});
        this.toggleLoadingOverlayStatus(false);
        this.loadingNewPage = false;
      }
    },

    async configureNewPage(file = {data: undefined, name: "NEW PAGE"}) {
      // Get page name
      let fileName = "";
      if (file === undefined || file.data === undefined) {
        fileName = "NEW BLANK PAGE";
      } else {
        const regexMatch = file.name.match(/.+(?=\.)/);
        if (regexMatch[0]) fileName = regexMatch[0].toUpperCase();
      }

      await this.$store.dispatch("sortDocumentPages", {
        docId: this.currentDocIndex
      });

      // Calculate page number
      let pageNumber = 1;
      if (this.hasExistingPages) {
        pageNumber += this.getDocTemplate.existingPages.length;
      }
      if (this.hasCurrentSessionPages) {
        pageNumber += this.getDocTemplate.currentSessionPages.length;
      }

      // Check that name of file is unique and change it if not, so it will not overwrite page that already exists in storage
      let isNameUnique = this.isPageNameUnique(fileName, "new-page");

      if (!isNameUnique) {
        fileName = this.generateUniqueName(fileName);
      }

      // Create UID for new page
      const uid = nanoid(12);

      this.$store
          .dispatch("addDocumentPage", {
            docIndex: this.currentDocIndex,
            newPage: {
              uid: uid,
              page: pageNumber,
              name: fileName,
              isNameUnique: isNameUnique,
              file: file.data
            }
          })
          .then(() => {
            if (!isNameUnique) {
              this.activatePageNameDialog(
                  this.getDocTemplate.currentSessionPages.length - 1,
                  "current-session-pages"
              );
            }
          });
    },

    removeExistingPage(index) {
      this.$store.dispatch("deleteDocumentPage", {
        docId: this.getDocTemplate.uid,
        type: "existing",
        pageIndex: index,
      });
    },

    removeCurrentSessionPage(index) {
      this.$store.dispatch("deleteDocumentPage", {
        docId: this.getDocTemplate.uid,
        type: "current-session",
        pageIndex: index,
      });
    },

    updateExistingPages(pages) {
      this.$store.dispatch("updateDocumentPageNumbers", {
        type: "existing",
        currentDocIndex: this.currentDocIndex,
        pageIndex: index
      });

      this.pdfTemplate.existingPages = pages;
      this.form.docs[this.currentDocIndex].existingPages = pages;
    },

    updateCurrentSessionPages(pages) {
      this.pdfTemplate.existingPages = pages;
      this.form.docs[this.currentDocIndex].existingPages = pages;
    },

    isPageNameUnique(name, type = "existing-page") {
      let duplicateCount = 0;

      this.docCombinedPages.forEach(page => {
        if (page.name.trim().toUpperCase() === name.trim()) duplicateCount++;
      });

      // This runs before the page is added and combinedPages is updated, so if there are any duplicates, the page name
      // is not unique
      return !duplicateCount;
    },

    generateUniqueName(name, counter = 0) {
      name = counter ? `${name} ${counter}` : name;
      let isUnique = this.isPageNameUnique(name);

      if(!isUnique) {
        return this.generateUniqueName(name, ++counter);
      }

      return name;
    },

    checkPdfTemplateName() {
      // check that name of file is unique and update pdfTemplatePageName.updatedNameIsUnique flag
      this.pdfTemplatePageName.updatedName = this.pdfTemplatePageName.updatedName.toUpperCase();

      this.pdfTemplatePageName.updatedNameIsUnique = this.isPageNameUnique(
          this.pdfTemplatePageName.updatedName,
          "existing-page"
      );
    },

    acceptPdfTemplateName() {
      // check that name of file is unique and change it if not, so it will not overwrite page that already exists in storage
      this.checkPdfTemplateName();

      const isNameUnique = this.pdfTemplatePageName.updatedNameIsUnique;
      const index = this.pdfTemplatePageName.index;
      const type = this.pdfTemplatePageName.type;
      const updatedName = this.pdfTemplatePageName.updatedName;

      if (isNameUnique) {
        const payload = {
          pageIndex: index,
          type: type,
          updates: {
            name: updatedName,
            isNameUnique: isNameUnique
          }
        };
        this.$store.dispatch("editDocumentPage", payload);

        // Reset renaming dialog
        this.pdfTemplatePageName = {
          dialog: false,
          updatedName: "",
          updatedNameIsUnique: false,
          type: "",
          index: null
        };
      }
    },

    declinePdfTemplateName() {
      if (!this.pdfTemplatePageName.updatedNameIsUnique) {
        let index = this.form.docs[this.currentDocIndex].existingPages.findIndex(item => {
          if(item) {
            item.name === this.pdfTemplatePageName.name;
          }
        });

        if (!index) {
            index = this.form.docs[this.currentDocIndex].currentSessionPages.findIndex(item => {
              if(item) {
                item.name === this.pdfTemplatePageName.name;
              }
            })
        }

        if (this.pdfTemplatePageName.type === 'current-session-pages') {
          this.removeCurrentSessionPage(index);
        } else {
          this.removeExistingPage(index);
        }
      }

      this.pdfTemplatePageName = {
        dialog: false,
        updatedName: "",
        updatedNameIsUnique: false,
        type: "",
        index: null
      };
    },

    acceptDocPageMovement() {
      this.$store
          .dispatch("moveDocumentPage", {
            docId: this.getDocTemplate.uid,
            type: this.pdfTemplatePageNumber.type,
            index: this.pdfTemplatePageNumber.index,
            pageFrom: this.pdfTemplatePageNumber.currentPage,
            pageTo: this.pdfTemplatePageNumber.pageSelection
          })
          .then(() => {
            // Clear move page dialog
            this.pdfTemplatePageNumber = {
              dialog: false,
              pageSelection: null,
              currentPage: null,
              type: "",
              index: null
            };
          });
    },

    declineDocPageMovement() {
      this.pdfTemplatePageNumber = {
        dialog: false,
        pageSelection: null,
        currentPage: null,
        type: "",
        index: null
      };
    },
  }
}
</script>
