<template>
  <div style="padding: 40px;">
    <v-icon style="font-size:200px;" color="info">mdi-alert-outline</v-icon>
    <h2 style="text-align: center;">404</h2>
    <h1>Page Not Found</h1>
    <p>The specified file was not found on this website. Please check the URL for mistakes and try again.</p>
    <ContactSupport />
  </div>
</template>

<script>
import ContactSupport from '@/components/ContactSupport';

export default {
  name: 'NotFound',

  components: {
    ContactSupport
  }
}
</script>