const defaultState = () => ({
    stateInfo: {},
    usedCounties: []
})

export const counties = {
    namespaced: true,

    state: defaultState(),

    actions: {
        resetState({ commit }) {
            commit('RESET_STATE')
        },
        
        async setCountiesByState({ commit, rootState }) {
            const resp = await rootState.firebaseConfig.db.collection('state-counties').get()
            
            if(resp.size) {
                resp.docs.forEach(doc => {
                    commit('SET_STATE_INFO', { stateName: doc.id, counties: doc.data() })
                })
            }
        },

        async setUsedCounties({ rootState, commit }, { state, formId, childrenOptions, collectionName = 'forms' }) {
            const resp = await rootState.firebaseConfig.db.collection(collectionName)
                .where('state', '==', state).where('childrenOptions', '==', childrenOptions).where('id', '!=', formId).get()
            const payload = []
            resp.forEach(f => {
                const currentForm = f.data()
                if(currentForm?.counties && currentForm.counties.length > 0) {
                    currentForm.counties.forEach(c => {
                        payload.push(c)
                    })
                }
            })
            
            commit('SET_USED_COUNTIES', payload)
            return
        }
    },

    mutations: {
        RESET_STATE(state) {
            Object.assign(state, defaultState());
        },
        
        SET_STATE_INFO(state, { stateName, counties }) {
            state.stateInfo[stateName] = counties
        },

        SET_USED_COUNTIES(state, payload) {
            state.usedCounties = payload
        }
    },

    getters: {
        stateInfo: (state) => state.stateInfo,

        usedCounties: (state) => state.usedCounties,

        countiesByState: (state) => {
            return (stateName) => {
                return state.stateInfo[stateName]?.counties ?? []
            }
        }
    }
}