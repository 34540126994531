import { mapState, mapGetters, mapActions } from "vuex";
import draggable from "vuedraggable";
import { nanoid } from "nanoid";
import YouTubeServices from "@/services/youtube.js";
import pdfMixin from "@/mixins/pdf.mixin";
import TeapAlert from "@/components/Alerts/TeapAlert"
import FormBuilderPreview from "@/components/FormBuilderPreview";
import FileRenderer from "@/components/FileRenderer";
import RulesBuilder from "@/components/WorkflowBuilder/RulesBuilder";
import FormElement from "@/components/FormElement";
import ActionsConfiguration from "@/components/WorkflowBuilder/Actions/ActionsConfiguration";
import DisplayLogicConfiguration from "@/components/WorkflowBuilder/DisplayLogic/DisplayLogicConfiguration";
import DocumentsConfiguration from "@/components/WorkflowBuilder/Documents/DocumentsConfiguration";
import MultiOptionAdd from "@/components/Common/MultiOptionAdd";

export default {
  mixins: [pdfMixin],

  components: {
    MultiOptionAdd,
    DocumentsConfiguration,
    DisplayLogicConfiguration,
    ActionsConfiguration,
    FormBuilderPreview,
    FileRenderer,
    RulesBuilder,
    FormElement,
    draggable,
    TeapAlert,
  },

  async mounted() {
    this.updatePdfFile({templateFile: true});
    this.updatePdfFile({processedFile: true});
    if (this.form && this.form?.fields) this.checkForUniqTitle();
    this.getContentHeight();
    this.setCountiesByState()
    window.addEventListener('resize', this.getContentHeight);
  },

  data: () => ({
    ignoreFormDataChanges: false,
    updating: false,
    hasDisplayLogic: false,
    displayLogicDirty: false,
    fieldsToDelete: [],
    searchFormFields: "",
    contentHeight: 500,
    isLoading: false,
    isNewForm: false,
    currentStep: '1',
    currentPage: null,
    currentField: "",

    closeFormBuilderDialog: null,
    saveFormBuilderDialog: false,
    addFormElementSelection: null,
    singleSelectVariants: ["radio", "drop-down", "slider"],
    pdfTemplateChangeFlag: 0,

    numberNonUniqueNames: 0,
    saved: false,

    actionItems: [
      "Add X",
      "Add check mark",
      "Add input from question as text",
      "Add custom image",
    ],
    duplicateNewFormElementTitleId: 0,
    duplicatesTitleIndexes: [],
    formChangeDebounce: null,
    confirmationDialog: false,
    formElementIndex: false
  }),

  computed: {
    ...mapState({
      form: 'formBuilderActiveForm',
      pdfActionActive: 'pdfActionActive',
      pdfTemplateImagesLoaded: 'pdfPagesLoadedFlag',
    }),

    ...mapGetters(["getStates"]),

    ...mapGetters('workflow', {
      currentRuleIndex: 'getWorkflowBuilderCurrentRule',
      currentActionIndex: 'getWorkflowBuilderCurrentAction',
    }),

    ...mapGetters("profile", {
      user: 'userInfo',
    }),

    ...mapGetters("counties", ["countiesByState",'usedCounties']),

    ...mapGetters([
      'getPdfTemplateActions',
      'getFormElementTypes',
      'loadingStatus',
      'getFormDocsById',
      'getPdfActionTemplateDialog',
      'isFormDirty',
      'getAcceptedUploadFileTypes',
      'getWorkflowFieldsAll',
      'getWorkflowPages',
      'getFirebaseConfig',
    ]),

    showSearch() {
      return (pgIndex) => {
        return ((this.searchFormFields && this.searchFormFields.length >= 3) || this.getFieldsByPageNumber(pgIndex)?.length > 10)
      }
    },

    getCounties() {
      return this.countiesByState(this.form.state).map(c => {
        return {
          disabled: this.usedCounties.findIndex(u => u === c) > -1,
          name: c
        }
      })
    },

    currentDocIndex: {
      get() {
        return this.$store.state.formBuilderDocIndex;
      },
      set(value) {
        if(this.form?.docs?.length > 0) {
          this.$store.dispatch("setFormBuilderDocIndex", value);
        }
      },
    },

    formBuilderDialog: {
      get() {
        return this.$store.state.formBuilderDialog;
      },
      set(value) {
        this.toggleFormBuilderDialog(value);
      },
    },

    hasExistingPages() {
      if (this.form?.docs?.length > parseInt(this.currentDocIndex)) {
        return this.form?.docs[this.currentDocIndex]?.existingPages?.length > 0
      } else {
        return false;
      }
    },

    getActiveFormDocs() {
      const docs = this.form?.docs || [];
      const formattedDocs = [];

      // Close edit/create document panel
      this.editDocument = false;
      this.createDocument = false;

      docs.map((doc, i) => {
        formattedDocs.push({
          id: i,
          title: doc.name ?? `Document ${doc.uid}`,
        });
      });

      return formattedDocs;
    },

    getDocTemplate() {
      return this.form?.docs
          ? this.form.docs[this.currentDocIndex]
          : {
            dbRefs: [],
            existingPages: [],
            currentSessionPages: [],
            combinedPages: [],
            pagesToDelete: [],
          };
    },

    getDocCombinedPages() {
      if (this.form?.docs[this.currentDocIndex]) {
        let combinedPages = [];

        if (this.form.docs[this.currentDocIndex]?.existingPages) {
          const copyExistingPages =
            this.form.docs[this.currentDocIndex].existingPages.filter(ep => ep).slice();
          combinedPages = [...combinedPages, ...copyExistingPages];
        }

        if (this.form.docs[this.currentDocIndex]?.currentSessionPages) {
          const copyCurrentSessionPages =
            this.form.docs[this.currentDocIndex].currentSessionPages.filter(csp => csp).slice();
          combinedPages = [...combinedPages, ...copyCurrentSessionPages];
        }

        return combinedPages;
      } else {
        return [];
      }
    },

    pages() {
      let pageNumber = 0;

      this.form.fields?.map((field) => {
        if (field["type"] === "page-break") {
          pageNumber += 1;
        }
        field["page"] = pageNumber;
      });

      return pageNumber;
    },

    pageNames() {
      if(!this.getWorkflowFieldsAll) return [];
      return this.getWorkflowFieldsAll
        .filter(f => f?.type && f.type === 'page-break')
        .sort((a, b) => a.fieldsIndex - b.fieldsIndex)
        .map((f, i) => f?.name ? f.name : 'Page ' + (i + 1));
    },

    formElementsListForMovement() {
      let list = this.form.fields ?? [];
      list = list.slice();
      let pageNumber = 0;

      for (let i = 0; i < list.length; i++) {
        let question = "";

        if (list[i].type === "page-break") {
          pageNumber++;
          if (list[i].title) {
            question = "Page " + pageNumber + " - " + list[i].title;
          } else {
            question = "Page " + pageNumber;
          }
        } else {
          question = "Type: " + list[i].type + " | Title: " + list[i].title;
        }

        list[i].questionListTitle = question;
        list[i].fieldsIndex = i;
      }

      // Return all form elements except page breaks
      return list.filter(x => x.type !== 'page-break');
    },

    formElementsListForSelection() {
      let list = this.getWorkflowFieldsAll ?? [];
      list = list.slice();
      let pageNumber = 0;

      for (let i = 0; i < list.length; i++) {
        let question = "";

        if (list[i].type === "page-break") {
          pageNumber++;
          if (list[i].title) {
            question = "Page " + pageNumber + " - " + list[i].title;
          } else {
            question = "Page " + pageNumber;
          }
        } else {
          question = "Type: " + list[i].type + " | Title: " + list[i].title;
        }

        list[i].questionListTitle = question;
        //list[i].fieldsIndex = i;
      }

      list.push({
            uid: 'participating',
            title: 'participating',
            type: 'participating',
            questionListTitle: 'Participating'
        })

      // Exclude form elements that can't trigger logic (i.e. there's no way for end user to make selection on these form elements)
      return list
        .filter(x => ['page-break', 'heading', 'subheading', 'paragraph', 'youtube-video', 'label'].indexOf(x.type) === -1)
        .sort((a,b) => a.fieldsIndex - b.fieldsIndex);
    },

    displayLogicFormElementsList() {
      const filteredList = [];
      if (!this.form.fields) return filteredList;
      const list = this.form.fields.filter((x) => x.type !== "page-break");
      const listLength = list.length;
      for (let i = 0; i < listLength; i++) {
        let iconName = "";
        switch (list[i].type) {
          case "single-select":
          case "multi-select":
          case "state-select":
            iconName = "check_circle";
            break;
          case "text-input-short":
          case "text-input-long":
          case "label":
            iconName = "short_text";
            break;
          case "dialog-window":
            iconName = "announcement";
            break;
          case "date-picker":
            iconName = "date_range";
            break;
          case "heading":
          case "subheading":
          case "paragraph":
            iconName = "notes";
            break;
          case "youtube-video":
            iconName = "ondemand_video";
            break;
          case "child-support-calculator":
            break;
          case "file-input":
            iconName = "cloud_upload";
            break;
          case "computed-property":
            iconName = "calculate";
            break;
          case "signature-pad":
            iconName = "gesture";
            break;
          case "number-input":
            iconName = "add_box";
            break;
          case "item-table":
            iconName = "table_chart";
            break;
          case "embedded-image":
            iconName = "image";
            break;
        }

        filteredList.push({
          uid: list[i].uid,
          title: list[i].title,
          label: list[i].label,
          type: list[i].type,
          fieldsIndex: list[i].fieldsIndex,
          page: list[i].page,
          icon: iconName,
        });
      }
      return filteredList;
    },

    currentDocName() {
      if (this.hasDocs) {
        return this.form?.docs[this.currentDocIndex]?.name ?? "";
      } else {
        return "";
      }
    },

    currentRuleName() {
      if (this.hasDocs && this.isCurrentDocIndexValid) {
        return (
            this.form?.docs[this.currentDocIndex].rules[this.currentRuleIndex]
                ?.name ?? ""
        );
      } else {
        return "";
      }
    },

    currentActionName() {
      if (this.hasDocs && this.hasRules && this.hasActions) {
        return (
            this.form.docs[this.currentDocIndex].rules[this.currentRuleIndex]
                .actions[this.currentActionIndex]?.title ?? ""
        );
      } else {
        return "";
      }
    },

    actionStepEditable() {
      if (this.hasDocs && this.hasRules) {
        return (
            this.form &&
            this.currentRuleIndex !== null &&
            this.currentRuleIndex !== undefined &&
            this.form.docs[this.currentDocIndex].rules.length > this.currentRuleIndex &&
            this.form.docs[this.currentDocIndex].rules[this.currentRuleIndex].type === "Action Logic"
        );
      } else {
        return false;
      }
    },

    hasDocs() {
      return this.currentDocIndex >= 0 && this.form && this.form?.docs?.length > 0;
    },

    isCurrentDocIndexValid() {
      return this.form.docs.length > this.currentDocIndex
    },

    hasRules() {
      return (
          this.form &&
          this.form?.docs?.length > this.currentDocIndex &&
          this.form.docs[this.currentDocIndex]?.rules?.length > 0
      );
    },

    hasActions() {
      return (
          this.form &&
          this.form?.docs && this.form.docs.length > this.currentDocIndex &&
          this.form.docs[this.currentDocIndex]?.rules &&
          this.currentRuleIndex !== null &&
          this.currentRuleIndex !== undefined &&
          this.form.docs[this.currentDocIndex].rules.length > this.currentRuleIndex &&
          this.form.docs[this.currentDocIndex].rules[this.currentRuleIndex]?.actions &&
          this.form.docs[this.currentDocIndex].rules[this.currentRuleIndex].actions.length > 0
      );
    },
  },

  watch: {
    pdfTemplateChangeFlag: {
      handler(newValue) {
        if (
            typeof newValue !== "undefined" &&
            newValue.length !== null &&
            this.form &&
            this.form.pdfTemplate.existingPages.length > 0
        ) {
          this.pdfTemplate.combinedPages = [
            ...this.form.pdfTemplate.existingPages,
            ...this.pdfTemplate.currentSessionPages,
          ];
          this.setPdfTemplateImages(this.pdfTemplate.combinedPages).then(() => {
            this.updatePdfFile({templateFile: true});
            this.updatePdfFile({processedFile: true});
          });
        } else if (
            typeof newValue !== "undefined" &&
            newValue.length !== null
        ) {
          this.pdfTemplate.combinedPages = [
            ...this.pdfTemplate.currentSessionPages,
          ];
          this.setPdfTemplateImages(this.pdfTemplate.combinedPages).then(() => {
            this.updatePdfFile({templateFile: true});
            this.updatePdfFile({processedFile: true});
          });
        }
      },
      deep: true,
    },

    form: {
      handler: async function (newVal) {
        if (this.isLoading || this.ignoreFormDataChanges || this.updating || !newVal) return

        if(newVal?.id && this.currentStep == 1) {
          await this.setUsedCounties({
            state: newVal.state,
            formId: newVal.id,
            childrenOptions: newVal?.childrenOptions || '',
          })
        }

        if(newVal?.docs && newVal?.fields) {            
          this.updating = true
          await this.buildDisplayLogic()
          this.updating = false
        }        
        // Anytime anything about the form changes, mark it as dirty (exception when form is loaded handled in mounted)
        if (this.pdfTemplateImagesLoaded) {
          clearTimeout(this.formChangeDebounce);
          this.formChangeDebounce = setTimeout(() => {
            if (this.saved) {
              this.formIsDirty();
            }
            this.saved = false;
          }, 500);
        }
        return
      },
      deep: true,
    },

    currentStep: async function(newVal, oldVal) {
      if(this.isLoading === false && oldVal == 7 && newVal != 7) {
        await this.updateProcessedFile({ processedFile: true })
      }
    }
  },

  methods: {
    ...mapActions([
      'addPdfTemplateAction',
      'formAdd',
      'formEdit',
      'setFormBuilderPagesLoadedFlag',
      'toggleFormBuilderDialog',
      'toggleLoadingOverlayStatus',
      'updateDocumentRender',
      'setFormBuilderActiveForm',
      'setNewForm',
      'formIsDirty',
      'formIsClean',
      'resetActiveForm',
      'clearFormBuilderActiveForm',
      'updateWorkflowField'
      ]),

    ...mapActions('workflow', ['embeddedImageDelete','clearProcessedFile','setWorkflowBuilderCurrentAction','setWorkflowBuilderCurrentRule']),

    ...mapActions('displayLogic', ['updateFormElementsDisplayStatus','setOverrideStatus','buildTemplates']),

    ...mapActions("counties", ["setCountiesByState", 'setUsedCounties']),

    ...mapActions("blocks", ["setBlocks"]),

    handleIgnoreFormDataChanges(event) {
      this.ignoreFormDataChanges = event
    },

    async handleKidOption() {
      await this.findUsedCounties()
      return
    },

    async handleStateClick() {
      this.form.counties = []
      await this.findUsedCounties()
      return
    },

    async findUsedCounties() {
      if(this.isNewForm === false && this.form?.state && this.form?.childrenOptions) {
        await this.setUsedCounties({
          state: this.form.state,
          formId: this.form.id,
          childrenOptions: this.form.childrenOptions
        })
      }
      return
    },

    checkMove(evt) {
      return evt.draggedContext.index !== 0
    },

    async handleDocumentsStep() {
      await this.updatePdfFile({ templateFile: true, docIndex: this.currentDocIndex, type: 'blob' });
      await this.updatePdfFile({ processedFile: true, docIndex: this.currentDocIndex, type: 'blob' });
    },

    async handlePreviewStep() {
      await this.displayLogicShowAll(false)
    },

    async buildDisplayLogic() {
      if(this.hasDisplayLogic) return
      let displayLogicRules = [];

      for (let i = 0; i < this.form.docs.length; i++) {
        const filteredRules = this.form.docs[i]?.rules?.filter(rule => rule.type === 'Display Logic');
        if (filteredRules !== undefined) displayLogicRules = [...displayLogicRules, ...filteredRules];          
      }

      await this.buildTemplates({
        workflowId: this.form.id,
        workflowType: 'in-edit',
        formElements: this.form.fields,
        rules: displayLogicRules
      })

      this.hasDisplayLogic = true
      return
    },

    async handleBuildFormStep() {
      await this.displayLogicShowAll(true)
      if(this.displayLogicDirty) {
        await this.toggleLoadingOverlayStatus(true)
        // Update display logic
        await this.buildDisplayLogic()
        this.checkForUniqTitle()

        await this.toggleLoadingOverlayStatus(false)
        this.displayLogicDirty = false
      }
    },

    getContentHeight() {
      // Used to calculate the height of several DOM elements
      const height = window.innerHeight || document.documentElement.clientHeight ||
          document.body.clientHeight;
      this.contentHeight = height - 138;
      return height - 138;
    },

    closeDialog() {
      this.reset();
      this.currentDocIndex = 0;
      if (this.isFormDirty) {
        this.closeFormBuilderDialog = true;
      } else {
        this.closeFormBuilder();
      }
    },

    getFieldsByPageNumber(pageNum) {
      const fieldsByPage = this.getWorkflowPages[(pageNum - 1)];
      if(this.searchFormFields && this.searchFormFields.length >= 3) {
        const search = this.searchFormFields.toLowerCase()
        return fieldsByPage.filter(field => ((field.title && field.title.toLowerCase().indexOf(search) >= 0) || (field.type && field.type.toLowerCase().indexOf(search) >= 0) || (field.label && field.label.toLowerCase().indexOf(search) >= 0)))
      }
      return fieldsByPage
    },

    checkForUniqTitle() {
      const duplicates = [];
      this.form.fields.forEach((field, index) => {
        this.form.fields.find((compareField, compareIndex) => {
          if (
              field.title &&
              index !== compareIndex &&
              field.title === compareField.title
          ) {
            duplicates.push(index);
          }
        });
      });
      return this.duplicatesTitleIndexes = duplicates;
    },

    isFieldTitleUnique(fieldIndex) {
      return !this.duplicatesTitleIndexes.includes(fieldIndex);
    },

    async newForm() {
      this.isNewForm = true
      let userEmail = this.user.email;
      const formTemplate = {
        version: '0.1.0',
        collaborate: true,
        id: nanoid(20),
        owner: userEmail,
        name: "",
        description: "",
        jurisdiction: '',
        modifiedDate: Date.now(),
        fields: [
          {
            uid: nanoid(12),
            type: "page-break",
            title: "",
            page: 1,
          },
        ],
        access: [userEmail],
        docs: [
          {
            uid: nanoid(12),
            type: "pdf", // When we support spreadsheets, that will be an option here
            name: "New Document",
            dbRefs: [],
            combinedPages: [],
            existingPages: [],
            currentSessionPages: [],
            pagesToDelete: [],
            rules: [this.createBlankRule()],
          },
        ],
        state: '',
        counties: [],
        childrenOptions: 'both'
      };

      this.toggleLoadingOverlayStatus(true)
      await this.setFormBuilderActiveForm(formTemplate, 0);
      this.toggleLoadingOverlayStatus(false)
      this.setNewForm(true);
      this.formBuilderDialogToggle(true);
    },

    reset() {
      this.clearProcessedFile();
      this.currentSurveyTab = 0;
      this.currentStep = '1';
      this.setWorkflowBuilderCurrentRule(0);
      this.setWorkflowBuilderCurrentAction(0);
    },

    async deleteFields() {
      const toDelete = []
      for(let f = 0; f < this.fieldsToDelete.length; f++) {
        const fId = this.fieldsToDelete[f]
        toDelete.push(this.getFirebaseConfig.db.collection("forms").doc(this.form.id).collection("fields").doc(fId).delete())
      }
      await Promise.all(toDelete)
      return
    },

    async saveWorkflow() {
      this.isLoading = true
      await this.toggleLoadingOverlayStatus(true);
            let pageNamesNonUnique = 0;
      this.reset();
      for (let i = 0; i < this.form.docs.length; i++) {
        this.form.docs[i]?.currentSessionPages?.forEach((currentPg) => {
          if (!currentPg.isNameUnique) {
            pageNamesNonUnique++;
          }
        });
      }

      if (pageNamesNonUnique === 0) {
        const activeForm = Object.assign({}, this.form,
          { haveDuplicates: Boolean(this.duplicatesTitleIndexes.length) }
        );

        this.saved = true;
        if (this.isNewForm) {
          await this.formAdd(activeForm)
          this.isNewForm = false
          this.currentDocIndex = 0;
          this.setNewForm(false);
          this.setWorkflowBuilderCurrentRule(0);
          this.formIsClean()
        } else {
          await this.formEdit(activeForm)
          await this.deleteFields()
          this.setNewForm(false);
          this.setWorkflowBuilderCurrentRule(0);
          this.formIsClean();
          this.currentDocIndex = 0;
          await this.setFormBuilderActiveForm(this.form)
          await this.toggleLoadingOverlayStatus(false)
        }
      } else {
        this.numberNonUniqueNames = pageNamesNonUnique;
        this.saveFormBuilderDialog = true;
      }
      await this.toggleLoadingOverlayStatus(false)
      this.isLoading = false
    },

    closeFormBuilder() {
      this.clearFormBuilderActiveForm();
      this.resetActiveForm();
      this.setBlocks([])
      this.setFormBuilderPagesLoadedFlag(false);
      this.toggleFormBuilderDialog(false);
    },

    async addFormElement(formElementType) {
      this.addFormElementSelection = formElementType;

      switch (formElementType) {
        case "single-select":
          this.addRadioButtons();
          break;
        case "state-select":
          this.addStateSelect();
          break;          
        case "multi-select":
          this.addCheckboxes();
          break;
        case "signature-pad":
          this.addSignaturePad();
          break;
        case "number-input":
          this.addNumberInput();
          break;
        case "text-input-short":
          this.addTextField();
          break;
        case "text-input-long":
          this.addTextArea();
          break;
        case "file-input":
          this.addFileInput();
          break;
        case "page-break":
          this.addPageBreak();
          break;
        case "heading":
          this.addHeading();
          break;
        case "subheading":
          this.addSubheading();
          break;
        case "paragraph":
          this.addParagraph();
          break;
        case "youtube-video":
          this.addYoutubeVideo();
          break;
        case "date-picker":
          this.addDatePicker();
          break;
        case "dialog-window":
          this.addDialogWindow();
          break;
        case "item-table":
          this.addItemTable();
          break;
        case "label":
          this.addLabel();
          break;
        case "computed-property":
          this.addComputedProperty();
          break;
        case "grouped-options":
          this.addGroupedOptions();
          break;
        case "embedded-image":
          this.addEmbeddedImage();
          break;
        case "child-support-calculator":
          this.addChildSupportCalculator();
          break;
        default:
      }

      await this.buildTemplates({
        workflowType: "in-edit",
        formElements: [...this.form.fields],
        rules: this.form?.docs?.[this.currentDocIndex]?.rules || [],
      })
      return
    },

    createBlankRule() {
      return {
        uid: nanoid(12),
        name: "",
        type: "",
        displayLogicQuestions: [],
        order: 1,
        condition: null,
        operator: null,
        questions: [
          {
            uid: null,
            title: null,
            type: "",
            selection: {},
            options: [],
          },
        ],
        actions: [],
      };
    },

    getNewFormElement(content = "string") {
      let contentInitialized = "";

      if (content === "array") {
        contentInitialized = [];
      }

      const type = this.addFormElementSelection
      return {
        uid: nanoid(12),
        type,
        fieldsIndex: null,
        title: `${type} | title goes here`,
        label: `${type} | label goes here`,
        name: "",
        content: contentInitialized,
        required: false,
        spaceAfter: false,
        moveToSelection: null,
        page: this.currentSurveyTab + 1,
      };
    },

    insertNewFormElement(newFormObj) {
      let index = 0;
      if (newFormObj.type === "page-break") {
        index =
            this.form.fields.findIndex(
                (field) =>
                    field.type === "page-break" && field.page === newFormObj.page + 1
            ) - 1;
        index = index > -1 ? index : this.form.fields.length;
      } else {
        index =
          this.form.fields.findIndex(
              (field) =>
                  field.type === "page-break" && field.page === newFormObj.page
          );
      }
      newFormObj.fieldsIndex = index + 0.5
      this.form.fields.splice(index, 0, newFormObj);
      this.reorderFields();
    },

    addTextField() {
      const newTextField = this.getNewFormElement();

      this.insertNewFormElement(newTextField);
    },

    addLabel() {
      const newLabelField = this.getNewFormElement();

      this.insertNewFormElement(newLabelField);
    },

    addComputedProperty() {
      const newFormElement = this.getNewFormElement();
      const newComputedPropertyField = {
        ...newFormElement,
        prefix: "",
        suffix: "",
        visible: false,
      };

      this.insertNewFormElement(newComputedPropertyField);
    },

    addGroupedOptions() {
      const newFormElement = this.getNewFormElement("array");
      const newComputedPropertyField = {
        ...newFormElement,
        groupingQuestion: "",
        groupingVariant: "text",
        groupingOptions: {},
        optionToAdd: "",
        visible: false,
      };

      this.insertNewFormElement(newComputedPropertyField);
    },

    addTextArea() {
      const newTextField = this.getNewFormElement();

      this.insertNewFormElement(newTextField);
    },

    addSignaturePad() {
      const newSignaturePad = this.getNewFormElement();

      this.insertNewFormElement(newSignaturePad);
    },

    addRadioButtons() {
      const newFormElement = this.getNewFormElement("array");
      const newSingleSelect = {
        ...newFormElement,
        variant: "",
        selection: "",
        optionToAdd: "",
      };

      this.insertNewFormElement(newSingleSelect);
    },

    addStateSelect() {
      const newFormElement = this.getNewFormElement("array");
      const newSingleSelect = {
        ...newFormElement,
        variant: "",
        selection: "",
        optionToAdd: "",
      };

      this.insertNewFormElement(newSingleSelect);
    },    

    addCheckboxes() {
      const newFormElement = this.getNewFormElement("array");
      const newMultiSelect = {
        ...newFormElement,
        variant: "",
        selection: [],
        optionToAdd: "",
      };

      this.insertNewFormElement(newMultiSelect);
    },

    addNumberInput() {
      const newFormElement = this.getNewFormElement();
      const newNumberInput = {
        ...newFormElement,
        prefix: "",
        suffix: "",
        lowerBound: -100,
        upperBound: 100,
        useCommas: false,
        commaValue: ''
      };

      this.insertNewFormElement(newNumberInput);
    },

    addFileInput() {
      const newFileInput = this.getNewFormElement();

      newFileInput.content = {
        file: undefined,
        storageRef: null,
        deleteFlag: false,
      };
      this.insertNewFormElement(newFileInput);
    },

    addPageBreak() {
      let pageNumber = 1;

      const pageBreaks = this.form.fields.filter((el) => {
        return el.type === "page-break";
      }).length;

      pageNumber = pageBreaks;

      const newFormElement = this.getNewFormElement();
      const newPageBreak = {
        ...newFormElement,
        pageNumber: pageNumber + 1,
      };

      this.insertNewFormElement(newPageBreak);
    },

    addHeading() {
      const newFormElement = this.getNewFormElement();
      const newHeading = {
        ...newFormElement,
        align: "",
      };

      this.insertNewFormElement(newHeading);
    },

    addSubheading() {
      const newFormElement = this.getNewFormElement();
      const newSubheading = {
        ...newFormElement,
        align: "",
      };

      this.insertNewFormElement(newSubheading);
    },

    addParagraph() {
      const newFormElement = this.getNewFormElement();
      const newParagraph = {
        ...newFormElement,
        align: "",
      };

      this.insertNewFormElement(newParagraph);
    },

    addYoutubeVideo() {
      const newFormElement = this.getNewFormElement();
      const newYoutubeVideo = {
        ...newFormElement,
        videoURL: "",
        videoID: "",
        embed: "",
        img: "",
      };

      this.insertNewFormElement(newYoutubeVideo);
    },

    async checkYoutubeVideo(index, youTubeService = YouTubeServices) {
      this.form.fields[index].videoID = /(?:embed\/|\?v=|\.be\/)(.{11})/gi.exec(
          this.form.fields[index].videoURL
      )[1];

      const videoData = await youTubeService.getVideo(
          this.form.fields[index].videoID
      );
      let videoTemp = {};
      const videoSnippet = videoData.data.items[0].snippet;

      videoTemp.name = videoSnippet.title;
      videoTemp.tags = videoSnippet.tags;
      videoTemp.embed =
          "https://www.youtube.com/embed/" + this.form.fields[index].videoID;
      videoTemp.author = videoSnippet.channelTitle;
      videoTemp.img = videoSnippet.thumbnails.medium.url;

      this.form.fields[index].name = videoTemp.name;
      this.form.fields[index].embed = videoTemp.embed;
      this.form.fields[index].img = videoTemp.img;
    },

    addDatePicker() {
      const newFormElement = this.getNewFormElement();
      const newDatePicker = {
        ...newFormElement,
        format: "MMMM Do, YYYY",
        variant: "single-date",
        monthPicker: false,
        dateValue: '',
        dateSingle: new Date().toISOString().substr(0, 10),
        dateMultiple: [],
        minDate: null,
        maxDate: null,
        dialog: false,
      };

      this.insertNewFormElement(newDatePicker);
    },

    addDialogWindow() {
      const newFormElement = this.getNewFormElement();
      const newDialogWindow = {
        ...newFormElement,
        heading: "",
        text: "",
        selection: "",
        optionToAdd: "",
        content: [],
        dialog: false,
      };

      this.insertNewFormElement(newDialogWindow);
    },

    addItemTable() {
      const newItemTable = {
        uid: nanoid(12),
        type: "item-table",
        fieldsIndex: null,
        visible: true,
        title: "",
        label: "",
        required: false,
        spaceAfter: false,
        headers: [{text: "Actions", value: "actions", sortable: false}],
        headerToAdd: {
          variant: 'text',
          text: "",
          align: 'center',
          sortable: false,
          divider: true,
          value: "",
          writeable: true
        },
        readOnlyRows: [],
        inputRows: [],
        inputRowsLimit: null,
        variant: "user-input-allowed",
        dialog: false,
        moveToSelection: null,
        page: this.currentSurveyTab + 1,
      };

      this.insertNewFormElement(newItemTable);
    },

    addChildSupportCalculator() {
      const newChildSupportElement = this.getNewFormElement();
      this.insertNewFormElement(newChildSupportElement);
    },

    addEmbeddedImage() {
      const newFormElement = this.getNewFormElement();
      const newEmbeddedImage = {
        ...newFormElement,
        alignment: "center",
        imageRefs: [],
        variant: "image-carousel",
        interactionType: "single-select",
        selectionSingle: {},
        selectionMultiple: [],
      };

      this.insertNewFormElement(newEmbeddedImage);
    },

    moveFormElement(field, desiredPosition, direction = "down") {
      const placement = direction === "down" ? 0.5 : -0.5
      // Add 0.5 to fieldsIndex to put it directly after form element that has been selected; reorderFields handles updating all the other form elements' fieldsIndex
      this.updateWorkflowField({
        field: {
          uid: field.uid,
          title: field.title,
          type: field.type,
        },
        updates: {
          'fieldsIndex': desiredPosition + placement,
        }
      })

      this.checkForUniqTitle();
    },

    addOption(field) {
      this.form.fields[field.fieldsIndex].content.push(
          this.form.fields[field.fieldsIndex].optionToAdd
      );
      this.form.fields[field.fieldsIndex].optionToAdd = "";
    },

    removeOption(field, index) {
      field.content.splice(index, 1);
    },

    removeFormElement(index) {
      this.formElementIndex = index;
      return this.confirmationDialog = !this.confirmationDialog;
    },

    confirmFormDeleting() {
      const index = this.formElementIndex
      const currentField = this.form.fields[index];
      if (currentField.type === "embedded-image") {
        this.toggleLoadingOverlayStatus(true);
        this.embeddedImageDelete({field: currentField})
            .then(() => {
              this.form.fields.splice(index, 1);
            }).finally(() => {
              this.toggleLoadingOverlayStatus(false)
            })
      } else {
        this.fieldsToDelete.push(this.form.fields[index].uid)
        this.form.fields.splice(index, 1);
      }

      this.handleBuildFormStep();
      this.confirmationDialog = false;
    },

    duplicateFormElement(index) {
      // Duplicate form element data
      let formElementData = Object.assign({}, this.form.fields[index]);

      // Reassign necessary parts of form element data
      formElementData.uid = nanoid(12);
      formElementData.title = formElementData.title + " - COPY";
      switch (formElementData.type) {
        case "embedded-image":
          formElementData = {
            ...formElementData,
            answeredStatus: false,
            selectionMultiple: [],
            selectionSingle: {},
            imageRefs: [],
          };
          break;
        case "file-input":
          formElementData = {
            ...formElementData,
            answeredStatus: false,
            content: {
              file: undefined,
              storageRef: null,
              deleteFlag: false,
            },
          };
          break;
        case "signature-pad":
          formElementData = {
            ...formElementData,
            answeredStatus: false,
            signature: {},
          };
          break;
        case "grouped-options":
          formElementData = {
            ...formElementData,
            groupingOptions: {},
          }
          break;
      }
      this.$store.dispatch("addFormElement", formElementData).then(() => {
        this.reorderFields();
        this.checkForUniqTitle();
      });
    },

    formBuilderDialogToggle(value) {
      if (value === false) {
        this.setNewForm(false);
        this.pdfTemplate.name = "";
        this.pdfTemplate.combinedPages = [];
        this.pdfTemplate.existingPages = [];
        this.pdfTemplate.currentSessionPages = [];
        this.pdfTemplate.pagesToDelete = [];
        this.pdfTemplateChangeFlag = 0;
        this.setFormBuilderPagesLoadedFlag(false);
      }

      this.toggleFormBuilderDialog(value);
    },

    reorderFields(fields = this.getWorkflowFieldsAll) {
      fields.map((field, index) => {
        field.fieldsIndex = index;
      });
    },

    handleNewOrder(evt, pageIndex) {
      if(evt.oldIndex === 0 || evt.newIndex === 0) return
      const direction = evt.oldIndex > evt.newIndex ? "up" : "down"
      const sortedPages = this.getWorkflowPages[(pageIndex - 1)].sort((a,b) => a.fieldsIndex - b.fieldsIndex)
      const fieldToMove = sortedPages.find((f,i) => i === evt.oldIndex);
      const oldField = sortedPages[evt.newIndex]
      
      this.moveFormElement(fieldToMove, oldField.fieldsIndex, direction);
    },

    async displayLogicShowAll(status) {
      this.setOverrideStatus(status);
      await this.updateFormElementsDisplayStatus(
        {
          formElementsToUpdate: "all",
          workflowType: "in-edit",
        }
      );
      return
    },

    async updateProcessedFile(payload) {
      await this.toggleLoadingOverlayStatus(true)
      if (!payload?.docIndex)
        payload["docIndex"] = this.currentDocIndex;

      await this.updateDocumentRender()
      this.currentPdfFile = this.pdfFile
      await this.toggleLoadingOverlayStatus(false)
      return
    },

    updateWorkflow() {
      this.currentDocIndex = 0;
      this.saveWorkflow();
      this.currentStep = '4';
    },
  },
}