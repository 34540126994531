export default (error) => {
    switch (error.code) {
        case 'storage/object-not-found':
            throw "File doesn't exist"
        case 'storage/unauthorized':
            throw "User doesn't have permission to access the object"
        case 'storage/canceled':
            throw 'User canceled the upload'
        case 'storage/unknown':
            throw 'Unknown error occurred, inspect the server response'
    }
}