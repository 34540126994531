import { v4 as uuidv4 } from 'uuid';
import validatorsMixin from './validators.mixin';

export default {
  mixins: [validatorsMixin],
  
  data() {
    return {
      currentField: {
        headers: [],
        variant: ""
      },
      fields: [],
      editedIndex: -1,
      formFieldsIndex: null,
      itemTableEditItemDialog: false,
      itemTableDeleteItemDialog: false,
      defaultItem: {},
      editedItem: {},
      itemTableVariants: ['read-only', 'user-input-allowed'],
      itemTableHeaderAlignOptions: ['start', 'center', 'end'],
    }
  },

  watch: {
    formFields: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fields = newVal
        }
      }
    }
  },

  props: {
   formFields: {
      type: Array,
      default: () => []
    },

    previewMode: {
      type: Boolean,
      default: false
    },

    readOnly: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    formatMoney() {
      return value => {
        return (parseInt(value) || 0).toLocaleString('en-US')
      }
    },
    
    itemTableReadOnlyHeaders() {
      return headers => {
        return headers.filter(h => h.readonly === false && this.previewMode === false).length > 0 ? headers : headers.filter(el => el.text !== 'Actions')
      };
    },
    
    itemTableRows() {
      return headers => headers.filter(el => el.text !== 'Actions')
    },

    itemTableRowTitle() {
      return this.editedIndex === -1 ? 'New Row' : 'Edit Row'
    },

    itemTableCanCreate () {
      return !this.readOnly && !this.previewMode
    },
  },
  
  methods: {
    itemTableUpdateValueField(data) {
      const value = data.headerToAdd.text.toLowerCase().split(' ').join('-')
      data.headerToAdd.value = `${value}-${uuidv4()}`
    },
    
    itemTableChangeWriteableDefault(data) {
      data.headerToAdd.writeable = data.variant === 'user-input-allowed'
    },
    
    itemTableAddNewRow(field) {
      this.formFieldsIndex = field.fieldsIndex;
      this.currentField = field;
      const item = {};
      for (let i = 0; i < field.headers.length; i++) {
        item[field.headers[i].value] = "";
      }
      this.editedItem = Object.assign({}, item);
      this.itemTableEditItemDialog = true
    },

    itemTableEditItem(item, index, field) {
      this.formFieldsIndex = field.fieldsIndex;
      this.currentField = field
      this.editedIndex = index
      this.editedItem = {...item}
      this.itemTableEditItemDialog = true
    },
    
    itemTableDeleteItem(item, index, field) {
      this.currentField = field
      this.formFieldsIndex = field.fieldsIndex;
      this.editedIndex = index
      this.editedItem = {...item}
      this.itemTableDeleteItemDialog = true;
    },
    
    async itemTableDeleteItemConfirm(prop = 'inputRows') {
      const rowsToUpdate = this.currentField[prop].filter((_,i) => i !== this.editedIndex)
      this.currentField[prop] = rowsToUpdate
      if(this.canAutoSave) {
        this.currentField.answeredStatus = false
        await this.autoSave({...this.currentField})
      }
      this.itemTableCloseDeleteDialog()
    },

    async itemTableSaveItem(currentField, prop = 'inputRows') {
      if (this.editedIndex > -1) {
        const rowsToUpdate = [...currentField[prop]]
        rowsToUpdate[this.editedIndex] = this.editedItem
        currentField[prop] = rowsToUpdate
      } else if(currentField?.[prop]) {
        currentField[prop].push(this.editedItem)
      }
      if(this.canAutoSave) {
        currentField.answeredStatus = true
        await this.autoSave(currentField)
      }      
      this.itemTableCloseDialog();
    },

    itemTableCloseDialog() {      
      this.itemTableEditItemDialog = false;      
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },

    itemTableCloseDeleteDialog() {
      this.itemTableDeleteItemDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    }
  }
}