import BlockChecker from "@/components/BlockChecker.vue";
import pdfjsLib from "pdfjs-dist";
import { mapState, mapActions, mapGetters } from "vuex";
import xMark from "@/assets/clear-24px.png";
import { checkMark } from "@/assets/check-mark";
import VueResizable from "vue-resizable";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Table from "@editorjs/table";
import Delimiter from "@editorjs/delimiter";
import RuleConfiguration from "@/components/TextEditorAction/RuleConfiguration";
import FileRenderer from "@/components/FileRenderer";
import RulesBuilder from "@/components/WorkflowBuilder/RulesBuilder";
import FormBuilderPreview from "@/components/FormBuilderPreview";
import pdfMixin from "@/mixins/pdf.mixin";
import parserMixin from "@/mixins/parser.mixin";
import { quillEditor } from "vue-quill-editor";
import { QUILL_MODULES } from "@/constants/optionsConstants";
import AutoNumbering from "@/components/TextEditorAction/AutoNumbering";
import ActionsList from "@/components/WorkflowBuilder/Actions/ActionsList";
import ActionsPdfPlacement from "@/components/WorkflowBuilder/Actions/ActionsConfigByType/ActionsPdfPlacement";
import ActionsTextEditor from "@/components/WorkflowBuilder/Actions/ActionsConfigByType/ActionsTextEditor";

export default {
  components: {
    ActionsTextEditor,
    ActionsPdfPlacement,
    ActionsList,
    AutoNumbering,
    VueResizable,
    RuleConfiguration,
    FileRenderer,
    RulesBuilder,
    FormBuilderPreview,
    quillEditor,
    BlockChecker
  },

  mixins: [pdfMixin, parserMixin],

  props: {
    pdfFile: {
      required: true,
      type: Blob,
    },
    currentPdfPages: {
      required: false,
      type: Array,
      default: () => [],
    },
    contentHeight: {
      type: Number,
      required: false,
      default: 500,
    },
  },

  data() {
    return {
      types: [
        "add-x",
        "add-check-mark",
        "text-static",
        "text-from-question",
        "custom-image",
        "resp-signature-date",
        "resp-signature-img",
        "pet-signature-img",
        "pet-signature-date"
      ],
      dialog: false,
      editorOption: {
        modules: QUILL_MODULES,
      },
      actionLegendHeight: 0,
      pdfHeight: 0,
      actionCorners: ["rb"],
      scaleHeight: 100,
      scaleWidth: 100,
      snackbar: false,
      savedStatusText: "",
      actionTop: 0,
      actionLeft: 0,
      actionWidth: 0,
      actionHeight: 0,
      savedStatusColor: "success",
      canvasWidth: 0,
      canvasHeight: 0,
      activeTab: 0,
      activeRule: 0,
      changeNameFlag: false,
      pdfDoc: null,
      pageNum: 1,
      currentPageNum: 1,
      totalPageNum: 1,
      pageRendering: false,
      pageNumPending: null,
      scale: 0.5,
      currentPdfFile: null,
      clickDragEvent: {
        isDown: false,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
      },

      customImageVariants: ["image-from-survey", "image-from-upload"],

      actionItems: [
        "Add X",
        "Add check mark",
        "Add static text",
        "Add input from question as text",
        "Text Editor",
        "Add custom image",
      ],
      actionSelection: null,
      orderSelection: null,
      answers: [],
      answerSelection: null,

      actionOptions: {
        textDecoration: ["bold", "italic", "underlined"],
        size: ["xs", "sm", "md", "lg", "xl"],
        fontFamily: ["Calibri", "Times New Roman", "Arial", "Helvetica"],
        textColor: [
          "black",
          "white",
          "red",
          "orange",
          "yellow",
          "green",
          "blue",
          "violet",
          "light-grey",
          "grey",
          "dark-grey",
        ],
      },

      editorJS: undefined,

      updateProcessedFileDebounce: null,

      selectedAction: undefined,
      editMode: false,
      chosenImage: xMark,

      rules: {
        pointPosition: (value) => {
          if (value <= 100 && value > 0) {
            return true;
          } else {
            return "Must be a value between 0 and 100";
          }
        },
      },
    };
  },

  computed: {
    ...mapState({
      pdfActionActive: "pdfActionActive",
      actionTemplateDialog: "pdfActionTemplateDialog",
      form: "formBuilderActiveForm",
    }),

    ...mapGetters({
      getPdfTemplateActions: "getPdfTemplateActions",
      getPdfActionActive: "getPdfActionActive",
      getAcceptedUploadFileTypes: "getAcceptedUploadFileTypes",
    }),

    needsBlockChecker() {
      return this.activeAction?.type === 'text-editor' && this.activeAction?.quillSavedData?.length > 0
    },

    actionListHeight() {
      return this.needsBlockChecker ? 'fit-content' : '100%'
    },

    currentDocIndex: {
      get() {
        return this.$store.state.formBuilderDocIndex;
      },
      set(value) {
        this.$store.dispatch("setFormBuilderDocIndex", value).then(() => {
          this.currentPdfFile = this.pdfFile;
        });
      },
    },

    ...mapGetters({
      currentDoc: "getWorkflowBuilderCurrentDoc",
      currentRule: "workflow/getWorkflowBuilderCurrentRule",
      currentAction: "workflow/getWorkflowBuilderCurrentAction",
    }),

    activeAction: {
      get() {
        if (this.hasRuleAndActions) {
          return this.currentDoc.rules[this.currentRule].actions[
            this.currentAction
          ];
        } else {
          return null;
        }
      },
      set(value) {
        // Do nothing
      },
    },

    hasRuleAndActions() {
      return this.currentDoc?.rules?.[this.currentRule]?.actions?.length > 0;
    },

    isActionType() {
      let actionType = "";

      if (this.form?.docs?.length > this.currentDocIndex) {
        if (this.getActiveDoc?.rules?.[this.activeRule]) {
          actionType = this.getActiveRule?.type || "";
        }
      }

      return actionType === "Action Logic";
    },

    getActiveFormDocs() {
      const docs = this.form?.docs || [];
      const formattedDocs = [];
      docs.map((doc, i) => {
        formattedDocs.push({
          id: i,
          title: doc?.name || `Document ${i + 1}`,
        });
      });
      return formattedDocs;
    },

    actionLegend() {
      return this.getActiveRule.actions.filter(
        (action) => action.location.page == this.currentPageNum
      );
    },

    currentPageIsBlank() {
      if (this.currentPdfPages.length >= this.currentPageNum) {
        return this.currentPdfPages[this.currentPageNum - 1].file === undefined;
      } else {
        return false;
      }
    },

    currentPageHasTextEditorAction() {
      return this.getActiveRule.actions.some(
        (action) =>
          parseInt(action.location.page) === parseInt(this.currentPageNum) &&
          action.type === "text-editor"
      );
    },

    getActionItems() {
      let currentActionItems = [...this.actionItems];
      if (this.currentPageHasTextEditorAction) {
        if (this.currentPageIsBlank) {
          currentActionItems = [];
        } else {
          const index = currentActionItems.findIndex(
            (a) => a === "Text Editor"
          );
          currentActionItems.splice(index, 1);
        }
      }

      return currentActionItems;
    },

    getActiveDoc() {
      return this.form.docs[this.currentDocIndex];
    },

    getActiveRule() {
      return this.getActiveDoc.rules[this.activeRule];
    },

    getActiveAction() {
      const activeAction = this.getActiveRule.actions[this.activeActionIndex];
      return activeAction;
    },
  },

  watch: {
    currentPdfFile: function (newVal) {
      if (newVal !== null) {
        this.onPdfFormPreview();
      }
    },

    actionTemplateDialog: function (newVal) {
      // If there are no rules, the action editor won't render. This loads a blank one up in that case.
      if (!this.getActiveDoc?.rules) {
        this.getActiveDoc.rules = {};
      }
      if (Object.keys(this.getActiveDoc.rules).length === 0) {
        this.addRule(this.currentDocIndex);
      }

      if (newVal === false) {
        this.resetData();
      }
    },

    pdfActionActive: function (newVal) {
      if (
        [
          "text-static",
          "text-from-question",
          "signature-pad",
          "text-editor",
        ].indexOf(newVal) > -1 ||
        (newVal === "text-editor" && !this.currentPageIsBlank)
      ) {
        // Wait for canvas to be rendered
        setTimeout(() => {
          const canvas = this.$refs["pdf-action-viewer"];

          canvas.addEventListener("mousedown", (e) => {
            this.handleMouseDown(e);
          });
          canvas.addEventListener("mouseup", (e) => {
            this.handleMouseUp(e);
          });
          canvas.addEventListener("mouseout", (e) => {
            this.handleMouseOut(e);
          });
        }, 200);
      } else {
        setTimeout(() => {
          const canvas = this.$refs["pdf-action-viewer"];

          canvas.removeEventListener("mousedown", (e) => {
            this.handleMouseDown(e);
          });
          canvas.removeEventListener("mouseup", (e) => {
            this.handleMouseUp(e);
          });
          canvas.removeEventListener("mouseout", (e) => {
            this.handleMouseOut(e);
          });
        }, 200);
      }
    },

    currentDocIndex: {
      handler: async function (newValue, oldValue) {
        await this.$nextTick();
        this.activeTab = 0;
        this.actionType = "";
        this.currentPageNum = 1;
        this.pageNum = 1;
      },
      deep: true,
    },
  },

  mounted() {
    this.currentPdfFile = this.pdfFile;
    if (Object.keys(this.getActiveDoc.rules).length === 0) {
      this.addRule(this.currentDocIndex);
    }
    this.$store.dispatch("toggleLoadingOverlayStatus", true);
    this.$store.dispatch("updateDocumentRender").then(() => {
      this.currentPdfFile = this.pdfFile;
      const toolbarHeight = document.querySelector(
          "#workflow-builder-toolbar"
      ).clientHeight;
      const iconHeaderTabHeight = document.querySelector(
          "#workflow-builder-stepper"
      ).clientHeight;
      const screenHeight = window.innerHeight;
      const finalHeight =
          screenHeight - (toolbarHeight + iconHeaderTabHeight + 177);
      this.actionLegendHeight = finalHeight;
      this.pdfHeight = finalHeight;
    }).finally(() => {
      this.$store.dispatch("toggleLoadingOverlayStatus", false)
    })
  },

  methods: {
    ...mapActions([
      "setPdfTemplateActions",
      "deletePdfTemplateAction",
      "setPdfActionActive",
      "updatePdfTemplateAction",
      "toggleLoadingOverlayStatus",
      "addAction",
      "updateDocumentRender",
    ]),

    ...mapActions("confirmation", ["confirm"]),

    ...mapActions("workflow", ["clearProcessedFile" ]),

    updatePageNumber(newPage) {
      this.currentPageNum = newPage;
    },

    resetData() {
      // Reset component data when dialog is closed
      this.actionCorners = ["rb"];
      this.scaleHeight = 100;
      this.scaleWidth = 100;
      this.snackbar = false;
      this.savedStatusText = "";
      this.actionTop = 0;
      this.actionLeft = 0;
      this.actionWidth = 0;
      this.actionHeight = 0;
      this.savedStatusColor = "success";
      this.canvasWidth = 0;
      this.canvasHeight = 0;
      this.activeTab = 0;
      this.activeRule = 0;
      this.activeAction = "";
      this.activeActionIndex = null;
      this.changeNameFlag = false;
      this.pdfDoc = null;
      this.pageNum = 1;
      this.currentPageNum = 1;
      this.totalPageNum = 1;
      this.pageRendering = false;
      this.pageNumPending = null;
      this.scale = 0.5;
      this.clickDragEvent = {
        isDown: false,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
      };

      const actionSelected = document.querySelector("#selectedAction");

      if (actionSelected !== null) {
        actionSelected.style.display = "none";
      }

      this.chosenImage = null;
    },

    addRule(docIndex) {
      this.$store.dispatch("addRule", docIndex);
    },

    //
    // text-editor (EditorJS) methods
    //

    initializeEditorJS(savedData = {}) {
      let initializedData = {};

      if (savedData !== undefined) {
        initializedData = savedData;
      } else {
        initializedData = {};
      }

      this.editorJS = new EditorJS({
        /**
         * Create a holder for the Editor and pass its ID
         */
        holder: "editor-js",

        /**
         * Available Tools list.
         * Pass Tool's class or Settings object for each Tool you want to use
         */
        tools: {
          header: Header,
          list: List,
          delimiter: Delimiter,
          table: Table,
        },

        /**
         * Previously saved data that should be rendered
         */
        data: initializedData,
      });
    },

    toggleEditorJS(location = undefined) {
      const editorJS = document.getElementById("editor-js");
      const zIndex = editorJS.style.zIndex;

      if (location !== undefined) {
        editorJS.style.border = "black solid 1px";
        editorJS.style.width = location.width.toString() + "%";
        editorJS.style.height = location.height.toString() + "%";

        if (location.endX > location.startX) {
          editorJS.style.left =
            (location.startX * this.canvasWidth).toString() + "px";
        } else {
          editorJS.style.left =
            (location.endX * this.canvasWidth).toString() + "px";
        }

        if (location.endY > location.startY) {
          editorJS.style.top =
            (location.startY * this.canvasHeight).toString() + "px";
        } else {
          editorJS.style.top =
            (location.endY * this.canvasHeight).toString() + "px";
        }

        editorJS.style.background = "rgba(50, 50, 50, 0.2)";
        editorJS.style.color = "black";
      }

      if (zIndex === "10") {
        editorJS.style.zIndex = "-10";
      } else {
        editorJS.style.zIndex = "10";
      }
    },

    clearEditorJS() {
      const editorJS = document.getElementById("editor-js");
      editorJS.innerHTML = "";
      editorJS.removeAttribute("style");
      this.editorJS = undefined;
    },

    selectAction(actionObj, i, action = false) {
      this.editMode = action;

      this.activeAction = actionObj;
      this.activeActionIndex = i;

      const actionSelected = document.querySelector("#selectedAction");

      this.selectedAction = i;

      switch (actionObj.type) {
        case "add-check-mark":
          this.chosenImage = checkMark;
          break;
        case "add-x":
          this.chosenImage = xMark;
          break;
        default:
          this.chosenImage = null;
          break;
      }

      // actionSelected.style.width = null;
      // actionSelected.style.height = null;
      this.actionWidth = 42;
      this.actionHeight = 42;

      if (
        action === "select" &&
        this.selectedAction.style.display === "block" &&
        i === this.selectedAction
      ) {
        this.selectedAction = undefined;
        actionSelected.style.display = "none";
      } else if (["add-x", "add-check-mark"].indexOf(actionObj.type) > -1) {
        let leftValue = 17;
        let topValue = 20;
        let sqrSize = 42;
        switch (actionObj.size) {
          case "xs":
            leftValue = 5;
            topValue = 7;
            sqrSize = 16;
            break;
          case "sm":
            leftValue = 10;
            topValue = 12;
            sqrSize = 26;
            break;
          case "lg":
            leftValue = 25;
            topValue = 25;
            sqrSize = 52;
            break;
          case "xl":
            leftValue = 34;
            topValue = 34;
            sqrSize = 72;
            break;
        }

        this.actionWidth = sqrSize;
        this.actionHeight = sqrSize;

        this.actionLeft = this.getCorrectSize(
          actionObj.location.x,
          this.scaleWidth,
          this.canvasWidth,
          leftValue
        );
        this.actionTop = this.getCorrectSize(
          actionObj.location.y,
          this.scaleHeight,
          this.canvasHeight,
          topValue
        );
        actionSelected.style.display = "block";
        this.actionCorners = [];
      } else if (actionObj.type === "text-editor") {
        this.initializeEditorJS(actionObj.savedData);

        if (!actionObj.pageIsBlank) {
          this.toggleEditorJS(actionObj.location);
        } else {
          this.toggleEditorJS();
        }
      } else if (actionObj.type === "signature-pad") {
        this.actionLeft = this.getCorrectSize(
          actionObj.location.startX,
          this.scaleWidth,
          this.canvasWidth,
          0
        );
        this.actionTop = this.getCorrectSize(
          actionObj.location.startY,
          this.scaleHeight,
          this.canvasHeight,
          0
        );
        this.actionWidth = this.getCorrectSize(
          actionObj.location.width * 2.45,
          this.scaleWidth,
          this.canvasWidth,
          0
        );
        this.actionHeight = this.getCorrectSize(
          actionObj.location.height,
          this.scaleHeight,
          this.canvasHeight,
          0
        );
        this.actionCorners = ["rb"];
        actionSelected.style.display = "block";
      } else {
        this.actionLeft = this.getCorrectSize(
          actionObj.location.startX,
          this.scaleWidth,
          this.canvasWidth,
          0
        );
        this.actionTop = this.getCorrectSize(
          actionObj.location.startY,
          this.scaleHeight,
          this.canvasHeight,
          0
        );
        this.actionWidth = this.getCorrectSize(
          actionObj.location.width,
          this.scaleWidth,
          this.canvasWidth,
          0
        );
        this.actionHeight = this.getCorrectSize(
          actionObj.location.height,
          this.scaleHeight,
          this.canvasHeight,
          0
        );
        this.actionCorners = ["rb"];
        actionSelected.style.display = "block";
      }

      if (actionObj.type !== "text-editor" && this.editorJS) {
        this.toggleEditorJS();
        this.clearEditorJS();
      }
    },

    getCorrectSize: (pos, layout, canvasSize, sub) =>
      pos === 0 ? layout : (pos / layout) * canvasSize - sub,

    renderPage(num) {
      /**
       * Get page info from document, resize canvas accordingly, and render page.
       * @param num Page number.
       */

      const canvas = document.getElementById("pdf-action-viewer");

      if (canvas !== null) {
        const ctx = canvas.getContext("2d");

        this.pageRendering = true;
        // Using promise to fetch the page
        this.pdfDoc.getPage(num).then((page) => {
          const vCardTextPadding =
            parseFloat(
              window.getComputedStyle(this.$refs.pdf_container, null)
                .paddingRight
            ) * 2;

          // Width of pdf canvas is width of pdf_container element minus padding of the v-card-text element minus 2 pixels for the border of the canvas
          const canvasWidth =
            parseFloat(this.$refs.pdf_container.clientWidth) -
            vCardTextPadding -
            4;

          let viewport = page.getViewport({ scale: this.scale });

          const pdfScale = canvasWidth / parseFloat(viewport.viewBox[2]);

          this.scale = pdfScale;

          viewport = page.getViewport({ scale: this.scale });

          const pdfAspectRatio =
            parseFloat(viewport.height) / parseFloat(viewport.width);

          canvas.width = canvasWidth;
          canvas.height = pdfAspectRatio * canvasWidth;

          this.canvasWidth = canvas.width;
          this.canvasHeight = canvas.height;
          // Render PDF page into canvas context
          const renderContext = {
            canvasContext: ctx,
            viewport: viewport,
          };
          const renderTask = page.render(renderContext);

          // Wait for rendering to finish
          renderTask.promise.then(() => {
            this.pageRendering = false;

            if (this.pageNumPending !== null) {
              // New page rendering is pending
              this.renderPage(this.pageNumPending);
              this.pageNumPending = null;
            }
          });
        });
      }

      // Update page counters
      this.currentPageNum = num;
    },

    queueRenderPage(num) {
      /**
       * If another page rendering in progress, waits until the rendering is
       * finised. Otherwise, executes rendering immediately.
       */

      if (this.pageRendering) {
        this.pageNumPending = num;
      } else {
        this.renderPage(num);
      }
    },

    onPdfFormPreview() {
      if (this.currentPdfFile === null) this.currentPdfFile = this.pdfFile;
      // If absolute URL from the remote server is provided, configure the CORS
      // header on that server.
      const url = URL.createObjectURL(this.currentPdfFile);

      /**
       * Asynchronously downloads PDF.
       */

      pdfjsLib.getDocument(url).promise.then((pdfDoc_) => {
        this.pdfDoc = pdfDoc_;
        this.totalPageNum = this.pdfDoc.numPages;

        // Initial/first page rendering
        this.renderPage(this.pageNum);

        // window.onresize = this.queueRenderPage(this.pageNum);
      });

      if (this.pdfActionActive === "text-from-question") {
        // this flag is true when the user is dragging the mouse
        this.clickDragEvent.isDown = false;

      }
    },

    handleMouseDown(e) {
      this.canvasWidth = parseFloat(
        this.$refs["pdf-action-viewer"].clientWidth
      );
      this.canvasHeight = parseFloat(
        this.$refs["pdf-action-viewer"].clientHeight
      );

      e.preventDefault();
      e.stopPropagation();

      // save the starting x/y of the rectangle
      const startX = parseFloat(e.offsetX) / this.canvasWidth;
      const startY = parseFloat(e.offsetY) / this.canvasHeight;

      if (startX < 0) {
        this.clickDragEvent.startX = 0;
      } else if (startX > 1) {
        this.clickDragEvent.startX = 1;
      } else {
        this.clickDragEvent.startX = startX;
      }

      if (startY < 0) {
        this.clickDragEvent.startY = 0;
      } else if (startY > 1) {
        this.clickDragEvent.startY = 1;
      } else {
        this.clickDragEvent.startY = startY;
      }

      this.$logger.info("startX: " + this.clickDragEvent.startX);
      this.$logger.info("startY: " + this.clickDragEvent.startY);

      // set a flag indicating the drag has begun
      this.clickDragEvent.isDown = true;
    },

    handleMouseUp(e) {
      e.preventDefault();
      e.stopPropagation();

      // save the ending x/y of the rectangle
      let endX = parseFloat(e.offsetX) / this.canvasWidth;
      let endY = parseFloat(e.offsetY) / this.canvasHeight;

      endX = endX < 0 ? 0 : endX > 1 ? 1 : endX;

      endY = endY < 0 ? 0 : endY > 1 ? 1 : endY;

      if (endX < this.clickDragEvent.startX) {
        this.clickDragEvent.endX = this.clickDragEvent.startX;
        this.clickDragEvent.startX = endX;
      } else {
        this.clickDragEvent.endX = endX;
      }

      if (endY < this.clickDragEvent.startY) {
        this.clickDragEvent.endY = this.clickDragEvent.startX;
        this.clickDragEvent.startY = endY;
      } else {
        this.clickDragEvent.endY = endY;
      }

      const diffX = this.clickDragEvent.endX - this.clickDragEvent.startX;

      if (diffX < 0.1) {
        this.clickDragEvent.endX += 0.1;
      }

      const diffY = this.clickDragEvent.endY - this.clickDragEvent.startY;

      if (diffY < 0.02) {
        this.clickDragEvent.endY += 0.02;
      }

      this.$logger.info("endX: " + this.clickDragEvent.endX);
      this.$logger.info("endY: " + this.clickDragEvent.endY);

      // the drag is over, clear the dragging flag
      this.clickDragEvent.isDown = false;
    },

    handleMouseOut(e) {
      if (this.clickDragEvent.isDown) {
        e.preventDefault();
        e.stopPropagation();

        // save the ending x/y of the rectangle
        const endX = parseFloat(e.offsetX) / this.canvasWidth;
        const endY = parseFloat(e.offsetY) / this.canvasHeight;

        if (endX < 0) {
          this.clickDragEvent.endX = 0;
        } else if (endX > 1) {
          this.clickDragEvent.endX = 1;
        } else {
          this.clickDragEvent.endX = endX;
        }

        if (endY < 0) {
          this.clickDragEvent.endY = 0;
        } else if (endY > 1) {
          this.clickDragEvent.endY = 1;
        } else {
          this.clickDragEvent.endY = endY;
        }

        this.$logger.info("endX: " + this.clickDragEvent.endX);
        this.$logger.info("endY: " + this.clickDragEvent.endY);

        // the drag is over, clear the dragging flag
        this.clickDragEvent.isDown = false;
      }
    },
  },
}