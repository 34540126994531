<template>
  <v-container>
    <v-tabs
      class="rules-legend"
      :style="`max-height: ${rulesLegendHeight}px;`"
      v-model="currentRule"
      vertical
      dark
      background-color="deep-purple accent-4"
    >
      <v-text-field
        v-show="showSearch"
        v-model="ruleSearch"
        label="Search Rules..."
        style="margin-top: 10px; padding: 3px"
        clearable
      />

      <v-tab class="rule-button" v-for="(rule, index) in rules" :key="index"
        >{{ rule.name }}
      </v-tab>

      <v-tabs-slider color="orange darken-5" />

      <v-tab-item
        v-for="(rule, index1) in form.docs[docIndex].rules"
        :key="index1"
      >
        <v-card color="teal lighten-5">
          <v-card-actions v-if="isReadOnly === false">
            <v-btn color="primary" @click="addRule">
              <v-icon>add</v-icon>
              <span>Add Another Rule</span>
            </v-btn>

            <v-spacer />

            <v-btn outlined icon @click="toggleDeleteRuleDialog(index1)">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <v-row>
              <v-col cols="0" md="1" xl="2" />
              <v-col cols="12" md="10" xl="8">
                <v-row>
                  <v-col cols="2" />
                  <v-col cols="8">
                    <v-btn
                      v-if="!isReadOnly"
                      text
                      class="rule-name"
                      @click="changeName"
                      >{{
                        !!rule.name
                          ? "Rule: " + rule.name
                          : "Rule " + (parseInt(index1) + 1)
                      }}
                    </v-btn>
                    <v-text-field
                      v-if="changeNameFlag && !isReadOnly"
                      v-model="rule.name"
                      :readonly="isReadOnly"
                      class="rule-name-change"
                      label="Set Current Rule Name"
                    />
                    <br />
                    <br />
                  </v-col>
                  <v-col cols="2" />
                </v-row>

                <v-divider />

                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="rule.type"
                      :readonly="isReadOnly"
                      :items="types"
                      label="Rule Type"
                      @change="onTypeChange"
                    />
                  </v-col>
                </v-row>

                <template v-if="rule.type === 'Display Logic'">
                  <v-switch
                    v-if="rule.condition !== 'always'"
                    v-model="rule.displayLogicQuestionsDefault"
                    :label="`Questions ${
                      rule.displayLogicQuestionsDefault ? 'Hidden' : 'Shown'
                    } By Default`"
                  />

                  <v-autocomplete
                    v-model="rule.displayLogicQuestions"
                    :readonly="isReadOnly"
                    :label="`Questions to ${
                      rule.displayLogicQuestionsDefault &&
                      rule.condition !== 'always'
                        ? 'Show'
                        : 'Hide'
                    }`"
                    :items="parsedQuestionList"
                    multiple
                    class="text-left"
                    item-text="questionListTitle"
                    return-object
                  />
                </template>

                <v-divider />

                <template v-if="rule.type === 'Sharing Logic'">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="rule.readOnlyShareLimit"
                        :readonly="isReadOnly"
                        type="number"
                        label="Read-Only Share Limit"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="rule.commentShareLimit"
                        :readonly="isReadOnly"
                        type="number"
                        label="Comment Share Limit"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="rule.editShareLimit"
                        :readonly="isReadOnly"
                        type="number"
                        label="Edit Share Limit"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                </template>

                <template v-if="rule.type !== 'Sharing Logic'">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-select
                        :items="orderList"
                        v-model="rule.order"
                        :readonly="isReadOnly"
                        label="Order"
                        @blur="setOrder(index1)"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        :items="conditionals"
                        v-model="rule.condition"
                        :readonly="isReadOnly"
                        label="Conditional"
                      />
                    </v-col>
                    <v-col v-if="rule.condition !== 'always'" cols="12" md="4">
                      <v-select
                        :items="logicalOperators"
                        v-model="rule.operator"
                        :readonly="isReadOnly"
                        label="Logical Operator"
                      />
                    </v-col>
                  </v-row>

                  <v-divider />

                  <template v-if="rule.condition !== 'always'">
                    <v-btn
                      v-if="isReadOnly === false"
                      class="my-4"
                      outlined
                      @click="addQuestion(index1)"
                    >
                      <v-icon>add</v-icon>
                      <span>Add Question</span>
                    </v-btn>

                    <v-divider />

                    <template v-for="(question, index) in rule.questions">
                      <v-row :key="index">
                        <v-col
                          cols="6"
                          class="d-flex justify-center align-center"
                        >
                          <!-- Sets answer options when changed -->
                          <v-autocomplete
                            v-model="question.uid"
                            :readonly="isReadOnly"
                            :label="'Question ' + (index + 1)"
                            :items="parsedQuestionList"
                            class="py-2 text-left solo-height-fix"
                            item-text="questionListTitle"
                            item-value="uid"
                            auto-select-first
                            solo
                            @change="
                              getSelectedQuestionAnswers(question.uid, index, index1)
                            "
                          />
                        </v-col>
                        <v-col
                          v-if="showAnswerDropdown(question)"
                          cols="5"
                        >
                          <v-select
                            v-model="question.selection['answer']"
                            :readonly="isReadOnly"
                            :label="'Answer'"
                            :items="question.options"
                            solo
                            class="py-2 text-left solo-height-fix"
                          />
                        </v-col>
                        <template v-if="question.type === 'number-input'">
                          <v-col cols="3">
                            <v-select
                              v-model="question.selection.comparison"
                              :readonly="isReadOnly"
                              :label="'Comparison'"
                              :items="question.options"
                              solo
                              class="py-2 text-left solo-height-fix"
                            />
                          </v-col>
                          <template
                            v-if="
                              question.selection.comparison === 'greater-than'
                            "
                          >
                            <v-col cols="2">
                              <v-text-field
                                v-model="question.selection.lowerLimit"
                                :readonly="isReadOnly"
                                type="number"
                                label="Lower Limit"
                                solo
                                class="py-2 text-left solo-height-fix"
                              />
                            </v-col>
                          </template>
                          <template
                            v-if="question.selection.comparison === 'equal-to'"
                          >
                            <v-col cols="2">
                              <v-text-field
                                v-model="question.selection.equalTo"
                                :readonly="isReadOnly"
                                type="number"
                                label="Equal To"
                                solo
                                class="py-2 text-left solo-height-fix"
                              />
                            </v-col>
                          </template>
                          <template
                            v-if="question.selection.comparison === 'less-than'"
                          >
                            <v-col cols="2">
                              <v-text-field
                                v-model="question.selection.upperLimit"
                                :readonly="isReadOnly"
                                type="number"
                                label="Upper Limit"
                                solo
                                class="py-2 text-left solo-height-fix"
                              />
                            </v-col>
                          </template>
                          <template
                            v-if="question.selection.comparison === 'between'"
                          >
                            <v-col cols="1">
                              <v-text-field
                                v-model="question.selection.lowerLimit"
                                :readonly="isReadOnly"
                                type="number"
                                label="Lower Limit"
                                solo
                                class="py-2 text-left solo-height-fix"
                              />
                            </v-col>
                            <v-col cols="1">
                              <v-text-field
                                v-model="question.selection.upperLimit"
                                :readonly="isReadOnly"
                                type="number"
                                label="Upper Limit"
                                solo
                                class="py-2 text-left solo-height-fix"
                              />
                            </v-col>
                          </template>
                        </template>
                        <template
                          v-if="
                            question.type === 'multi-select' ||
                            (question.type === 'embedded-image' &&
                              question.interactionType === 'multi-select')
                          "
                        >
                          <v-col cols="4">
                            <v-select
                              v-model="question.selection.answer"
                              :readonly="isReadOnly"
                              :label="'Answer'"
                              :items="question.options"
                              multiple
                              solo
                              class="pt-4 solo-height-fix"
                            />
                          </v-col>
                          <v-col cols="1">
                            <v-switch
                              v-model="question.selection.orLogic"
                              :readonly="isReadOnly"
                              :label="
                                question.selection.orLogic ? 'Any' : 'All'
                              "
                            />
                          </v-col>
                        </template>
                        <v-col
                          cols="1"
                          class="d-flex align-center justify-center"
                        >
                          <v-btn
                            icon
                            v-if="isReadOnly === false"
                            class="mt-3"
                            @click="deleteQuestion(index1, index)"
                          >
                            <v-icon>cancel</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <div
                        v-if="
                          rule &&
                          rule.logical_operator &&
                          index !== rule.questions.length - 1
                        "
                        class="d-flex justify-center align-center mt-4 mb-0"
                      >
                        <span class="pa-1 border-solid-1">{{
                          rule.logical_operator
                        }}</span>
                      </div>
                    </template>
                  </template>
                </template>
              </v-col>
              <v-col cols="0" md="1" xl="2" />
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
    
    <!--        Dialog that pops up when attempting to delete a rule -->
    <v-dialog v-model="deleteRuleMetadata.dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Rule</v-card-title>

        <v-card-text>Are you sure you want to delete this rule?</v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green lighten-1"
            text
            @click="
              deleteRuleMetadata.dialog = false;
              deleteRuleMetadata.index = null;
            "
            >No
          </v-btn>

          <v-btn
            color="red lighten-1"
            text
            @click="deleteRule(deleteRuleMetadata.index)"
            >Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import rulesBuilderMixin from '@/mixins/components/rulesBuilder.mixin'

export default {
  name: "RulesBuilder",

  mixins: [rulesBuilderMixin]
};
</script>
