export const confirmation = {
  namespaced: true,

  state: () => ({
    title: '',
    desc: '',
    show: false,
    accepted: () => {},
    denied: () => {}
  }),

  getters: {
    show: (s) => s.show,

    desc: (s) => s.desc,

    title: (s) => s.title,

    accepted: (s) => s.accepted,

    denied: (s) => s.denied
  },

  actions: {
    setShow({ commit }, payload) {
      commit('SET_SHOW', payload)
    },

    confirm({ commit }, { show = true, title = '', desc = '', accepted = () => {}, denied = () => {} }) {
      commit('SET_SHOW', show)
      commit('SET_TITLE', title)
      commit('SET_DESC', desc)
      commit('SET_ACCEPTED', accepted)
      commit('SET_DENIED', denied)
    }
  },

  mutations: {
    SET_SHOW(state, payload) {
      state.show = payload
    },

    SET_TITLE(state, payload) {
      state.title = payload
    },

    SET_DESC(state, payload) {
      state.desc = payload
    },

    SET_ACCEPTED(state, payload) {
      state.accepted = payload
    },

    SET_DENIED(state, payload) {
      state.denied = payload
    }    
  }
}