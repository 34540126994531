<template>
  <div>
    <v-divider />
    <h4 class="mt-2">{{ title }}</h4>
      <v-col
        v-for="(image, index) in pages"
        class="page-card"
        :key="index"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
      >
        <v-card
          v-if="image !== undefined"
          :class="{ 'border-warning': !image.isNameUnique }"
          tile
          class="my-2 py-2"
        >
          <v-row>
            <v-col
              cols="9"
              lg="8"
              class="text-left d-flex align-center"
            >
              <v-btn text class="a-page-button" @click="movePage(index)">
                <v-icon class="pl-1">image</v-icon>
                <span class="ml-1 font-weight-bold">Page {{ image.page }}</span>
                <!--                                                                    <span class="ml-1 font-weight-600">Page {{ (index + 1).toString() }}</span>-->
              </v-btn>
            </v-col>
            <v-col cols="3" lg="4" class="align-center">
              <v-btn icon @click="showConfirmationDialog(index, image)">
                <v-icon>clear</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-1" />
          <v-row v-if="image.name">
            <v-col cols="12">
              <v-btn v-if="nameEditable" :title="image.name" class="page-btn" text @click="changeName(index)">
                <span style="text-transform: uppercase; ">{{ image.name }}</span>
              </v-btn>
              <span v-else class="page-content" :title="image.name" style="text-transform: uppercase">{{
                image.name
              }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="pageConfirmationDialog" :retain-focus="false" max-width="500">
      <v-card class="pa-3">
        <v-card-title class="headline">
          <v-icon color="error" class="mr-2">error</v-icon>
          Delete <span class="text-lowercase mx-1">{{ selectedPage.name }}</span>?
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete this page? <br />
          Changes will be permanent <strong>only after you save Workflow</strong>.
        </v-card-text>


        <v-card-actions>
          <v-btn color="primary" text @click="pageConfirmationDialog = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn class="error" text @click="removePage">
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: 'PageCards',

  components: {
    draggable
  },

  data() {
    return {
      pageConfirmationDialog: false,
      selectedPage: {
        index: null,
        name: null,
      },
    };
  },

  props: {
    title: {
      type: String,
      required: true
    },

    pages: {
      type: Array,
      required: true,
      default: () => []
    },

    nameEditable: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  methods: {
    showConfirmationDialog(index, data) {
      this.selectedPage = {
        index: index,
        name: data.name,
      };
      this.pageConfirmationDialog = true;
    },

    removePage() {
      this.$emit('remove', this.selectedPage.index);
      this.pageConfirmationDialog = false;
    },

    movePage (index) {
      this.$emit('move', index);
    },

    changeName (index) {
      this.$emit('change-name', index);
    },

    async handleNewOrder(evt) {
      let pageItems = [...this.pages];
      pageItems.forEach((p, index) => {
        p.page = index + 1;
      });
      this.$emit("update", pageItems);
      return
    }
  }
};
</script>

<style lang="scss">
.page-card {
  display: inline-block;
  
  .page-content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    margin: 10px;
  }

  .page-btn {
    width: 100%;
    display: block;

    .v-btn__content {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
  }
}
</style>
