<template>
  <v-card tile elevation="2">
    <v-card-title class="bg-primary white--text">
      <span>Preview</span>
      <v-spacer/>
      <template v-if="!showSwitch">
        Participating: 
        Uni
        <v-switch 
          v-model="form.collaborate"
          style="margin-left:10px;"
          dark
          def
          @change="updateClick()" />
        Col
      </template>
      <v-spacer/>

      <v-switch
        v-if="showSwitch"
        :loading="displayLogicLoading"
        :label="switchLabel"
        input-value="defaultValue"
        dark
        @change="switchFlip"
      />

      <template v-if="showButton">
        <v-spacer/>
        <v-btn @click="updateClick" title="Update PDF">
          <slot name="button-label">
            <v-icon>refresh</v-icon>
          </slot>
        </v-btn>
      </template>
    </v-card-title>
    <QuestionForm
      :form-fields="form.fields"
      :reset="reset"
      :read-only="readOnly"
      :form-id="form.id"
      :preview-mode="false"
      :current-survey-tab="currentSurveyTab"
      :content-height="contentHeight"
      @ignoreFormDataChanges="$emit('ignoreFormDataChanges', $event)"
      @update-current-survey-tab="updateCurrentSurveyTab"
    />
  </v-card>
</template>

<script>
import QuestionForm from "./QuestionForm";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FormBuilderPreview",
  components: {QuestionForm},
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },

    form: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },

    showButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    showSwitch: {
      type: Boolean,
      required: false,
      default: false,
    },

    switchLabel: {
      type: String,
      required: false,
    },

    defaultValue: {
      type: Boolean,
      default: true
    },

    currentSurveyTab: {
      type: Number,
      required: false,
    },

    contentHeight: {
      type: Number,
      default: 500,
    }
  },

  watch: {
    overrideStatus: {
      handler: function(newVal, oldVal) {
        if(newVal != oldVal) {
          this.displayLogicLoading = false
        }
      }
    },
  },

  data() {
    return {
      currentPage: null,
      reset: false,
      displayLogicLoading: false
    };
  },

  computed: {
    ...mapGetters(["getFirebaseConfig"]),

    ...mapGetters("displayLogic", ["overrideStatus"])
  },

  methods: {
    ...mapActions("confirmation", ["confirm"]),

    updateClick() {
      this.$emit("button-click");
    },

    switchFlip(value) {
      this.displayLogicLoading = true
      this.$emit("switch-flip", value);
    },

    updateCurrentSurveyTab(index) {
      this.$emit("update-current-survey-tab", index);
    },
  },
};
</script>
