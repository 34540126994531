import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions(["setTitle"])
  },

  beforeDestroy() {
    this.setTitle('')
  },  
}