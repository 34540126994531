<template>
  <div>
    <v-dialog
      v-model="showDialogBuilder"
      :retain-focus="false"
      max-width="75%"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="headline">Dialog Builder</span>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <br />
          <v-row>
            <v-col cols="8">
              <v-select
                v-model="addDialogElementSelection"
                :items="dialogElementTypes"
                item-text="name"
                item-value="name"
                label="Add Form Element"
              />
            </v-col>
            <v-col cols="4">
              <v-btn
                class="mx-2"
                fab
                dark
                color="blue lighten-1"
                @click="addDialogElement"
              >
                <v-icon dark>add_box</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <draggable
                v-model="dialogFields"
                group="elements"
                :animation="200"
                @start="handleStartOrder($event)"
                @end="handleNewOrder($event)"
              >
                <template v-for="(df, index) in getDialogFields">

                  <DialogElement
                    v-if="df.type === 'signature-pad'"
                    :key="index"
                    :data="df"
                    :is-unique="
                      duplicateNewDialogElementTitleId !== df.fieldsIndex
                    "
                    :bg="'bg-' + df.type"
                    :questionList="parsedQuestionList"
                    icon="gesture"
                    title="Signature Pad"
                    unique-title="Admin | Signature Title"
                    label="User-Facing | Signature Pad Label"
                    @uniqueCheck="checkForUniqTitle(df.fieldsIndex)"
                    @move="moveDialogElement(df.fieldsIndex, $event)"
                    @remove="removeInput(df.fieldsIndex)"
                  />
                  <DialogElement
                    v-if="df.type === 'text-input-long'"
                    :key="index"
                    :data="df"
                    :is-unique="
                      duplicateNewDialogElementTitleId !== df.fieldsIndex
                    "
                    :bg="'bg-' + df.type"
                    :questionList="parsedQuestionList"
                    icon="short_text"
                    title="Long Text Field"
                    unique-title="Admin | Text Field Title"
                    label="User-Facing | Input Label"
                    @uniqueCheck="checkForUniqTitle(df.fieldsIndex)"
                    @move="moveDialogElement(df.fieldsIndex, $event)"
                    @remove="removeInput(df.fieldsIndex)"
                  />
                  <DialogElement
                    v-if="df.type === 'text-input-short'"
                    :key="index"
                    :data="df"
                    :is-unique="
                      duplicateNewDialogElementTitleId !== df.fieldsIndex
                    "
                    :bg="'bg-' + df.type"
                    :questionList="parsedQuestionList"
                    icon="short_text"
                    title="Short Text Field"
                    unique-title="Admin | Text Field Title"
                    label="User-Facing | Input Label"
                    @uniqueCheck="checkForUniqTitle(df.fieldsIndex)"
                    @move="moveDialogElement(df.fieldsIndex, $event)"
                    @remove="removeInput(df.fieldsIndex)"
                  />
                </template>
              </draggable>
            </v-col>
            <v-col cols="8">
              <DialogBuilderPreview
                v-if="dialogFields.length > 0"
                :form-id="formId"
                :fields="getDialogFields"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="blue darken-1" outlined @click="cancel">Cancel</v-btn>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" outlined @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <a @click="showDialogBuilder = true">Advanced</a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DialogElement from "@/components/DialogElement";
import DialogBuilderPreview from "@/components/DialogBuilderPreview";
import { nanoid } from "nanoid";
import draggable from "vuedraggable";

export default {
  name: "DialogBuilder",

  components: {
    DialogElement,
    DialogBuilderPreview,
    draggable,
  },

  mounted() {
    setTimeout(() => {
      if (
        this.field?.dialogFields &&
        this.field.dialogFields
      ) {
        this.dialogFields = [...this.field.dialogFields];
      } else {
        this.dialogFields = [];
      }
    }, 1000);
  },

  watch: {
    field: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.dialogFields = [...this.field?.dialogFields || []];
        }
      },
    },
  },

  props: {
    field: {
      type: Object,
      default: {},
    },

    formId: {
      type: String,
      default: "",
    },
  },

  data: () => {
    return {
      duplicateNewDialogElementTitleId: false,
      showDialogBuilder: false,
      addDialogElementSelection: null,
      dialogFields: [],
      draggedItem: null,
      snapshotList: [],
    };
  },

  computed: {
    ...mapGetters(["dialogElementTypes"]),

    getDialogFields() {
      return this.dialogFields.filter((d) => d != undefined);
    },

    parsedQuestionList() {
      const list = this.getDialogFields;

      let pageNumber = 0;

      for (let i = 0; i < list.length; i++) {
        let question = "";

        if (list[i].type === "page-break") {
          pageNumber++;
          if (list[i].title) {
            question = "Page " + pageNumber + " - " + list[i].title;
          } else {
            question = "Page " + pageNumber;
          }
        } else {
          question = "Type: " + list[i].type + " | Title: " + list[i].title;
        }

        list[i].questionListTitle = question;
        list[i].fieldsIndex = i;
      }

      return list;
    },
  },

  methods: {
    ...mapActions(["toggleLoadingOverlayStatus"]),

    handleStartOrder(evt) {
      this.snapshotList = [...this.getDialogFields];
      this.draggedItem = { ...this.getDialogFields[evt.oldIndex] };
    },

    handleNewOrder(evt) {
      if (evt.oldIndex === evt.newIndex) return;
      const fields = [...this.snapshotList];
      let toDelete = -1;
      if (evt.oldIndex < evt.newIndex) {
        fields.splice(evt.newIndex + 1, 0, this.draggedItem);
        toDelete = fields.findIndex(
          (f, i) => i < evt.newIndex && f.type === this.draggedItem.type
        );
      } else {
        fields.splice(evt.newIndex, 0, this.draggedItem);
        toDelete = fields.findIndex(
          (f, i) => i > evt.newIndex && f.type === this.draggedItem.type
        );
      }

      fields.splice(toDelete, 1);
      this.reorderFields(fields);
      this.dialogFields = [...fields];
    },

    save() {
      this.field.dialogFields = this.dialogFields;
      this.cancel();
    },

    cancel() {
      this.addDialogElementSelection = null;
      this.showDialogBuilder = false;
    },

    checkForUniqTitle(fieldIndex) {
      const currentTitle = this.getDialogFields[fieldIndex].title;
      const duplicate = this.getDialogFields.findIndex(
        (f, i) => f.title === currentTitle && i !== fieldIndex
      );

      this.duplicateNewDialogElementTitleId = duplicate >= 0 ? fieldIndex : -1;
    },

    addDialogElement() {
      if (this.dialogFields === undefined) {
        this.field["dialogFields"] = [];
      }

      switch (this.addDialogElementSelection) {
        case "signature-pad":
          this.addSignaturePad();
          break;
        case "text-input-short":
          this.addTextField();
          break;
        case "text-input-long":
          this.addTextArea();
          break;
      }
    },

    getNewDialogElement(content = "string") {
      let contentInitialized = "";

      if (content === "array") {
        contentInitialized = [];
      }

      return {
        uid: nanoid(12),
        type: this.addDialogElementSelection,
        fieldsIndex: null,
        title: "",
        label: "",
        name: "",
        content: contentInitialized,
        required: false,
        spaceAfter: false,
        moveToSelection: null,
        page: this.currentSurveyTab + 1,
      };
    },

    insertNewDialogElement(newDialogObj) {
      let index = 0;
      if (newDialogObj.type === "page-break") {
        index =
          this.getDialogFields.findIndex(
            (field) =>
              field.type === "page-break" &&
              field.page === newDialogObj.page + 1
          ) - 1;
        index = index > -1 ? index : this.getDialogFields.length;
      } else {
        index =
          this.getDialogFields.findIndex(
            (field) =>
              field.type === "page-break" && field.page === newDialogObj.page
          ) + 1;
      }

      this.dialogFields.splice(index, 0, newDialogObj);
      this.reorderFields();
    },

    reorderFields(fields = this.getDialogFields) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        field.fieldsIndex = i;
      }
    },

    addTextField() {
      const newTextField = this.getNewDialogElement();

      this.insertNewDialogElement(newTextField);
    },

    addTextArea() {
      const newTextField = this.getNewDialogElement();

      this.insertNewDialogElement(newTextField);
    },

    addSignaturePad() {
      const newSignaturePad = this.getNewDialogElement();

      this.insertNewDialogElement(newSignaturePad);
    },

    moveDialogElement(index, event) {
      const currentPosition = index;
      const desiredPosition = event;
      if (desiredPosition < currentPosition) {
        this.dialogFields.splice(
          desiredPosition + 1,
          0,
          this.dialogFields.splice(currentPosition, 1)[0]
        );
        return "moved element down"
      } else {
        this.dialogFields.splice(
          desiredPosition,
          0,
          this.dialogFields.splice(currentPosition, 1)[0]
        );
        return "moved element up"
      }
    },

    removeInput(index) {
      this.dialogFields.splice(index, 1);
    },
  },
};
</script>
