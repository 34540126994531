<template>
  <div>
    <v-text-field
      v-model="cmpValue"
      v-bind:min="min"
      v-bind:max="max"
      v-bind:label="label"
      v-bind:placeholder="placeholder"
      v-bind:readonly="readonly"
      v-bind:disabled="disabled"
      v-bind:outlined="outlined"
      v-bind:dense="dense"
      v-bind:hide-details="hideDetails"
      v-bind:error="error"
      v-bind:error-messages="errorMessages"
      v-bind:rules="rules"
      v-bind:clearable="clearable"
      v-bind:backgroundColor="backgroundColor"
      v-bind:prefix="options.prefix"
      v-bind:suffix="options.suffix"
      v-bind="properties"
      v-on:keypress="keyPress, $emit('input')"
      v-on:blur="handleValue($event.target.value)"
      v-on:focus="$emit('focus')"
      v-on:click="$emit('click')"
    ></v-text-field>
  </div>
</template>

<script>
import vuetifyMoneyMixin from '../mixins/components/vuetifyMoney.mixin';

export default {
  name: 'VuetifyMoney',

  mixins: [vuetifyMoneyMixin]
}
</script>
