<template>
  <div class="elevation-4 o-question-form">
    <v-row no-gutters>
      <v-col cols="12">
        <v-form ref="questionForm">
          <v-tabs
              v-show="showTabs"
              id="form-tab-container"
              v-model="currentTab"
              background-color="#42b983"
              dark
              center-active
              show-arrows
          >
            <v-tabs-slider color="primary"/>
            <v-tab
                v-for="(pageTab, index) in pageTabs"
                :key="index"
                @click="clickedTab"
            >
              {{ pageTab }}
            </v-tab>
          </v-tabs>

          <!-- Next & Previous Buttons  -->
          <v-row class="mt-1" v-if="!previewMode">
            <v-col cols="12" class="text-right">
              <v-btn
                  :disabled="currentTab === 0"
                  @click="previousTab"
                  small
                  outlined
                  fab
                  color="secondary"
              >
                <v-icon>
                  chevron_left
                </v-icon>
              </v-btn>

              <v-btn
                  :disabled="currentTab === Object.keys(getWorkflowPages).length - 1"
                  @click="nextTab"
                  class="mx-2"
                  small
                  outlined
                  fab
                  color="secondary"
              >
                <v-icon>
                  chevron_right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-tabs-items
            v-if="getWorkflowPages && getWorkflowPages.length > 0"
            v-model="currentTab"
            :class="{ 'condensed-preview': previewMode }"
          >
            <v-tab-item
                v-for="(page, index) in getWorkflowPages"
                :key="index"
                class="pa-4"
            >
              <br/>
              <v-row>
                <v-col
                    v-show="fill === false"
                    cols="1"
                />
                <v-col
                  v-if="page && page.length > 0"
                  cols="10"
                >
                  <template v-for="(field, index) in page">
                    <div :id="`${field.type}-${field.uid}`" class="form-field-container" :key="index">
                      <template v-if="field.type === 'child-support-calculator'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                          style="min-height: 100px;"
                        >
                          <HelpIcon
                            v-if="field.helpData && (field.helpData.text || field.helpData.youtubeLink)"
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <h3>Child Support Calculator</h3>
                          <p class="mt-2 mb-1">
                            <i><span class="text-primary">Note*</span>  This is admin template only!
                              <br> Actual content will be visible on the user's side.
                            </i>
                          </p>
                          <p class="mt-5 mb-1">Description will be placed here</p>
                          <a href="">Hyperlink will be here</a>
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-if="field.type === 'text-input-short'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <v-text-field
                            v-mask="`${getMask(field)}`"
                            :type="fieldType(field)"
                            :value="field.content"
                            :label="expressionator(field.label, formFields)"
                            :readonly="readOnly"
                            :rules="rulesStatus(field)"
                            :error="answerStatus(field)"
                            :placeholder="field.placeholder || 'Enter text here'"
                            autocomplete="chrome-off"
                            clearable
                            outlined
                            :class="{ emailText: fieldType(field) === 'email', timeEntry: field?.validator === 'time' }"
                            @click:clear="inputHandler(field, $event)"
                            @input="inputHandler(field, $event)"
                          />
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-if="field.type === 'label'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <v-text-field
                              :value="expressionator(field.label, formFields)"
                              :label="field.name"
                              :error="answerStatus(field)"
                              readonly
                          />
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template
                        v-if="
                          field.type === 'computed-property' && field.visible
                        "
                      >
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <v-text-field
                              :value="expressionator(field.label, formFields)"
                              :label="field.name"
                              :prefix="field.prefix"
                              :suffix="field.suffix"
                              background-color="#eeeeee"
                              disabled
                              readonly
                          />
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template
                          v-if="field.type === 'grouped-options'"
                      >
                        <div
                            :id="field.uid"
                            :class="{ 'd-none': (displayStatus(field) || !field.visible) }"
                            class="p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <v-text-field
                            :value="expressionator(groupOptions(field, formFields), formFields)"
                            :label="expressionator(field.label, formFields)"
                            readonly
                          />
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-if="field.type === 'signature-pad'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <SignaturePad
                              :id="`signature-pad-${index}`"
                              :field="field"
                              :read-only="readOnly"
                              :required="field.required"
                              :form-id="formId"
                              :label="expressionator(field.label, formFields)"
                              :error="answerStatus(field)"
                              @change="inputHandler(field, $event)"
                          />
                          <br/>
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-if="field.type === 'number-input'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                              (field.helpData.text ||
                                field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <VuetifyMoney
                            v-if="field.useCommas === true"
                            :label="expressionator(field.label, formFields)"
                            :value="field.content"
                            :readonly="readOnly"
                            :min="field.lowerBound"
                            :max="field.upperBound"
                            :rules="rulesStatus(field)"
                            :error="answerStatus(field)"
                            clearable
                            outlined
                            :options="{ 
                              locale: 'en-US', 
                              precision: 0,
                              prefix:field.prefix,
                              suffix:field.suffix                              
                            }"
                            @click:clear="inputHandler(field, $event)"
                            @input="inputHandler(field, $event)"
                          />
                          <v-text-field
                            v-else
                            :label="expressionator(field.label, formFields)"
                            :value="field.content"
                            :prefix="field.prefix"
                            :suffix="field.suffix"
                            :readonly="readOnly"
                            :min="field.lowerBound"
                            :max="field.upperBound"
                            :rules="rulesStatus(field)"
                            :error="answerStatus(field)"
                            type="number"
                            clearable
                            outlined
                            @click:clear="inputHandler(field, $event)"
                            @input="inputHandler(field, $event)"
                          />
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-if="field.type === 'text-input-long'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <v-textarea
                              :value="field.content"
                              :label="expressionator(field.label, formFields)"
                              :readonly="readOnly"
                              outlined
                              :error="answerStatus(field)"
                              @input="inputHandler(field, $event)"
                          />
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template
                        v-else-if="field.type === 'state-select'"
                      >
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                              (field.helpData.text ||
                                field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <v-select
                            :value="field.selection"
                            :label="expressionator(field.label, formFields)"
                            :items="getStates"
                            :readonly="readOnly"
                            :error="answerStatus(field)"
                            clearable
                            outlined
                            @click:clear="inputHandler(field, $event)"
                            @input="inputHandler(field, $event)"
                          />
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template
                        v-if="
                          field.type === 'single-select' &&
                          field.variant.toLocaleLowerCase() === 'radio'
                        "
                      >
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                              (field.helpData.text ||
                                field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <header>
                            {{ expressionator(field.label, formFields) }}
                          </header>
                          <v-radio-group
                            :id="field.uid"
                            :value="field.selection"
                            :error="answerStatus(field)"
                            @change="inputHandler(field, $event)"
                          >
                            <v-radio
                              v-for="(radio, index) in field.content"
                              :key="index"
                              :id="expressionator(radio.uid, formFields)"
                              :readonly="readOnly"
                              :value="expressionator(radio, formFields)"
                              :label="expressionator(radio, formFields)"
                            />
                          </v-radio-group>
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template
                        v-else-if="
                          field.type === 'single-select' &&
                          field.variant.toLocaleLowerCase() === 'drop-down'
                        "
                      >
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                              (field.helpData.text ||
                                field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <v-select
                            :value="field.selection"
                            :label="expressionator(field.label, formFields)"
                            :items="selectOptions(field.content)"
                            :readonly="readOnly"
                            :error="answerStatus(field)"
                            clearable
                            outlined
                            @click:clear="inputHandler(field, $event)"
                            @input="inputHandler(field, $event)"
                          />
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template
                        v-else-if="
                          field.type === 'single-select' &&
                          field.variant.toLocaleLowerCase() === 'slider'
                        "
                      >
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                              (field.helpData.text ||
                                field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <v-slider
                            :key="index"
                            :value="field.selection"
                            :label="expressionator(field.label, formFields)"
                            :tick-labels="sliderOptions(field.content)"
                            :max="field.content.length - 1"
                            :readonly="readOnly"
                            :error="answerStatus(field)"
                            ticks="always"
                            tick-size="4"
                            @input="inputHandler(field, $event)"
                          />
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template
                        v-else-if="
                          field.type === 'multi-select' &&
                          field.variant &&
                          field.variant.toLocaleLowerCase() === 'checkboxes'
                        "
                      >
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <v-row justify="space-around" :key="index">
                            <v-col cols="12">
                              <header>
                                {{ expressionator(field.label, formFields) }}
                              </header>
                            </v-col>
                            <v-checkbox
                              v-for="(option, index2) in field.content"
                              v-model="field.selection"
                              :key="
                                index.toString() + '>' + index2.toString()
                              "
                              :value="expressionator(option, formFields)"
                              :label="expressionator(option, formFields)"
                              :readonly="readOnly"
                              :error="answerStatus(field)"
                              @change="inputHandler(field, $event)"
                            />
                          </v-row>
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template
                        v-else-if="
                          field.type === 'multi-select' &&
                          field.variant &&
                          field.variant.toLocaleLowerCase() === 'drop-down'
                        "
                      >
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <v-row justify="space-around" :key="index">
                            <HelpIcon
                                v-if="
                                  field.helpData &&
                                  (field.helpData.text ||
                                    field.helpData.youtubeLink)
                                "
                                :value="field.helpData"
                                :field-id="field.uid"
                            />
                            <v-select
                              :items="selectOptions(field.content)"
                              :value="field.selection"
                              :label="expressionator(field.label, formFields)"
                              :error="answerStatus(field)"
                              ticks="always"
                              multiple
                              clearable
                              outlined
                              @click:clear="inputHandler(field, $event)"
                              @change="inputHandler(field, $event)"
                            />
                          </v-row>
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-else-if="field.type === 'file-input'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <file-upload
                              :id="`file-upload-${index}`"
                              :key="index"
                              :label="expressionator(field.label, formFields)"
                              :rules-status="rulesStatus(field)"
                              :answer-status="answerStatus(field)"
                              :field="field"
                              :read-only="readOnly"
                              :required="field.required"
                              :form-id="formId"
                              :accept="`${getAcceptedUploadFileTypes},${getAllowedUploadFileTypes}`"
                            />
                            <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-else-if="field.type === 'youtube-video'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <div class="embed-container">
                            <iframe
                              v-if="videoUrl(field)"
                              v-lazy="videoUrl(field)"
                              style="
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                              "
                              frameborder="0"
                              allowfullscreen
                            />
                            <div v-else style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                              <img src="/img/icons/simple_ending_icon_square_transparent.png" width="50%" />
                              Video coming soon!
                            </div>
                          </div>

                          <br/>

                          <h4>
                            {{ expressionator(field.content, formFields) }}
                          </h4>

                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-else-if="field.type === 'paragraph'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="my-1 p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <p :class="'text-' + field.align">
                            {{ expressionator(field.content, formFields) }}
                          </p>
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-else-if="field.type === 'subheading'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="my-2 p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <h3 :class="['text-center', field.align && 'text-' + field.align]">
                            {{ expressionator(field.content, formFields) }}
                          </h3>
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-else-if="field.type === 'heading'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="my-2 p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <h1 :class="['text-center', field.align && 'text-' + field.align]">
                            {{ expressionator(field.content, formFields) }}
                          </h1>
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-if="field.type === 'date-picker'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <v-text-field
                            v-if="field.variant === 'single-date' && field.monthPicker !== true"
                            :id="field.uid"
                            :value="field.dateValue"
                            placeholder="YYYY"
                            autocomplete="chrome-off"
                            :label="expressionator(field.label, formFields)"
                            :rules="rulesStatus(field)"
                            :error="answerStatus(field)"
                            clearable
                            outlined
                            @focus="$emit('ignoreFormDataChanges', true)"
                            @blur="handleDateChanges(field, $event.target.value, updateFieldAction)"
                          />
                          
                          <div 
                            v-if="field.variant === 'single-date' && field.monthPicker === true"
                            :id="field.uid"
                            class="month-picker-container"
                          >
                            <v-label class="month-picker-label" style="margin-bottom: 10px;">
                              {{ expressionator(field.label, formFields) }}
                            </v-label>
                            <div class="month-picker-form-fields">
                              <v-select
                                :value="getMonthName(field.dateValue)"
                                label="Month"
                                :items="monthsOfTheYear"
                                :readonly="readOnly"
                                :error="answerStatus(field)"
                                :rules="rulesStatus(field, 'month')"
                                clearable
                                outlined
                                class="month-picker-select"
                                @click:clear="inputHandler(field, $event)"
                                @input="handleDateChanges(field, $event, updateFieldAction)"
                              />
                              <v-text-field
                                :value="getYear(field.dateValue)"
                                type="number"
                                placeholder="XXXX"
                                autocomplete="chrome-off"
                                label="Year"
                                :rules="rulesStatus(field, 'year')"
                                :error="answerStatus(field)"
                                clearable
                                outlined
                                class="month-picker-text"
                                @focus="$emit('ignoreFormDataChanges', true)"
                                @blur="handleDateChanges(field, $event.target.value, updateFieldAction)"
                              />
                            </div>
                          </div>

                          <v-text-field
                            v-if="field.variant === 'multiple-dates'"
                            :value="field.dateMultiple"
                            :label="
                              expressionator(field.label, formFields)
                            "
                            :rules="rulesStatus(field)"
                            autocomplete="chrome-off"
                            :error="answerStatus(field)"
                            clearable
                            outlined
                            @blur="inputHandler(field, null)"
                          />

                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-else-if="!readOnly && field.type === 'dialog-window'">
                        <div :id="field.uid" class="p-relative">
                          <v-btn
                            color="primary"
                            :class="{ 'd-none': displayStatus(field) }"
                            @click="field.dialog = true"
                          >
                            OPEN {{ field.title }} FORM ...
                          </v-btn>
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <v-dialog
                            v-model="field.dialog"
                            :retain-focus="false"
                            :persistent="field.required"
                            @input="field.dialog = false"
                            max-width="75%"
                          >
                            <v-card>
                              <v-card-title class="headline">
                                {{
                                  expressionator(field.heading, formFields)
                                }}
                                <v-btn
                                    class="closeDialogBtn"
                                    v-if="!readOnly"
                                    title="Close Dialog Window"
                                    icon
                                    @click="field.dialog = false"
                                >
                                  <v-icon>clear</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-divider/>

                              <v-card-text>
                                {{ expressionator(field.text, formFields) }}
                                <DialogQuestions
                                    :form-id="formId"
                                    :dialog-fields="field.dialogFields"
                                    :preview-mode="false"
                                />
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer/>

                                <v-btn
                                    v-for="(item, index) in field.content"
                                    :key="index"
                                    @click="
                                      dialogWindowSelectionMade(field, item)
                                    "
                                >{{ expressionator(item, formFields) }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </div>
                      </template>

                      <template v-else-if="field.type === 'item-table' && field.visible">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="my-4 py-4 p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <v-row justify="space-around" :key="index">
                            <v-col cols="12">
                              <v-data-table
                                v-if="field.variant === 'read-only'"
                                :headers="itemTableReadOnlyHeaders(field.headers)"
                                :items="field.readOnlyRows"
                                class="elevation-1"
                              >
                                <template v-slot:top>
                                  <v-toolbar flat>
                                    <v-subheader>
                                      {{ expressionator(field.label, formFields) }}
                                    </v-subheader>
                                    <v-spacer></v-spacer>
                                  </v-toolbar>
                                </template>
                                <template v-slot:body="{ items }">
                                  <tbody>
                                  <tr
                                      v-for="(item, rIndex) in items"
                                      :key="rIndex"
                                      class
                                  >
                                    <td
                                        v-for="(header, hIndex) in itemTableRows(field.headers)"
                                        :key="hIndex"
                                        :class="`text-${header.align}`"
                                    >
                                    <template v-if="header.variant !== 'money'">
                                      {{ expressionator(item[header.value], formFields) }}
                                    </template>
                                    <template v-else>
                                      ${{ formatMoney(item[header.value]) }}
                                    </template>
                                    </td>
                                  </tr>
                                  </tbody>
                                </template>
                              </v-data-table>

                              <v-data-table
                                v-else
                                :headers="field.headers"
                                :items="field.inputRows"
                                class="elevation-1"
                              >
                                <template v-slot:top>
                                  <v-toolbar flat>
                                    <v-subheader>
                                      {{ expressionator(field.label, formFields) }}
                                    </v-subheader>
                                    <v-spacer/>
                                    <v-btn
                                      color="primary"
                                      dark
                                      class="mb-2"
                                      v-bind="$attrs"
                                      @click="itemTableAddNewRow(field)"
                                    >
                                      New Row
                                    </v-btn>
                                  </v-toolbar> 
                                </template>
                                <template v-slot:body="{ items }">
                                  <tbody>
                                    <tr
                                      v-for="(item, rIndex) in items"
                                      :key="rIndex"
                                      class
                                    >
                                    <v-dialog
                                      v-model="itemTableDeleteItemDialog"
                                      :retain-focus="false"
                                      max-width="500px"
                                    >
                                      <v-card>
                                        <v-card-title class="headline">
                                          Are you sure you want to delete
                                          this item?
                                        </v-card-title>
                                        <v-card-actions style="justify-content: right;">
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            color="primary"
                                            @click="itemTableDeleteItemConfirm()"
                                          >
                                            OK
                                          </v-btn>
                                          <v-btn
                                            color="primary darken-1"
                                            text
                                            @click="itemTableCloseDeleteDialog"
                                          >
                                            Cancel
                                          </v-btn>
                                        </v-card-actions>
                                      </v-card>
                                    </v-dialog>
                                      <td
                                        v-for="(
                                          header, hIndex
                                        ) in itemTableRows(field.headers)"
                                        :key="hIndex"
                                        :class="`text-${header.align}`"
                                      >
                                        <template v-if="header.variant !== 'money'">
                                          {{ expressionator(item[header.value], formFields) }}
                                        </template>
                                        <template v-else>
                                          ${{  formatMoney(item[header.value]) }}
                                        </template>
                                      </td>
                                      <td v-if="previewMode === false">
                                        <v-icon
                                            small
                                            class="mr-2"
                                            @click="
                                                itemTableEditItem(
                                                  item,
                                                  rIndex,
                                                  field
                                                )
                                              "
                                        >
                                          mdi-pencil
                                        </v-icon>
                                        <v-icon
                                            small
                                            @click="
                                                itemTableDeleteItem(
                                                  item,
                                                  rIndex,
                                                  field,
                                                )
                                              "
                                        >
                                          mdi-delete
                                        </v-icon>
                                      </td>
                                    </tr>
                                  </tbody>                               
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-else-if="field.type === 'embedded-image'">
                        <div
                          :id="field.uid"
                          class="
                            p-relative
                            embedded-image-container
                            elevation-2
                          "
                          :class="{ 'd-none': displayStatus(field) }"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <v-row>
                            <template
                                v-if="field.variant === 'image-carousel'"
                            >
                              <v-col v-if="!!field.label" cols="12">
                                <p>
                                  {{
                                    expressionator(field.label, formFields)
                                  }}
                                </p>
                              </v-col>
                              <v-col
                                  cols="12"
                                  v-if="
                                    ['multi-select', 'single-select'].indexOf(
                                      field.interactionType
                                    ) > -1
                                  "
                              >
                                <v-select
                                  v-if="
                                    field.interactionType === 'single-select'
                                  "
                                  :value="field.selectionSingle"
                                  label="Use this to select an image from the carousel below."
                                  :items="field.imageRefs"
                                  :error="answerStatus(field)"
                                  item-text="title"
                                  return-object
                                  clearable
                                  outlined
                                  @click:clear="inputHandler(field, $event)"
                                  @input="inputHandler(field, $event)"
                                />
                                <v-select
                                  v-if="
                                    field.interactionType === 'multi-select'
                                  "
                                  :value="field.selectionMultiple"
                                  label="Use this to select multiple images from the carousel below."
                                  :items="field.imageRefs"
                                  :error="answerStatus(field)"
                                  multiple
                                  item-text="title"
                                  return-object
                                  clearable
                                  outlined
                                  @click:clear="inputHandler(field, $event)"
                                  @input="inputHandler(field, $event)"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-carousel
                                  :show-arrows-on-hover="true"
                                  hide-delimiters
                                  height="100%"
                                  class="elevation-4"
                                >
                                  <v-carousel-item
                                      v-for="(image, i) in field.imageRefs"
                                      :key="i"
                                      reverse-transition="fade-transition"
                                      transition="fade-transition"
                                  >
                                    <v-card
                                        class="fill-height"
                                        justify="center"
                                        v-if="!!image"
                                    >
                                      <v-img v-if="image.url" :src="image.url"/>
                                      <v-divider/>
                                      <v-card-title
                                          v-if="!!image && image.title"
                                      >
                                        {{
                                          expressionator(
                                              image.title,
                                              formFields
                                          )
                                        }}
                                        <v-spacer/>
                                        <v-checkbox
                                            v-if="
                                              !!image &&
                                              !!image.title &&
                                              field.interactionType ===
                                                'single-select'
                                            "
                                            disabled
                                            label="Selected?"
                                            :input-value="
                                              embeddedImageCheckSelection(
                                                field,
                                                image,
                                                'single-select'
                                              )
                                            "
                                        />
                                        <v-checkbox
                                            v-if="
                                              !!image &&
                                              !!image.title &&
                                              field.interactionType ===
                                                'multi-select'
                                            "
                                            disabled
                                            label="Selected?"
                                            :input-value="
                                              embeddedImageCheckSelection(
                                                field,
                                                image,
                                                'multi-select'
                                              )
                                            "
                                        />
                                      </v-card-title>
                                      <v-card-text
                                          v-if="
                                            (!!image && image.description) ||
                                            [
                                              'multi-select',
                                              'single-select',
                                            ].indexOf(field.interactionType) >
                                              -1
                                          "
                                      >
                                        <v-divider
                                            v-if="
                                              !!image &&
                                              image.description &&
                                              [
                                                'multi-select',
                                                'single-select',
                                              ].indexOf(field.interactionType) >
                                                -1
                                            "
                                            class="mx-4 my-2"
                                        />
                                        <div
                                            v-if="!!image && image.description"
                                        >
                                          {{
                                            expressionator(
                                                image.description,
                                                formFields
                                            )
                                          }}
                                        </div>
                                      </v-card-text>
                                    </v-card>
                                  </v-carousel-item>
                                </v-carousel>
                              </v-col>
                            </template>
                            <template
                                v-else-if="field.variant === 'image-static'"
                            >
                            </template>
                          </v-row>
                          <br/>
                        </div>
                      </template>
                    </div>
                  </template>
                </v-col>
                <v-col
                  v-show="fill === false"
                  cols="1"
                />
              </v-row>
            </v-tab-item>
          </v-tabs-items>

          <v-dialog
            v-if="previewMode === false"
            :retain-focus="false"
            v-model="itemTableEditItemDialog"
            max-width="800"
          >
            <TableRowEditForm
                v-if="previewMode === false"
                :row-title="itemTableRowTitle"
                :headers="currentField.headers"
                :data="editedItem"
                :fields="formFields"
                :read-only="currentField.variant === 'read-only'"
                @cancel="itemTableCloseDialog"
                @save="
                  itemTableSaveItem(
                    currentField,
                    currentField.variant === 'read-only'
                      ? 'readOnlyRows'
                      : 'inputRows'
                  )
                "
            />
          </v-dialog>
        </v-form>
      </v-col>
    </v-row>

    <div>
      <!--  Divider & Submit Button  -->
      <v-row
          v-if="
            formFields &&
            !readOnly &&
            !previewMode &&
            parseInt(currentTab) === parseInt(Object.keys(getWorkflowPages).length - 1)
          "
          no-gutters
      >

        <v-col cols="12" class="text-right">
          <v-btn
              dark
              class="ma-2"
              @click="submitForm"
          >Submit
          </v-btn
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { urlExists } from "@/utils/file.util";
import workflowMixin from "@/mixins/workflow.mixin"
import monthPickerMixin from "@/mixins/monthPicker.mixin"
import { getDateFormat } from '@/utils/getDateFormat.util'

export default {
  name: "QuestionForm",

  mixins: [workflowMixin,monthPickerMixin],

  watch: {
    reset: {
      handler: function(newVal, oldVal) {
        if(newVal != oldVal && newVal === true) {
          this.$refs.questionForm.reset()
        }
      }
    }
  },

  props: {
    reset: {
      type: Boolean,
      default: false
    },

    currentSurveyTab: {
      type: Number,
      required: false,
    },

    previewMode: {
      type: Boolean,
      default: false,
    },

    contentHeight: {
      type: Number,
      default: 500,
    },

    formFields: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      canAutoSave: false,
      updateFieldAction: 'updateFormBuilderActiveFormField',
      dialog: false,
      checkAnswerCalled: false,
      defaultSurveyTab: 0,
      inputHandlerDebounce: undefined,
      formResponse: {form: this.formFields},
    };
  },

  mounted() {
    this.embeddedImageFileChecker();
    this.setDefaults()
  },

  computed: {
    ...mapGetters(['getWorkflowFieldsAll', 'getWorkflowPages', 'getStates']),

    currentTab: {
      get: function () {
        if (this.currentSurveyTab === undefined) {
          return this.defaultSurveyTab;
        } else {
          return this.currentSurveyTab;
        }
      },
      set: function (index) {
        if (this.currentSurveyTab === undefined) {
          this.defaultSurveyTab = index;
        } else {
          this.$emit("update-current-survey-tab", index);
        }
      },
    },

    pageTabs() {
      if(!this.getWorkflowFieldsAll) return [];
      return this.getWorkflowFieldsAll
        .filter(f => f?.type && f.type === 'page-break')
        .sort((a, b) => a.fieldsIndex - b.fieldsIndex)
        .map((f, i) => f?.name ? f.name : 'Page ' + (i + 1));
    },

    //
    // Display Logic
    //

    displayStatus() {
      return (field) => {
        if(this.previewMode) return false
        const dt = this.displayTemplate(field.uid)
        if(this.displayTemplate && field.uid && dt) {
          if(dt.display === false) {
            if(["text-input-short", "text-input-long", "number-input"].indexOf(field.type) > -1) {
              field.content = ''
            } else if(['single-select','state-select'].indexOf(field.type) > -1) {
              field.selection = ''
            }
          }
          return !dt.display
        } else {
          const defaultLogic = this.defaultDisplayLogic[field.uid]
          return defaultLogic?.hideByDefault ? !defaultLogic.hideByDefault : false
        }
      };
    },
  },

  methods: {
    fixDateFormat(value) {
      return getDateFormat(value, 'YYYY-MM-DD')
    },

    embeddedImageFileChecker() {
      Object.keys(this.getWorkflowPages).forEach((key) => {
        const embeddedImages = this.getWorkflowPages[key].filter(
            (pg) => pg.type === "embedded-image" && pg.url
        );
        embeddedImages.forEach(async (img) => {
          const urlFound = await urlExists(img.imageRefs[0].url);
          if (urlFound) {
            img.imageRefs[0]["exists"] = true;
          }
        });
      });    
    },

    // GENERAL Methods

    clickedTab() {
      this.muteVideo();
    },

    previousTab() {
      this.currentTab -= 1
      this.muteVideo();
    },

    nextTab() {
      this.currentTab += 1

      this.muteVideo();
    },

    submitForm() {
      this.checkAnswerCalled = !this.checkAnswerCalled;
    },

    inputHandler(field, event) {
      // Triggers display logic template to update after user has input something

      if (this.inputHandlerDebounce) {
        clearTimeout(this.inputHandlerDebounce);
      }

      this.inputHandlerDebounce = setTimeout(() => {
        let value = event
        if(typeof event === 'object' && event?.target)
          value = event.target.value

        const toUpdate = this.updateField(field, value)
        this.$store.dispatch(this.updateFieldAction,toUpdate)
      }, 500);
    },

    // File-Input Methods

    fileInputUpload(file, field) {
      this.inputHandler(field, file);
    },

    fileInputClear(field) {
      this.inputHandler(field, undefined);
    },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  position: relative;

  .closeDialogBtn {
    position: absolute;
    right: 10px;
  }
}

.form-field-container {
  margin-top: 20px;
}

.embedded-image-container {
  background: #eeeeee;
  padding: 1rem;
  margin: 1rem 0rem;
}

.embed-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.full-height-width {
  width: 100%;
  height: 100%;
}

.condensed-preview {
  overflow-y: auto;
  height: 250px;
}

.background-color-light-grey {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>

<style lang="scss">
/* this should not be scoped */
.v-text-field {
  .v-text-field__slot {
    width: 90%;
  }

  .v-input__append-inner {
    position: absolute;
    right: 0px;
  }
}
</style>
