import { getDateFormat } from '@/utils/getDateFormat.util'
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters("comments", [
            "commentsSavedCounter",
            "spouseComments",
            "unreadCommentsByPage",
            "hasUnreadComments"
        ]),

        commentType() {
            return this.isOwner ? 'respondent' : 'petitioner'
        },

        unreadComments() {
            return this.hasUnreadComments(this.commentType).toString()
        },

        showUnreadCommentWarning() {
            return (pageIndex) => this.unreadCommentsByPage({ type: this.commentType, pageIndex })
        },
    },

    methods: {
        hasEmptyComment(result, index) {            
            return result?.comments?.length <= index || (result?.comments[index] && !result?.comments[index]?.comment)
        },

        async markAsRead() {
            const spouseComments = this.results.filter(r => r.commentOwner === (this.commentType))[0]
            if(spouseComments?.comments?.length > 0 && spouseComments.comments[this.currentTab]) {
                spouseComments.comments[this.currentTab]['hasRead'] = true
                this.updateSpouseComments({ docId: this.formData.id, commentId: spouseComments.commentId, comments: spouseComments.comments })
            }
            return
        },

        dateFormat(payload) {
            return getDateFormat(payload.createdAt.toMillis(),'MM/DD/YYYY')
        },
    
        timeFormat(payload) {
            return getDateFormat(payload.createdAt.toMillis(),'hh:mm A')
        },
    }
}