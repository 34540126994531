export const pdfOptions = {
    colors: [
      {
        name: 'black',
        code: '#000000'
      },
      {
        name: 'white',
        code: '#FFFFFF'
      },
      {
        name: 'red',
        code: '#FF0000'
      },
      {
        name: 'orange',
        code: '#ff6600'
      },
      {
        name: 'yellow',
        code: '#ffff66'
      },
      {
        name: 'green',
        code: '#00cc00'
      },
      {
        name: 'blue',
        code: '#0066ff'
      },
      {
        name: 'light-grey',
        code: '#c4c4c4'
      },
      {
        name: 'grey',
        code: '#828282'
      },
      {
        name: 'dark-grey',
        code: '#424242'
      }
    ],
  }