import {promiseBlobToDataURL} from "@/services/workflow";
import { getCloudStorage } from "@/services/gcloud/getProject"
import $logger from '@/utils/logger.util'

const defaultState = () => {
  return {
    workflow: {},
    activeDocumentPages: {},
    activeDocIndex: null,
    activeDocId: null,
    documentFile: undefined,
  }
}

export const publishGuide = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, defaultState())
    },

    RESET_PUBLISH_GUIDE(state) {
      state.workflow = {};
      state.activeDocumentPages = [];
      state.activeDocIndex = null;
      state.renderedFile = undefined;
    },

    SET_WORKFLOW(state, workflow) {
      state.workflow = workflow;
    },

    SET_ACTIVE_DOC_INDEX(state, index) {
      state.activeDocIndex = index;
    },

    SET_ACTIVE_DOC_ID(state, id) {
      state.activeDocId = id;
    },

    SET_ACTIVE_DOCUMENT_PAGES(state, payload) {
      state.activeDocumentPages[payload.docId] = payload.docPages;
    },

    SET_DOCUMENT_FILE(state, file) {
      state.documentFile = file;
    }
  },
  getters: {
    getWorkflow: state => state.workflow,
    getActiveDocIndex: state => state.activeDocIndex,
    getActiveDocId: state => state.activeDocId,
    getDocumentFile: state => state.documentFile,
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },
        
    resetPublishGuide({commit}) {
      commit('RESET_PUBLISH_GUIDE');
    },

    async setWorkflow({commit, dispatch}, payload) {
      commit('SET_WORKFLOW', payload.workflow);
      if (payload.workflow.docs.length > 0) {
        dispatch('setActiveDocIndex', 0);
        await dispatch('setActiveDocId', payload.workflow.docs[0].uid)
      }
      return
    },

    setDocuments({commit, dispatch, state}, docId) {
      return new Promise(resolve_1 => {
        if (state.activeDocumentPages?.[docId]) {
          dispatch('updateRenderedFile', { docId: docId }).then(() => {
            resolve_1();
          });
        } else {
          let activeDoc = state.workflow.docs.find(doc => doc.uid === docId);

          if (activeDoc !== undefined && activeDoc?.dbRefs && activeDoc.dbRefs.length > 0) {

            // Make copy of doc
            activeDoc = Object.assign({}, activeDoc);

            // Initialize array for promises containing the pages updated with the downloaded file
            const downloadRequests = [];

            // Iterate through the database references to request a download and then return a page with the file property
            // filled with a data URL of the downloaded image file
            const dbRefsLength = activeDoc.dbRefs.length;
            for (let i = 0; i < dbRefsLength; i++) {
              const fileRequest = new Promise((resolve_2, reject_2) => {
                const tempUrl = activeDoc.dbRefs[i].dbRef
                const dbRefUrl = tempUrl.indexOf('http') < 0 ? `${getCloudStorage()}${tempUrl}` : tempUrl
                fetch(dbRefUrl, { method: 'GET' }).then(response => {
                  return response.blob();
                }).then(blob => {
                  return promiseBlobToDataURL(blob);
                }).then(dataUrl => {
                  activeDoc.dbRefs[i].file = dataUrl;
                  resolve_2(activeDoc.dbRefs[i]);
                }).catch(error => {
                  reject_2(error);
                });
              });

              downloadRequests.push(fileRequest);
            }

            Promise.all(downloadRequests).then(documentPages => {
              commit('SET_ACTIVE_DOCUMENT_PAGES', {docId: docId, docPages: documentPages});
              dispatch('updateRenderedFile', { docId: docId }).then(() => {
                resolve_1();
              });
            }).catch(error => {
              $logger.info('Promise.all error: ', error);
              resolve_1();
            });

          } else {
            resolve_1();
          }
        }
      });
    },

    async setActiveDocIndex({commit, dispatch}, docIndex) {
      commit('SET_ACTIVE_DOC_INDEX', docIndex);
      await dispatch('setDocuments', docIndex)
      return
    },

    async setActiveDocId({commit, dispatch}, docId) {
      commit('SET_ACTIVE_DOC_ID', docId);
      dispatch('setDocuments', docId)
      return
    },

    async setDocumentFile({commit}, file) {
      commit('SET_DOCUMENT_FILE', file);
      return
    },

    updateRenderedFile({dispatch, state}, payload) {
      return new Promise((resolve, reject) => {

        let images = [];
        if (state.activeDocumentPages?.[payload.docId]) {
          images = [...images, ...state.activeDocumentPages[payload.docId]];
        }

        dispatch('workflow/setPdfTemplateImages', images, { root: true }).then(() => {
          return dispatch('workflow/generatePdfFile', {
            type: 'blob',
            publishFile: true,
            docIndex: state.workflow.docs.findIndex(doc => doc.uid === payload.docId) ?? 0
          }, { root: true }).catch(error => {
            reject(error)
          })
        }).then(() => {
          resolve();
        }).catch(error => {
          reject(`${error}`);
        });
      });
    },
  }
}