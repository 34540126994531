<template>
  <div id="user-profile">
    <v-tabs :vertical="!isMobile" :horizontal="isMobile">
      <v-tab class="justify-start">
        <v-icon class="ms-2 me-2">
          mdi-account
        </v-icon>
        My account
      </v-tab>
      <v-tab class="justify-start">
        <v-icon class="ms-2 me-2">
          mdi-lock
        </v-icon>
        Change password
      </v-tab>
      <v-tab class="justify-start" @click="userProfileData">
        <v-icon class="ms-2 me-2">
          mdi-folder
        </v-icon>
        My Documents
      </v-tab>

      <v-tab-item class="tab-item">
        <v-row>
          <v-col cols="12" sm="8">
            <v-card flat>
              <v-card-text>
                <v-row v-if="$route.name !== 'user-profile'" ref="form" class="mt-5 justify-center">
                  <v-col cols="12">
                    <v-img
                        v-if="user.profile_photo && !profilePhotoPreviewFlag"
                        :src="user.profile_photo"
                        class="profile-photo-preview ml-auto mr-auto"
                        aspect-ratio="1"
                        max-height="130px"
                        max-width="130px"
                    />
                    <v-img
                        v-else
                        :src="profilePhotoGetter"
                        class="profile-photo-preview ml-auto mr-auto"
                        aspect-ratio="1"
                        max-height="130px"
                        max-width="130px"
                    />
                  </v-col>
                  <v-col cols="12">
                    <h1 class="primary--text">Hi {{ user.first_name }},</h1>
                    <p>Update your information and manage your account</p>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12">
                    <h1 class="primary--text">Hi {{ user.first_name }},</h1>
                    <p>Update your information and manage your account</p>
                  </v-col>
                  <v-col cols="12" sm="10" md="8" lg="6" xl="5">
                    <v-text-field
                        v-model.trim="user.email"
                        readonly
                        label="Email Address"
                        placeholder="username@example.com"
                        type="email"
                        outlined
                        background-color="#eee"
                    />
                  </v-col>
                </v-row>
                <ProfileForm
                  :user-profile="user"
                  :rules="rules"
                  @formValid="userFormValidity = $event"
                >
                  <template v-slot:buttons>
                    <v-row class="justify-center">
                      <v-col cols="12">
                        <v-btn depressed large @click="cancelForm">
                          Cancel
                        </v-btn>
                        &nbsp;
                        <v-btn
                            :disabled="!userFormValidity"
                            color="primary"
                            large
                            @click="saveProfile"
                        >
                          Save Profile
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </ProfileForm>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="tab-item">
        <v-card flat>
          <v-card-text>
            <v-row class="justify-start">
              <v-col cols="12" sm="6">
                <v-row>
                  <v-col cols="12">
                    <h1 class="primary--text">Change your password</h1>
                    <p>Type new password</p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="currentPass"
                      :append-icon="showCurrentPass ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showCurrentPass ? 'text' : 'password'"
                      label="Current password"
                      name="input-10-1"
                      counter
                      required
                      @blur="checkPass()"
                      @click:append="showCurrentPass = !showCurrentPass"
                      outlined
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        v-model="newPass"
                        :rules="[rules.required, rules.password, rules.lengthPassword]"
                        :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showNewPass ? 'text' : 'password'"
                        label="New password"
                        name="input-10-1"
                        hint="At least 10 characters"
                        counter
                        required
                        @click:append="showNewPass = !showNewPass"
                        outlined
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-btn large color="primary" @click="changePass()" :disabled="newPass === '' || currentPass === ''">
                      Change Password
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item class="tab-item">
        <v-row>
          <v-col cols="12" md="6">
            <h1 class="primary--text">My Documents</h1>
            <v-card flat class="text-start">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-if="finalPDFData.link" class="mb-3" style="border-bottom: 1px solid lightgray">Download completed PDF</v-list-item-title>
                  <div v-if="finalPDFData.link">
                    <p class="font-size-small font-weight-600">State {{finalPDFData.state}} -
                      <a :href="finalPDFData.link" target="_blank">Final pdf document download</a>
                    </p>
                    <p class="font-size-small font-weight-600">Modified : {{finalPDFData.modified}}</p>
                    <p class="font-size-small font-weight-600">eSigned : {{finalPDFData.eSigned ? 'Yes' : 'No'}} </p>
                    <p class="font-size-small font-weight-600">Spouse eSigned : {{finalPDFData.eSignedSpouse ? 'Yes' : 'No'}} </p>
                  </div>
                  <v-list-item-subtitle v-else>
                    <p class="font-weight-700 text-wrap">When you complete your questionnaire, you will be able to download the documents.</p>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>

    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      right
      color="primary"
    >
      <p class="font-weight-600">{{ text }}</p>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="currentPassError"
      :multi-line="multiLine"
      right
      color="red accent-2"
    >
      <p class="font-weight-600">{{ currentPassErrorMSG }}</p>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="currentPassError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FormBuilder from "../components/WorkflowBuilder/FormBuilder";
import ProfileForm from "../components/ProfileForm.vue";
import rulesMixin from "@/mixins/rules.mixin";
import resetPasswordMixin from '@/mixins/resetPassword.mixin';
import {getAuth, updatePassword} from "firebase/auth";
import analyticsMixin from "../mixins/analytics.mixin";

export default {
  name: "UserProfile",
  components: {
    FormBuilder,
    ProfileForm
  },

  mixins: [rulesMixin, resetPasswordMixin,analyticsMixin],

  watch: {
    user: {
      handler: function (newVal) {
        this.userProfile = newVal
        this.buildUserProfile()
      },
      deep: true
    }
  },

  async mounted() {
    await this.buildUserProfile()
  },

  data() {
    return {
      fullName: "",
      userFormValidity: false,
      userProfile: {
        first_name: "",
        last_name: "",
        full_name: "",
        gender: "",
        date_of_birth: "",
        address_street_1: "",
        address_street_2: "",
        address_city: "",
        address_state: "",
        address_zip_code: "",
        phone_number: "",
        permission_to_contact: null,
      },
      tab: 'option-1',
      currentPass: '',
      newPass: '',
      showCurrentPass: false,
      showNewPass: false,
      finalPDFData: {
        link: '',
        modified: '',
        eSigned: false,
        eSignedSpouse: false,
        state: ''
      },
      finalPdfLinkMSG: '',
      multiLine: true,
      snackbar: false,
      currentPassError: false,
      currentPassErrorMSG: 'Sorry, but you typed the wrong current password',
      text: `Success, password is changed successfully!`,
    };
  },

  computed: {
    ...mapGetters({
      user: "profile/userInfo",
      getStates: "getStates",
      getAcceptedUploadFileTypes: "getAcceptedUploadFileTypes",
      getFirebaseConfig: "getFirebaseConfig"
    }),

    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    getFullName() {
      return this.userProfile.first_name + " " + this.userProfile.last_name;
    },
  },

  async created() {
    await this.establishUserAuth()
    await this.buildUserProfile()
  },

  methods: {
    ...mapActions(["toggleLoadingOverlayStatus"]),

    ...mapActions('profile', ['userUpdate','establishUserAuth']),

    async userProfileData() {
      const docId = this.user.forms[0]?.workflowsUserId
      const formRef = this.getFirebaseConfig.db.collection("workflows-user").doc(docId)
      const finalPdf = await formRef.get()
      this.finalPDFData.link = finalPdf?.data()?.finalPDF
      const modifiedDate = finalPdf?.data()?.modifiedDate
      this.finalPDFData.modified = new Date(modifiedDate).toDateString();
      this.finalPDFData.eSigned = finalPdf?.data()?.esign?.signature?.signed ?? null;
      this.finalPDFData.eSignedSpouse = finalPdf?.data()?.spouseEsign?.signature?.signed ?? null;
      this.finalPDFData.state = finalPdf?.data()?.state
    },
    checkPass() {
      let user = this.getFirebaseConfig.auth().currentUser;
      let credentials = this.getFirebaseConfig.auth.EmailAuthProvider.credential(
        user.email,
        this.currentPass
      );
      user.reauthenticateWithCredential(credentials).then(() => {
      }).catch(() => {
        this.currentPassError = true
      });
    },

    changePass() {
      const auth = getAuth();
      const user = auth.currentUser;

      updatePassword(user, this.newPass).then(() => {
        this.newPass = ''
        this.snackbar = true
      }).catch((error) => {
        console.log('error wrong', error)
      });
    },

    async buildUserProfile() {
      await this.toggleLoadingOverlayStatus(true)
      for (const property in this.user) {
        if (this.userProfile.hasOwnProperty(property)) {
          this.userProfile[property] = this.user[property];
        }
      }
      await this.toggleLoadingOverlayStatus(false)
      return
    },

    async saveProfile() {
      this.userProfile.full_name = this.userProfile.first_name + " " + this.userProfile.last_name;
      const userData = this.userProfile;

      await this.userUpdate(userData)
      void this.$router.push({ name: "home" }).catch(() => {});
      return
    },

    clearForm() {
      this.userProfile = {
        gender: "",
        date_of_birth: "",
        first_name: "",
        last_name: "",
        full_name: "",
        address_street_1: "",
        address_street_2: "",
        address_city: "",
        address_state: "",
        address_zip_code: "",
        phone_number: "",
        permission_to_contact: null,
      };
    },

    cancelForm() {
      if(this.$route.name !== 'user-profile') {
        this.clearForm()
      }
      this.$router.push({ name: "home" }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.full-height {
  padding: 0px 20%;
  margin-bottom: 75px;
}
.cursor-pointer-interior > div > div.v-input__slot {
  cursor: pointer !important;
}

.background-light-grey {
  padding: 1rem 0;
  margin-bottom: 2rem;
  background: rgba(0, 0, 0, 0.1);
}

@media(max-width: 600px) {
  .full-height {
    padding: 0px 10px;
  }
}
</style>

<style lang="scss">
#user-profile {
  margin: 28px;

  .tab-item {
    margin-left: 28px;
    padding-left: 28px;
    border-left: 1px solid lightgray;
  }
}

@media (max-width:700px) {
  #user-profile {
    margin: 0;

    .tab-item {
      margin-left: 0;
      padding-left: 10px;
    }
  }
}
</style>
