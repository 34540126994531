<template>
  <div id="rule-config-container">

    <v-card
      tile
    >
      <v-card-title class="background-light-blue">
        Dynamic Blocks
        <v-spacer/>

        <MultiOptionAdd 
          :options="DYNAMIC_BLOCK_TYPES" 
          selection-label="Block Type To Add"
          button-title="Add New Dynamic Block"
          item-text="text"
          item-value="value"
          @add-new-option="createBlock"
        />
      </v-card-title>

      <v-divider/>

      <v-text-field
        v-model="searchBlocks"
        placeholder="Search Blocks..."
        class="solo-height-fix ma-2"
        clearable
        solo
      />

      <v-select 
        :items="['Incomplete','Completed']"
        placeholder="Filter by"
        v-model="filterBy"
        clearable
        solo
        class="a-action-list-filter"
      />

      <v-virtual-scroll
        :items="filteredDynamicBlocks"
        :max-height="256"
        bench="4"
        item-height="64"
        style="border: solid 1px lightgray"
      >
        <template v-slot:default="{ item, index }">
          <v-list-item
            :key="index"
            @click="activateBlock(index)"
            :class="{ 'm-active-list-item': currentBlockIndex === index }"
            class="block-container"
          >
            <v-icon
              v-if="hasDisplayLogic(item.uid)"
              :color="displayLogicColor(item.uid)"
              large
              title="Has Display Logic"
              class="hasDisplayLogic"
            >
              mdi-circle-small
            </v-icon>

            <v-list-item-content>
              <v-list-item-title class="text-left font-size-x-small">
                <strong>{{ item && item.tag ? item.tag : `Block ${parseInt(index) + 1}` }}</strong>
                <span class="float-right font-size-x-small font-italic">{{ item && item.type }}</span>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn outlined icon @click.stop="removeBlockClick(item.uid)">
                <v-icon small>delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider/>
        </template>
      </v-virtual-scroll>

      <v-card-text>
        <v-tabs-items
          v-model="currentBlockIndex"
          background-color="blue-grey"
        >
          <v-tab-item
            v-for="(block, index_1) in filteredDynamicBlocks"
            :key="index_1"
          >
            <v-card color="teal lighten-5">
              <v-card-actions>
                <v-spacer/>
                <v-btn
                  outlined
                  icon
                  @click="currentBlockIndex = null"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col>
                        <v-btn
                          text
                          class="block-name"
                          @click="changeName"
                        >{{ !!block.name ? block.name : 'Block ' + (parseInt(index_1) + 1) }}
                        </v-btn>

                        <v-text-field
                          v-if="changeNameFlag"
                          v-model="block.name"
                          class="block-name-change"
                          label="Set Current Block Name"
                          @blur="setBlockTag(block,'name',$event.target.value)"
                        />
                        <br>
                        <br>
                      </v-col>
                    </v-row>

                    <v-divider/>

                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          :items="DYNAMIC_BLOCK_TYPES"
                          item-text="text"
                          item-value="value"
                          v-model="block.type"
                          label="Block Type"
                          @input="setBlockTag(block,'type',$event)"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-autocomplete
                          v-if="block.type === 'question-input'"
                          v-model="block.value"
                          :items="parsedTextQuestionsList"
                          class="text-left"
                          label="Question To Get Value From"
                          item-text="questionListTitle"
                          return-object
                          @input="setBlockTag(block,'value',$event)"
                        />
                        <v-autocomplete
                          v-else-if="block.type === 'image-input'"
                          v-model="block.value"
                          :items="parsedImageFormElementsList"
                          class="text-left"
                          label="Form Element To Get Image From"
                          item-text="questionListTitle"
                          return-object
                          @input="setBlockTag(block,'value',$event)"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <v-text-field
                          v-if="block.type !== null"
                          v-model="block.tag"
                          :id="`block-tag-${index_1}`"
                          label="Block Tag"
                          readonly
                          append-icon="content_copy"
                          @click:append="copyText(index_1, block.tag, 'clip')"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="block.type.indexOf('-signature-') < 0">
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          :items="conditionals"
                          v-model="block.condition"
                          label="Conditional"
                          @change="setBlock(block.tag, 'condition', $event)"
                        />
                      </v-col>
                      <v-col
                        v-if="block.condition !== 'always'"
                        cols="12"
                        md="6"
                      >
                        <v-select
                          :items="logicalOperators"
                          v-model="block.operator"
                          label="Logical Operator"
                          @change="setBlock(block.tag, 'operator', $event)"
                        />
                      </v-col>
                    </v-row>

                    <v-divider/>

                    <template v-if="block.condition !== 'always' && block.type.indexOf('-signature') < 0">
                      <v-btn
                        class="my-4"
                        outlined
                        @click="addQuestion(block.tag)"
                      >
                        <v-icon>add</v-icon>
                        <span>Add Question</span>
                      </v-btn>

                      <v-divider/>

                      <template v-for="(question, index_2) in block.questions">
                        <v-row :key="index_1.toString() + index_2.toString()">
                          <v-col cols="6">
                            <!-- Sets answer options when changed -->
                            <v-autocomplete
                              v-model="question.title"
                              :label="'Question ' + (index_2 + 1)"
                              :items="parsedQuestionList"
                              class="text-left"
                              item-text="questionListTitle"
                              item-value="title"
                              @change="getSelectedQuestionAnswers(block, question.title, index_2)"
                            />
                          </v-col>
                          <v-col
                            v-if="showAnswerDropdown(question)"
                            cols="5"
                          >
                            <v-autocomplete
                              v-model="question.selection.answer"
                              :label="'Answer'"
                              :items="question.options"
                            />
                          </v-col>
                          <v-col
                            v-if="['grouped-options'].indexOf(question.type) > -1"
                            cols="5"
                          >
                            <v-autocomplete
                              v-model="question.selection.answer"
                              :label="'Answer'"
                              :items="groupedOptions(question)"
                            />
                          </v-col>
                          <template v-if="question.title && question.type === 'number-input'">
                            <v-col cols="3">
                              <v-select
                                v-model="question.selection.comparison"
                                :label="'Comparison'"
                                :items="question.options"
                              />
                            </v-col>
                            <template v-if="question.selection.comparison === 'greater-than'">
                              <v-col cols="2">
                                <v-text-field
                                  v-model="question.selection.lowerLimit"
                                  type="number"
                                  label="Lower Limit"
                                />
                              </v-col>
                            </template>
                            <template v-if="question.selection.comparison === 'equal-to'">
                              <v-col cols="2">
                                <v-text-field
                                  v-model="question.selection.equalTo"
                                  type="number"
                                  label="Equal To"
                                />
                              </v-col>
                            </template>
                            <template v-if="question.selection.comparison === 'less-than'">
                              <v-col cols="2">
                                <v-text-field
                                  v-model="question.selection.upperLimit"
                                  type="number"
                                  label="Upper Limit"
                                />
                              </v-col>
                            </template>
                            <template v-if="question.selection.comparison === 'between'">
                              <v-col cols="1">
                                <v-text-field
                                  v-model="question.selection.lowerLimit"
                                  type="number"
                                  label="Lower Limit"
                                />
                              </v-col>
                              <v-col cols="1">
                                <v-text-field
                                  v-model="question.selection.upperLimit"
                                  type="number"
                                  label="Upper Limit"
                                />
                              </v-col>
                            </template>
                          </template>
                          <template v-if="question.title && question.type === 'multi-select'">
                            <v-col cols="4">
                              <v-autocomplete
                                v-model="question.selection.answer"
                                :label="'Answer'"
                                :items="question.options"
                                multiple
                              />
                            </v-col>
                            <v-col cols="1">
                              <v-switch
                                v-model="question.selection.orLogic"
                                :label="question.selection.orLogic ? 'Any' : 'All' "
                              />
                            </v-col>
                          </template>
                          <v-col
                            cols="1"
                            class="align-center justify-center"
                          >
                            <v-btn
                              icon
                              @click="deleteQuestion(block.tag, index_2)"
                            >
                              <v-icon>cancel</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                    </template>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ruleConfigurationMixin from '@/mixins/components/ruleConfiguration.mixin'

export default {
  name: 'RuleConfiguration',

  mixins: [ruleConfigurationMixin]
};
</script>

<style scoped lang="scss">
.block-container {
  position: relative;

  .hasDisplayLogic {
    position: absolute;
    right: -10px;
    top: -10px;
  }
}

.block-name {
  cursor: pointer;
  font-size: x-large;
  color: #212121;
}

.block-name-change {
  transition: ease-in-out all 0.1s;
}
</style>
