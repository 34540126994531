<template>
  <!-- publish form dialog -->
  <v-dialog
    v-model="shareFormMetadata.publishDialog"
    :retain-focus="false"
    scrollable
    max-width="400px"
  >
    <v-card>
      <v-card-title>Publish Form?</v-card-title>
      <v-divider />
      <v-card-subtitle>
        <v-list-item>
          <v-list-item-content>
            <v-select
              v-model="shareFormMetadata.state"
              :items="getStates"
              label="What state does this form belong to?"
            />
          </v-list-item-content>
        </v-list-item>
      </v-card-subtitle>

      <v-divider />

      <v-card-text style="height: 300px">
        <v-list
          id="stakeholders"
          v-if="
            shareFormMetadata.stakeholders &&
            shareFormMetadata.stakeholders.length > 0
          "
        >
          <v-subheader class="full-header">Stakeholders</v-subheader>
          <v-list-item
            v-for="(stakeholder, index) in stakeHolders"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title>{{ stakeholder.email }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-btn
                  icon
                  outlined
                  color="red"
                  title="Delete?"
                  @click="deleteStakeHolder(stakeholder.email)"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-alert style="margin-top:10px;margin-left:0px;" v-else type="warning">
          Sorry, no stakeholders found, this will automatically approve and
          publish this form
        </v-alert>
      </v-card-text>
      <v-divider />
      <v-card-actions style="justify-content: right;">
        <v-btn
          v-if="stakeHolders.length > 0"
          color="primary"
          @click="sendPublishedForm"
        >
          Submit
        </v-btn>
        <v-btn v-else color="primary" @click="sendPublishedForm">
          Approved
        </v-btn>
        <v-btn
          color="primary darken-1"
          text
          @click="shareFormMetadata.publishDialog = false"
          >Close
        </v-btn>        
      </v-card-actions>
    </v-card>
    <div v-show="false" id="email-template-container">
      <email-template :message="message" />
    </div>
  </v-dialog>
</template>

<script>
import publishFormMixin from '../mixins/components/publishForm.mixin';

export default {
  name: "PublishForm",

  mixins: [publishFormMixin]
};
</script>

<style lang="scss" scoped>
.v-card__text {
  padding: 0 20px;

  #stakeholders {
    .v-list-item {
      padding: 0px;
      border: 1px solid white;
    }

    .full-header {
      width: 100%;
    }

    .header1 {
      text-align: left;
    }

    .header2 {
      text-align: right;
    }

    .v-subheader {
      padding: 10px 0;
      flex: 1 1 auto;
      height: fit-content;
      display: inline;
    }

    .v-list-item__title {
      text-align: left;
      flex: 2 2 auto;
    }

    .v-list-item__subtitle {
      text-align: right;
      flex: 1 1 auto;

      .stakeholder {
        display: inline;
        vertical-align: middle;

        input[type="checkbox"] {
          height: 15px;
          width: 15px;
        }
      }

      button {
        margin-left: 10px;
        width: 20px;
        height: 20px;

        .v-icon {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

    .v-list-item__content {
      padding: 0px;
    }
  }
}
</style>
