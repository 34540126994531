/* eslint-disable no-console */

import { register } from 'register-service-worker'
import $logger from '@/utils/logger.util'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      $logger.info(
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      $logger.info('Service worker has been registered.')
    },
    cached () {
      $logger.info('Content has been cached for offline use.')
    },
    updatefound () {
      $logger.info('New content is downloading.')
    },
    updated () {
      $logger.info('New content is available; Refresh...')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    },
    offline () {
      $logger.info('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      $logger.warn('Error during service worker registration: ', error)
    }
  })
}
