import jsPDF from "jspdf";
import xMark from "@/assets/x-transparent-512.png";
import checkMark from "@/assets/check-transparent-214.png";
import {mapActions, mapState, mapGetters} from 'vuex';

export default {
  data() {
    return {
      pdfTemplate: {
        currentSessionPages: [],
        existingPages: [],
        combinedPages: [],
        pagesToDelete: [],
        dbRefs: [],
      },
      
      currentSurveyTab: 0,
      currentPreviewPage: null,
      xMark,
      checkMark
    }
  },

  computed: {
    ...mapState('workflow', {
      templateFile: 'templateFile'
    }),

    ...mapGetters('workflow', {
      processedFile: 'getProcessedFile'
    }),

    currentTab: {
      get: function () {
        return this.currentSurveyTab;
      },
      set: function (newValue) {
        this.currentSurveyTab = newValue;
      }
    }
  },

  methods: {
      ...mapActions(['toggleLoadingOverlayStatus']),

      ...mapActions('workflow', ['generatePdfFile', 'setPdfTemplateImages', 'setProcessedFile']),

      buildPdfTemplate(doc = this.form.docs[this.currentDocIndex]) {
        this.pdfTemplate = {
          name: doc?.name || `Document ${(this.currentDocIndex + 1)}`,
          combinedPages: doc?.combinedPages || [],
          existingPages: doc?.existingPages || [],
          currentSessionPages: doc?.currentSessionPages || [],
          pagesToDelete: doc?.pagesToDelete || [],
          pdfTemplateChangeFlag: 0
        };
      },

      updateCurrentSurveyTab(index) {
        this.currentSurveyTab = index;
      },

      setCurrentPage(value) {
        this.currentPreviewPage = value;
      },

      async updatePdfFile(payload) {
        if (this.form.docs === undefined) return;
        payload.docId = payload.docId || this.currentDocIndex || 0;
        await this.generatePdfFile(payload)
        return
      },

      getDefaultPdf(newPDF = new jsPDF()) {
        newPDF.setFontSize(40);
        newPDF.text(
          10,
          40,
          "Add an image file as a PDF Document Template under the 'Documents' section to create a new PDF.",
          {maxWidth: "190"}
        );
        return newPDF.output("blob");
      }
  }
}