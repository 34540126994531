const setupProcessedFile = async (payload, commit, dispatch, processedFile, newPDF) => {
    if (!!payload) {
        if (payload.type === 'download') {
          newPDF.output('save', 'SIMPLE-ENDING Divorce Documents');
        }

        if (payload.templateFile) {
          commit('SET_TEMPLATE_FILE', processedFile);
        }
        if (payload.processedFile) {
          commit('SET_PROCESSED_FILE', processedFile);
        }
        if (payload.publishFile) {
          await dispatch('publishGuide/setDocumentFile', processedFile, {root: true});
        }
        if (!payload.templateFile && !payload.processedFile && !payload.publishFile) {
          // By default, set both files
          commit('SET_PROCESSED_FILE', processedFile);
          commit('SET_TEMPLATE_FILE', processedFile);
        }
      } else {
        commit('SET_TEMPLATE_FILE', processedFile);
        commit('SET_PROCESSED_FILE', processedFile);
      }    
}

module.exports = { setupProcessedFile }