<template>
  <v-card class="align-center justify-center a-fullscreen-overlay" :class="showOverlay ? 'loading-card-show' : 'loading-card-hide'">
    <div class="loading-container">
      <v-img class="loading-logo" :class="showOverlay ? 'loading-logo-show' : 'loading-logo-hide'" max-width="275" max-height="275" width="260" src="@/assets/simple_ending_logo_icon_horizontal.svg"></v-img>
      <v-progress-circular class="loading-progress" :class="showOverlay ? 'loading-progress-show' : 'loading-progress-hide'" :size="275" :width="10" color="#BBDEFB" indeterminate/>      
      <div style="position:absolute; bottom: -60px; z-index:999;width:100%;">
        {{text}}  
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'LoadingOverlay',
  props: {
    showOverlay: {
      type: Boolean,
      required: true,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  },
}
</script>

<style lang="scss" scoped >

  .loading-card-show {
    z-index: 9980;
    opacity: 0.95;
    pointer-events: none;
  }

  .loading-card-hide {
    z-index: -10000;
    opacity: 0;
  }

  .loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 275px;
    height: 275px;

    .loading-logo {
      position: relative;
      top: 114.16px;
      margin-right: auto;
      margin-left: auto;
    }

    .loading-logo-show {
      z-index: 10000;
      opacity: 1;
    }

    .loading-logo-hide {
      z-index: -10000;
      opacity: 0;
    }

    .loading-progress {
      position: absolute;
      top: 0;
      left: 0;
    }

    .loading-progress-show {
      z-index: 9990;
      opacity: 0.9;
    }

    .loading-progress-hide {
      z-index: -10000;
      opacity: 0;
    }

  }

</style>
