<template>
  <div class="comments-frame" style="position:fixed; bottom: 140px; right: 40px; width: 340px">
    <v-alert
        v-model="drawer"
        prominent
        dismissible
        color="green"
        dark
    >
      <h5 class="text-left mb-3">The following pages have been changed.</h5>
      <v-tabs
          v-model="tabM"
          vertical
          background-color="transparent"
      >
        <v-tab
            v-for="(page, index) in pageNames && pageNames.concat(results && results[1].comments?.filter(e => e.comment !== '').map(e => e.page)).filter((currentValue, currentIndex) =>
            pageNames.concat(finalFilter).indexOf(currentValue) !== currentIndex)"
            :key="index"
            :href="`#tab-${pageNames.indexOf(page)}`"
        >
          {{page}}
        </v-tab>
      </v-tabs>


    </v-alert>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import commentsMixin from "@/mixins/comments.mixin"

export default {
  mixins: [commentsMixin],

  props: {
    pageNames: Array,
    commentButton: {},
    isOwner: Boolean,
    commentOwnerName: String,
    currentStep: [String, Number],
    showDefault: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,
      currentTab: Number,
      panel: 0,
      drawer: false,
      snackbar: false,
      comments: [],
      tabM: null,
      text: [],
      finalFilter: []
    }
  },

  watch: {
    tabM(val) {
      let formatted = val.split('tab-');
      this.$emit('change-tab', formatted[1] -1);
    },
  },

  computed: {
    ...mapGetters("workflowPublished", {
      formData: "getFormData",
    }),

    ...mapGetters("comments", {
      results: "spouseComments",
      unreadCommentsByPage: "unreadCommentsByPage"
    })
  },

  async beforeMount() {
    if(this.showDefault) this.drawer = true;
  },

  async mounted() {
    let feedback = this.results

    this.text = []
    this.text = feedback && feedback[0]?.comments?.filter(e => e.comment !== '')
    this.finalFilter = this.text && this.text.map(e => e.page)
  },

  methods: {
    ...mapActions('comments', {
      getComments: 'getSpouseComments',
    }),
  }
};
</script>
<style lang="scss">

.comments-frame {
  .v-tabs--vertical > .v-tabs-bar .v-tab {
    height: 25px;
    text-align: left;
    justify-content: left;
  }
  .v-alert__wrapper {
    align-items: flex-start;
  }
  .v-alert__dismissible {
    margin-top: 0;
  }
}
</style>