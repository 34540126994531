import payPalService from "@/services/paypal";
import {mapActions, mapGetters} from "vuex";
import sendEmailUtil from "@/utils/send-email.util"
import FindCoupon from "@/components/FindCoupon"
import Decimal from 'decimal.js'
import analyticsMixin from "../analytics.mixin";
import Carousel from "@/components/PayPal/Carousel.vue";
import CostInfo from "@/components/CostInfo.vue";
import copyWorkflowFiles from '@/services/copy-workflow-files.service'

export default {
  mixins: [analyticsMixin],

  components: {
    CostInfo,
    Carousel,
    FindCoupon,
  },

  props: {
    level: {
      type: Number,
      default: 1,
    },

    type: {
      type: String,
      default: 'collaborative'
    },

    userId: {
      type: String,
      require: true,
    },

    formId: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      tabStatus: "active",
      paypalBtnsLoaded: false,
      dialog: false,
      isChild: true,
      payInfo: {},
      submitted: false,
      message: "",
      isGoodToken: true,
      loading: false,
      paypal: {},
      cost: 0,
      originalCost: 0,
      couponKey: '',
    };
  },

  computed: {
    ...mapGetters(["baseUrl", "loadingStatus"]),

    ...mapGetters('analytics', ['awID', 'paywallIds']),

    ...mapGetters("coupons", ["currentCoupon", "coupons"]),

    ...mapGetters("prices", ["getPriceByLevel", "getCardCosts"]),

    ...mapGetters("profile", ["alreadyUsedToken", "userInfo", "getPayments"]),

    costTypeIndex() {
      return this.$route.params.type === 'collaborative' ? 0 : 1
    },

    couponSaved() {
      return ![null, 'null', undefined].includes(localStorage.getItem(this.couponKey))
    },

    costInfoData() {
      return this.getPriceByLevel(this.payInfo.type,`level_${this.payInfo.level}`);
    },

    pmt() {
      return this.getPriceByLevel(this.payInfo.type,`level_${this.$route.params.level}`)
    },

    showPayWall() {
      return this.loading === false && this.costInfoData && this.payInfo.formId.length > 0 && !this.$route?.query?.token
    }
  },

  created() {
    if (this.$route?.params) {
      this.isChild = false
    }

    this.payInfo = {
      type: this.$route?.params?.type || this.type,
      level: this.$route?.params?.level || this.level,
      userId: this?.$route?.params?.userId || this.userId,
      formId: this?.$route?.params?.formId || this.formId
    }
  },

  async mounted() {
    this.loading = true
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    await this.establishUserAuth()
    await this.setPrices();
    this.couponKey = `${this.userInfo.uid}-coupon_code`
    if (this.$route?.params?.level != 2 && !this.$route?.query?.token) {
      localStorage.removeItem(this.couponKey)
    }

    await this.hasPaidForLevel()

    if (this.userInfo.is_petitioner === false) {
      void this.$router.push(`/respondent-guide/${this.$route.params.formId}`)
      return
    }
    this.paypal = payPalService();
    this.calculateCost()
    this.originalCost = this.cost
    if (localStorage.getItem(this.couponKey)) {
      if (this.$route?.query?.token) {
        const coupon_code = localStorage.getItem(this.couponKey)
        await this.findCoupon(coupon_code)
        this.deductAmount(false)
      }
    }

    await this.handleToken();
    this.loading = false
  },

  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  },

  methods: {
    ...mapActions(["toggleLoadingOverlayStatus", "setOverlayMessage"]),

    ...mapActions("profile", ["setPayments", "establishUserAuth"]),

    ...mapActions("prices", ["setPrices"]),

    ...mapActions("workflowPublished", ["loadForm", "saveForm", "saveStep", "getRawForm", "updateFormDocs", 'clearPublishedWorkflows']),

    ...mapActions('coupons', ['findCoupon', 'getCoupons', 'resetCurrentCoupon', 'setCoupons']),

    ...mapActions("confirmation", ["confirm"]),

    goBackToQuestionnaire() {
      this.confirm({
        title: "Are you sure?",
        accepted: async () => {
          this.loading = true
          await this.saveStep({ docId: this.payInfo.formId, step: 1 })
          this.loading = false
          void this.$router.push(`/legal-form-guide/divorce-forms/${this.payInfo.formId}`)
        },
      });
    },

    handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        this.tabStatus = "active";
        // keep this for now. See if this was needed from a previous story CU-868715za5
        //location.reload()
      } else {
        this.tabStatus = "inactive";
      }
    },
    cancelPayment() {
      this.dialog = false
      this.paypalBtnsLoaded = false
      const childComponent = this.$refs.childComponentRef;
      if (childComponent && typeof childComponent.cancelPayment === 'function') {
        childComponent.cancelPayment();
      }
      this.cost = new Decimal(this.costInfoData.price).toFixed(0)
    },
    async hasPaidForLevel() {
      await this.setPayments(this.payInfo.userId)
      const parentId = await this.getParentId()
      const hasPaid = this.getPayments[parentId]?.['level_' + this.$route.params.level]
      if(!hasPaid) return
      if(this.$route.params.level == 1) {
        void this.$router.push('/')
      } else {
        void this.$router.push(`/legal-form-guide/divorce-forms/${this.$route.params.formId}`)
      }
      return
    },
    calculateCost() {
      this.cost = new Decimal(this.costInfoData?.price || 0).toFixed(0)
    },
    async showDialog() {
      this.resetCurrentCoupon()
      this.dialog = true
      const paypalClientId = await this.paypal.getClientId()
      await this.hasPaidForLevel()
      const getPayment = Object.keys(this.getPayments)[0]
      const nestedObject = this.getPayments[getPayment];
      const level1Value = nestedObject?.amount_1;
      const level2Value = nestedObject?.amount_2;
      if((typeof level1Value !== 'undefined' && level1Value !== '' && this.$route?.params?.level === '1') || (typeof level2Value !== 'undefined' && level2Value !== '' && this.$route?.params?.level === '2')) {
        void this.$router.push('/')
      }
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientId}`;
      script.addEventListener('load', this.initializePayPalButton);
      document.body.appendChild(script);
    },
    initializePayPalButton() {
      const paypal = window.paypal;

      const FUNDING_SOURCES = [
        paypal.FUNDING.PAYPAL,
        paypal.FUNDING.CARD
      ];

      const buttonStyle = {
        layout: 'horizontal', // Set the layout to horizontal
        shape: 'rect',
        color: 'white' // You can change the color if needed
      };

      FUNDING_SOURCES.forEach(e => {
        paypal.Buttons({
          fundingSource: e,
          style: buttonStyle,
          onInit: () => {
            this.paypalBtnsLoaded = true
          },

          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: this.cost,
                    "currency_code": "USD",
                  },
                },
              ],
            });
          },

          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            if (order.status === "COMPLETED") {
              await Promise.all([
                this.toggleLoadingOverlayStatus(true),
                this.sendPayment()
              ])
              this.setOverlayMessage('Please wait while we build your questionnaire.'),
              await this.handlePayment(data.orderID)
            }
          },
        }).render('#paypal-button-container');
      })
    },

    ...mapActions('alert', ['setAlert']),

    deductAmount(store = true) {
      const percentage = this.cost * (this.currentCoupon.amount / 100)
      this.cost = new Decimal(this.cost - percentage).toFixed(0)
      if (store && this.currentCoupon?.code && this.couponKey) {
        localStorage.setItem(this.couponKey, this.currentCoupon.code)
      }
    },

    async handleToken() {
      this.toggleLoadingOverlayStatus(true);
      if (this.$route.query.token) {
        const {token} = this.$route.query
        if (!this.alreadyUsedToken(token)) {
          const response = await this.paypal.validToken(token);
          this.isGoodToken = response.data;
        } else {
          this.isGoodToken = false;
          this.setAlert({ type: 'error', message: 'Sorry, incorrect Paypal token.' })
        }

        if (this.isGoodToken) {
          this.setOverlayMessage('Please wait while we build your questionnaire.')
          const amt = localStorage.getItem("paypalAmt")
          const {PayerID, paymentId} = this.$route.query;

          const transaction = await this.paypal.success(PayerID, paymentId, amt)
          let transactionId = ''
          if (transaction?.data?.payment?.transactions?.[0]?.related_resources?.[0]?.sale?.id)
            transactionId = transaction.data.payment.transactions[0].related_resources[0].sale.id

          await this.handlePayment(transactionId)
          this.setOverlayMessage()
        }
      } else {
        this.toggleLoadingOverlayStatus(false);
      }
      return
    },

    async getParentId() {
      if (this.payInfo.level === "2") {
        return (await this.getRawForm({formId: this.$route?.params.formId})).parentId
      } else {
        return this.$route?.params.formId
      }
    },

    async handlePayment(transactionId) {
      await this.setPayments(this.payInfo.userId);
      const parentId = await this.getParentId()
      const { userId, level } = this.payInfo

      let type = level === '1' ? 'initial payment' : 'final payment'

      const amount = localStorage.getItem('paypalAmt') || '0'
      if (!this.userInfo?.uid) {
        await this.establishUserAuth()
      }
      const couponCode = localStorage.getItem(`${this.userInfo.uid}-coupon_code`) || ''
      this.paypal.log(
        userId,
        parentId,
        Date.now(),
        level,
        amount,
        couponCode,
        transactionId
      );
      if(transactionId !== 'No Paypal Transaction') {
        this.trackEvent('event','payment_' + level, {
          couponCode: couponCode,
          type: this.$route.params.type
        })
        this.trackEvent('event', 'conversion', {
          send_to: `${this.awID}/${this.paywallIds[level - 1]}`,
          value: amount,
          currency: 'USD',
          transaction_id: transactionId
        })
      }

      localStorage.removeItem("paypalAmt")
      await this.createUserForm(parentId, type)
      return
    },

    async createUserForm (parentId, type) {
      const user = {
        name: this.userInfo.full_name,
        email: this.userInfo.email
      }

      await Promise.all([
        sendEmailUtil(this.baseUrl, user, type, "Thank you for your payment", '', 'sign-in', null, '', {cost: this.cost}),
        this.setPayments(this.payInfo.userId)
      ])
      if (this.payInfo.level == "2") {
        await this.$nextTick()
        await this.saveStep({docId: this.payInfo.formId, step: 5, set: false})
        await this.toggleLoadingOverlayStatus(false)
        this.setOverlayMessage()
        void this.$router.push(`/legal-form-guide/divorce-forms/${this.payInfo.formId}`)
      } else {
        await this.loadForm({parentId: this.payInfo.formId})
        const formId = await this.saveForm(false)
        await Promise.all([
          copyWorkflowFiles({
            oldWorkflowId: parentId,
            newWorkflowId: formId,
            oldWorkflowName: 'workflows-published',
            newWorkflowName: 'workflows-user'
          }),
          this.updateFormDocs({ id: formId }),
          this.setCoupons({}),
          this.clearPublishedWorkflows()
        ])
        await this.toggleLoadingOverlayStatus(false)
        this.setOverlayMessage()
        void this.$router.push(`/legal-form-guide/divorce-forms/${formId}`)
      }
    },

    async skipPayment() {
      this.setOverlayMessage('Please wait while we build your questionnaire.')
      await Promise.all([
        this.toggleLoadingOverlayStatus(true),
        this.handlePayment('No Paypal Transaction')
      ])
      await this.toggleLoadingOverlayStatus(false);
      return
    },

    async sendPayment() {
      this.loading = true;

      try {
        if (this.cost > 0) {
          localStorage.setItem("paypalAmt", this.cost)
          // const resp = await this.paypal.requestPayment(this.cost, this.payInfo.level, location.pathname)
          this.submitted = true;
        } else {
          this.setOverlayMessage('Please wait while we build your questionnaire.')
          await Promise.all([
            this.toggleLoadingOverlayStatus(true),
            this.handlePayment('No Paypal Transaction')
          ])
          await this.toggleLoadingOverlayStatus(false)
          this.setOverlayMessage()
        }
      } catch (error) {
        this.$logger.error(error);
      }
      this.loading = false;
      return
    },
  }
}