<template>
  <v-container class="workflow-guide pt-5 ps-0 pe-0 pb-0">
    <div v-if="showForm">
      <v-row no-gutters>
      <v-col cols="12">
        <v-form
          :disabled="!isAdmin && !isOwner && sharedUserProps && (sharedUserProps.reviewType === 'read-only' || !sharedUserProps.access)">
          <v-tabs
            v-show="showTabs"
            v-if="pageNames && pageNames.length > 0"
            id="form-tab-container"
            v-model="currentTab"
            background-color="background-gradient-light-blue-to-green"
            dark
            class="float-nav"
            style="position: sticky; top: 0; z-index: 0"
          >
            <v-tabs-slider color="primary" />
            <v-tab
              v-for="(pageName, index) in pageNames"
              :key="index"
              :id="`tab-${index}`"
              @click="clickedTab()"
            >
              {{ pageName }}
              <v-icon
                v-if="pageWarnings[pageName] > 0"
                class="pl-1 pb-5" color="white">
                error
              </v-icon>
            </v-tab>
          </v-tabs>
          <v-btn
            v-if="readOnly"
            title="This page is locked and cannot be changed."
            text
            class="lock-btn"
            @click="showCommentMessage = true"
          >
            <v-icon
              id="lock-icon"
              color="error"
              large
            >
              lock
            </v-icon>
          </v-btn>
          <v-tabs-items v-if="pages && pages.length > 0" v-model="currentTab">
            <v-tab-item
              v-for="(page, index) in pages"
              :key="index"
              class="pa-4"
            >
              <br />
              <v-row 
                v-if="currentTab === index || isLoading"
                :id='`currentTab${index}`'
              >
                <v-col
                  v-show="fill === false"
                  cols="1"
                />
                <v-col
                  class="form-wrapper"
                  cols="10"
                  :key="componentKey + commentsSavedCounter"
                >

                <template v-for="(field, index) in page">
                  <div 
                    :id="`${field.type}-${field.uid}`" 
                    class="form-field-container" 
                    :key="index"
                  >
                      <template v-if="field.type === 'text-input-short'">
                        <div
                          :id="field.uid"
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                            />

                            <label :for="field.uid">
                              {{expressionator(field.label, formFields)}}
                            </label>

                            <v-text-field
                              v-mask="`${getMask(field)}`"
                              :id="field.uid"
                              :name="field.uid"
                              :value="field.content"
                              :type="fieldType(field)"
                              :readonly="readOnly"
                              :rules="rulesStatus(field)"
                              :error="answerStatus(field)"
                              :placeholder="field.placeholder || 'Enter text here'"
                              :title="expressionator(field.label, formFields)"
                              :disabled="disableChecker(field)"
                              autocomplete="chrome-off"
                              :clearable="readOnly === false"
                              outlined
                              :class="{ emailText: fieldType(field) === 'email', timeEntry: field?.validator === 'time' }"
                              @blur="inputHandler(field, $event)"
                            />
                            <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-if="field.type === 'child-support-calculator'">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                          style="min-height:100px;text-align:left;"
                        >
                          <HelpIcon
                            v-if="field.helpData && (field.helpData.text || field.helpData.youtubeLink)"
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <h3>Child Support Calculator</h3>
                          <p class="mt-2 mb-1" v-html="childCalculatorInfo.description"></p>
                          <a :href="childCalculatorInfo.website" target="_blank">
                            {{ childCalculatorInfo.hyperlink || 'Click here' }}
                          </a>
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-if="field.type === 'label'">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <v-text-field
                            :id="field.uid"
                            :value="expressionator(field.label, formFields)"
                            :label="field.name"
                            :error="answerStatus(field)"
                            readonly
                            outlined
                          />
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-if="field.type === 'computed-property' && field.visible">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                            />

                            <label :for="field.uid">
                              {{ expressionator(field.name) }}
                            </label>

                            <v-text-field
                              :id="field.uid"
                              :prefix="field.prefix"
                              :suffix="field.suffix"
                              :value="expressionator(field.label, formFields)"
                              background-color="#eeeeee"
                              readonly
                              outlined
                            />
                            <br v-if="field.spaceAfter" />
                          </div>
                      </template>

                      <template v-if="field.type === 'grouped-options'"
                      >
                        <div
                            :id="field.uid"
                            :class="{ 'd-none': (displayStatus(field) || !field.visible) }"
                            class="p-relative"
                        >
                          <HelpIcon
                              v-if="
                                field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                          />
                          <v-text-field
                            :value="expressionator(groupOptions(field, formFields), formFields)"
                            :label="expressionator(field.label, formFields)"
                            readonly
                          />
                          <br v-if="field.spaceAfter"/>
                        </div>
                      </template>

                      <template v-if="field.type === 'signature-pad'">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <signature-pad
                            :id="field.uid"
                            :field="field"
                            :read-only="previewMode"
                            :required="field.required"
                            :form-id="formId"
                            :label="expressionator(field.label, formFields)"
                            :error="answerStatus(field)"
                            :disabled="disableChecker(field)"
                            @change="inputHandler(field, $event)"
                          />
                          <br />
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-if="field.type === 'number-input'">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />

                          <label :for="field.uid">
                            {{ expressionator(field.label, formFields) }}
                          </label>
                          <VuetifyMoney
                            v-if="field.useCommas === true"
                            :value="field.content"
                            :readonly="readOnly"
                            :min="field.lowerBound"
                            :max="field.upperBound"
                            :rules="rulesStatus(field)"
                            :error="answerStatus(field)"
                            :disabled="disableChecker(field)"
                            :title="expressionator(field.label, formFields)"
                            :clearable="readOnly === false"
                            outlined
                            :options="{
                              locale: 'en-US',
                              precision: 0,
                              prefix:field.prefix,
                              suffix:field.suffix
                            }"
                            @click:clear="inputHandler(field, $event)"
                            @blur="inputHandler(field, $event)"
                          />
                          <v-text-field
                            v-else
                            :id="field.uid"
                            :value="field.content"
                            :prefix="field.prefix"
                            :suffix="field.suffix"
                            :readonly="readOnly"
                            :min="field.lowerBound"
                            :max="field.upperBound"
                            :title="expressionator(field.label, formFields)"
                            :rules="rulesStatus(field)"
                            :error="answerStatus(field)"
                            :disabled="disableChecker(field)"
                            type="number"
                            :clearable="readOnly === false"
                            outlined
                            class="number-input"
                            @click:clear="inputHandler(field, $event)"
                            @blur="inputHandler(field, $event)"
                          />
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-if="field.type === 'text-input-long'">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />

                          <label :for="field.uid">
                            {{expressionator(field.label, formFields)}}
                          </label>

                          <v-textarea
                            :id="field.uid"
                            :value="field.content"
                            :label="expressionator(field.label, formFields)"
                            :readonly="readOnly"
                            :error="answerStatus(field)"
                            :title="expressionator(field.label, formFields)"
                            :disabled="disableChecker(field)"
                            @blur="inputHandler(field, $event)"
                            outlined
                          />
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template
                        v-if="
                          field.type === 'single-select' &&
                            field.variant.toLocaleLowerCase() === 'radio'
                        "
                      >
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <header>
                            {{ expressionator(field.label, formFields) }}
                          </header>

                          <v-radio-group
                            :id="field.uid"
                            :value="field.selection"
                            :error="answerStatus(field)"
                            :disabled="disableChecker(field)"
                            @change="inputHandler(field, $event)"
                          >
                            <v-radio
                              v-for="(radio, index) in field.content"
                              :key="index"
                              :id="field.uid"
                              :readonly="readOnly"
                              :label="expressionator(radio, formFields)"
                              :title="expressionator(field.title, formFields)"
                              :value="radio"
                            />
                          </v-radio-group>
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template
                        v-else-if="field.type === 'state-select'"
                      >
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />

                          <label :for="field.uid">
                            {{expressionator(field.label, formFields)}}
                          </label>

                          <v-select
                            :id="field.uid"
                            :value="field.selection"
                            placeholder="Select option"
                            :items="getStates"
                            :readonly="readOnly"
                            :error="answerStatus(field)"
                            :title="expressionator(field.label, formFields)"
                            :disabled="disableChecker(field)"
                            clearable
                            outlined
                            @click:clear="field.selection = '', field.answeredStatus = false"
                            @input="inputHandler(field, $event)"
                          />
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template
                        v-else-if="
                          field.type === 'single-select' &&
                            field.variant.toLocaleLowerCase() === 'drop-down'
                        "
                      >
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />

                          <label :for="field.uid">
                            {{expressionator(field.label, formFields)}}
                          </label>

                          <v-select
                            :id="field.uid"
                            :value="field.selection"
                            placeholder="Select option"
                            :items="selectOptions(field.content)"
                            :readonly="readOnly"
                            :error="answerStatus(field)"
                            :title="expressionator(field.label, formFields)"
                            :disabled="disableChecker(field)"
                            :clearable="readOnly === false"
                            outlined
                            @click:clear="field.selection = '', field.answeredStatus = false"
                            @input="inputHandler(field, $event)"
                          />
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template
                        v-else-if="
                          field.type === 'single-select' &&
                            field.variant.toLocaleLowerCase() === 'slider'
                        "
                      >
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />

                          <label :for="field.uid">
                            {{expressionator(field.label, formFields)}}
                          </label>

                          <v-slider
                            :id="field.uid"
                            :key="index"
                            :value="field.selection"
                            :tick-labels="sliderOptions(field.content)"
                            :max="field.content.length - 1"
                            :readonly="readOnly"
                            :error="answerStatus(field)"
                            :disabled="disableChecker(field)"
                            ticks="always"
                            tick-size="4"
                            @change="inputHandler(field, $event)"
                          />
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template
                        v-else-if="
                          field.type === 'multi-select' &&
                            field.variant &&
                            field.variant.toLocaleLowerCase() === 'checkboxes'
                        "
                      >
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <v-row justify="space-around" :key="index">
                            <v-col cols="12">
                              <header>
                                {{ expressionator(field.label, formFields) }}
                              </header>
                            </v-col>
                            <div :id="field.uid" class="d-contents">
                              <v-checkbox
                                v-for="(option, index2) in field.content"
                                v-model="field.selection"
                                :key="index.toString() + '>' + index2.toString()"
                                :value="expressionator(option, formFields)"
                                :label="expressionator(option, formFields)"
                                :readonly="readOnly"
                                :error="answerStatus(field)"
                                :disabled="!isOwner && sharedUserProps && sharedUserProps.reviewType != 'edit'"
                                @change="inputHandler(field, $event)"
                              />
                            </div>
                          </v-row>
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template
                        v-else-if="
                          field.type === 'multi-select' &&
                            field.variant &&
                            field.variant.toLocaleLowerCase() === 'drop-down'
                        "
                      >
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <div :key="index">
                            <HelpIcon
                              v-if="
                                field.helpData &&
                                  (field.helpData.text ||
                                    field.helpData.youtubeLink)
                              "
                              :value="field.helpData"
                              :field-id="field.uid"
                            />

                            <label :for="field.uid">
                              {{ expressionator(field.label, formFields) }}
                            </label>

                            <v-select
                              :id="field.uid"
                              :items="selectOptions(field.content)"
                              :value="field.selection"
                              :label="expressionator(field.label, formFields)"
                              :error="answerStatus(field)"
                              :disabled="
                                !isOwner &&
                                  sharedUserProps &&
                                  sharedUserProps.reviewType != 'edit'
                              "
                              ticks="always"
                              multiple
                              :clearable="readOnly === false"
                              outlined
                              @click:clear="inputHandler(field, $event)"
                              @input="inputHandler(field, $event)"
                            />
                          </div>
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-else-if="field.type === 'file-input'">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <file-upload
                            :id="field.uid"
                            :key="index"
                            :label="expressionator(field.label, formFields)"
                            :rules-status="rulesStatus(field)"
                            :answer-status="answerStatus(field)"
                            :field="field"
                            :read-only="readOnly"
                            :required="field.required"
                            :form-id="formId"
                            :accept="`${getAcceptedUploadFileTypes},${getAllowedUploadFileTypes}`"
                            :disabled="disableChecker(field)"
                            :is-published="true"
                            @blur="autoSave(field)"
                          />
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-else-if="field.type === 'youtube-video'">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <div class="embed-container">
                            <iframe
                              v-if="videoUrl(field)"
                              :id="field.uid"
                              v-lazy="videoUrl(field)"
                              style="
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                              "
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"                              
                              frameborder="0"
                              allowfullscreen
                            />
                            <div v-else style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
                              <img src="/img/icons/simple_ending_icon_square_transparent.png" width="50%" />
                              Video coming soon!
                            </div>
                          </div>

                          <br />

                          <h4>
                            {{ expressionator(field.content, formFields) }}
                          </h4>

                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-else-if="field.type === 'paragraph'">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="my-1 p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <p :class="'text-' + field.align">
                            {{ expressionator(field.content, formFields) }}
                          </p>
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-else-if="field.type === 'subheading'">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="my-2 p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <h3 :class="['text-center', field.align && 'text-' + field.align]">
                            {{ expressionator(field.content, formFields) }}
                          </h3>
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-else-if="field.type === 'heading'">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="my-2 p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <h1 :class="['text-center', field.align && 'text-' + field.align]">
                            {{ expressionator(field.content, formFields) }}
                          </h1>
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-if="field.type === 'date-picker'">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />

                          <label :for="field.uid">
                            {{ expressionator(field.label, formFields) }}
                          </label>

                          <v-text-field
                            v-if="field.variant === 'single-date' && field.monthPicker === false"
                            :id="field.uid"
                            placeholder="MM/DD/YYYY"
                            :readonly="readOnly"
                            :disabled="disableChecker(field)"
                            :value="field.dateValue"
                            :title="expressionator(field.label, formFields)"
                            :rules="rulesStatus(field)"
                            :error="answerStatus(field)"
                            :required="field.required"
                            autocomplete="chrome-off"
                            :clearable="readOnly === false"
                            outlined
                            @focus="$emit('ignoreFormDataChanges', true)"
                            @blur="handleDateChanges(field, $event.target.value, updateFieldAction)"
                          />
                          
                          <div 
                            v-if="field.variant === 'single-date' && field.monthPicker === true"
                            :id="field.uid"
                            class="month-picker-container"
                          >
                            <div class="month-picker-form-fields">
                              <v-select
                                :value="getMonthName(field.dateValue)"
                                placeholder="Month"
                                :items="monthsOfTheYear"
                                :readonly="readOnly"
                                :disabled="disableChecker(field)"
                                :rules="rulesStatus(field, 'month')"
                                :error="answerStatus(field)"
                                :required="field.required"
                                clearable
                                outlined
                                class="month-picker-select"
                                @click:clear="inputHandler(field, $event)"
                                @change="handleDateChanges(field, $event, updateFieldAction)"
                              />
                              <v-text-field
                                :value="getYear(field.dateValue)"
                                type="number"
                                placeholder="XXXX"
                                autocomplete="chrome-off"
                                label="Year"
                                :rules="rulesStatus(field, 'year')"
                                :error="answerStatus(field)"
                                :required="field.required"
                                :disabled="disableChecker(field)"
                                clearable
                                outlined
                                class="month-picker-text"
                                @focus="$emit('ignoreFormDataChanges', true)"
                                @blur="handleDateChanges(field, $event.target.value, updateFieldAction)"
                              />
                            </div>
                          </div>

                          <v-text-field
                            v-if="field.variant === 'multiple-dates'"
                            :id="field.uid"
                            :value="field.dateMultiple"
                            :title="expressionator(field.label, formFields)"
                            :rules="rulesStatus(field)"
                            :error="answerStatus(field)"
                            append-icon="mdi-calendar"
                            autocomplete="chrome-off"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :clearable="readOnly === false"
                            outlined
                            @blur="autoSave(field)"
                            @click:clear="inputHandler(field, null)"
                          />

                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-else-if="field.type === 'dialog-window'">
                        <div class="p-relative">
                        <v-btn
                          :class="{ 'd-none': displayStatus(field) }"
                          color="primary"
                          @click="field.dialog = true"
                        >
                          OPEN {{ field.title }} FORM ...
                        </v-btn>
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <v-dialog
                            v-model="field.dialog"
                            class="dialog-window"
                            max-width="75%"
                            :retain-focus="false"
                            :persistent="field.required"
                            @input="field.dialog = false"
                          >
                            <v-card>
                              <v-card-title class="headline">
                                {{ expressionator(field.heading, formFields) }}
                                <v-btn
                                  class="closeDialogBtn"
                                  title="Close Dialog Window"
                                  icon
                                  @click="field.dialog = false"
                                >
                                  <v-icon>clear</v-icon>
                                </v-btn>
                              </v-card-title>
                              <v-divider />

                              <v-card-text>
                                {{ expressionator(field.text, formFields) }}
                                <DialogQuestions :id="`dialog-questions-${field.uid}`" :dialog-fields="field.dialogFields" :preview-mode="true" />
                                <iframe :id="`dialog-questions-print-${field.uid}`" style="width:100%;display:none;"></iframe>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer />
                                <v-btn
                                  color="primary"
                                  title="Print"
                                  @click="printDialog(field.uid)"
                                >
                                  <v-icon>print</v-icon>
                                </v-btn>
                                <v-btn
                                  v-for="(item, index) in field.content"
                                  :key="index"
                                  @click="dialogWindowSelectionMade(field, item)"
                                >
                                  {{ expressionator(item, formFields) }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </div>
                      </template>

                      <template v-else-if="field.type === 'item-table' && field.visible">
                        <div
                          :class="{ 'd-none': displayStatus(field) }"
                          class="my-4 py-4 p-relative"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <v-row justify="space-around" :key="index">
                            <v-col cols="12" :id="`item-table-field-${field.uid}`">
                              <v-data-table
                                v-if="field.variant === 'read-only'"
                                :headers="
                                  itemTableReadOnlyHeaders(field.headers)
                                "
                                :items="field.readOnlyRows"
                                class="elevation-1"
                              >
                                <template v-slot:top>
                                  <v-toolbar flat>
                                    <v-subheader>{{expressionator(field.label, formFields)}}</v-subheader>
                                    <v-spacer></v-spacer>
                                  </v-toolbar>
                                </template>
                                <template v-slot:body="{ items }">
                                  <tbody>
                                    <tr
                                      v-for="(item, rIndex) in items"
                                      :key="rIndex"
                                      class
                                    >
                                      <td
                                        v-for="(header,
                                        hIndex) in itemTableRows(
                                          field.headers
                                        )"
                                        :key="hIndex"
                                        :class="`text-${header.align}`"
                                      >
                                        <template v-if="header.variant !== 'money'">
                                          {{ expressionator(item[header.value], formFields) }}
                                        </template>
                                        <template v-else>
                                          ${{ formatMoney(item[header.value]) }}
                                        </template>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-data-table>
                              <v-data-table
                                v-else
                                :headers="field.headers"
                                :items="field.inputRows"
                                class="elevation-1"
                              >
                                <template v-slot:top>
                                  <v-toolbar flat>
                                    <v-subheader>{{expressionator(field.label, formFields)}}</v-subheader>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      :id="field.uid"
                                      color="primary"
                                      dark
                                      class="mb-2"
                                      v-bind="$attrs"
                                      @click="itemTableAddNewRow(field)"
                                    >
                                      New Row
                                    </v-btn>
                                  </v-toolbar>
                                </template>
                                <template v-slot:body="{ items }">
                                  <tbody>
                                    <tr
                                      v-for="(item, rIndex) in items"
                                      :key="rIndex"
                                      class
                                    >
                                      <v-dialog
                                        v-model="itemTableDeleteItemDialog"
                                        :retain-focus="false"
                                        max-width="500px"
                                      >
                                        <v-card>
                                          <v-card-title class="headline">
                                            Are you sure you want to delete this item?
                                          </v-card-title>
                                          <v-card-actions style="justify-content: right;">
                                            <v-spacer />
                                            <v-btn
                                              color="primary"
                                              @click="itemTableDeleteItemConfirm()"
                                            >
                                              OK
                                            </v-btn>                                    
                                            <v-btn
                                              color="primary darken-1"
                                              text
                                              @click="itemTableCloseDeleteDialog"
                                            >
                                              Cancel
                                            </v-btn>
                                            <v-spacer />
                                          </v-card-actions>
                                        </v-card>
                                      </v-dialog>
                                      <td
                                        v-for="(header, hIndex) in itemTableRows(field.headers)"
                                        :key="hIndex"
                                        :class="`text-${header.align}`"
                                      >
                                        <template v-if="header.variant !== 'money'">
                                          {{ expressionator(item[header.value], formFields) }}
                                        </template>
                                        <template v-else>
                                          ${{ formatMoney(item[header.value]) }}
                                        </template>
                                      </td>
                                      <td v-if="previewMode === false">
                                        <v-icon
                                          small
                                          class="mr-2"
                                          @click="itemTableEditItem(item, rIndex, field)"
                                        >
                                          mdi-pencil
                                        </v-icon>
                                        <v-icon
                                          small
                                          @click="
                                            itemTableDeleteItem(
                                              item,
                                              rIndex,
                                              field,
                                            )
                                          "
                                        >
                                          mdi-delete
                                        </v-icon>
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-data-table>
                              <sub v-if="field.variant !== 'read-only' && field.required">
                                You must enter at least one row
                              </sub>
                            </v-col>
                          </v-row>
                          <br v-if="field.spaceAfter" />
                        </div>
                      </template>

                      <template v-else-if="field.type === 'embedded-image'">
                        <div
                          class="p-relative embedded-image-container elevation-2"
                          :class="{ 'd-none': displayStatus(field) }"
                        >
                          <HelpIcon
                            v-if="
                              field.helpData &&
                                (field.helpData.text ||
                                  field.helpData.youtubeLink)
                            "
                            :value="field.helpData"
                            :field-id="field.uid"
                          />
                          <v-row>
                            <template
                              v-if="field.variant === 'image-carousel'"
                            >
                              <v-col v-if="!!field.label" cols="12">
                                <p>
                                  {{
                                    expressionator(field.label, formFields)
                                  }}
                                </p>
                              </v-col>
                              <v-col
                                cols="12"
                                v-if="
                                  ['multi-select', 'single-select'].indexOf(
                                    field.interactionType
                                  ) > -1
                                "
                              >
                                <v-select
                                  v-if="
                                    field.interactionType === 'single-select'
                                  "
                                  :id="field.uid"
                                  :value="field.selectionSingle"
                                  label="Use this to select an image from the carousel below."
                                  :items="field.imageRefs"
                                  :error="answerStatus(field)"
                                  item-text="title"
                                  return-object
                                  :clearable="readOnly === false"
                                  outlined
                                  @click:clear="inputHandler(field, $event)"
                                  @input="inputHandler(field, $event)"
                                />
                                <v-select
                                  v-if="
                                    field.interactionType === 'multi-select'
                                  "
                                  :id="field.uid"
                                  :value="field.selectionMultiple"
                                  label="Use this to select multiple images from the carousel below."
                                  :items="field.imageRefs"
                                  :error="answerStatus(field)"
                                  multiple
                                  item-text="title"
                                  return-object
                                  :clearable="readOnly === false"
                                  outlined
                                  @click:clear="inputHandler(field, $event)"
                                  @input="inputHandler(field, $event)"
                                />
                              </v-col>
                              <v-col cols="12">
                                <v-carousel
                                  :id="field.uid"
                                  :show-arrows-on-hover="true"
                                  hide-delimiters
                                  height="100%"
                                  class="elevation-4"
                                >
                                  <v-carousel-item
                                    v-for="(image, i) in field.imageRefs"
                                    :key="i"
                                    reverse-transition="fade-transition"
                                    transition="fade-transition"
                                  >
                                    <v-card
                                      class="fill-height"
                                      justify="center"
                                      v-if="!!image"
                                    >
                                      <v-img :src="image.url" />
                                      <v-divider />
                                      <v-card-title
                                        v-if="!!image && image.title"
                                      >
                                        {{
                                          expressionator(
                                            image.title,
                                            formFields
                                          )
                                        }}
                                        <v-spacer />
                                        <v-checkbox
                                          v-if="
                                            !!image &&
                                              !!image.title &&
                                              field.interactionType ===
                                                'single-select'
                                          "
                                          disabled
                                          label="Selected?"
                                          :input-value="
                                            embeddedImageCheckSelection(
                                              field,
                                              image,
                                              'single-select'
                                            )
                                          "
                                        />
                                        <v-checkbox
                                          v-if="
                                            !!image &&
                                              !!image.title &&
                                              field.interactionType ===
                                                'multi-select'
                                          "
                                          disabled
                                          label="Selected?"
                                          :input-value="
                                            embeddedImageCheckSelection(
                                              field,
                                              image,
                                              'multi-select'
                                            )
                                          "
                                        />
                                      </v-card-title>
                                      <v-card-text
                                        v-if="
                                          (!!image && image.description) ||
                                            [
                                              'multi-select',
                                              'single-select',
                                            ].indexOf(field.interactionType) >
                                              -1
                                        "
                                      >
                                        <v-divider
                                          v-if="
                                            !!image &&
                                              image.description &&
                                              [
                                                'multi-select',
                                                'single-select',
                                              ].indexOf(
                                                field.interactionType
                                              ) > -1
                                          "
                                          class="mx-4 my-2"
                                        />
                                        <div
                                          v-if="!!image && image.description"
                                        >
                                          {{
                                            expressionator(
                                              image.description,
                                              formFields
                                            )
                                          }}
                                        </div>
                                      </v-card-text>
                                      <v-card-actions>
                                        <v-btn @click="printEmbeddedImage(image)">
                                          <v-icon>print</v-icon>
                                          Print
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-carousel-item>
                                </v-carousel>
                              </v-col>
                            </template>
                          </v-row>
                        </div>
                      </template>
                    </div>
                      <v-card
                        class="text-left red lighten-4"
                        v-if="
                          isOwner &&
                            field.reviews &&
                            Object.keys(field.reviews).length > 0
                        "
                      >
                        <v-card-title>Reviews</v-card-title>
                        <v-list-item
                          two-line
                          v-for="review in field.reviews"
                          :key="review.email"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="
                                review.name +
                                  ' (' +
                                  new Date(review.time)
                                    .toISOString()
                                    .slice(0, 10) +
                                  ')'
                              "
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="review.data"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <div class="text-center">
                              <v-btn
                                x-small
                                color="primary"
                                dark
                                @click="
                                  processAcceptReview(
                                    field.fieldsIndex,
                                    review.data
                                  )
                                "
                              >
                                Accept
                              </v-btn>
                              <v-btn
                                x-small
                                color="error"
                                dark
                                @click="
                                  processDeclineReview(
                                    field.fieldsIndex,
                                    review.email
                                  )
                                "
                              >
                                Decline
                              </v-btn>
                            </div>
                          </v-list-item-action>
                        </v-list-item>
                      </v-card>
                      <br
                        v-if="
                          isOwner &&
                            field.reviews &&
                            Object.keys(field.reviews).length > 0
                        "
                      />
                      <!-- Start Comments list -->
                      <v-card
                        v-if="
                          isOwner &&
                            field.comments &&
                            Object.keys(field.comments).length > 0
                        "
                        class="text-left green lighten-4"
                      >
                        <v-card-title>Comments</v-card-title>
                        <v-list-item
                          two-line
                          v-for="comment in field.comments"
                          :key="comment.email"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="
                                comment.name +
                                  (comment.time
                                    ? ' (' +
                                      new Date(comment.time)
                                        .toISOString()
                                        .slice(0, 10) +
                                      ')'
                                    : '')
                              "
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="comment.text"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                      <br
                        v-if="
                          isOwner &&
                            field.comments &&
                            Object.keys(field.comments).length > 0
                        "
                      />
                      <!-- End Comments list -->
                    </template>
                  </v-col>
                  <v-col
                    v-show="fill === false"
                    cols="1"
                  />
              </v-row>
            </v-tab-item>
            </v-tabs-items>
            <div class="published-workflow-side-buttons">

              <workflow-help-dialog/>

              <v-btn
                v-if="isOutDated"
                @click="updateForm"
                class="a-btn"
                fab
                color="error"
                title="Update"
              >
                <v-icon>update</v-icon>
              </v-btn>

              <a
                id="downloadFormDataLink"
                style="display:none;"
                download="fields.json"
              >
                Download Form Data
              </a>

              <v-btn
                v-if="isAdmin || isProd === false"
                @click="downloadFormData"
                class="a-btn"
                fab
                color="error"
                title="Download form data"
              >
                <v-icon>download</v-icon>
              </v-btn>

              <v-btn
                v-if="isAdmin || isProd === false"
                @click="uploadFormData"
                class="a-btn"
                fab
                color="error"
                title="Upload form data"
              >
                <v-icon>upload</v-icon>
              </v-btn>

              <WarningModal
                v-if="visibleRequiredFields?.length"
                :pages="pageNames"
                :errors="tabsWarnings"
                :hasDisplayLogic="hasDisplayLogic"
                :emptyRequired="pages"
                :getTipsTrigger="activateWarningDialog"
                class="a-btn"
                @modalClose="hideWarBtn"
              />

              <v-file-input
                id="uploadFormData"
                :clearable="readOnly === false"
                accept="text/json, application/json"
                style="display:none;"
                @change="uploadJsonFields"
              />

              <CommentSheet
                :disable-comments="disableComments"
                :totalPages="Object.keys(pages).length"
                :show-default="(formType === 'petitioner' && step === 3) || (formType === 'respondent' && step === 1)"
                :pageNames="pageNames"
                :current-tab="currentTab"
                :commentButton="sharedUserProps"
                :isOwner="isOwner"
                :comment-owner-name="commentOwnerName"
                @change-tab="currentTab = $event"
                @limit-exceeded="$emit('limit', $event)"
                :current-step="currentStep"
              />

              <CommentChanges
                v-if="commentChangesActive && unreadComments != 0"
                :totalPages="Object.keys(pages).length"
                :show-default="(formType === 'petitioner' && step === 3) || (formType === 'respondent' && step === 1)"
                :pageNames="pageNames"
                :current-tab="currentTab"
                :commentButton="sharedUserProps"
                :isOwner="isOwner"
                :comment-owner-name="commentOwnerName"
                @change-tab="currentTab = $event + 1"
                @limit-exceeded="$emit('limit', $event)"
                :current-step="currentStep"
              />
            </div>
            <CommentMessage :show="showCommentMessage" @close-dialog="showCommentMessage = false" />
            <v-dialog
              v-if="previewMode === false && itemTableEditItemDialog"
              v-model="itemTableEditItemDialog"
              :retain-focus="false"
              max-width="800"
            >
              <TableRowEditForm
                v-if="previewMode === false"
                :row-title="itemTableRowTitle"
                :headers="currentField.headers"
                :data="editedItem"
                :fields="formFields"
                :read-only="currentField.variant === 'read-only'"
                @cancel="itemTableCloseDialog"
                @save="
                  itemTableSaveItem(
                    currentField,
                    currentField.variant === 'read-only'
                      ? 'readOnlyRows'
                      : 'inputRows'
                  )
                "
              />
            </v-dialog>
        </v-form>
        </v-col>
      </v-row>
      <div>
        <!--  Next & Previous Buttons  -->
        <v-row class="footer-btns py-2" v-if="!previewMode">
          <v-col cols="4">
            <v-btn
              v-if="!(currentTab === 0)"
              color="primary"
              large
              :disabled="currentTab === 0"
              dark
              @click="previousTab">
              Previous
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              v-if="!(currentTab === Object.keys(pages).length - 1)"
              color="primary"
              large
              :disabled="currentTab === Object.keys(pages).length - 1"
              dark
              @click="nextTab">
              Next
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              v-if="canSave"
              @click="saveClick"
              dark
              large
              color="primary"
              title="Save Progress"
            >
              <v-icon dark>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!--  Divider & Submit Button  -->
        <v-row
          v-if="
            formFields &&
              !readOnly &&
              !previewMode &&
              parseInt(currentTab) === parseInt(Object.keys(pages).length - 1)
          "
          no-gutters
        >
        </v-row>
      </div>
    </div>
    <div class="py-15" v-else-if="sharedUserProps && sharedUserProps.agreedWithSpouse">
      <h2 class="mb-5">Nice, we made progress</h2>
      <p class="text-success">Your spouse has agreed to everything. Proceed to the next step</p>
    </div>
    <div v-else class="py-15">
      <v-img
        :src="require(`@/assets/images/wait_green.svg`)"
        height="150"
        contain>
      </v-img>
      <h2 class="mt-5 mb-1">Please wait for your spouse...</h2>
      <p class="text-danger">You are not able to make any changes until you get the feedback from your spouse!</p>
    </div>

    <v-snackbar
        v-model="saveSnackbar"
        right
        color="success"
    >

      {{ saveSnackbarText }}

    </v-snackbar>

  </v-container>
</template>

<script>
import workflowGuideMixin from '../mixins/components/workflowGuide.mixin';

export default {
  name: "WorkflowGuide",

  mixins: [workflowGuideMixin]
};
</script>

<style lang="scss">
.v-text-field__suffix {
  margin-right: 40px;
}

.headline {
  position: relative;
  white-space: pre !important;

  .closeDialogBtn {
    position: absolute;
    right: 10px;
  }
}

.embedded-image-container {
  background: #eeeeee;
  padding: 1rem;
  margin: 1rem 0rem;
}

.embed-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.full-height-width {
  width: 100%;
  height: 100%;
}

.background-color-light-grey {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>

<style lang="scss">
  .v-dialog--active {
    overflow:auto;
  }

  .float-nav {
    position: fixed !important;
    z-index:5 !important;
    top: 64px !important;
    left: 25% !important;
    width: 75% !important;
  }

  .lock-btn {
    position:absolute;
    top:60px;
    left:0px;
    z-index:4;
    padding:0px;
    min-width:fit-content;    
  }

  #formFieldContainer {
    .v-input__slot {
      position: static;
    }
  }
  .v-stepper {
    box-shadow: none !important;
  }

  .v-main {
    margin-bottom: 55px !important;
  }

  .form-field-container {
    position: relative;
    margin-top: 20px;
    text-align: left;

    label, header {
      font-weight: 700;
      font-size: 12px;
      text-transform: uppercase;
      color: $dark;
    }

    h3, h4 {
      text-align: center;
    }

    .comment-button {
      right: -45px;
    }
  }

  .workflow-guide {
    margin-bottom: 75px;
    height:100%;
  }

  .v-application .py-2 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .footer-btns {
    position:fixed;
    background-color:white;
    width: 75%;
    height: 80px;
    border: 1px solid silver;
    border-radius: 5px;
    right: 0px;
    margin: 0px;
    bottom: 55px;
    padding-left: 20px;

    button {
      min-width: 120px !important;
    }
  }

  /* this should not be scoped */
  .emailText {
    input {
      text-transform: lowercase !important;
    }
  }

  .v-text-field {
    .v-input__append-inner {
      position: absolute;
      right: 0px;
    }
  }

  .number-input {
    .v-text-field__slot {
      input {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  @media(max-width: 900px) {
    .workflow-guide {
      margin-top: -20px !important;
    }

    .float-nav {
      position: sticky !important;
      width: 100% !important;
      top: 0px !important;
      left: 0% !important;
      z-index: 0 !important;
    }

    .lock-btn {
      display:none; 
    }    
  }

  @media(max-width: 600px) {
    .v-sheet.v-footer {
      position: relative !important;
      display: block !important;
      z-index: 0;
    }

    .form-field-container {
      margin-top: 3px;
    }

    .v-stepper__content {
      padding: 0px !important;
    }

    .footer-btns {
      width: 100%;
      left: 0px;
      padding-left: 0px;
      bottom: 0px;

      button {
        min-width: 100px !important;
      }
    }

    .left-nav {
      .v-stepper {
        box-shadow: none !important;
      }

      .stepper-btn {
        height: 100% !important;
      }
    }
  }
</style>
