<template>
    <div style="padding: 40px;">
        <v-icon style="font-size:200px;" color="warning">mdi-alert-outline</v-icon>
        <header style="font-weight:bold; font-size: 24px; margin-bottom: 20px;">
            Our Apologies
        </header>
        <p>
            Sorry for the inconvenience. 
            Support has been notified and will handle this shortly. 
        </p>
        <ContactSupport />
    </div>
</template>

<script>
import ContactSupport from '@/components/ContactSupport'
import { mapActions } from 'vuex';

export default {
    name: 'Page503',

    components: {
        ContactSupport
    },

    mounted() {
        this.toggleLoadingOverlayStatus(false)
    },

    methods: {
        ...mapActions(['toggleLoadingOverlayStatus'])
    }
}
</script>
