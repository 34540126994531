const { v4: uuidv4 } = require('uuid');

export const blocks = {
    namespaced: true,

    state: () => ({
        blocks: []
    }),

    getters: {
        blocks: (state) => state.blocks.map(b => ({ ...b, uid: b.tag }))
    },

    mutations: {
        SET_BLOCKS(state, payload) {
            state.blocks = payload
        },

        ADD_BLOCK(state, payload) {
            state.blocks.unshift(payload)
        },

        REMOVE_BLOCK(state, index) {
            state.blocks.splice(index, 1)
        },

        UPDATE_BLOCK(state, { index, block }) {
            const tmpBlocks = [...state.blocks]
            tmpBlocks[index] = block
            state.blocks = [...tmpBlocks]
        }
    },

    actions: {
        async setBlocks({ commit, rootState }, { id, collectionName = 'workflows-user' }) {
            const db = rootState.firebaseConfig.db
            const blocks = (await db.collection(collectionName).doc(id).collection('blocks').get()).docs.map(d => ({...d.data()}))
            const data = []
            blocks.forEach(block => {
                if(block?.uid)
                    delete block.uid

                if(!block?.type) {
                    block['type'] = 'question-input'
                }

                if(block?.type === 'question-input' && !block?.value) {
                    block['value'] = {
                        title: block.type.toLowerCase(),
                        uid: uuidv4()
                    }
                }

                data.push(block)
            })
            commit('SET_BLOCKS', data)
            return
        },

        async addBlock({ commit }, payload) {
            commit('ADD_BLOCK', payload)
        },

        async updateBlock({ commit }, payload) {
            commit('UPDATE_BLOCK', payload)
        },

        async deleteBlock({ commit }, index) {
            commit('REMOVE_BLOCK', index)
        }
    }
}