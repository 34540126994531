<template>
  <v-dialog
    v-model="dialog"
    :retain-focus="false"
    @click:outside="$emit('modalClose')"
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        v-show="hasDisplayLogic"
        bordered
        color="error"
        overlap
        offset-x="20"
        title="Remaining required fields"
        id="remaining-required-fields"
        :content="visibleRequiredFields.length"
      >
        <v-btn
          fab
          class="a-btn-w mb-2"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="error">
            error
          </v-icon>
        </v-btn>    
      </v-badge>
    </template>
    <template>
      <v-card id="WarningModal">
        <v-card-title style="position:relative;">
          Required Fields
          <v-btn
            style="margin-left:1px;position:absolute;top:10px;right:0px;"
            icon
            @click="dialog=false, $emit('modalClose')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-1">
          <template v-if="loading === true">
            <div style="margin:20px;">
              <v-progress-circular
                indeterminate
                color="red"
              ></v-progress-circular>
              Loading...
            </div>
          </template>

          <v-container v-else>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-center align-center">
                  <v-icon color="error">
                    error
                  </v-icon>
                  <h1 class="ml-2">Oops, take a look!</h1>
                </div>
                <p class="subtitle-1 mt-2">
                  Additional Attention Required
                  <span class="text-danger font-weight-600">{{visibleRequiredFields.length}} fields</span>
                </p>
                <p class="subtitle-2 font-weight-light mt-5 mb-7">In order to fully complete the questionnaire. You must fill out all required fields.</p>
              </v-col>
            </v-row>
            
            <v-chip
              v-for="(empty, index) in visibleRequiredFields" :key="index"
              :id="`required-field-empty-${empty.uid}`"
              class="ma-1"
              @click="navigate(index, empty)"
              color="red"
              text-color="white"
            >
              {{expressionator(empty.label, requiredFields)}}
            </v-chip>
          </v-container>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import expressionatorMixin from "@/mixins/expressionator.mixin";

export default {
  name: 'WarningModal',

  mixins: [expressionatorMixin],

  data() {
    return {
      tab: null,
      dialog: false,
      errorFields: [],
      loading: true,
    };
  },
  props: {
    hasDisplayLogic: {
      type: Boolean,
      default: false
    },
    emptyRequired: [],
    errors: Object,
    pages: {
      type: Array,
      required: true,
    },
    getTipsTrigger: Boolean
  },

  watch: {
    getTipsTrigger: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.dialog = newValue
      }
    },

    visibleRequiredFields(newVal, oldVal) {            
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  },

  computed: {
    ...mapGetters("workflowPublished", ["requiredFields"]),

    ...mapGetters('displayLogic', ['visiblyRequired']),

    visibleRequiredFields() {
      return this.visiblyRequired(this.requiredFields).filter(f => f.answeredStatus === false)
    },
  },

  methods: {
    navigate(index, error) {
      const tab = document.getElementById("tab-" + (error.page - 1));
      this.close();
      tab.click()
      this.$nextTick(() => this.focusIt(error))
    },

    focusIt({uid}) {
      setTimeout(() => {
        const input = document.getElementById(uid);
        if (input) {
          // Scroll to element
          input.scrollIntoView({ behavior: 'smooth', block: 'center' });
          input.focus();

          // Add focus-error class to element to highlight it
          input.parentElement.classList.add('focus-error');

          // Now remove the focus-error class
          setTimeout(() => {
            input.parentElement.classList.remove('focus-error');
          }, 1500);
        } else {
          this.$logger.warn('no input found')
        }
      }, 1000);
    },

    close() {
      this.dialog = false;
    }
  },
};
</script>

<style lang="scss">
#WarningModal{
  .v-chip__content {
    max-width:525px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    height: auto;
  }
}
</style>