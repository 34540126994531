<template>
  <v-card tile id="file-renderer">
    <v-card-title v-if="!hideTitle" class="bg-primary white--text">
      <slot name="card-title">File Renderer</slot>
    </v-card-title>
    <v-divider />
    <v-card-text id="dfr-pdf-container" ref="dfr-pdf-container">
      <v-row justify="space-around">
        <v-btn id="prev" outlined style="width: 110px" @click="onPrevPage">
          <slot name="previous-page">Previous</slot>
        </v-btn>
        <span id="paging-text">
          Page:
          <span id="dfr-page-num">{{ pageNum }}</span> /
          <span id="page_count">{{ totalPageNum }}</span>
        </span>
        <v-btn id="next" outlined style="width: 110px" @click="onNextPage">
          <slot name="next-page">Next</slot>
        </v-btn>
      </v-row>

      <div
        class="canvas-container"
        ref="canvas-container"
        style="position: relative; margin-top: 1.5rem; border: 1px solid gray"
      >
        <slot name="vue-resizable" />
        <canvas :id="`dfr-canvas-${checker}`" ref="dfr-canvas" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import pdfjsLib from "pdfjs-dist";
import { mapState } from "vuex";

export default {
  name: "FileRenderer",

  props: {
    pdfFile: {
      required: false,
      type: Blob,
      default: null,
    },

    hideTitle: {
      required: false,
      type: Boolean,
      default: false,
    },

    docId: {
      type: Number,
      default: -1,
    },

    checker: {
      type: Number,
      default: -1,
    },

    currentPageNum: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      pdfDoc: null,
      pageNum: 1,
      totalPageNum: 1,
      pageRendering: false,
      pageNumPending: null,
      scale: 0.5,
    };
  },

  computed: {
    ...mapState(["formBuilderDialog"]),
  },

  watch: {
    pdfFile: function (newVal, oldVal) {
      this.renderPdfDocument(newVal);
    },

    checker: function (newVal) {
      this.pageNum = this.currentPageNum;
      //this.renderPdfDocument(this.pdfFile);
      this.queueRenderPage(this.pageNum);
    },

    currentPageNum: function (newVal) {
      if (this.pageNum != newVal) {
        this.pageNum = newVal;
      }
    },
  },

  mounted() {
    this.renderPdfDocument(this.pdfFile);
  },

  methods: {
    async renderPdfDocument(filePath) {
      if (!filePath) return;
      // If absolute URL from the remote server is provided, configure the CORS
      // header on that server.
      const url = URL.createObjectURL(filePath);
      /**
       * Asynchronously downloads PDF.
       */

      this.pdfDoc = await pdfjsLib.getDocument(url);
      this.pageNum = this.currentPageNum;
      this.totalPageNum = this.pdfDoc._pdfInfo.numPages;

      // Initial/first page rendering
      this.queueRenderPage(this.pageNum);

      // window.onresize = this.queueRenderPage(this.pageNum);
    },

    renderPage(num) {
      /**
       * Get page info from document, resize canvas accordingly, and render page.
       * @param num Page number.
       */
      let canvas, ctx;
      try {
        canvas = this.$refs["dfr-canvas"];
        ctx = canvas.getContext("2d");
      } catch {
        return;
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      this.pageRendering = true;
      // Using promise to fetch the page
      this.pdfDoc.getPage(num).then((page) => {
        const dfrPdfContainer = this.$refs["dfr-pdf-container"];
        const vCardTextPadding =
          parseFloat(
            window.getComputedStyle(dfrPdfContainer, null).paddingRight
          ) * 2;

        // Width of pdf canvas is width of pdf_container element minus padding of the v-card-text element minus 2 pixels for the border of the canvas
        const canvasWidth =
          parseFloat(dfrPdfContainer.clientWidth) - vCardTextPadding - 2;

        let viewport = page.getViewport({ scale: this.scale });

        const pdfScale = canvasWidth / parseFloat(viewport.viewBox[2]);

        this.scale = pdfScale;

        viewport = page.getViewport({ scale: this.scale });

        const pdfAspectRatio =
          parseFloat(viewport.height) / parseFloat(viewport.width);

        canvas.width = canvasWidth;
        canvas.height = pdfAspectRatio * canvasWidth;

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        const renderTask = page.render(renderContext);

        // Wait for rendering to finish
        renderTask.promise.then(() => {
          this.pageRendering = false;
          if (this.pageNumPending !== null) {
            // New page rendering is pending
            this.renderPage(this.pageNumPending);
            this.pageNumPending = null;
          }
        });
      });
    },

    queueRenderPage(num) {
      /**
       * If another page rendering in progress, waits until the rendering is
       * finised. Otherwise, executes rendering immediately.
       */

      if (this.pageRendering) {
        this.pageNumPending = num;
      } else {
        this.renderPage(num);
      }
    },

    onPrevPage() {
      /**
       * Displays previous page.
       */

      if (this.pageNum <= 1) {
        return;
      }

      this.pageNum--;
      this.$emit("page-change", this.pageNum);
      this.queueRenderPage(this.pageNum);
    },

    onNextPage() {
      /**
       * Displays next page.
       */

      if (this.pageNum >= this.pdfDoc.numPages) {
        return;
      }

      this.pageNum++;
      this.$emit("page-change", this.pageNum);
      this.queueRenderPage(this.pageNum);
    },
  },
};
</script>

<style lang="scss">
#dfr-canvas {
  border: solid black 1px;
}

#file-renderer {
  #paging-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
