const axios = require("axios");
const { getProject } = require("../services/gcloud/getProject");

const getSecret = async (key) => {
  const secret = await axios({
    method: "GET",
    url: `${getProject()}/getSecret?key=${key}`,
  });
  return secret.data;
};

module.exports = getSecret;
