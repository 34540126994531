import { getDoc, doc, updateDoc, setDoc } from "firebase/firestore";

const defaultState = () => {
  return {
    commentsSavedCounter: 0,
    comments: []
  };
};

export const comments = {
  namespaced: true,
  state: defaultState(),

  mutations: {
    UPDATE_COMMENTS_SAVED_COUNTER(state) {
      state.commentsSavedCounter++;
    },

    SET_COMMENTS(state, payload) {
      state.comments = payload;
    },

    RESET_ALL_COMMENTS(state) {
      state.commentsSavedCounter = 0;
      state.comments = [];
    },
  },

  getters: {
    spouseComments: (state) => state?.comments || [],

    unreadCommentsByPage:
      (state) =>
      ({ type, pageIndex }) => {
        const spouseComments = state.comments
          .filter((c) => c.commentOwner === type)
          .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
        const latestComment = spouseComments[0]?.comments[pageIndex];
        return latestComment?.hasRead === false || false;
      },

    hasUnreadComments: (state) => (type) => {
      if (state.comments.length === 0) return 0;
      return (
        state.comments
          ?.filter((c) => c.commentOwner === type)
          .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)[0]
          ?.comments.filter((c) => c?.hasRead === false).length || 0
      );
    },

    commentsSavedCounter: (state) => state.commentsSavedCounter,
  },

  actions: {
    setComments({ commit }, payload) {
      commit("SET_COMMENTS", payload);
    },

    async updateSpouseComments({ rootState }, { docId, commentId, comments }) {
      await rootState.firebaseConfig.db
        .collection("workflows-user")
        .doc(docId)
        .collection("spouse-comments")
        .doc(commentId)
        .set({ comments }, { merge: true });
      return
    },

    async disableEditingComments({ rootState }, docId) {
      const docRef = doc(rootState.firebaseConfig.db, "workflows-user", docId);
      const getData = await getDoc(docRef);
      const dataFromDoc = getData.data();
      let sharingUserEmail = dataFromDoc.sharingProps[0];
      sharingUserEmail.commentsDisabled = true;

      try {
        await updateDoc(docRef, { sharingProps: [sharingUserEmail] });
        return "success";
      } catch (error) {
        return "error";
      }
    },

    async saveSpouseComments({ commit, rootState, rootGetters }, payload) {
      const docId = rootGetters["workflowPublished/getFormData"].id;
      const docRef = doc(
        rootState.firebaseConfig.db,
        "workflows-user",
        docId,
        "spouse-comments",
        payload.commentId
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, { comments: payload.comments });
      } else {
        const newPayload = {
          ...payload,
          createdAt: new Date(),
          commentOwner: payload.commentOwner,
          commentOwnerName: payload.commentOwnerName,
        }
        await setDoc(docRef, newPayload);
      }
      return "success";
    },

    async getSpouseComments({ rootState, rootGetters, commit }) {
      if (!rootGetters["workflowPublished/getFormData"].id) return [];
      const docId = rootGetters["workflowPublished/getFormData"].id;
      const db = rootState.firebaseConfig.db;

      try {
        let comments = (await db
          .collection("workflows-user")
          .doc(docId)
          .collection("spouse-comments")
          .orderBy("createdAt", "desc")
          .get()).docs.map((doc) => ({ ...doc.data(), commentId: doc.id }))
        commit("SET_COMMENTS", comments);
      } catch (error) {
        console.error(error);
      }
      return;
    },

    async getCommentHistory({ rootState }, docId) {
      let comments = (await rootState.firebaseConfig.db
        .collection("workflows-user")
        .doc(docId)
        .collection("spouse-comments")
        .orderBy("createdAt", "asc")
        .get()).docs.map(doc => ({ ...doc.data(), commentId: doc.id }))
      const activeComments = comments.pop(); // removed active comment
      return comments;
    },

    async enableEditingComments({ rootState }, docId) {
      const docRef = doc(rootState.firebaseConfig.db, "workflows-user", docId);
      const getData = await getDoc(docRef);
      const dataFromDoc = getData.data();
      let sharingUserEmail = dataFromDoc.sharingProps[0];
      sharingUserEmail.commentsDisabled = false;

      try {
        await updateDoc(docRef, { sharingProps: [sharingUserEmail] });
        return "success";
      } catch (error) {
        return "error";
      }
    },
  },
};
