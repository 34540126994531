<template>
  <div>
    <v-dialog
      v-model="helpDialog"
      max-width="600px"
    >
      <v-card
        class="mx-auto text-left"
        tile
      >
        <v-card-title>How Can We Help You?</v-card-title>
        <v-list-item two-line class="ms-2">
          <v-list-item-content>
            <v-list-item-title class="font-weight-600 mb-3">Need Legal Advice?</v-list-item-title>
            <v-list-item-subtitle class="text-wrap">
              <div style="width:fit-content; float:left;margin-right:10px;">
                <v-img src="/img/icons/knot-to-not.png" width="75px"/>
              </div>
              <div style="padding-left:10px;">
                <p class="mb-1 mt-1 small">Need help working out one or two issues on your do-it-yourself
                  divorce? Knot til Not is here for you. We give you the legal advice you need to keep your
                  divorce simple and keep costs low.
                </p>
                <a href="https://knottilnot.com/" target="_blank" class="text-decoration-none font-weight-600">
                  Click here for advice
                </a>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line class="ms-2">
          <v-list-item-content>
            <v-list-item-title class="font-weight-600 mb-3">Looking for answers? Contact us!</v-list-item-title>
            <v-list-item-subtitle>
              <div style="width:fit-content; float:left;margin-right:10px;">
                <v-img src="/img/SimpleEnding_just_logo.png" width="75px"/>
              </div>
              <div>
                <p class="mb-1 mt-1 small text-wrap">You can find answers to many of your questions in SimpleEnding’s
                  FAQ’s page.
                </p>
                <a href="https://simpleending.com/contact-us/" target="_blank" class="text-decoration-none font-weight-600">
                  Click here for answers
                </a>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions class="d-flex justify-end mt-3">
          <v-btn color="primary" @click="helpDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-badge
      bordered
      color="primary"
      overlap
      offset-x="20"
      content="?"
    >
      <v-btn
        @click="helpClick"
        class="a-btn"
        fab
        style="padding:10px;"
        title="Need Help?"
      >
        <v-img src="/img/icons/knot-to-not.png" width="10px" />
      </v-btn>
    </v-badge>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        helpDialog: false,
      }
    },

    methods: {
      helpClick() {
        this.helpDialog = true
      },
    }
  }

</script>
