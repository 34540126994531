<template>
  <!-- Add form collaborators dialog -->
  <v-dialog
    v-model="shareFormMetadata.collaboratorDialog"
    :retain-focus="false"
    scrollable
    max-width="400px"
  >
    <v-card>
      <v-card-title>Collaborators</v-card-title>
      <v-divider />

      <v-card-subtitle>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model="shareFormMetadata.userToAdd"
              label="Add Collaborators"
            >
              <template v-slot:append-outer>
                <v-btn text @click="addCollaborator">
                  <v-icon>add_circle</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-list-item-content>
        </v-list-item>
      </v-card-subtitle>

      <v-divider />

      <v-card-text style="height: 300px">
        <v-list id="collaborators">
          <div class="row">
            <v-subheader class="header1">Collaborators</v-subheader>
            <v-subheader class="header2">Stakeholder?</v-subheader>
          </div>
          <v-list-item v-for="(email, index) in collaborators" :key="index">
            <v-list-item-content>
              <v-list-item-title>{{ email }}</v-list-item-title>
              <v-list-item-subtitle>
                <div class="stakeholder">
                  <input
                    type="checkbox"
                    :checked="isStakeHolder(email)"
                    @change="setStakeHolder($event, email)"
                  />
                </div>
                <v-btn
                  icon
                  outlined
                  color="red"
                  title="Delete?"
                  @click="deleteCollaborator(index)"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions style="justify-content: right;">
        <v-btn color="primary" @click="shareFormUpdate">Save </v-btn>
        <v-btn
          color="primary darken-1"
          text
          @click="shareFormMetadata.collaboratorDialog = false"
          >Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import sendEmailUtil from '@/utils/send-email.util'

export default {
  name: "CollaboratorsDialog",

  props: {
    shareFormMetadata: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    collaborators() {
      return this.shareFormMetadata.access;
    },

    isStakeHolder() {
      return (collaborator) =>
        this.shareFormMetadata.stakeholders.findIndex(
          (s) => s.email === collaborator
        ) >= 0;
    },
  },

  methods: {
    ...mapActions([
      "updateFormAccessAndStakeHolders",
      "toggleLoadingOverlayStatus",
    ]),

    ...mapActions("confirmation", ["confirm"]),

    addCollaborator() {
      const collaborator = {
        email: this.shareFormMetadata.userToAdd,
        sent: false,
      };
      this.shareFormMetadata.access.push(this.shareFormMetadata.userToAdd);
      this.shareFormMetadata.collaborators.push(collaborator);
      this.shareFormMetadata.stakeholders.push({
        email: this.shareFormMetadata.userToAdd,
        status: "",
      });
      this.shareFormMetadata.userToAdd = "";
    },

    setStakeHolder(event, email) {
      if (event.target.checked) {
        const index = this.shareFormMetadata.stakeholders.findIndex(
          (s) => s.email === email
        );
        if (index < 0) {
          this.shareFormMetadata.stakeholders.push({ email, status: "" });
        }
      } else {
        this.deleteStakeHolder(email);
      }
    },

    deleteStakeHolder(email) {
      this.confirm({
        title: 'Are you sure you want to remove this stake holder?',
        accepted: () => {
          const index = this.shareFormMetadata.stakeholders.findIndex(
            (s) => s.email === email
          );
          if (index >= 0) {
            this.shareFormMetadata.stakeholders.splice(index, 1);
            this.$forceUpdate();
          }
        }
      })
    },

    async shareFormUpdate() {
      const shareData = {
        id: this.shareFormMetadata.id,
        index: this.shareFormMetadata.index,
        access: this.shareFormMetadata.access,
        stakeholders: this.shareFormMetadata.stakeholders,
        collaborators: this.shareFormMetadata.collaborators,
      };
      this.updateFormAccessAndStakeHolders(shareData);      
      this.shareFormMetadata.collaboratorDialog = false;
      await this.sendEmailToCollaborators();
    },

    matchCollaborators(sent = false) {
      if (
        this.shareFormMetadata.access.length >
        this.shareFormMetadata.collaborators.length
      ) {
        this.shareFormMetadata.access.forEach((email) => {
          const collaboratorIndex =
            this.shareFormMetadata.collaborators.findIndex(
              (c) => c.email === email
            );
          if (collaboratorIndex < 0) {
            this.shareFormMetadata.collaborators.push({
              email,
              sent,
            });
          }
        });
      }
    },

    async sendEmailToCollaborators() {
      return new Promise(async (resolve, reject) => {
        const baseUrl = `${location.protocol}//${location.host}`;
        this.matchCollaborators();
        const collaboratorsToEmail =
          this.shareFormMetadata.collaborators.filter((c) => c.sent === false);
        if (collaboratorsToEmail.length === 0) resolve();
        collaboratorsToEmail.forEach((collaborator, i) => {
          const user = {
            email: collaborator.email,
            name: collaborator.name
          }
          sendEmailUtil(baseUrl, user, 'collaborate', 'Divorce Form Approval')
            .then((status) => {
              collaborator.sent = true;
              this.$logger.info(`Email sent to ${collaborator.email} with as collaborator with status of: ${status}`);
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      });
    },

    deleteCollaborator(index) {
      const email = this.shareFormMetadata.access[index];
      this.confirm({
        title: `Are you sure you want to delete ${email}?`,
        accepted: () => {
          this.shareFormMetadata.access.splice(index, 1);
          this.shareFormMetadata.collaborators.splice(index, 1);
          index = this.shareFormMetadata.stakeholders.findIndex(
            (s) => s.email === email
          );
          this.shareFormMetadata.stakeholders.splice(index, 1);
          }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  padding: 0 20px;

  #collaborators {
    .v-list-item {
      padding: 0px;
      border: 1px solid white;
    }

    .full-header {
      width: 100%;
    }

    .header1 {
      text-align: left;
    }

    .header2 {
      text-align: right;
    }

    .v-subheader {
      padding: 10px 0;
      flex: 1 1 auto;
      height: fit-content;
      display: inline;
    }

    .v-list-item__title {
      text-align: left;
      flex: 2 2 auto;
    }

    .v-list-item__subtitle {
      text-align: right;
      flex: 1 1 auto;

      .stakeholder {
        display: inline;
        vertical-align: middle;

        input[type="checkbox"] {
          height: 15px;
          width: 15px;
        }
      }

      button {
        margin-left: 10px;
        width: 20px;
        height: 20px;

        .v-icon {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

    .v-list-item__content {
      padding: 0px;
    }
  }
}
</style>