const postIt = require('@/services/http/postIt')
const { getProject } = require('@/services/gcloud/getProject')
const defaultFromAddress = require('@/constants/defaultFromEmail')

const sendEmail = async ({ to, subject, message, from = defaultFromAddress, attachment = null }, httpPost = postIt) => {
    const project = getProject()
    const url = `${project}/sendEmail`;
    const body = {
        project,
        to,
        subject,
        from,
        html: message,
        attachment
    }

    return { type: "sent", status: await httpPost({ url, body }) }
}

module.exports = sendEmail