export default {
  methods: {
    setPartnerContactInfo(fd) {
      if(fd?.fields) {
        this.partner.firstName = fd.fields.find(field => field.title === 'RES_FN')?.content || '';
        this.partner.lastName = fd.fields.find(field => field.title === 'RES_LN')?.content || '';
        this.partner.email = fd.fields.find(field => field.title === 'RES_EADD')?.content || '';
      }
    }
  }
}
