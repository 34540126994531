<template>
  <v-container class="fill-height">
    <v-row class="text-center">
      <v-col cols="12">
        Redirecting...
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedUserMixin from '../mixins/sharedUser.mixin'
import { mapGetters, mapActions } from "vuex";
import getUserByEmail from '../services/get-user-by-email.service';
import analyticsMixin from '../mixins/analytics.mixin';

export default {
  name: "Home",

  mixins: [sharedUserMixin,analyticsMixin],
  
  computed: {
    ...mapGetters(['getFirebaseConfig']),

    ...mapGetters("profile", ["userInfo"]),
  },

  methods: {
    ...mapActions(["toggleLoadingOverlayStatus"]),
    
    ...mapActions("profile", ["establishUserAuth"])
  },

  async created() {
    await Promise.all([
      this.toggleLoadingOverlayStatus(true),
      this.establishUserAuth()
    ]).catch(() => {})
    if(this.userInfo.role === 'admin-global') {
      void this.$router.push('/admin')
    } else if(this.userInfo?.is_petitioner === false) {
      const petEmail = this.userInfo?.respondent_data?.petitioner_email || ''
      const petData = await getUserByEmail(this.getFirebaseConfig.db, petEmail)
      if(petData && petData.forms.length > 0) {
        void this.$router.push(`/respondent-guide/${petData.forms[0].workflowsUserId}`)
      }
    } else if(this.userInfo?.forms?.length > 0) {
      void this.$router.push(`/legal-form-guide/divorce-forms/${this.userInfo.forms[0].workflowsUserId}`).catch(() => {});
    } else {
      void this.$router.push('/sign-in')
    }
    await this.toggleLoadingOverlayStatus(false)
  }
}
</script>
