<template>
  <div>
    <v-switch label="Help Icon" v-model="switchValue" @change="onChange" />
    <keep-alive>
      <help-icon-inputs
        :value="value"
        v-if="switchValue"
        @helpDataChanged="handleInputsChange"
      />
    </keep-alive>
  </div>
</template>

<script>
import HelpIconInputs from "./HelpIconInputs.vue";

export default {
  components: { HelpIconInputs },
  props: {
    value: Object,
  },

  data() {
    return {
      switchValue: this.value,
    }
  },

  methods: {
    onChange(switchValue) {
      if (!switchValue) {
        this.handleInputsChange(null);
      }

      return this.switchValue = switchValue;
    },

    handleInputsChange(data) {
      this.$emit("helpDataChanged", data);
      this.cacheValue = data;
    },
  },
};
</script>
