<template>
  <v-dialog
    v-model="show"
    :persistent="false"
    width="auto"
    @click:outside="setShow(false)"
  >
    <v-card class="confirmation-dialog">
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text>{{ desc }}</v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          @click="clickChoice(true)"
        >
          Yes
        </v-btn>
        <v-btn
          color="primary darken-1"
          text
          @click="clickChoice(false)"
        >
          No
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template> 

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Confirmation',

  computed: {
    ...mapGetters("confirmation", [
      "title",
      "desc",
      "accepted",
      "show"
    ])
  },

  methods: {
    ...mapActions("confirmation", ["setShow"]),

    clickChoice(choice) {
      if(choice) {
        this.accepted()
      }
      this.setShow(false)
    }
  }
}
</script>