const dayjs = require('dayjs')
const sendEmail = require('./sendEmail')
const scheduleEmail = require('./scheduleEmail')
const defaultFromAddress = require('@/constants/defaultFromEmail')

const smtp = (schedulingEmail = scheduleEmail, sendingEmail = sendEmail) => {
  return {
    handleEmail: async ({ to, subject, message, sendOn = new Date(), from = defaultFromAddress, formId = '', attachment = null }, _dayjs = dayjs) => {
      if (_dayjs(sendOn).diff(_dayjs(), 'day') <= 0) {
        return sendingEmail({ to, subject, message, from, attachment })
      } else {
        return schedulingEmail({ to, subject, message, sendOn, from, formId, attachment })
      }
    },

    sendEmail: (email) => sendingEmail(email),

    scheduleEmail: (email) => schedulingEmail(email)
  }
};

module.exports = smtp
 