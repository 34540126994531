import petitionerSteps from "@/constants/petitionerSteps";
import TeapAlert from "@/components/Alerts/TeapAlert";
import WorkflowGuide from "@/components/WorkflowGuide";
import { mapActions, mapGetters } from "vuex";
import SignaturePad from "@/components/SignaturePad";
import dayjs from "dayjs";
import ShareFormDialog from "@/components/ShareFormDialog";
import FileRenderer from "@/components/FileRenderer";
import titleMixin from "@/mixins/pageTitle.mixin";
import displayLogicMixin from "@/mixins/displayLogic.mixin";
import partnerMixin from "@/mixins/partner.mixin";
import GenerateDoc from "@/components/GenerateDoc";
import pdfGenerator from "@/services/pdfGenerator";
import pagesMixin from "@/mixins/pages.mixin";
import { download_file } from "@/utils/downloadFile.util";
import userExists from "@/services/user-exists.service";
import getUserById from "../../services/get-user-by-id.service";
import sendEmailUtil from "@/utils/send-email.util";
import signaturePadMixin from "@/mixins/signature.pad.mixin";
import { getDateFormat } from "@/utils/getDateFormat.util";
import analyticsMixin from "@/mixins/analytics.mixin";

export default {
  components: {
    ShareFormDialog,
    SignaturePad,
    WorkflowGuide,
    FileRenderer,
    GenerateDoc,
    TeapAlert,
  },

  mixins: [
    analyticsMixin,
    partnerMixin,
    titleMixin,
    displayLogicMixin,
    pagesMixin,
    signaturePadMixin,
  ],

  data() {
    return {
      disableComments: false,
      steps: [],
      collaborate: true,
      tipsActivation: false,
      warningErrorsDialog: false,
      ignoreFormDataChanges: false,
      updating: false,
      characterLimit: false,
      checkbox: false,
      submitResponseDialog: null,
      confirmSpouseEmailDialog: null,
      loading: false,
      spousalEmailChecker: null,
      spousalEmailCount: 0,
      btnLoading: false,
      replyLoading: false,
      sentToSpouse: false,
      step: 1,
      e6: 1,
      esign: {
        signature: {
          signed: false,
        },
      },
      warningIssues: {},
      sharing: {
        isNew: true,
        access: true,
        reviewType: "",
        user: {
          confirmation: {},
          email: "",
          name: "",
          firstAccessed: null,
          lastAccessed: null,
        },
      },
      partner: {
        firstName: "",
        lastName: "",
        email: "",
      },
      paymentLevel: 1,
    };
  },

  watch: {
    confirmSpouseEmailDialog(opened) {
      if (!opened) this.checkbox = false;
    },

    formData: {
      handler: async function (newVal, oldVal) {
        if (this.ignoreFormDataChanges || this.updating || !newVal) return;

        if (
          newVal?.docs &&
          newVal?.fields &&
          this.userInfo.forms.length > 0 &&
          this.userInfo.forms.findIndex(
            (uf) => uf.workflowsUserId === this.$route.params.id
          ) > -1
        ) {
          this.updating = true;
          await this.buildDisplayLogic();
          this.updating = false;
        }

        this.collaborate = newVal.hasOwnProperty("collaborate")
          ? newVal.collaborate
          : true;

        this.step = newVal?.step || 1;

        if (newVal.step >= 4) {
          this.paymentLevel = 2;
        }

        if (newVal.step === 1 && !this.userHasPaid) {
          await this.payForFormRedirect(
            this.formType,
            1,
            this.$route.params.id,
            this.userInfo.email
          );
        } else if (newVal.step === 4) {
          if (this.isAdmin || this.getPayments[newVal.parentId]?.level_2) {
            newVal.step = 5;
            this.step = 5;
            await this.reviewAndEsign(newVal);
          } else {
            await this.payForFormRedirect(
              this.formType,
              2,
              newVal.id,
              this.userInfo.email
            );
          }
        } else if(newVal.step === 5 || newVal.step === 7) {
          await this.reviewAndEsign(newVal);
        }

        this.setPartnerContactInfo(newVal);
        return;
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapGetters(["baseUrl", "getFirebaseConfig"]),

    ...mapGetters("profile", [
      "userInfo",
      "isAdmin",
      "formHasPaymentForUser",
      "myStates",
      "getPayments",
    ]),

    ...mapGetters("workflowPublished", [
      "getActiveDocIndex",
      "isFormSaved",
      "sharingData",
      "isOwner",
      "finalPDF",
      "respondentStep",
      "requiredFields",
    ]),

    ...mapGetters("displayLogic", ["visiblyRequired"]),

    ...mapGetters("workflowPublished", {
      formData: "getFormData",
      processedFile: "getProcessedFile",
      forms: "publishedWorkflows",
    }),

    showFinishReplyingBtn() {
      return this.sentToSpouse === false &&
        this.sharingData[0]?.commentsDisabled &&
        !this.characterLimit && !this.visibleRequiredFields?.length
        ? "v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default green"
        : "disable-events";
    },

    formType() {
      return this.formData.collaborate ? "collaborative" : "unilateral";
    },

    visibleRequiredFields() {
      return this.visiblyRequired(this.requiredFields).filter(
        (f) => f.answeredStatus === false
      );
    },

    agreedWithSpouse() {
      return (withForm) => {
        if (
          withForm &&
          this.formData.sharingData &&
          this.formData.sharingData.length > 0
        ) {
          return this.formData.sharingData[0]?.agreedWithSpouse;
        } else if (
          !withForm &&
          this.sharingData &&
          this.sharingData[0]?.agreedWithSpouse
        ) {
          return this.sharingData[0]?.agreedWithSpouse;
        }
        return false;
      };
    },

    isOutDated() {
      if (!this.formData) return false;
      return (
        this.forms.findIndex(
          (f) =>
            f.id === this.formData.parentId &&
            dayjs(f.statusDateStamp).diff(
              dayjs(this.formData.modifiedDate),
              "seconds"
            ) > 0
        ) >= 0
      );
    },

    readOnly() {
      return (
        (!this.isAdmin && this.respondentStep === 1 &&
          (this.sharingData === undefined ||
            !this.sharingData[0]?.commentsDisabled)) ||
        this.sentToSpouse
      );
    },

    canSendSpouseEmail() {
      const dateChecker = dayjs().diff(dayjs(this.spousalEmailChecker), "day");
      return this.spousalEmailChecker && dateChecker <= 1 && !this.isAdmin;
    },

    sharedUserProps() {
      if (this.formData) {
        const formData = this.formData.sharingProps;
        return formData && formData[0];
      }
    },

    disableBtn() {
      if (this.step > 7) {
        return true;
      } else if (this.step === 4) {
        return !this.userHasPaid;
      } else if (this.step === 5) {
        return !this.esign.signature.signed;
      } else if (this.step === 6) {
        return this.canSendSpouseEmail;
      } else {
        return false;
      }
    },

    formSelection() {
      return this.formData != null;
    },

    buttonText() {
      switch (this.step) {
        case 1:
          return this.collaborate ? "Proceed to Share" : "Proceed";
        case 2:
          return "Send Share Invite";
        case 3:
          return "Send Back for Spousal Review";
        case 4:
          return "Complete Payment";
        case 5:
          return this.collaborate ? "Submit Signature" : "Proceed";
        case 6:
          return this.spousalEmailCount > 0
            ? "Notify Spouse Again"
            : "Notify Spouse";
        case 7:
          return "Download";
        default:
          return "Advance";
      }
    },

    userHasPaid() {
      if (this.isAdmin) return true;
      if (this.userInfo.forms.length === 0) return false;
      const stateFound = this.userInfo.forms.findIndex(
        (f) => f.workflowsUserId === this.$route.params.id
      );
      const paymentFound = this.formHasPaymentForUser(
        this?.stateFound?.workflowsUserId,
        this.paymentLevel
      );
      return stateFound > -1 || paymentFound || false;
    },

    publishedFormId() {
      return this.$route.params.id;
    },

    formId() {
      return this.publishedFormId || this.formData?.id || "Loading";
    },

    userHasFinalPayment() {
      if (!this?.formData?.id) return false;
      const paymentFound = this.formHasPaymentForUser(
        this?.formData?.id,
        this.paymentLevel
      );
      return paymentFound || false;
    },
  },

  created() {
    this.steps = petitionerSteps;
  },

  async mounted() {
    await this.toggleLoadingOverlayStatus(true);
    if (this.userInfo?.full_name === "") {
      await this.establishUserAuth();
    }
    this.spousalEmailChecker = dayjs(
      localStorage.getItem("spouse-email-stamp")
    );

    if (this.userInfo.is_petitioner === false) {
      void this.$router.push(`/respondent-guide/${this.$route.params.id}`);
      return;
    }

    //await this.setPublishedWorkflows();
    await this.getMyStates(this.userInfo.email);

    if (!this.formData) {
      this.step = this.formData?.step ?? 1;
      if (!this.userHasPaid) {
        await this.payForFormRedirect(
          this.formType,
          1,
          this.$route.params.id,
          this.userInfo.email
        );
      } else {
        await this.loadFormData({ id: this.$route.params.id });
      }
    }

    this.setPageNames(),
    this.setPages()
    await this.toggleLoadingOverlayStatus(false)
    return;
  },

  methods: {
    ...mapActions("confirmation", ["confirm"]),

    ...mapActions([
      "setForms",
      "toggleLoadingOverlayStatus",
      "setOverlayMessage",
    ]),

    ...mapActions("displayLogic", ["buildTemplates"]),

    ...mapActions("workflowPublished", [
      "setPublishedWorkflows",
      "loadForm",
      "saveForm",
      "saveRawForm",
      "setFormData",
      "saveStep",
      "saveRespondentStep",
      "saveSignature",
      "addShareData",
      "shareForm",
      "deleteShare",
      "generateDocument",
      "generatePdfFile",
      "setFinalPDF",
      "setActiveDocIndex",
      "agreedToTerms",
    ]),

    ...mapActions("workflowPublished", {
      sendBack: "sendBackForReview",
    }),

    ...mapActions("comments", {
      saveComments: "saveSpouseComments",
      enableComments: "enableEditingComments",
    }),

    ...mapActions("profile", [
      "getMyStates",
      "establishUserAuth",
      "removeFormById",
      "setPayments",
      "removeAllForms",
      "removeAllPayments",
    ]),

    ...mapActions("alert", ["setAlert"]),

    async payForFormRedirect(type, level, formId, email) {
      void this.$router.push(`/pay-for-form/${formId}/${email}/${type}/${level}`)
      return true
    },

    handleIgnoreFormDataChanges(value) {
      this.ignoreFormDataChanges = value;
    },

    activateTipsWarning() {
      this.tipsActivation = true;
      this.warningErrorsDialog = false;
    },

    renderWarningButton() {
      return (
        this.currentTab === Object.keys(this.pages).length - 1 &&
        Object.keys(this.tabsWarnings).length
      );
    },

    restart() {
      this.confirm({
        title: "Are you sure you want to go back to the questionnaire?",
        accepted: () => {
          this.step = 1;
          this.formData.step = 1;
          this.formData.respondentStep = 1;
          this.formData.sharingProps = [];
          this.formData.sharingUser = "";
          this.formData.finalPDF = "";
        },
      });
    },

    async reviewAndEsign(fd) {
      this.ignoreFormDataChanges = true;
      await this.toggleLoadingOverlayStatus(true)
      await this.$nextTick()
      if (this.step === 5) {
        const docIndex = fd.docs.findIndex(
          (d) => d.name.toLowerCase().indexOf("stipulation") >= 0
        );
        this.setOverlayMessage()        
        await this.setActiveDocIndex(fd.docs[docIndex].uid);
        await this.generateDocument(false);
        await this.generatePdfFile({ processedFile: true, docIndex })
      } else if (this.step === 7) {
        localStorage.removeItem(`${this.userInfo.uid}-coupon_code`);
        if (!this.finalPDF) {
          await this.generateDoc();
        }
      }
      await this.toggleLoadingOverlayStatus(false);
      this.setOverlayMessage()
      this.ignoreFormDataChanges = false
      return;
    },

    scrollToBottom() {
      const rc = document.querySelector(".right-content");
      rc.scrollTop = rc.scrollHeight;
    },

    goBackToQuestionnaire() {
      this.confirm({
        title: "Are you sure?",
        accepted: async () => {
          this.loading = true;
          this.step = 1;
          await this.saveStep({ docId: this.formData.id, step: 1 });
          this.loading = false;
        },
      });
    },

    disableProceedButton(warning) {
      this.warningIssues = warning;
    },

    async sendReply() {
      // EventBus.$emit('save-comments-progress');
      // EventBus.$emit('save-form-progress');
      await this.updateForm()
      //this.$root.$emit("finalSaveAction");
      this.replyLoading = true;
      await this.sendBack({
        numberOfPages: 2,
        commentOwner: "respondent",
        commentOwnerName: this.sharedUserProps.user.name,
      });
      const response = await this.enableComments(this.formData.id);
      if (response === "success") {
        this.setAlert({
          type: "success",
          message:
            "You are now waiting for your spouse to respond to your comments. Until they respond, you can only check the comment history.",
        });
      }
      this.sentToSpouse = true;
      this.replyLoading = false;
      this.disableComments = true
      this.submitResponseDialog = false;
    },

    resetSharing() {
      this.sharing = {
        isNew: true,
        access: true,
        comment: "",
        agreedWithSpouse: false,
        user: {
          confirmation: {},
          email: "",
          name: "",
          firstAccessed: null,
          lastAccessed: null,
        },
      };
    },

    async processShareData() {
      let respExists = false;
      if (this.userInfo?.petitioner_data?.respondent_email) {
        respExists = await userExists(
          this.userInfo.petitioner_data.respondent_email.toLowerCase()
        );
      }
      const link = respExists === false ? "create-user" : "sign-in";
      const qs = `?wfid=${this.formData.id}&petitioner=${this.userInfo.email}&respondent=${this.userInfo.petitioner_data.respondent_email}`;
      this.sharing.user.name = `${this.partner.firstName} ${this.partner.lastName}`;
      this.sharing.user.email = this.partner.email;
      await this.addShareData({ sharingData: this.sharing, pg: link + qs });
      this.resetSharing();
      return;
    },

    async processShareForm() {
      this.resetSharing();
      await this.processShareData();
      return;
    },

    async updateForm() {
      await Promise.all([
        this.toggleLoadingOverlayStatus(true),
        this.saveForm(false)
      ])
      await this.toggleLoadingOverlayStatus(false);
      this.setOverlayMessage();
    },

    async handleUserPayment() {
      await Promise.all([
        this.toggleLoadingOverlayStatus(true),
        this.loadForm({ parentId: this.$route.params.id })
      ])
      await this.saveForm(false);
      this.$router.replace(location.pathname);
      await this.toggleLoadingOverlayStatus(false);
    },

    async stepClick(desiredStep) {
      if (this.isAdmin) {
        if (
          desiredStep === 4 &&
          !this.getPayments[this.$route.params.id]?.level_2
        ) {
          await this.payForFormRedirect(
            this.formType,
            2,
            this.formData.id,
            this.userInfo.email
          );
        } else {
          if (desiredStep === 4) {
            desiredStep += 1;
          }
          this.toggleLoadingOverlayStatus(true);
          this.step = desiredStep;
          this.stepItUp();
          this.toggleLoadingOverlayStatus(false);
        }
      }
    },

    async stepItUp() {
      if (this.step > 7) return;
      if (!this.formData) {
        this.formData["step"] = this.step || 1;
        await this.setFormData(this.formData);
      }
      await this.saveStep({ docId: this.$route.params.id, step: this.step });
      this.handlePaymentLevel();
      return;
    },

    handlePaymentLevel() {
      switch (this.step) {
        case 4:
          this.paymentLevel = 2;
          break;
        default:
          this.paymentLevel = 1;
          break;
      }
    },

    async handle2ndPayment() {
      await this.saveStep({ docId: this.formData.id, step: 5 });
      this.step = 5;
      await this.generateDocument(false)
      await this.generatePdfFile({ processedFile: true, docIndex: 0 });
      this.$router.replace(location.pathname);
      await this.toggleLoadingOverlayStatus(false);
    },

    async sendSpousalReviewEmail() {
      if (this.canSendSpouseEmail) {
        this.btnLoading = false;
        return;
      }
      const { firstName, lastName, email } = this.partner;

      const user = {
        email,
        name: `${firstName} ${lastName}`,
      };

      await sendEmailUtil(
        this.baseUrl,
        user,
        "sign",
        "SIMPLEENDING™ form is ready for review and e-signature"
      );

      this.spousalEmailCount += 1;
      if (this.spousalEmailCount === 3) {
        localStorage.setItem("spouse-email-stamp", dayjs());
        this.spousalEmailChecker = dayjs();
      }
      this.btnLoading = false;
      this.setAlert({
        type: "success",
        message: "Successfully sent email for review and e-signature",
      });
    },

    async generateDoc() {
      this.setOverlayMessage("Building final document, Please wait.");
      this.btnLoading = true;
      await this.setFinalPDF({ docId: this.formId, finalPDF: null });
      const { full_name, petitioner_data } = await getUserById(this.formData.owner.email)
      const fileName = encodeURIComponent(
        `SIMPLEENDING-${petitioner_data.respondent_first_name}-and-${full_name.replace(' ', '-')}-${getDateFormat(
          dayjs(),
          "MMDDYYYY"
        )}`
      );
      const finalPDF = await pdfGenerator().run(this.formId, fileName);
      await this.setFinalPDF({ docId: this.formId, finalPDF });
      this.trackEvent("event", "workflow_completed");
      this.btnLoading = false;
      return;
    },

    moveForwardOnTheNextStep() {
      this.warningErrorsDialog = true;
    },

    closeWarningDialogAndMoveToTheNextStep() {
      this.warningErrorsDialog = false;
      this.step += 1;
      this.stepItUp();
    },

    async buttonClick() {
      if (this.step > 7) return;

      switch (this.step) {
        case 1:
          await this.updateForm();
          if (this.collaborate) {
            this.setPartnerContactInfo(this.formData);
          } else {
            this.step = this.getPayments[this.formData.parentId]?.level_2
              ? 5
              : 4;
            await this.saveStep({ docId: this.formData.id, step: this.step });
            await this.reviewAndEsign(this.formData);
          }
          break;
        case 2:
          this.btnLoading = true;
          this.loading = true;
          await this.processShareForm();
          await this.saveRespondentStep({
            docId: this.formId,
            respondentStep: 1,
          });
          this.btnLoading = false;
          this.loading = false;
          this.confirmSpouseEmailDialog = false;
          this.setAlert({
            type: "success",
            message:
              "Your questionnaire has been shared with the respondent! Please wait until they have finished reviewing it.",
          });
          this.tipsActivation = true;
          break;
        case 3:
          this.btnLoading = true;
          await this.enableComments(this.formId);
          await this.processShareForm();
          this.btnLoading = false;
          await this.saveStep({ docId: this.formData.id, step: 4 });
          setTimeout(() => {
            location.reload();
          }, 500);
          break;
        case 5:
          if (this.collaborate) {
            this.confirm({
              title:
                "You are about to sign a legally binding agreement. Please confirm.",
              accepted: async () => {
                await this.toggleLoadingOverlayStatus(true);
                await this.getFirebaseConfig.db.runTransaction(
                  async (transaction) => {
                    await Promise.all([
                      this.saveSignature({
                        transaction,
                        docId: this.formId,
                        esign: this.esign,
                      }),
                      this.saveRespondentStep({
                        transaction,
                        docId: this.formId,
                        respondentStep: 2,
                      }),
                      this.saveRawForm({
                        id: this.formData.id,
                        signatureRequestSentToRespondent: Date.now(),
                      })
                    ])
                  }
                );
                await this.sendSpousalReviewEmail();
                await this.toggleLoadingOverlayStatus(false);
                this.step += 1;
                this.stepItUp();
              },
            });
          } else {
            await this.saveStep({ docId: this.formData.id, step: 7 });
            this.step = 7;
            await this.reviewAndEsign(this.formData);
          }
          break;
        case 6:
          this.btnLoading = true;
          await this.sendSpousalReviewEmail();
          break;
        case 7:
          await this.toggleLoadingOverlayStatus(true);
          const fileName = decodeURIComponent(this.finalPDF).split("/").pop();
          await download_file(this.finalPDF, fileName);
          await this.toggleLoadingOverlayStatus(false);
          break;
      }

      if (this.collaborate && this.step < 5) {
        this.step += 1;
        this.stepItUp();
      }
      return;
    },

    async loadFormData(form) {
      if (!form || !this.userHasPaid) {
        return;
      }
      await Promise.all([
        this.toggleLoadingOverlayStatus(true),
        this.loadForm(form)
      ])
      return;
    },

    isMyState(state) {
      return this.myStates.findIndex((doc) => doc.state === state) >= 0;
    },
  },  
}