<template>
    <div style="margin-top:40px;">
        <h3 style="margin-left:20px;">COUPONS</h3>
        <v-btn @click="codeCreateClick">Add</v-btn>
        <div style="margin: 0 40px;">
            <v-data-table
                :headers="headers"
                :items="coupons"
                :loading="loading"
                loading-text="Loading ... Please wait"
                :items-per-page="50"
            >
                <template v-slot:item.campaign="{ item }">
                    <v-icon v-if="item.campaign" title="Campaign">
                        mdi-star
                    </v-icon>
                </template>
                <template v-slot:item.amount="{ item }">
                    {{ item.amount }}{{ showPercent(item.type) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn
                        icon
                        color="primary"
                        :loading="(item.code === updateCode)"
                        @click="codeUpdateClick(item.original)"
                    >
                        <v-icon
                            color="primary"
                            title="Update Coupon"
                            small
                        >
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color="primary"
                        :loading="(item.code === deleteCode)"
                        @click="codeDeleteClick(item.code)"
                    >
                        <v-icon
                            color="primary"
                            title="Delete Coupon"
                            small
                        >
                            mdi-delete-forever
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <v-dialog
            v-model="dialog"
            max-width="300px"
            :retain-focus="false"
        >
            <v-card>
                <v-card-title style="position:relative;">
                    {{ actionTitle }}
                    <v-btn
                        style="margin-left:1px;position:absolute;top:0;right:0;"
                        icon
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <EditCoupon
                        v-if="dialog"
                        :operation="operation"
                        @saved="handleSaved"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EditCoupon from './edit'
import adminCheckerMixin from '@/mixins/adminChecker.mixin'
const { getDateFormat } = require('@/utils/getDateFormat.util')

export default {
    name: "CouponManager",

    mixins: [adminCheckerMixin],

    components: {
        EditCoupon,
    },

    data() {
        return {
            actionTitle: '',
            dialog: false,
            updateCode: '',
            deleteCode: '',
            loading: true,
            operation: 'add',
            headers: [
                {
                    text: '',
                    value: 'campaign',
                    sortable: true,
                },
                {
                    text: 'Amount',
                    value: 'amount',
                    sortable: true,
                },
                {
                    text: 'Code',
                    value: 'code',
                    sortable: true,
                },
                {
                    text: 'Count',
                    value: 'count',
                    sortable: true,
                },
                {
                    text: 'Limit',
                    value: 'limit',
                    sortable: true,
                },
                {
                    text: 'Start Date',
                    value: 'startDate',
                    sortable: true,
                },
                {
                    text: 'End Date',
                    value: 'endDate',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                },
            ]
        }
    },

    computed: {
        ...mapGetters("coupons", ["coupons", "coupon"]),

        showPercent() {
            return (type) => {
                return type === 'percent' ? '%' : ''
            }
        }
    },

    methods: {
        ...mapActions("confirmation", ["confirm"]),

        ...mapActions("coupons", [
            "deleteCoupon",
            "getCoupons",
            "setCurrentCoupon",
        ]),

        handleSaved() {
            this.getCoupons()
            this.dialog = false
            this.operation = 'add'
        },

        codeCreateClick() {
            this.operation = 'add'
            this.setCurrentCoupon({
                amount: 1,
                code: '',
                count: 0,
                campaign: false,
                limit: 0,
                payall: 'both',
                type: 'percent',
                startDate: getDateFormat(new Date(), 'MM/DD/YYYY'),
                endDate: ''
            })
            this.dialog = true
            this.actionTitle = 'Create Coupon Code'
        },

        codeUpdateClick(coupon) {
            this.operation = 'edit'
            this.setCurrentCoupon({...coupon})
            this.dialog = true
            this.actionTitle = `Update ${coupon.code}`

        },

        async codeDeleteClick(code) {
            this.confirm({
                title: `Are you sure you want to delete ${code}`,
                accepted: async () => {
                    this.deleteCode = code
                    await this.deleteCoupon(code)
                    this.deleteCode = ''
                }
            })
        }
    },

    async mounted() {
        await this.getCoupons()
        this.loading = false
    }
}
</script>
