<template>
  <div id="email-container">
    <div id="form">
      <b>Form</b>
      <v-text-field class="form-field" label="To Name" v-model="to.name" /><br />
      <v-text-field class="form-field" label="To Email" v-model="to.email" /><br />
      <v-text-field
        class="form-field"
        label="Subject"
        v-model="subject"
      /><br />
      <v-textarea class="form-field" label="Message" v-model="message" /><br />
      <v-file-input v-model="file" @change="addAttachment($event)" />
      <v-menu
        v-if="1 == 0"
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="sendOn"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="sendOn"
            label="Send email on"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="sendOn" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(sendOn)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <template v-if="!editLink">
        <v-checkbox v-model="editLink" label="Edit Link?" />
      </template>
      <template v-else>
        <v-text-field
          class="form-field"
          label="Link Name"
          v-model="link.name"
        /><br />
        <v-text-field
          class="form-field"
          label="Link Path"
          v-model="link.url"
        /><br />
      </template>
      <div class="form-actions">
        <v-btn @click="ConfirmClear">Clear</v-btn>
        <v-btn
          color="primary"
          @click="Send"
          :loading="loading"
          :disabled="disableSendBtn || disableBtn"
          >Send</v-btn
        >
      </div>
    </div>
    <div id="template">
      <b>Template Example</b>
      <email-template :message="message" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import sendEmail from '@/utils/send-email.util'
import EmailTemplate from "./EmailTemplate.vue";
import { getDateFormat } from "@/utils/getDateFormat.util"

export default {
  name: "Emailer",

  components: {
    EmailTemplate,
  },

  data() {
    return {
      to: {
        name: 'Jon Doe',
        email: 'joeandtel@gmail.com',
      },
      from: {
        name: 'SimpleEnding™',
        email: 'no-reply@simpleending.com'
      },
      subject: "Practice",
      message: "Hello World",
      sendOn: getDateFormat(),
      link: {
        name: "login",
        url: "/sign-in",
      },
      loading: false,
      editLink: false,
      menu: false,
      attachment: null,
      file: null,
      disableBtn: false,
    };
  },

  watch: {
    link: {
      handler: function (newValue) {
        const footerLink = document.querySelector("#footer-link");
        footerLink.innerHTML = newValue.name;
        footerLink.href = `${this.baseUrl}/${newValue.url}`;
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(["baseUrl"]),

    disableSendBtn() {
      return !(this.to.email && this.to.name && this.subject && this.message);
    },
  },

  methods: {
    ...mapActions("alert", ["setAlert"]),

    ...mapActions("confirmation", ["confirm"]),

    async addAttachment(evt) {
      this.disableBtn = true
      
      if(evt) {
        const sizeInMB = evt.size / (1024 ** 2)
        if(sizeInMB > 2) {
          this.attachment = null
          this.file = undefined
          this.disableBtn = false
          this.setAlert({
            type: "warning",
            show: true,
            message: "Please choose a file that is under 2MB in size"
          })
        } else {
          this.attachment = await new Promise(resolve => {
            const reader = new FileReader()
            reader.onload = function (e) {
              resolve({ name: evt.name, content: reader.result })
            }
            reader.readAsDataURL(evt)
          })
        }
      } else {
        this.attachment = null
      }
      this.disableBtn = false
      return
    },

    ConfirmClear() {
      this.confirm({
        title: 'Are you sure you want to clear this form?',
        accepted: () => this.Clear()
      })
    },

    Clear() {
      this.to = {
        email: '',
        name: ''
      }
      this.from = {
        email: '',
        name: ''
      }
      this.subject = "";
      this.message = "";
    },

    Send() {
      this.loading = true;
      
      sendEmail(this.baseUrl, this.to, 'practice', this.subject, this.message, undefined, this.attachment)
      .then(() => {
        this.setAlert({
          show: true,
          type: "success",
          message: `Email was successfully sent to ${this.to.name}`,
        });
      })
      .catch((error) => {
        this.setAlert({
          show: true,
          type: "error",
          message: `Sorry, could not send email to ${this.to.name}`,
        });
      })
      .finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
#email-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 40px;

  #form {
    width: 50%;
    padding-right: 40px; 

    .form-field,
    .form-actions {
      width: 100%;
    }

    .form-actions {
      display: flex;
      justify-content: space-around;
    }
  }

  #template {
    width: 40%;
  }
}
</style>