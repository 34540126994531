const handleParagraphing = (value) => {
  const newValue = value
    .replaceAll('&lt;&lt;tab&gt;&gt;', '    ')
    .replaceAll('<p><br></p>', '\n')
    .replaceAll('</p>', '\n')
    .replaceAll(('<strong> </strong>'), ' ')
    .replace(/<strong.*?>/g,'<strong>')
    .replace(/<\/?span.*?>/g,'')
    //.replaceAll('<p class=\"ql-align-center\">','')
  return newValue
}

module.exports = handleParagraphing