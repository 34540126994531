import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './stores';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import { getFirebaseConfig } from './data/firebaseConnection';
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import logger from '@/utils/logger.util'
//import { VueMaskDirective, VueMask } from 'v-mask'
import VueMask from 'v-mask'
import axios from 'axios'
import LazyLoading from 'vue-lazy-loading'
import VueYoutube from 'vue-youtube'
const { VUE_APP_REGION, VUE_APP_PROJECT } = process.env 
Vue.use(VueMask)
Vue.use(LazyLoading)
Vue.use(VueYoutube)

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

getFirebaseConfig().then(async () => {
  Vue.config.productionTip = false;
  Vue.prototype.$logger = logger;

  const secretBaseUrl = `https://${VUE_APP_REGION}-${VUE_APP_PROJECT}.cloudfunctions.net/getSecret`
  const sentryConfig = await axios.get(`${secretBaseUrl}?key=sentry`);

  const { vueDsn, redirect } = sentryConfig.data
  Sentry.init({
    Vue,
    dsn: vueDsn,
    integrations: [  
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["simpleending-stage.web.app", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1
  })

  window.error = (error) => handleError(error)

  Vue.config.errorHandler = (error) => handleError(error)

  Vue.config.warnHandler = (warning) => handleError(warning)

  const handleError = (error) => {
    if(location.href.indexOf('/error') > 0) return
    logger.error(error.message, error)
    if(redirect)
      router.push('/error')
  }

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
})
