<template>
    <div style="padding-right: 40px;">
        <div v-if="showNewPassword" style="padding: 40px;" >
            <v-form>
                <v-text-field
                    v-model="newPassword"
                    :append-icon="showPasswordText ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPasswordText ? 'text' : 'password'"
                    placeholder="Please enter new password here."
                    label="New Password"
                    :rules="[rules.required, rules.min]"
                    @click:append="showPasswordText = !showPasswordText"
                />
                <v-btn 
                    :loading="loadingNewPassword"
                    color="primary" 
                    @click="submitNewPassword">
                    Submit
                </v-btn>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'VerifyUser',

    data() {
        return {
            newPassword: '',
            showNewPassword: false,
            actionCode: '',
            showPasswordText: false,
            loadingNewPassword: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',                
            }
        }
    },

    mounted() {
        if(this.$route.query?.mode && this.$route.query?.oobCode && this.$route.query?.apiKey ) {
            const { mode, oobCode } = this.$route.query
            this.actionCode = oobCode
            switch (mode) {
                case 'resetPassword':
                    this.handleResetPassword()
                    break;
                case 'verifyEmail':
                    this.handleVerifyEmail()
                    break;
                default:
                this.handleError('Sorry, I think you may have the wrong page')
                    break;
            }
        } else {
            this.handleError('Sorry, I think you may have the wrong page')
        }
    },

    computed: {
        ...mapGetters(["getFirebaseConfig"])
    },

    methods: {
        ...mapActions("alert", ["setAlert"]),

        redirectToSignIn() {
            setTimeout(() => {
                this.$router.push('/sign-in')
            }, 3000)
        },

        submitNewPassword() {
            this.loadingNewPassword = true
            const auth = this.getFirebaseConfig.auth
            auth.confirmPasswordReset(this.actionCode, this.newPassword).then(() => {
                this.setAlert({ type: 'success', message: 'You have successfully reset your password. Please try signing in again' })
                this.redirectToSignIn()
            }).catch(error => {
                this.setAlert({ type: 'error', message: 'Sorry, could not reset your password' })
                this.handleError()
            }).finally(() => {
                this.loadingNewPassword = false
            })
        },

        handleResetPassword() {
            const auth = this.getFirebaseConfig.auth
            auth.verifyPasswordResetCode(this.actionCode).then(email => {
                this.showNewPassword = true;
            })
        },

        handleVerifyEmail() {
            const auth = this.getFirebaseConfig.auth
            auth.applyActionCode(this.actionCode).then(() => {
                this.setAlert({ type: 'success', message: 'Your email has been successfully verified' })
                this.redirectToSignIn()
            }).catch(error => {
                this.setAlert({ type: 'error', message: 'Sorry, could not verify this email' })
            })
        }
    }
}
</script>