const alignTextEditor = (textToWrite, newPDF, action, percentToInchesWidth, textY) => {
  switch (action.textAlign) {
      case 'center':
        const textX = (parseFloat(action.location.startX) + (0.5 * parseFloat(action.location.width))) * percentToInchesWidth
        const options = {
          maxWidth: (action.location.width * percentToInchesWidth), 
          align: 'center'
        }
        newPDF.text(
          textToWrite,
          textX,
          textY,
          options
        );
        break;
      case 'left':
        newPDF.text(
          textToWrite,
          (parseFloat(action.location.startX) * percentToInchesWidth),
          textY,
          {maxWidth: (parseFloat(action.location.width) * percentToInchesWidth), align: 'left'}
        );
        break;
      case 'right':
        newPDF.text(
          textToWrite,
          (parseFloat(action.location.startX) + parseFloat(action.location.width)) * percentToInchesWidth,
          textY,
          {maxWidth: (parseFloat(action.location.width) * percentToInchesWidth), align: 'right'}
        );
        break;
      default:
        newPDF.text(
          textToWrite,
          (parseFloat(action.location.startX) * percentToInchesWidth),
          textY,
          {maxWidth: (parseFloat(action.location.width) * percentToInchesWidth)}
        );
        break;
    }
}

module.exports = { alignTextEditor }