export default {
  computed: {
    videoUrl() {
      return (field) => {
        let url = field?.embed || field?.videoURL
        if(url && (/youtu\.be/i.test(url))) {
          url = url.replace(/youtu\.be\/(.+)/i, 'youtube.com/embed/$1');
        }
        return url
      }
    },
  }
}