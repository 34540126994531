const rulesQuestionChecker = ({ fields, docs }) => {
  docs.forEach(d => {
    if(typeof d.rules === 'array') {      
      d.rules.forEach(r => {
        r.questions.forEach(q => {
          if(!q.title || typeof q.title === 'object') {
            const fld = fields.find(f => f.uid === q.uid)
            if(fld) {
              q.title = fld.title
            }
          }
        })
      })
    } else if(typeof d.rules === 'object') {
      Object.values(d.rules).forEach(r => {
        r.questions.forEach(q => {
          if(!q.title || typeof q.title === 'object') {
            const fld = fields.find(f => f.uid === q.uid)
            if(fld) {
              q.title = fld.title
            }
          }
        })
      })
    }
  })
}

module.exports = { rulesQuestionChecker }