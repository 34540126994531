import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      alertMessage: null,
      alertType: ''
    }
  },

  computed: {
    ...mapGetters(["getFirebaseConfig"]),
  },

  methods: {
    ...mapActions("alert", ["setAlert"]),

    async sendResetPwdRequest(email) {
      if (email) {
        try {
          await this.getFirebaseConfig.auth.sendPasswordResetEmail(email)
          await this.setAlert({ type: "success", message: "Please check your email to reset your password" })
        } catch(error) {
          if(error.code === "auth/user-not-found") {
            await this.setAlert({ type: "error", message: "Sorry, could not find this email in our database." })
          } else {
            await this.setAlert({ type: "error", message: error.message })
          }
        }
      } else {
        await this.setAlert({ type: "warning", message: "Please enter your email address first?" })
      }
    },
  }
}