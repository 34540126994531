import { mapGetters } from 'vuex'

export default {
    computed: {
      ...mapGetters(['getStates']),

      showAnswerDropdown() {
        return question => {          
          const allowed = ['participating','single-select', 'state-select', 'text-input-short', 'text-input-long', 'file-input', 'page-break', 'youtube-video', 'date-picker']
          return allowed.indexOf(question.type) > -1 || (question.type === 'embedded-image' && question.interactionType === 'single-select')
        }
      },
    },

    methods: {
        getSelectedQuestionAnswer(currentQuestion) {            
            switch (currentQuestion.type) {
                case "participating":
                  return ['collaborating', 'unilateral']
                case "single-select":
                case 'grouped-options':
                case "multi-select":
                  return currentQuestion.content;
                case 'state-select':
                  return this.getStates;
                case "number-input":
                  return [
                    "greater-than",
                    "equal-to",
                    "less-than",
                    "between",
                  ];
                case "text-input-short":
                case "text-input-long":
                  return ["answered", "not-answered"];
                case "file-input":
                  return ["uploaded", "not-uploaded"];
                case "embedded-image":
                  return currentQuestion.interactionType !== "none" ? currentQuestion.content : []
                case "youtube-video":
                  return ["before-watch", "after-watch"];
                case "date-picker":
                  return ["after", "equal-to", "before", "between"];
                case "signature-pad":
                  return ["signed", "not-signed"];
              }            
        }
    }
}