export default {
    data() {
        return {
            isSigned: false
        }
    },

    methods: {
        handleSignature(status) {
            this.isSigned = status
        },        
    }
}