import axios from 'axios';
import { getProject } from './gcloud/getProject';

export default async function getUsersService(projectUrl = getProject(), httpRequest = axios) {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  };

  return await httpRequest({
    url: `${projectUrl}/getUsers`,
    method: 'GET',
    headers: config,
  })    
}