<template>
  <div id="cost-info-container"
       style="display:flex;flex-direction: column; align-items: center; justify-content: center;height:100%;">
    <div class="top" :style="{ backgroundColor: tab.bg, color: tab.fg }"
         style="padding:0 20px;width:100%;height:60px;border-radius:10px 10px 0 0;display:flex;align-items: center; justify-content: space-between; font-weight: bold;">
      <div>{{ tab?.title }}</div>
    </div>
    <div class="main"
         style="height:100%; width: 100%;box-shadow:5px 10px 5px #888888;padding:10px;border:1px solid #ccc;border-radius:0 0 5px 5px;">
      <div class="price-frame">
        <div class="image">
          <img v-if="this.nameProp === 'collaborative'" src="/img/collaborate.svg" style="width:100%;height:100%;" alt=""/>
          <img v-else src="/img/solo.svg" style="width:100%;height:100%;" alt=""/>
        </div>
        <h1 class="text-center mb-0" style="color:#656565; font-size: 56px; line-height: normal;">
          ${{ price }}
        </h1>
        Total
      </div>

      <v-divider></v-divider>

      <v-btn v-if="showSelectBtn && (this.$route.name === 'PaywallPrices')" color="primary" @click="edit"
             style="margin: 10px;">
        {{ btnName }}
      </v-btn>
      <v-btn v-else-if="showSelectBtn" color="primary" @click="$emit('selected')" style="margin: 10px;">
        {{ btnName }}
      </v-btn>

      <v-divider></v-divider>

      <div style="color:gray; margin-top: 10px; font-size: 12px;">
        {{ summary }}
      </div>
      <div v-html="items" class="list-frame"></div>
    </div>
    <v-dialog
      v-if="this.$route.name === 'PaywallPrices'"
      v-model="dialog"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          Edit pricing card
        </v-card-title>

        <div class="px-4">
          <v-select
            :items="tabItems"
            label="Outlined style"
            v-model="selectedDropdown"
            @input="selectedValue"
            outlined
          ></v-select>
        </div>

        <div v-if="selectedDropdown === constantValue.WITH_SPOUSE_GENERAL" class="px-4">
          <v-text-field
            label="Header"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.WithSpouse.header"
          ></v-text-field>
          <v-text-field
            label="Title"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.WithSpouse.title"
          ></v-text-field>
          <v-text-field
            label="Total Price"
            hide-details="auto"
            class="mb-5"
            prefix="$"
            type="number"
            v-model="collaborativeData.WithSpouse.price"
          ></v-text-field>
          <v-text-field
            label="Body"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.WithSpouse.body"
          ></v-text-field>
          <v-text-field
            label="Summary"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.WithSpouse.summary"
          ></v-text-field>

          <v-divider></v-divider>

          <v-list-item-title class="text-start my-5 font-weight-600">Colors</v-list-item-title>

          <div class="d-flex align-items-center justify-start pb-5">
            <div class="d-flex align-items-center">
              <input type="color" id="bg" name="bg" v-model="collaborativeData.WithSpouse.bg"/>
              <label class="ms-2" for="bg">Background color</label>
            </div>

            <div class="d-flex align-items-center">
              <input type="color" id="font" name="font" v-model="collaborativeData.WithSpouse.fg"/>
              <label class="ms-2" for="font">Font color</label>
            </div>
          </div>

          <v-divider></v-divider>

          <v-list-item-title class="text-start my-5 font-weight-600">
            <span>Item list</span>
          </v-list-item-title>

          <quill-editor
            v-model="collaborativeData.WithSpouse.items"
            :options="editorOption"
            class="o-quill-editor"
            placeholder="Add items"
          />
        </div>

        <div v-if="selectedDropdown === constantValue.WITHOUT_SPOUSE_GENERAL" class="px-4">
          <v-text-field
            label="Header"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.WithoutSpouse.header"
          ></v-text-field>
          <v-text-field
            label="Title"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.WithoutSpouse.title"
          ></v-text-field>
          <v-text-field
            label="Price"
            prefix="$"
            type="number"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.WithoutSpouse.price"
          ></v-text-field>
          <v-text-field
            label="Body"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.WithoutSpouse.body"
          ></v-text-field>
          <v-text-field
            label="Summary"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.WithoutSpouse.summary"
          ></v-text-field>

          <v-divider></v-divider>

          <v-list-item-title class="text-start my-5 font-weight-600">Colors</v-list-item-title>

          <div class="d-flex align-items-center justify-start pb-5">
            <div class="d-flex align-items-center">
              <input type="color" id="bg" name="bg" v-model="unilateralData.WithoutSpouse.bg"/>
              <label class="ms-2" for="bg">Background color</label>
            </div>

            <div class="d-flex align-items-center">
              <input type="color" id="font" name="font" v-model="unilateralData.WithoutSpouse.fg"/>
              <label class="ms-2" for="font">Font color</label>
            </div>
          </div>

          <v-divider></v-divider>

          <v-list-item-title class="text-start my-5 font-weight-600">
            <span>Item list</span>
          </v-list-item-title>

          <quill-editor
            v-model="unilateralData.WithoutSpouse.items"
            :options="editorOption"
            class="o-quill-editor"
            placeholder="Add items"
          />
        </div>

        <div v-if="selectedDropdown === constantValue.WITH_SPOUSE_LEVEL_ONE" class="pa-4">
          <v-text-field
            label="Header"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.level_1.header"
          ></v-text-field>
          <v-text-field
            label="Title"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.level_1.title"
          ></v-text-field>
          <v-text-field
            label="Price"
            prefix="$"
            type="number"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.level_1.price"
          ></v-text-field>
          <v-text-field
            label="Body"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.level_1.body"
          ></v-text-field>
          <v-text-field
            label="Summary"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.level_1.summary"
          ></v-text-field>

          <v-divider></v-divider>

          <v-list-item-title class="text-start my-5 font-weight-600">Colors</v-list-item-title>

          <div class="d-flex align-items-center justify-start pb-5">
            <div class="d-flex align-items-center">
              <input type="color" id="bg" name="bg" v-model="collaborativeData.level_1.bg"/>
              <label class="ms-2" for="bg">Background color</label>
            </div>

            <div class="d-flex align-items-center">
              <input type="color" id="font" name="font" v-model="collaborativeData.level_1.fg"/>
              <label class="ms-2" for="font">Font color</label>
            </div>
          </div>

          <v-divider></v-divider>

          <v-list-item-title class="text-start my-5 font-weight-600">
            <span>Item list</span>
          </v-list-item-title>

          <quill-editor
            v-model="collaborativeData.level_1.items"
            :options="editorOption"
            class="o-quill-editor"
            placeholder="Add items"
          />
        </div>

        <div v-if="selectedDropdown === constantValue.WITHOUT_SPOUSE_LEVEL_ONE" class="pa-4">
          <v-text-field
            label="Header"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.level_1.header"
          ></v-text-field>
          <v-text-field
            label="Title"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.level_1.title"
          ></v-text-field>
          <v-text-field
            label="Price"
            prefix="$"
            type="number"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.level_1.price"
          ></v-text-field>
          <v-text-field
            label="Body"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.level_1.body"
          ></v-text-field>
          <v-text-field
            label="Summary"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.level_1.summary"
          ></v-text-field>

          <v-divider></v-divider>

          <v-list-item-title class="text-start my-5 font-weight-600">Colors</v-list-item-title>

          <div class="d-flex align-items-center justify-start pb-5">
            <div class="d-flex align-items-center">
              <input type="color" id="bg" name="bg" v-model="unilateralData.level_1.bg"/>
              <label class="ms-2" for="bg">Background color</label>
            </div>

            <div class="d-flex align-items-center">
              <input type="color" id="font" name="font" v-model="unilateralData.level_1.fg"/>
              <label class="ms-2" for="font">Font color</label>
            </div>
          </div>

          <v-divider></v-divider>

          <v-list-item-title class="text-start my-5 font-weight-600">
            <span>Item list</span>
          </v-list-item-title>

          <quill-editor
            v-model="unilateralData.level_1.items"
            :options="editorOption"
            class="o-quill-editor"
            placeholder="Add items"
          />
        </div>

        <div v-if="selectedDropdown === constantValue.WITH_SPOUSE_LEVEL_TWO" class="pa-4">
          <v-text-field
            label="Header"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.level_2.header"
          ></v-text-field>
          <v-text-field
            label="Title"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.level_2.title"
          ></v-text-field>
          <v-text-field
            label="Price"
            prefix="$"
            type="number"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.level_2.price"
          ></v-text-field>
          <v-text-field
            label="Body"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.level_2.body"
          ></v-text-field>
          <v-text-field
            label="Summary"
            hide-details="auto"
            class="mb-5"
            v-model="collaborativeData.level_2.summary"
          ></v-text-field>

          <v-divider></v-divider>

          <v-list-item-title class="text-start my-5 font-weight-600">Colors</v-list-item-title>

          <div class="d-flex align-items-center justify-start pb-5">
            <div class="d-flex align-items-center">
              <input type="color" id="bg" name="bg" v-model="collaborativeData.level_2.bg"/>
              <label class="ms-2" for="bg">Background color</label>
            </div>

            <div class="d-flex align-items-center">
              <input type="color" id="font" name="font" v-model="collaborativeData.level_2.fg"/>
              <label class="ms-2" for="font">Font color</label>
            </div>
          </div>

          <v-divider></v-divider>

          <v-list-item-title class="text-start my-5 font-weight-600">
            <span>Item list</span>
          </v-list-item-title>

          <quill-editor
            v-model="collaborativeData.level_2.items"
            :options="editorOption"
            class="o-quill-editor"
            placeholder="Add items"
          />
        </div>

        <div v-if="selectedDropdown === constantValue.WITHOUT_SPOUSE_LEVEL_TWO" class="pa-4">
          <v-text-field
            label="Header"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.level_2.header"
          ></v-text-field>
          <v-text-field
            label="Title"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.level_2.title"
          ></v-text-field>
          <v-text-field
            label="Price"
            prefix="$"
            type="number"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.level_2.price"
          ></v-text-field>
          <v-text-field
            label="Body"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.level_2.body"
          ></v-text-field>
          <v-text-field
            label="Summary"
            hide-details="auto"
            class="mb-5"
            v-model="unilateralData.level_2.summary"
          ></v-text-field>

          <v-divider></v-divider>

          <v-list-item-title class="text-start my-5 font-weight-600">Colors</v-list-item-title>

          <div class="d-flex align-items-center justify-start pb-5">
            <div class="d-flex align-items-center">
              <input type="color" id="bg" name="bg" v-model="unilateralData.level_2.bg"/>
              <label class="ms-2" for="bg">Background color</label>
            </div>

            <div class="d-flex align-items-center">
              <input type="color" id="font" name="font" v-model="unilateralData.level_2.fg"/>
              <label class="ms-2" for="font">Font color</label>
            </div>
          </div>

          <v-divider></v-divider>

          <v-list-item-title class="text-start my-5 font-weight-600">
            <span>Item list</span>
          </v-list-item-title>

          <quill-editor
            v-model="unilateralData.level_2.items"
            :options="editorOption"
            class="o-quill-editor"
            placeholder="Add items"
          />
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="dialogAction"
          >
            Save the changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      fixed
      right
      bottom
      tile
      :vertical="true"
      color="white"
    >
      <p class="mb-0 text-danger">
        {{this.priceErrorMsg}}
      </p>
      <p class="text-danger font-weight-600 mb-0">
        Difference is {{this.priceDiff}}
      </p>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackSuccess"
      fixed
      right
      bottom
      tile
      :vertical="true"
      color="green"
    >
      <p class="mb-0">Success!!!</p>
      <p class="mb-0">Pricing cards are updated.</p>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackSuccess = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {quillEditor} from "vue-quill-editor";
import {QUILL_MODULES_PRICING} from "@/constants/optionsConstants";
import {PRICING_CARD_DROPDOWN_OPTIONS} from "@/constants/cardPrices";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'CostInfo',

  components: {
    quillEditor
  },

  data() {
    return {
      dialog: false,
      checkbox: true,
      priceErrorMsg: '',
      priceDiff: 0,
      collaborativeData: {
        WithSpouse: {},
        level_1: {},
        level_2: {},
      },
      unilateralData: {
        WithoutSpouse: {},
        level_1: {},
        level_2: {}
      },
      selectedDropdown: '',
      editorOption: {
        modules: QUILL_MODULES_PRICING,
        placeholder: 'Write here something'
      },
      snackbar: false,
      snackSuccess: false,
    }
  },

  computed: {
    ...mapGetters({
      prices: 'prices/getCardCosts'
    }),
    constantValue() {
      return PRICING_CARD_DROPDOWN_OPTIONS;
    },
  },

  props: {
    price: {
      Type: String,
      required: true
    },
    btnName: {
      Type: String,
      default: 'Select'
    },
    summary: {
      Type: String,
      required: false,
      default: ''
    },
    tab: {
      Type: Object,
      required: true
    },
    items: {
      Type: String,
      require: true
    },
    showSelectBtn: {
      Type: Boolean,
      default: false
    },
    image: {
      Type: String,
      required: false,
      default: ''
    },
    tabItems: [],
    nameProp: '',
    previewChoice: '',
    collaborativePricingLevels: {},
    unilateralPricingLevels: {}
  },

  async mounted() {
    await this.getAllPrices()
  },

  methods: {
    ...mapActions({
      getAllPrices: 'prices/getPrices',
    }),

    selectedValue(data) {
      this.selectedDropdown = data
      if (this.nameProp === 'collaborative') {
        switch (data) {
          case PRICING_CARD_DROPDOWN_OPTIONS.WITH_SPOUSE_LEVEL_ONE:
            this.collaborativeData.level_1 = this.prices[0].level_1;
            break;
          case PRICING_CARD_DROPDOWN_OPTIONS.WITH_SPOUSE_LEVEL_TWO:
            this.collaborativeData.level_2 = this.prices[0].level_2;
            break;
          default:
            this.collaborativeData.WithSpouse = this.prices[0].WithSpouse;
        }
      } else {
        this.selectedDropdown = data
        switch (data) {
          case PRICING_CARD_DROPDOWN_OPTIONS.WITHOUT_SPOUSE_LEVEL_ONE:
            this.unilateralData.level_1 = this.prices[1].level_1;
            break;
          case PRICING_CARD_DROPDOWN_OPTIONS.WITHOUT_SPOUSE_LEVEL_TWO:
            this.unilateralData.level_2 = this.prices[1].level_2;
            break;
          default:
            this.unilateralData.WithoutSpouse = this.prices[1].WithoutSpouse;
        }
      }
    },

    dialogAction() {
      if (this.nameProp === 'collaborative') {
        const totalCol = this.collaborativeData.WithSpouse.price
        const level1Col = this.collaborativeData.level_1.price
        const level2Col = this.collaborativeData.level_2.price
        if ( Number(totalCol) !== Number(level1Col) + Number(level2Col)) {
          this.priceErrorMsg = 'Total price is not equal with level 1 and level 2 prices'
          this.priceDiff = Number(totalCol) - (Number(level1Col) + Number(level2Col))
          this.snackbar = true
        } else {
          this.$emit('dialogAction', {variant: this.nameProp, data: this.collaborativeData})
          this.selectedDropdown = PRICING_CARD_DROPDOWN_OPTIONS.WITH_SPOUSE_GENERAL
          this.priceErrorMsg = ''
          this.priceDiff = 0
          this.dialog = false
          this.snackSuccess = true
        }
      }
      if (this.nameProp === 'unilateral') {
        const totalUni = this.unilateralData.WithoutSpouse.price
        const level1Uni = this.unilateralData.level_1.price
        const level2Uni = this.unilateralData.level_2.price
        if ( Number(totalUni) !== Number(level1Uni) + Number(level2Uni)) {
          this.priceErrorMsg = 'Total price is not equal with level 1 and level 2 prices'
          this.priceDiff = Number(totalUni) - (Number(level1Uni) + Number(level2Uni))
          this.snackbar = true
        } else {
          this.$emit('dialogAction', {variant: this.nameProp, data: this.unilateralData})
          this.selectedDropdown = PRICING_CARD_DROPDOWN_OPTIONS.WITHOUT_SPOUSE_GENERAL
          this.priceErrorMsg = ''
          this.priceDiff = 0
          this.dialog = false
          this.snackSuccess = true
        }
      }
    },
    edit() {
      this.dialog = true;
      if (this.nameProp === 'collaborative') {
        this.collaborativeData.level_1 = this.prices[0].level_1;
        this.collaborativeData.level_2 = this.prices[0].level_2;
        this.collaborativeData.WithSpouse = this.prices[0].WithSpouse;

        switch (this.previewChoice) {
          case PRICING_CARD_DROPDOWN_OPTIONS.WITH_SPOUSE_LEVEL_ONE_PREVIEW:
            this.selectedDropdown = PRICING_CARD_DROPDOWN_OPTIONS.WITH_SPOUSE_LEVEL_ONE;
            this.collaborativeData.level_1 = this.prices[0].level_1;
            break;
          case PRICING_CARD_DROPDOWN_OPTIONS.WITH_SPOUSE_LEVEL_TWO_PREVIEW:
            this.selectedDropdown = PRICING_CARD_DROPDOWN_OPTIONS.WITH_SPOUSE_LEVEL_TWO;
            this.collaborativeData.level_2 = this.prices[0].level_2;
            break;
          default:
            this.selectedDropdown = PRICING_CARD_DROPDOWN_OPTIONS.WITH_SPOUSE_GENERAL;
            this.collaborativeData.WithSpouse = this.prices[0].WithSpouse;
            break;
        }
      } else if (this.nameProp === 'unilateral') {
        this.unilateralData.level_1 = this.prices[1].level_1;
        this.unilateralData.level_2 = this.prices[1].level_2;
        this.unilateralData.WithoutSpouse = this.prices[1].WithoutSpouse;

        switch (this.previewChoice) {
          case PRICING_CARD_DROPDOWN_OPTIONS.WITH_SPOUSE_LEVEL_ONE_PREVIEW:
            this.selectedDropdown = PRICING_CARD_DROPDOWN_OPTIONS.WITHOUT_SPOUSE_LEVEL_ONE;
            this.unilateralData.level_1 = this.prices[1].level_1;
            break;
          case PRICING_CARD_DROPDOWN_OPTIONS.WITHOUT_SPOUSE_LEVEL_TWO_PREVIEW:
            this.selectedDropdown = PRICING_CARD_DROPDOWN_OPTIONS.WITHOUT_SPOUSE_LEVEL_TWO;
            this.unilateralData.level_2 = this.prices[1].level_2;
            break;
          default:
            this.selectedDropdown = PRICING_CARD_DROPDOWN_OPTIONS.WITHOUT_SPOUSE_GENERAL;
            this.unilateralData.WithoutSpouse = this.prices[1].WithoutSpouse;
            break;
        }
      }
    }
  }
}
</script>

<style lang="scss">
.list-frame {
  text-align: left;
  padding-left: 10px;

  p {
    position: relative;
    color: gray;
    margin-top: 10px;
    font-size: 12px;
    margin-bottom: 0;
  }
}
.price-frame {
  position: relative;

  .image {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 70px;
    height: 70px;
    background-color: white;
    border-radius: 25px;
  }
}
</style>
