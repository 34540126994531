const addActionsToTextEditor = (text) => {
  let actionText = [];
  let jsonData = JSON.parse(text);
  const jsonDataSize = jsonData.length;
  for (let k = 0; k < jsonDataSize; k++) {
    switch (jsonData[k].Type) {
      case 'paragraph':
        let data = jsonData[k].Data;
        if (data && data.text) {
          actionText.push(jsonData[k].Data.text + '\n');
        }
        break;
      case 'list':
        let index = 0;
        for (let l = 0; l < jsonData[k].Data.Items.length; l++) {
          if (jsonData[k].Data.Style === 'ordered') {
            index++;
            actionText.push('\t' + index.toString() + ". " + jsonData[k].Data.Items[l] + '\n');
          } else {
            actionText.push('\t' + '· ' + jsonData[k].Data.Items[l] + '\n');
          }
        }
        break;
      case 'header':
        actionText.push(jsonData[k].Data.Text + '\n');
        break;
      case 'table':
        break;
      case 'delimiter':
        actionText.push('\n\n * * * \n\n');
        break;
      case 'log-data':
        actionText.push(jsonData[k].Title);
        break;
      case 'pet-signature-img':
      case 'resp-signature-img':
        actionText.push(jsonData[k]);
        break;
      case 'image-input':
      case 'item-table':
      case 'text-input':
        actionText.push(jsonData[k]);
        break;
    }
  }
  return actionText    
}

module.exports = { addActionsToTextEditor }